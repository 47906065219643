export const checkAppParamsIntegrity = appParams => {
  // Check that it's not null
  if (!appParams) return false
  // Check that it's an object
  if (typeof appParams !== "object") return false
  // Check that is has properties
  if (Object.keys(appParams).length === 0) return false
  // Check that its not having an error
  if (appParams.error) return false

  // Check that parameters are all here !
  if (!appParams.activites) return false
  if (!appParams.columns) return false
  if (!appParams.columnsCategories) return false
  if (!appParams.columnsCompare) return false
  if (!appParams.defaultInventories) return false
  if (!appParams.defaultInventoriesDefinition) return false
  if (!appParams.refQualif) return false

  return true
}
