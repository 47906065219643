import { formatDate } from "../../functions/formatDate"
import { todayDate } from "../../functions/todayDate"
import "./MyCheptel.scss"
import { dateWritten } from "../../functions/dateWritten"
import {
  defaultInventoriesDefinition,
  getDefaultInventories,
} from "../../functions/objectColums"

const MyCheptel = ({ data }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let dateAff = window.localStorage.dataSuffix ?? ""
  let timeAff = ""
  if (dateAff === "") {
    let numCheptel = window.localStorage.numCheptel ?? ""
    let dateOfLatestData =
      localStorage.getItem("dateOfLatestData" + numCheptel) ?? null

    if (dateOfLatestData) {
      dateAff = todayDate("displaytime", dateOfLatestData)
    } else dateAff = todayDate("displaytime")

    timeAff = dateAff.substring(10)
  }
  dateAff = dateWritten(dateAff)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  // This function will reset all the state parameters for inventories
  // The app navigation have Cheptel page -> inventory page
  // While navigating inside inventory page or in forward pages
  // like animal fiche, etc..., we save the inventories state
  // ex: sort/position in the table/etc..
  // if you go before inventory page (cheptel page for example)
  // then going back to inventories, we reset the state
  const moveToInv = () => {
    let defaultsInv = getDefaultInventories()
    let baseInv = defaultInventoriesDefinition()
    let orderedList = JSON.parse(localStorage.orderedList ?? "[]")
    const listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")

    for (let id in defaultsInv) {
      if (!orderedList.includes(id)) orderedList.push(id)
    }

    if (orderedList[0]) {
      if (orderedList[0].includes("custom"))
        window.localStorage.choixInv = orderedList[0]
      else window.localStorage.choixInv = defaultsInv[+orderedList[0]].dataInv
    } else window.localStorage.choixInv = "genealogie"

    // Reset for base inventories of app
    for (let index in baseInv) {
      let inv = baseInv[index]
      window.localStorage.setItem("customsort" + inv.num, inv.defaultSort)
      window.localStorage.setItem("customsortvisual" + inv.num, inv.defaultSort)
      window.localStorage.setItem("customsens" + inv.num, "up")
      window.localStorage.setItem("scrollX" + inv.num, 0)
      window.localStorage.setItem("scrollY" + inv.num, 0)
    }

    // Reset for custom inventories
    Object.keys(listCustInv).forEach(num => {
      let inv = listCustInv[num]
      let defaultSort = "numtravail"
      if (inv.action === "confirm") defaultSort = "colConfirm"
      if (inv.action === "declar") defaultSort = "colDeclar"
      if (inv.action === "compare") defaultSort = "colCompare"

      window.localStorage.setItem("customsort" + num, defaultSort)
      window.localStorage.setItem("customsortvisual" + num, defaultSort)
      window.localStorage.setItem("customsens" + num, "up")
      window.localStorage.setItem("scrollX" + "custom" + num, 0)
      window.localStorage.setItem("scrollY" + "custom" + num, 0)
    })

    window.localStorage.tmpListe = JSON.stringify([])
    window.localStorage.liveFilters = JSON.stringify({})

    window.location.hash = "inventaire"
  }
  const goToSection = section => {
    let hSection = document.getElementById(section)
    let pageCheptel = document.getElementById("wrap_page_cheptel")

    if (!hSection || !pageCheptel) return

    var rect = hSection.getBoundingClientRect()

    pageCheptel.scroll({
      top: rect.top - 70,
      left: rect.left,
      behavior: "smooth",
    })
  }

  return (
    <>
      <div className="MyCheptel">
        <div className="left_column">
          <h2>Mon inventaire</h2>
          <p className="textDate">
            au {dateAff}
            {timeAff}
          </p>
          <div className="left_bottom">
            <span className="nbmale">
              {data.nbMale}{" "}
              {(() => {
                if (data.nbMale === 1) return "mâle"

                return "mâles"
              })()}
            </span>
            <span className="nbfemale">
              {data.nbFemale}{" "}
              {(() => {
                if (data.nbFemale === 1) return "femelle"

                return "femelles"
              })()}
            </span>
            <span
              className="ivv-average pointer ripple2"
              onClick={() => goToSection("title_graphivv")}
            >
              IVV moy : {data.ivvMoyen} jrs
            </span>
            <span
              className="nbanimalindex pointer ripple2"
              onClick={() => goToSection("title_iboval")}
            >
              {data.nbAnimalIndexes} indexés
            </span>
          </div>
        </div>
        <div className="right_column">
          <p className="nbanimal">{data.nbAnimal}</p>
          <p className="nbanimal-text">
            {(() => {
              if (data.nbAnimal === 1) return "animal"
              else return "animaux"
            })()}
          </p>

          <button onClick={moveToInv} className="btn_inventaire ripple6">
            Voir mes animaux
          </button>
        </div>
      </div>
    </>
  )
}

export default MyCheptel
