import ModalLoader from "../components/ModalLoader"
import { fetchIsAdmin } from "../functions/fetches/fetchIsAdmin"
import { fetchLogin } from "../functions/fetches/fetchLogin"
import "./Login.scss"
import localforage from "localforage"
import { useEffect, useRef, useState } from "react"
import logo from "../assets/images/logo.png"
import { fetchLogConnec } from "../functions/fetches/fetchLogConnec"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import { fetchGetCustomInv } from "../functions/fetches/fetchGetCustomInv"
import { fetchGetOrderInv } from "../functions/fetches/fetchGetOrderInv"
import { fetchHighScore } from "../functions/fetches/fetchHighScore"
import ModalRegister from "../components/login/ModalRegister"
import ModalReinit from "../components/login/ModalReinit"

const Login = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const centerModal = useRef()

  const [errorMessage, setErrorMessage] = useState("") // Login error message
  const [isLogged, setIsLogged] = useState(false)
  const [showPass, setShowPass] = useState(false)

  const [modalReinit, setModalReinit] = useState(false)
  pageOption === "reinit" && !modalReinit && setModalReinit(true)
  pageOption !== "reinit" && modalReinit && setModalReinit(false)

  const [modalRegister, setModalRegister] = useState(false)
  pageOption === "register" && !modalRegister && setModalRegister(true)
  pageOption !== "register" && modalRegister && setModalRegister(false)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    if (isLogged) window.location.reload()
  }, [isLogged])
  useEffect(() => {
    const resizeLogo = () => {
      let imgLogo = document.getElementById("logo_login")
      let formLogin = document.getElementById("formlogin")

      if (imgLogo && formLogin) {
        var rect = formLogin.getBoundingClientRect()
        let topVal = Math.ceil(rect.top)
        let dixpourcent = topVal * 0.1

        imgLogo.style.top = dixpourcent + "px"
        imgLogo.style.height = topVal - 2 * dixpourcent + "px"
      }

      if (typeof centerModal.current === "function") centerModal.current()
    }

    window.onresize = resizeLogo

    resizeLogo()

    return () => {
      window.onresize = null
    }
  }, [])

  ///////////////
  // Functions //
  ///////////////
  const handleSubmit = async e => {
    e.preventDefault()

    let divModal = document.getElementById("loader_login")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let login = e.target[0].value
    let pass = e.target[1].value

    let data = await fetchLogin(login, pass, "O")

    // If login is successful
    if (data && data.reponse === "ok") {
      window.localStorage.dataReturnA = data.a
      window.localStorage.dataReturnB = data.b
      window.localStorage.personne = data.p
      window.localStorage.highScore = data.h
      window.localStorage.monthHighScore = data.mh
      window.localStorage.JWT_TOKEN = data.token

      let ret2 = await fetchLogConnec("L")

      if (ret2 === "true") localStorage.lastLog = Date.now()

      let highScores = await fetchHighScore()
      window.localStorage.highScore = highScores.h
      window.localStorage.monthHighScore = highScores.mh
      window.localStorage.monthOfScore = highScores.mois

      let custInv = await fetchGetCustomInv()
      if (typeof custInv !== "object") custInv = {}

      window.localStorage.listCustInv = JSON.stringify(custInv ?? "{}")
      let ordInv = await fetchGetOrderInv()
      if (!Array.isArray(ordInv)) ordInv = []

      window.localStorage.orderedList = JSON.stringify(ordInv ?? "[]")

      let isAdmin = await fetchIsAdmin()

      if ("ReactNativeWebView" in window) {
        let message = {
          type: "loginData",
          jwt: data.token,
          appVersion: window.localStorage.app_version,
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      }

      localforage.setItem("login", true)
      setIsLogged(true)

      let isOp = false

      if (data.t === "op") isOp = true

      if (isAdmin) {
        window.localStorage.valA = true
        window.localStorage.valO = false
        window.location.hash = "admin"
      } else if (isOp) {
        window.localStorage.adherents = JSON.stringify(data?.adh ?? "[]")
        window.localStorage.droitsOP = JSON.stringify(data?.droits_op ?? "{}")
        window.localStorage.nom_op = data?.nom_op ?? ""

        window.localStorage.valA = false
        window.localStorage.valO = true
        window.location.hash = "op"
      } else {
        window.localStorage.valA = false
        window.localStorage.valO = false
        window.localStorage.numCheptel = data.ch
        window.location.hash = "loadata"
      }

      if (data.testAccount && data.testAccount === "oui") {
        window.localStorage.isTestAccount = "oui"
      }
    }

    //Eror message generation
    if (data.reponse === "vide")
      setErrorMessage(
        "Vous devez renseigner votre identifiant et votre mot de passe."
      )
    if (data.reponse === "non")
      setErrorMessage(
        "Merci de vérifier votre identifiant et votre mot de passe."
      )

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  // Show reinit password modal
  const showReinitModal = () => (window.location.hash = "login_reinit")
  // Show register account form/modal
  const showRegisterForm = () => {
    window.location.hash = "login_register"
  }
  const showHidePass = () => {
    setShowPass(prev => !prev)
  }
  const goDeleteAccountPage = () => (window.location.hash = "supprcompte|inApp")

  return (
    <div className="login">
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_login"
        message="Connexion en cours."
        parentCenterModal={centerModal}
      />
      <ModalLoader
        id="laoder_reinit_pass"
        message="Vérification en cours..."
        parentCenterModal={centerModal}
      />
      <ModalLoader
        id="loader_register"
        message="Inscription en cours."
        parentCenterModal={centerModal}
      />

      <img src={logo} className="logo_login" id="logo_login" alt="" />
      <div className="formlogin" id="formlogin">
        <h2 className="loginTitle">CONNEXION</h2>
        <form onSubmit={event => handleSubmit(event)}>
          <p className="errorMessage">{errorMessage}</p>
          <input
            id="loginId"
            name="loginId"
            type="text"
            className="connectInput first"
            placeholder="Numéro de cheptel (ex : FR12345678)"
          />
          <div className="div_pass">
            <input
              id="loginPass"
              name="loginPass"
              type={showPass ? "text" : "password"}
              className="connectInput second"
              placeholder="Votre mot de passe"
            />
            <div
              className="icon_visibility pointer ripple2"
              onClick={showHidePass}
            >
              {showPass ? (
                <span className="material-symbols-outlined">
                  visibility_off
                </span>
              ) : (
                <span className="material-symbols-outlined">visibility</span>
              )}
            </div>
          </div>
          <div className="line_links_login">
            <a
              className="login_links pointer ripple2"
              onClick={showRegisterForm}
            >
              Créer un compte
            </a>
            <a
              className="login_links pass_forgotten pointer ripple2"
              onClick={showReinitModal}
            >
              Mot de passe oublié ?
            </a>
          </div>
          <button type="submit" className="connectButton">
            Connexion
          </button>
        </form>
        <a
          className="delete_link pointer ripple2"
          onClick={goDeleteAccountPage}
        >
          Supprimer mon compte
        </a>
      </div>
      {modalReinit && <ModalReinit />}
      {modalRegister && <ModalRegister />}
    </div>
  )
}

export default Login
