import { formatDate2 } from "../../functions/formatDate"
import { getColumns } from "../../functions/objectColums"
import FilterList from "../FilterList"
import "./SideFilters.scss"

const SideFilters = ({
  sidePanel,
  inventory,
  filters,
  setFilters,
  allData,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const columnsAvailable = getColumns()

  let confirmation = inventory?.action === "confirm" // Filtre confirm si inventaire avec confirmation
  let declarations = inventory?.action === "declar" // Filtre declarations si inventaire avec declarations

  let fltrs = structuredClone(inventory.filters)

  if (declarations) {
    fltrs["SEXBOV"] = {
      value: "1",
    }
  }

  // Filter data
  let analysesFilter = JSON.parse(window.localStorage.analyseFilter)
  let confirmFilter = JSON.parse(window.localStorage.confirmFilter)

  let invNum = inventory.num

  let fullFilters = {}
  for (let filter in inventory?.filters) {
    fullFilters[filter] = structuredClone(inventory?.filters[filter])
    fullFilters[filter].locked = true
  }
  for (let filter in filters[inventory.num]) {
    fullFilters[filter] = filters[inventory.num][filter]
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const setFiltersIntermediate = fltrs => {
    let newFilters = {}
    for (let filter in fltrs) {
      if (!fltrs[filter].locked) {
        if (!newFilters[inventory.num]) newFilters[inventory.num] = {}

        newFilters[inventory.num][filter] = fltrs[filter]
      }
    }

    setFilters(newFilters)
  }

  return (
    <div
      className={
        !sidePanel ? "side_panel_filters notShown" : "side_panel_filters"
      }
    >
      <div className="title_panel_filter">
        Filtres{" "}
        <span
          className="close_filters ripple2  material-symbols-outlined"
          onClick={() => {
            document
              .getElementById("div_inventaire")
              .dispatchEvent(new CustomEvent("sideFilters"))
          }}
        >
          close
        </span>
      </div>
      <div className="panel_filter">
        {fltrs && Object.keys(fltrs).length !== 0 && (
          <div className="filters_predifined">
            <p>Filtres prédéfinis dans cet inventaire :</p>
            <ul>
              {Object.keys(fltrs).map(col => {
                let name = columnsAvailable?.[col]?.name
                if (col === "confirmation") name = "Confirmations"
                if (col === "analyses") name = "Analyses"
                if (col === "prelevements") name = "Prélèvements"

                let type = columnsAvailable?.[col]?.type
                let val = ""

                let tCol = col
                if (col === "TYPANA") tCol = "ADN_filter"
                if (col === "CORDCG") tCol = "filiation_filter"
                if (col === "IDLABO") tCol = "Evalim_filter"
                if (col === "labo") tCol = "NOORGA"
                if (col === "COIDEN") tCol = "ident_adn_filter"

                switch (col) {
                  case "confirmation":
                    val = fltrs?.confirmation?.value
                      ? fltrs?.confirmation?.value === "nbGenissesToExamine"
                        ? `Génisses à examiner (${confirmFilter.nbGenissesToExamine})`
                        : fltrs?.confirmation?.value === "nbGenissesToConfirm"
                        ? `Génisses à confirmer (${confirmFilter.nbGenissesToConfirm})`
                        : fltrs?.confirmation?.value === "nbVachesToConfirm"
                        ? `Vaches à confirmer (${confirmFilter.nbVachesToConfirm})`
                        : fltrs?.confirmation?.value === "nbFemP6MNnInscrite"
                        ? `Femelles +6 mois non inscrites HBL (${confirmFilter.nbFemP6MNnInscrite})`
                        : fltrs?.confirmation?.value === "nbMaleToExamine"
                        ? `Mâles 6-18 mois à examiner (${confirmFilter.nbMaleToExamine})`
                        : fltrs?.confirmation?.value === "nbMalP6MNnInscrit"
                        ? `Mâles +6 mois non inscrits HBL (${confirmFilter.nbMalP6MNnInscrit})`
                        : fltrs?.confirmation?.value === "nbMaleToConfirm" &&
                          `Mâles à confirmer (${confirmFilter.nbMaleToConfirm})`
                      : "Aucun filtre"
                    break
                  case "analyses":
                    val = fltrs?.analyses?.value
                      ? fltrs.analyses.value === "needAnalyse"
                        ? `Analyse à faire (${
                            analysesFilter?.needAnalyse ?? 0
                          })`
                        : fltrs.analyses.value === "hasIncompatibility"
                        ? `Avec incompatibilité (${
                            analysesFilter?.hasIncompatibility ?? 0
                          })`
                        : fltrs.analyses.value === "noCompatibilityPere"
                        ? `Sans compatibilité de Père (${
                            analysesFilter?.maleSansCompatPere ?? 0
                          })`
                        : fltrs.analyses.value === "noAnalyseMh"
                        ? `Sans analyse MH (${analysesFilter?.maleSansMh ?? 0})`
                        : fltrs.analyses.value === "noProfilAdn" &&
                          `Sans profil ADN (${
                            analysesFilter?.maleSansProfilAdn ?? 0
                          })`
                      : "Aucun filtre"
                    break
                  case "prelevements":
                    val = fltrs?.prelevements?.value
                      ? fltrs?.prelevements?.value === "all"
                        ? "Tous les prélèvements"
                        : fltrs?.prelevements?.value === "inventory" &&
                          "Animaux à mon inventaire"
                      : "Aucun filtre"
                    break
                  case "SEXBOV":
                    if (fltrs[col]?.value === "1") val = "Mâle"
                    else val = "Femelle"
                    break
                  case "CORABO":
                  case "COV1VE":
                  case "qualifAnimal":
                  case "qualifAnimalLIBELO":
                  case "qualifAnimalLIDETA":
                  case "catasc":
                  case "borexa_coreex":
                  case "examAni":
                  case "examAniLIBELO":
                  case "examAniLIDETA":
                  case "inscrAnimal":
                  case "inscrAnimalLIBELO":
                  case "inscrAnimalLIDETA":
                  case "inscrPere":
                  case "inscrPereLIBELO":
                  case "inscrPereLIDETA":
                  case "qualifPere":
                  case "qualifPereLIBELO":
                  case "qualifPereLIDETA":
                  case "inscrMere":
                  case "inscrMereLIBELO":
                  case "inscrMereLIDETA":
                  case "qualifMere":
                  case "qualifMereLIBELO":
                  case "qualifMereLIDETA":
                  case "TYPANA":
                  case "CORDCG":
                  case "retyupsc":
                  case "retyupmh":
                  case "retyuppaf":
                  case "paf_filter":
                  case "retyuptrtxt":
                  case "IDLABO":
                  case "labo":
                  case "COIDEN":
                  case "retyupPere":
                  case "coreex_exa_pere":
                  case "exam_pere_txt":
                  case "examPereLIBELO":
                  case "examPereLIDETA":
                  case "coreex_exa_mere":
                  case "exam_mere_txt":
                  case "examMereLIBELO":
                  case "examMereLIDETA":
                  case "inscrGPP":
                  case "inscrGPPLIBELO":
                  case "inscrGPPLIDETA":
                  case "qualifGPP":
                  case "qualifGPPLIBELO":
                  case "qualifGPPLIDETA":
                  case "inscrPGPP":
                  case "inscrPGPPLIBELO":
                  case "inscrPGPPLIDETA":
                  case "qualifPGPP":
                  case "qualifPGPPLIBELO":
                  case "qualifPGPPLIDETA":
                  case "inscrMGPP":
                  case "inscrMGPPLIBELO":
                  case "inscrMGPPLIDETA":
                  case "qualifMGPP":
                  case "qualifMGPPLIBELO":
                  case "qualifMGPPLIDETA":
                  case "inscrGMP":
                  case "inscrGMPLIBELO":
                  case "inscrGMPLIDETA":
                  case "qualifGMP":
                  case "qualifGMPLIBELO":
                  case "qualifGMPLIDETA":
                  case "inscrPGMP":
                  case "inscrPGMPLIBELO":
                  case "inscrPGMPLIDETA":
                  case "qualifPGMP":
                  case "qualifPGMPLIBELO":
                  case "qualifPGMPLIDETA":
                  case "inscrMGMP":
                  case "inscrMGMPLIBELO":
                  case "inscrMGMPLIDETA":
                  case "qualifMGMP":
                  case "qualifMGMPLIBELO":
                  case "qualifMGMPLIDETA":
                  case "inscrGPM":
                  case "inscrGPMLIBELO":
                  case "inscrGPMLIDETA":
                  case "qualifGPM":
                  case "qualifGPMLIBELO":
                  case "qualifGPMLIDETA":
                  case "inscrPGPM":
                  case "inscrPGPMLIBELO":
                  case "inscrPGPMLIDETA":
                  case "qualifPGPM":
                  case "qualifPGPMLIBELO":
                  case "qualifPGPMLIDETA":
                  case "inscrMGPM":
                  case "inscrMGPMLIBELO":
                  case "inscrMGPMLIDETA":
                  case "qualifMGPM":
                  case "qualifMGPMLIBELO":
                  case "qualifMGPMLIDETA":
                  case "inscrGMM":
                  case "inscrGMMLIBELO":
                  case "inscrGMMLIDETA":
                  case "qualifGMM":
                  case "qualifGMMLIBELO":
                  case "qualifGMMLIDETA":
                  case "inscrPGMM":
                  case "inscrPGMMLIBELO":
                  case "inscrPGMMLIDETA":
                  case "qualifPGMM":
                  case "qualifPGMMLIBELO":
                  case "qualifPGMMLIDETA":
                  case "inscrMGMM":
                  case "inscrMGMMLIBELO":
                  case "inscrMGMMLIDETA":
                  case "qualifMGMM":
                  case "qualifMGMMLIBELO":
                  case "qualifMGMMLIDETA":
                  case "CIFNAI":
                  case "CCRSEV":
                  case "CDMSEV":
                  case "CDSSEV":
                  case "CFOSSE":
                  case "CAVELS":
                  case "CALAIT":
                  case "COPSEV":
                    let valsDisplay = ""
                    fltrs[tCol]?.value?.forEach(
                      val => (valsDisplay += val ? val + ", " : "Aucun(e), ")
                    )

                    valsDisplay = valsDisplay.replace(/,\s*$/, "")
                    val = valsDisplay
                    break
                  default:
                    if (type === "number") {
                      let min = fltrs[col]?.min
                      let max = fltrs[col]?.max

                      if (min && max) val = `entre ${min} et ${max}`
                      else if (min && !max) val = "minimum " + min
                      else if (!min && max) val = "maximum " + max
                    } else if (type === "date") {
                      let min = fltrs[col]?.min
                      let max = fltrs[col]?.max

                      if (min) min = formatDate2(min)
                      if (max) max = formatDate2(max)

                      if (min && max) val = `du ${min} au ${max}`
                      else if (min && !max) val = "après le " + min
                      else if (!min && max) val = "avant le " + max
                    } else {
                      val = fltrs[col]?.value
                    }
                }

                return (
                  <li key={col}>
                    {name} : {val}
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        <FilterList
          inventory={inventory}
          filters={fullFilters}
          setFilters={setFiltersIntermediate}
          allData={allData}
          origin="side_inventaire"
        />
      </div>
    </div>
  )
}

export default SideFilters
