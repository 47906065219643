import { useState } from "react"
import { getDateWritten } from "../../functions/getDateWritten"
import { getParamsActivities, getRFQUAL } from "../../functions/objectColums"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import ModalLoader from "../ModalLoader"
import "./DetailsVisite.scss"
import ModalListePdf from "./ModalListePdf"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"

let clickHeld = false
let hasMoved = false
let mouseX = 0
let mouseY = 0
let originMouseX = 0
let originMouseY = 0

const DetailsVisite = ({ data, page }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  let activites = getParamsActivities()
  let indexVisite = window.location.hash.split("|")[1] ?? 0
  let codeActivite = window.location.hash.split("|")[2] ?? ""
  let paramsListe = window.location.hash.split("|")[3] ?? ""
  let numCheptel = window.localStorage.numCheptel ?? ""
  let year = data.dateVisite.substring(0, 4)
  let month = data.dateVisite.substring(5, 7)
  let campagne = ""
  let early = month > 7 ? true : false

  if (early) campagne = `${year}/${+year + 1}`
  else campagne = `${+year - 1}/${year}`

  let dateVisite = data.dateVisite.split(" ")
  let heureVisite = dateVisite[1].substring(0, 5)
  let dateInp =
    dateVisite[0].substr(0, 4) +
    dateVisite[0].substr(5, 2) +
    dateVisite[0].substr(8, 2)

  let indexActivite
  activites.activites.forEach((acti, index) => {
    if (acti.codeActivite === codeActivite) {
      indexActivite = index
    }
  })

  let indexPtgeA
  let indexPtgeS
  activites.activites.forEach((acti, index) => {
    if (acti.codeActivite === "PTGE_A") indexPtgeA = index
    if (acti.codeActivite === "PTGE_S") indexPtgeS = index
  })

  let listePdfs = []

  for (let codeActivite in data.postes) {
    switch (codeActivite) {
      case "DGLOC":
        listePdfs.push("f_sabots")
        break
      case "CERTIF":
        listePdfs.push("f_ins")
        break
      case "QUALIF":
        listePdfs.push("exam_qualif")
        break
      case "TRI":
        listePdfs.push("tri_hbl")
        break
      case "PELV2":
        listePdfs.push("f_op")
        break
      case "DG":
        listePdfs.push("f_pm")
        break
      case "PTGE_A":
        listePdfs.push("ptgeA_col")
        listePdfs.push("f_el_ptgeA")
        break
      case "PTGE_S":
        listePdfs.push("ptgeS_col")
        listePdfs.push("f_el_ptgeS")
        break

      default:
        break
    }
  }

  let arrAni = Object.keys(data.postes[codeActivite])
    .map(numAni => numAni)
    .sort((a, b) =>
      +data.postes[codeActivite][a].numTravail >
      +data.postes[codeActivite][b].numTravail
        ? 1
        : +data.postes[codeActivite][a].numTravail <
          +data.postes[codeActivite][b].numTravail
        ? -1
        : 0
    )

  const [animaux, setAnimaux] = useState(arrAni)
  const [clSort, setClSort] = useState("animal")
  const [sensSort, setSensSort] = useState("")

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const getPdf = async (link, name) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let isOnline = await checkInternet()

    if (isOnline) {
      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      )
    }
  }
  const onClickPdfLink = async e => {
    e.target.disabled = true

    e.preventDefault()

    if (codeActivite === "PTGE_A" || codeActivite === "PTGE_S") {
      window.location.hash = `${page}_details|${indexVisite}|${codeActivite}|liste`
    } else {
      let pdfToDl

      switch (codeActivite) {
        case "DGLOC":
          pdfToDl = "f_sabots"
          break
        case "CERTIF":
          pdfToDl = "f_ins"
          break
        case "QUALIF":
          pdfToDl = "exam_qualif"
          break
        case "QUALIF2":
          pdfToDl = "exam_qualif2"
          break
        case "TRI":
          pdfToDl = "tri_hbl"
          break
        case "PELV2":
          pdfToDl = "f_op"
          break
        case "DG":
          pdfToDl = "f_pm"
          break

        default:
          break
      }

      let date = data.dateVisite.substr(0, 10)

      let cSplit = campagne.split("/")

      let ts = Math.round(Date.now() / 1000)
      let name = `${pdfToDl}_${numCheptel}_${date}_${ts}`
      let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${pdfToDl}/${data.idVisite}/${name}.pdf`

      getPdf(link, name)
    }

    e.target.disabled = false
  }
  const dlPdfPTGEAIndiv = numAni => {
    let date = data.dateVisite.substr(0, 10)

    let pdfToDl = "ptgeA_ind"
    let ts = Math.round(Date.now() / 1000)
    let name = `${pdfToDl}_${numCheptel}_${date}_${ts}`
    let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf_indiv/${pdfToDl}/${numAni}/${name}.pdf`

    getPdf(link, name)
  }
  const sorAnimaux = col => {
    let sens = sensSort

    if (sens === "up") {
      sens = "down"
    } else {
      sens = "up"
    }

    let datatmp = structuredClone(animaux)

    if (col === "animal") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +data.postes[codeActivite][a].numTravail >
          +data.postes[codeActivite][b].numTravail
            ? 1
            : +data.postes[codeActivite][a].numTravail <
              +data.postes[codeActivite][b].numTravail
            ? -1
            : 0
        )
      } else {
        datatmp.sort((b, a) =>
          +data.postes[codeActivite][a].numTravail >
          +data.postes[codeActivite][b].numTravail
            ? 1
            : +data.postes[codeActivite][a].numTravail <
              +data.postes[codeActivite][b].numTravail
            ? -1
            : 0
        )
      }
    } else if (col === "dmaj") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +data.postes[codeActivite][a].dMaj.replace(/\D/g, "") >
          +data.postes[codeActivite][b].dMaj.replace(/\D/g, "")
            ? 1
            : +data.postes[codeActivite][a].dMaj.replace(/\D/g, "") <
              +data.postes[codeActivite][b].dMaj.replace(/\D/g, "")
            ? -1
            : 0
        )
      } else {
        datatmp.sort((b, a) =>
          +data.postes[codeActivite][a].dMaj.replace(/\D/g, "") >
          +data.postes[codeActivite][b].dMaj.replace(/\D/g, "")
            ? 1
            : +data.postes[codeActivite][a].dMaj.replace(/\D/g, "") <
              +data.postes[codeActivite][b].dMaj.replace(/\D/g, "")
            ? -1
            : 0
        )
      }
    } else {
      let typeDonnee = activites.postes[codeActivite][+col].typeDonnee

      if (typeDonnee === "T") {
        const frCollator = new Intl.Collator("fr")

        if (sens === "up") {
          datatmp.sort((a, b) =>
            frCollator.compare(
              data.postes[codeActivite][a][+col].valeur,
              data.postes[codeActivite][b][+col].valeur
            )
          )
        } else {
          datatmp.sort((b, a) =>
            frCollator.compare(
              data.postes[codeActivite][a][+col].valeur,
              data.postes[codeActivite][b][+col].valeur
            )
          )
        }
      } else if (typeDonnee === "N") {
        if (sens === "up") {
          datatmp.sort((a, b) =>
            +data.postes[codeActivite][a][+col].valeur >
            +data.postes[codeActivite][b][+col].valeur
              ? 1
              : +data.postes[codeActivite][a][+col].valeur <
                +data.postes[codeActivite][b][+col].valeur
              ? -1
              : 0
          )
        } else {
          datatmp.sort((b, a) =>
            +data.postes[codeActivite][a][+col].valeur >
            +data.postes[codeActivite][b][+col].valeur
              ? 1
              : +data.postes[codeActivite][a][+col].valeur <
                +data.postes[codeActivite][b][+col].valeur
              ? -1
              : 0
          )
        }
      }
    }

    setAnimaux(datatmp)
    setClSort(col)
    setSensSort(sens)
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Methods to handle moving the table by holding the mouse (kinda like touch behaviour but with a mouse) //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const tbodyMouseDown = e => {
    if (e.button !== 0) return

    clickHeld = true
    let divInv = document.getElementById("div_table_recap")
    if (!isMobile) divInv.classList.add("cursor_grab")

    mouseX = e.nativeEvent.pageX
    mouseY = e.nativeEvent.pageY
    originMouseX = e.nativeEvent.pageX
    originMouseY = e.nativeEvent.pageY
  }
  const tbodyMouseMove = e => {
    if (clickHeld) {
      let divInv = document.getElementById("div_table_recap")
      divInv.scrollBy(
        mouseX - e.nativeEvent.pageX,
        mouseY - e.nativeEvent.pageY
      )

      if (Math.abs(originMouseX - e.nativeEvent.pageX) > 10) hasMoved = true
      if (Math.abs(originMouseY - e.nativeEvent.pageY) > 10) hasMoved = true

      mouseX = e.nativeEvent.pageX
      mouseY = e.nativeEvent.pageY
    }
  }
  const tbodyMouseUp = e => {
    if (clickHeld) {
      let divInv = document.getElementById("div_table_recap")
      if (!isMobile) divInv.classList.remove("cursor_grab")
      clickHeld = false
      setTimeout(() => {
        hasMoved = false
      }, 0)
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let rfqual = getRFQUAL()

  let definitionsPostes = activites.postes[codeActivite]
  let postesAnimaux = data.postes[codeActivite]

  if (codeActivite === "QUALIF2" || codeActivite === "QUALIF") {
    definitionsPostes = {
      1: {
        libelleCourt: "Qualif.",
      },
      2: {
        libelleCourt: "Libelle qualif",
      },
    }

    let formatedPostes = {}

    animaux.forEach(numAni => {
      let sexbov = postesAnimaux[numAni].sexe
      let valQual = postesAnimaux[numAni][1].valeur
      let libQual = ""
      let prefix = ""

      if (valQual === "R") {
        valQual = "Refusé"
        if (sexbov === "2") valQual += "e"
      } else {
        let third = valQual.substr(2, 1)
        if (third === "B") {
          prefix = "Bloqué"
          if (sexbov === "2") prefix += "e"
          prefix += " "
        }

        let code = valQual.substr(0, 2)
        valQual = rfqual[sexbov][3][code][""].LIBECO
        libQual = rfqual[sexbov][3][code][""].LIBELO
      }

      if (postesAnimaux)
        formatedPostes[numAni] = {
          1: {
            valeur: prefix + valQual,
          },
          2: {
            valeur: libQual,
          },
        }
    })

    postesAnimaux = formatedPostes
  }
  if (codeActivite === "CERTIF") {
    definitionsPostes = {
      1: {
        libelleCourt: "Résultat",
      },
      2: {
        libelleCourt: "Certif.",
      },
      3: {
        libelleCourt: "Libelle certif",
      },
      4: {
        libelleCourt: "Classement",
      },
    }

    let formatedPostes = {}

    animaux.forEach(numAni => {
      let sexbov = postesAnimaux[numAni].sexe
      let codeCertif = postesAnimaux[numAni][1]?.valeur ?? null
      let resultat = postesAnimaux[numAni][2]?.valeur ?? null
      let refus = postesAnimaux[numAni][3]?.valeur ?? null
      let classement = postesAnimaux[numAni][35]?.valeur ?? null
      let valQual = ""
      let libQual = ""

      let coqubo = codeCertif.substr(1)
      let ccqubo = codeCertif.substr(0, 1)

      valQual = rfqual[sexbov][1]?.[coqubo]?.[ccqubo].LIBECO
      libQual = rfqual[sexbov][1]?.[coqubo]?.[ccqubo].LIBELO

      if (resultat === "C") {
        resultat = "Examiné"
        if (sexbov === "2") resultat += "e"
        resultat += " conforme"
      } else if (resultat === "1") {
        resultat = "Confirmé"
        if (sexbov === "2") resultat += "e"
      } else if (resultat === "Q") {
        resultat = "Certifié"
        if (sexbov === "2") resultat += "e"
      } else if (resultat === "A") {
        resultat = "Ajourné"
        if (sexbov === "2") resultat += "e"
      } else if (resultat === "R" || refus) {
        resultat = "Refusé"
        if (sexbov === "2") resultat += "e"
      }

      if (postesAnimaux)
        formatedPostes[numAni] = {
          1: {
            valeur: resultat,
          },
          2: {
            valeur: valQual,
          },
          3: {
            valeur: libQual,
          },
          4: {
            valeur: classement,
          },
        }
    })

    postesAnimaux = formatedPostes
  }

  return (
    <>
      <ModalLoader id="loader_dlmydocs" message="Téléchargement en cours..." />
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {paramsListe !== "" && (
        <ModalListePdf
          codeActivite={activites.activites[indexActivite].codeActivite}
          nomActivite={activites.activites[indexActivite].nom}
          idVisite={data.idVisite}
          dateVisite={data.dateVisite}
          numCheptel={numCheptel}
          campagne={campagne}
          paramsListe={paramsListe}
          listePdfs={listePdfs}
          indexPtgeS={indexPtgeS}
          page={page}
        />
      )}

      <div className="div_raison_sociale_details">
        <p className="modal_visit_name">{`${data?.prenomPointeur ?? ""} ${
          data?.nomPointeur ?? ""
        }`}</p>
        <p className="modal_visit_name">{`${getDateWritten(dateInp)}`}</p>
        <button
          className="button_pdf rapport_all pointer"
          onClick={onClickPdfLink}
        >
          Rapport PDF
        </button>
      </div>
      <div id="div_table_recap" className="div_table_recap">
        <table className="table_recap">
          <thead>
            <tr>
              <th
                className={
                  clSort === "animal"
                    ? sensSort === "up"
                      ? "sortDown nowrap"
                      : "sortUp nowrap"
                    : "nowrap"
                }
                onClick={() => sorAnimaux("animal")}
              >
                Numéro animal
              </th>
              {codeActivite === "PTGE_A" && <th className="nowrap">PDF</th>}
              {definitionsPostes &&
                Object.keys(definitionsPostes).map(indiceChamp => (
                  <th
                    className={
                      clSort === `${indiceChamp}`
                        ? sensSort === "up"
                          ? "sortDown nowrap"
                          : "sortUp nowrap"
                        : "nowrap"
                    }
                    key={indiceChamp}
                    onClick={() => sorAnimaux(`${indiceChamp}`)}
                  >
                    {definitionsPostes[indiceChamp].libelleCourt}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody
            onMouseDown={tbodyMouseDown}
            onMouseUp={tbodyMouseUp}
            onMouseMove={tbodyMouseMove}
            onMouseLeave={tbodyMouseUp}
          >
            {animaux.map(numAni => (
              <tr key={numAni}>
                <td className="nowrap">
                  <div className="ani_pocket">
                    <div className="num_nom_pocket">
                      <p>
                        {numAni.substring(0, 8)}
                        <span className="numtrav">{numAni.substring(8)}</span>
                      </p>
                      <div className="second_line">
                        <p>{data.postes[codeActivite][numAni].nomAnimal}</p>
                        {data.postes[codeActivite][numAni].sexe === "1" && (
                          <div className="sexe male">1</div>
                        )}
                        {data.postes[codeActivite][numAni].sexe === "2" && (
                          <div className="sexe femelle">2</div>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                {codeActivite === "PTGE_A" && (
                  <td
                    className="nowrap"
                    onClick={() => dlPdfPTGEAIndiv(numAni)}
                  >
                    <span className="material-symbols-outlined">
                      picture_as_pdf
                    </span>
                  </td>
                )}
                {definitionsPostes &&
                  Object.keys(definitionsPostes).map(indiceChamp => (
                    <td className="nowrap" key={indiceChamp}>
                      {postesAnimaux[numAni]?.[+indiceChamp].valeur}
                    </td>
                  ))}
              </tr>
            ))}
            <tr className="pointer"></tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DetailsVisite
