import { fetchUrlEncoded } from "./fetch"

// Function to login
export const fetchIsConnectedSession = async cheptel => {
  let url = `${process.env.REACT_APP_OLD_API_URL}ajax.php`
  let action = "is_connected"

  let params = {
    a: action,
    cheptel: cheptel,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    falseOnError: false,
  })
}
