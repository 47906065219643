import { fetchUrlEncoded } from "./fetch"

// Function to fetch the data of confirmations
export const fetchGoConfirm = async (ii, ed, ar, n, d, e) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "confirm_p"

  let params = {
    a: action,
    ii: ii,
    ed: ed,
    ar: ar,
    n: n,
    d: d,
    e: e,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
