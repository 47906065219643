import { useEffect, useRef, useState } from "react"
import Analyses from "../components/station/Analyses"
import EvalimStation from "../components/station/EvalimStation"
import FicheStationHeader from "../components/station/FicheStationHeader"
import IndexStation from "../components/station/IndexStation"
import PerfStation from "../components/station/PerfStation"
import "./FicheStation.scss"
import ModalLoader from "../components/ModalLoader"
import { useCustomAlert } from "../hooks/useCustomAlert"
import Alert from "../components/Alert"
import html2canvas from "html2canvas"
import FicheShareStation from "../components/station/FicheShareStation"
import { changeHashOption } from "../functions/hash"
import ModalShareFicheStation from "../components/station/ModalShareFicheStation"
import { checkInternet } from "../functions/checkInternet"
import { fetchLogActionFiche } from "../functions/fetches/fetchLogActionFiche"

const FicheStation = ({ data, pageOption, moyennes, cheptelName }) => {
  console.log(data)
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let urlPhoto = `${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_STATION_URL}${
    data.id_vente
  }/photos/${+data.tag}.JPG`

  const div = useRef()
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  const [contentShare, setContentShare] = useState(false)
  const [blobShareFiche, setBlobShareFiche] = useState(null)

  const refFicheShare = useRef()
  const blurredPhoto = useRef()
  const blurredCanvas = useRef()
  const b64Blur = useRef()
  const graphPerf = useRef()

  const [clSharing, setClSharing] = useState(false)
  pageOption === "sharing" && !clSharing && setClSharing(true)
  pageOption !== "sharing" && clSharing && setClSharing(false)
  pageOption === "sharing" &&
    !contentShare &&
    !blobShareFiche &&
    setContentShare(true)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = div.current

    const shareFiche = async () => {
      let isOnline = await checkInternet()

      if (isOnline) {
        fetchLogActionFiche(
          "fiche_station",
          "generation",
          data.copaip + data.nunati
        )

        setContentShare(true)
      } else {
        doAlert(
          "Vous devez être connecté à internet pour partager votre fiche. Veuillez vérifier votre connexion internet et réessayer s.v.p."
        )
      }
    }

    curr.addEventListener("shareFiche", shareFiche)

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = addHeight + 60

      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }

    return () => {
      curr.removeEventListener("shareFiche", shareFiche)
    }
  }, [])
  useEffect(() => {
    if (contentShare) {
      generateImageFiche()
    }
  }, [contentShare])
  ///////////////
  // Functions //
  ///////////////
  const generateImageFiche = async () => {
    const generateImage = async ref => {
      let toPrint = ref.current

      if (!toPrint) {
        doAlert(
          "Une erreur est survenue lors de la génération de la fiche à partagée."
        )

        setContentShare(false)

        return false
      }

      let width = toPrint.scrollWidth
      let height = toPrint.scrollHeight

      const canvas = await html2canvas(toPrint, {
        windowWidth: width,
        windowHeight: height,
        scale: 1,
        useCORS: true,
      })

      const blob = await new Promise(resolve => canvas.toBlob(resolve))

      return blob
    }

    let divModal = document.getElementById("loader_share_ani")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    await Promise.all([generateImage(refFicheShare)]).then(values => {
      let fiche = values[0]

      setBlobShareFiche(fiche)

      setContentShare(false)
      changeHashOption("sharing")
    })

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const onLoadBlurredPhoto = () => {
    let ctx = blurredCanvas.current.getContext("2d")

    blurredCanvas.current.width = blurredPhoto.current.width
    blurredCanvas.current.height = blurredPhoto.current.height

    // filter
    if (typeof ctx.filter !== "undefined") {
      ctx.filter = "blur(16px)"
      ctx.drawImage(blurredPhoto.current, 0, 0)
    } else {
      ctx.drawImage(blurredPhoto.current, 0, 0)
    }

    b64Blur.current = blurredCanvas.current.toDataURL("image/png")
  }

  return (
    <div ref={div} id="fiche_station" className={"fiche_station"}>
      <div className="photo_ani_blur">
        <canvas ref={blurredCanvas}></canvas>
        <img
          crossOrigin="*"
          ref={blurredPhoto}
          onLoad={onLoadBlurredPhoto}
          src={urlPhoto}
        />
      </div>
      {contentShare && (
        <div className="side_to_print">
          <div ref={refFicheShare}>
            <FicheShareStation
              data={data}
              b64Blur={b64Blur.current}
              b64Graph={graphPerf?.current?.toBase64Image()}
              moyennes={moyennes}
              cheptelName={cheptelName}
            />
          </div>
        </div>
      )}
      {clSharing && blobShareFiche && (
        <ModalShareFicheStation
          blobShareFiche={blobShareFiche}
          numani={data.copaip + data.nunati}
        />
      )}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_share_ani" message="Génération en cours..." />
      <div className="fiche_station_content">
        <FicheStationHeader data={data} pageOption={pageOption} />
        <Analyses data={data} />
        <PerfStation data={data} moyennes={moyennes} graphPerf={graphPerf} />
        <IndexStation data={data} />
        {data.coentr === "E" && <EvalimStation data={data} />}
      </div>
    </div>
  )
}

export default FicheStation
