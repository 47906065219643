// Calculate the months diff between a date and today date
export const monthAmount = dateNaiss => {
  let naissMonth = dateNaiss.getMonth() + 1
  let naissYear = dateNaiss.getFullYear()
  let naissDay = dateNaiss.getDate()

  let dateToday = new Date()
  let todayMonth = dateToday.getMonth() + 1
  let todayYear = dateToday.getFullYear()
  let todayDay = dateToday.getDate()

  let nbMonth = 0
  let diffYear = todayYear - naissYear

  if (todayYear > naissYear) {
    if (diffYear > 1) {
      nbMonth += (diffYear - 1) * 12
      diffYear = 1
    }
    if (diffYear === 1) {
      if (
        todayMonth > naissMonth ||
        (todayMonth === naissMonth && todayDay >= naissDay)
      ) {
        nbMonth += 12
        if (todayDay >= naissDay) nbMonth += todayMonth - naissMonth
        else nbMonth += todayMonth - naissMonth - 1
      } else {
        if (todayDay >= naissDay)
          nbMonth += 12 - Math.abs(naissMonth - todayMonth)
        else nbMonth += 11 - Math.abs(naissMonth - todayMonth)
      }
    }
  } else {
    if (todayDay >= naissDay) nbMonth += todayMonth - naissMonth
    else nbMonth += todayMonth - naissMonth - 1
  }

  return nbMonth
}
