import "./IVV.scss"
import CheptelSectionTitle from "../CheptelSectionTitle"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { useState } from "react"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const IVV = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showivv === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const optionsIvv = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "IVV (Interval Velage Velage)",
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Annees",
        },
      },
      y: {
        title: {
          display: true,
          text: "Jours",
        },
      },
    },
  }
  const dataIvv = {
    labels: data.datesIVV,
    datasets: [
      {
        label: "IVV Moyen",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: data.ivvArray,
        fill: false,
        tension: 0.4,
      },
      {
        label: "IVV 1-2",
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgb(54, 162, 235)",
        data: data.ivv12Array,
        tension: 0.4,
      },
      {
        label: "IVV 2+",
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgb(75, 192, 192)",
        data: data.ivv2pArray,
        tension: 0.4,
      },
    ],
  }
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showivv = !show
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div>
      <CheptelSectionTitle
        title="IVV (Interval Velage Velage)"
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        id="title_graphivv"
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="graphivv">
          <Line options={optionsIvv} data={dataIvv} />
          <div className="infos-bottom">Données BGTA</div>
        </div>
      )}
    </div>
  )
}

export default IVV
