import { useEffect, useRef, useState } from "react"
import "./ModalListePdf.scss"
import { fetchListeComparaisonPeres } from "../../functions/fetches/fetchListeComparaisonPeres"
import ModalLoader from "../ModalLoader"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"
import {
  statutBarColor,
  statutBarN1Color,
} from "../../functions/statutBarColor"
import { goBack, goBackXAmount } from "../../functions/handleNavigation"

let pereSelected = []
let peresA = null
let peresS = null
let isFetching = false

const ModalListePdf = ({
  codeActivite,
  idVisite,
  dateVisite,
  nomActivite,
  numCheptel,
  campagne,
  paramsListe,
  listePdfs,
  indexPtgeS,
  yaPtgeA,
  yaPtgeS,
  page,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  let hash = window.location.hash.split("|")[0] ?? ""
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [peres, setPeres] = useState({})

  let doPereS = (window.location.hash.split("|")[6] ?? "N") === "O"
  let isPerePtgeA = (window.location.hash.split("|")[5] ?? "N") === "O"
  let isPerePtgeS = (window.location.hash.split("|")[6] ?? "N") === "O"

  // Center the modal
  const divModal = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    if (paramsListe === "peres" && Object.keys(peres).length === 0) {
      if (!isFetching) goGetPeres(codeActivite)
    }

    centerModalPere()
  }, [paramsListe])
  useEffect(() => {
    centerModalPere()
  }, [peres])

  ///////////////
  // Functions //
  ///////////////
  const getPdf = async (link, name, callback) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let isOnline = await checkInternet()

    if (isOnline) {
      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      )
    }

    typeof callback === "function" && callback()
  }
  const goPdfLink = choix => {
    let date = dateVisite.substr(0, 10)

    let cSplit = campagne.split("/")

    let ts = Math.round(Date.now() / 1000)
    let name = `${choix}_${numCheptel}_${date}_${ts}`
    let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${choix}/${idVisite}/${name}.pdf`

    getPdf(link, name, () => goBack())
  }
  const centerModalPere = () => {
    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    divModal.current.style.left = `${leftWidth}px`
  }
  const goModalListePeres = async () => {
    // let divModal = document.getElementById("loader_liste_peres")
    // if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    // let listePeres = await fetchListeComparaisonPeres(codeActivite, numCheptel)

    // if (listePeres && listePeres.error !== undefined) {
    //   doAlert("Un problème est survenu lors de la récupération des pères.")
    // }

    // setPeres(listePeres)
    // pereSelected = []

    let indexVisite = window.location.hash.split("|")[1] ?? 0
    let indexActivite = window.location.hash.split("|")[3] ?? ""

    if (hash === `#${page}_recap`)
      window.location.hash = `${hash}|${indexVisite}|peres|${indexActivite}`
    else if (hash === `#${page}_details`)
      window.location.hash = `${hash}|${indexVisite}|${codeActivite}|peres|${indexActivite}`

    // if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const cocheCase = nPere => {
    let div = document.getElementById(`div_ligne_${nPere}`)

    if (pereSelected.includes(nPere)) {
      div.firstChild.classList.remove("on")
      pereSelected = pereSelected.filter(val => val !== nPere)
    } else {
      if (pereSelected.length < 6) {
        div.firstChild.classList.add("on")
        pereSelected.push(nPere)
      } else
        doAlert("Vous ne pouvez comparer que 6 peres à la fois au maximum.")
    }
  }
  const doComparaisonPeres = e => {
    e.target.disabled = true

    if (pereSelected.length < 2) {
      doAlert(
        "Veuillez sélectionner au moins deux pères pour faire la comparaison."
      )
    } else {
      let choix
      let isMulti = null

      if (codeActivite === "PTGE_A") {
        choix = "moy_pere_ptgeA"
        peresA = JSON.stringify(pereSelected)
      } else if (codeActivite === "PTGE_S") {
        choix = "moy_pere_ptgeS"
        peresS = JSON.stringify(pereSelected)
      }

      if (codeActivite === "PTGE_A" && doPereS) {
        let indexVisite = window.location.hash.split("|")[1] ?? 0

        goGetPeres("PTGE_S")

        window.location.hash = `${page}_recap|${indexVisite}|peres|${indexPtgeS}|N|O|O`

        setPeres({})
        return
      }

      if (window.location.hash.split("|")[5]) {
        isMulti = true

        if (isPerePtgeA && isPerePtgeS && codeActivite === "PTGE_S") {
          let indPtge = listePdfs.indexOf("f_el_ptgeA") + 1

          listePdfs = [
            ...listePdfs.slice(0, indPtge),
            "moy_pere_ptgeA",
            ...listePdfs.slice(indPtge),
          ]
        }

        let indPtge =
          codeActivite === "PTGE_A"
            ? listePdfs.indexOf("f_el_ptgeA") + 1
            : listePdfs.indexOf("f_el_ptgeS") + 1

        listePdfs = [
          ...listePdfs.slice(0, indPtge),
          choix,
          ...listePdfs.slice(indPtge),
        ]

        choix = JSON.stringify(listePdfs)
      }

      let date = dateVisite.substr(0, 10)

      let cSplit = campagne.split("/")

      let ts = Math.round(Date.now() / 1000)
      let name = `${choix}_${numCheptel}_${date}_${ts}`
      if (isMulti) name = `Rapport_complet_${numCheptel}_${date}`

      let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf_peres/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${choix}/${idVisite}/${isMulti}/${peresA}/${peresS}/${name}.pdf`

      setTimeout(() => {
        getPdf(link, name, () => {
          if (isMulti) {
            if (isPerePtgeA && isPerePtgeS && codeActivite === "PTGE_S")
              goBackXAmount(4)
            else {
              if (yaPtgeA && yaPtgeS) goBackXAmount(3)
              else goBackXAmount(2)
            }
          } else goBackXAmount(2)
        })
      }, 0)
    }

    e.target.disabled = false
  }
  const goGetPeres = async codeActivite => {
    isFetching = true
    let divModal = document.getElementById("loader_liste_peres")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let listePeres = await fetchListeComparaisonPeres(codeActivite, numCheptel)

    if (listePeres && listePeres.error !== undefined) {
      doAlert("Un problème est survenu lors de la récupération des pères.")
    }

    setPeres(listePeres)
    pereSelected = []

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

    isFetching = false
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  if (
    paramsListe === "peres" &&
    Object.keys(peres).length !== 0 &&
    window.innerWidth < 770
  ) {
    statutBarColor(statutBarN1Color)
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_dlmydocs" message="Téléchargement en cours..." />
      <ModalLoader
        id="loader_liste_peres"
        message="Récupération des pères en cours..."
      />
      {paramsListe === "liste" && (
        <div className="div_modal_liste" ref={divModal}>
          <div className="div_header_filter">
            <h3 className="titre_filtrer">{"PDFs " + (nomActivite ?? "")}</h3>
          </div>
          <div className="div_filters">
            {(codeActivite === "PTGE_A" || codeActivite === "PTGE_S") && (
              <p
                className="p_filter pointer ripple2"
                onClick={() => {
                  let choix

                  if (codeActivite === "PTGE_A") choix = "ptgeA_col"
                  else if (codeActivite === "PTGE_S") choix = "ptgeS_col"

                  goPdfLink(choix)
                }}
              >
                Fiche collective
              </p>
            )}
            {(codeActivite === "PTGE_A" || codeActivite === "PTGE_S") && (
              <p
                className="p_filter pointer ripple2"
                onClick={() => {
                  let choix

                  if (codeActivite === "PTGE_A") choix = "f_el_ptgeA"
                  else if (codeActivite === "PTGE_S") choix = "f_el_ptgeS"

                  goPdfLink(choix)
                }}
              >
                Fiche élevage
              </p>
            )}
            {(codeActivite === "PTGE_A" || codeActivite === "PTGE_S") && (
              <p
                className="p_filter pointer ripple2"
                onClick={goModalListePeres}
              >
                Comparaison père
              </p>
            )}
          </div>
          <div className="div_close_modal_text">
            <button
              className="close_modal_text ripple2 pointer"
              onClick={goBack}
            >
              Annuler
            </button>
          </div>
        </div>
      )}
      {paramsListe === "peres" && Object.keys(peres).length !== 0 && (
        <div className="modal_listePeres" ref={divModal}>
          <div className="div_header_filter">
            <h3 className="titre_filtrer">
              Choix des pères{" "}
              {codeActivite === "PTGE_A"
                ? "p. adultes"
                : codeActivite === "PTGE_S"
                ? "p. sevrages"
                : ""}
            </h3>
          </div>
          <div className="div_filters">
            {Object.keys(peres).map(nPere => (
              <div
                id={`div_ligne_${nPere}`}
                className="div_ligne pointer ripple2"
                key={nPere}
                onClick={e => cocheCase(nPere)}
              >
                <div className="cocheConfirmable"></div>
                <p className="p_filter">
                  {`${peres[nPere]["nomPere"]}${
                    peres[nPere]["qualif"] ? " - " + peres[nPere]["qualif"] : ""
                  } (${nPere}) - ${peres[nPere]["nbFilles"]} filles`}
                </p>
              </div>
            ))}
          </div>
          <div className="div_close_modal_text">
            <button
              className="btn_validate_list ripple2 pointer"
              onClick={doComparaisonPeres}
            >
              Valider
            </button>
            <button
              className="btn_cancel_list ripple2 pointer"
              onClick={goBack}
            >
              Annuler
            </button>
          </div>
        </div>
      )}

      <button
        className={"background-modal"}
        onClick={() => paramsListe === "liste" && goBack()}
      ></button>
    </>
  )
}

export default ModalListePdf
