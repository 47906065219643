import { useEffect } from "react"

export const useCenterModal = ref => {
  useEffect(() => {
    if (!ref.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = ref.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    ref.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = ref.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    ref.current.style.left = `${leftWidth}px`
  }, [])
}
