import "./Page.scss"
import Cheptel from "../pages/Cheptel"
import { useEffect, useRef, useState } from "react"
import NotificationUpdate from "../components/NotificationUpdate"
import Inventaire from "../pages/Inventaire"
import Animal from "../pages/Animal"
import GestionInv from "../pages/GestionInv"
import GestionColumns from "../pages/GestionColumns"
import FilterCustom from "../pages/FilterCustom"
import Comparaison from "../pages/Comparaison"
import Account from "../pages/Account"
import {
  getColumns,
  getColumnsCompare,
  getRFQUAL,
} from "../functions/objectColums"
import Station from "../pages/Station"
import FicheStation from "../pages/FicheStation"
import Visites from "../pages/Visites"

//Variables init
let areWedonwloading = false
let downloadCrashed = false

const Page = ({
  page,
  pageOption,
  dataCalculated,
  downloading,
  cheptelName,
}) => {
  // navigator.storage.estimate().then(estimate => {
  //   console.log(
  //     "quota: " +
  //       estimate.quota / 1024 / 1024 +
  //       "mb " +
  //       " usage: " +
  //       estimate.usage / 1024 +
  //       "kb"
  //   )
  // })
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const RFQUAL = getRFQUAL()
  const div = useRef()

  const [classConfirm, setClassConfirm] = useState("hide")
  const [classDecl, setClassDecl] = useState("hide")
  let clPage = page.split("_")[0] === "animal" ? "page_ani" : ""
  let clOverflow =
    page === "gestioninv" || page === "gestioncolumns" ? "no_overflow" : ""
  let clFixScrollbarIOS = ""

  // if (
  //   window.localStorage.platformNative === "ios" &&
  //   ["animal", "fichestation"].includes(page)
  // )
  //   clFixScrollbarIOS = "fix_scrollbar_ios"

  // list of confirms that coulnt be tried (to show notif)
  let listTryConfirm = JSON.parse(window.localStorage.listCouldntTry ?? "[]")
  listTryConfirm.length !== 0 &&
    classConfirm !== "show" &&
    setClassConfirm("show")
  listTryConfirm.length === 0 &&
    classConfirm !== "hide" &&
    setClassConfirm("hide")

  // list of declarations that coulnt be tried (to show notif)
  let listCouldntTryDecl = JSON.parse(
    window.localStorage.listCouldntTryDecl ?? "[]"
  )
  listCouldntTryDecl.length !== 0 &&
    classDecl !== "show" &&
    setClassDecl("show")
  listCouldntTryDecl.length === 0 &&
    classDecl !== "hide" &&
    setClassDecl("hide")

  // CALCUL MARGIN TOP
  let marginTop = 60

  if (
    "ReactNativeWebView" in window &&
    window.localStorage.platformNative === "android"
  ) {
    let addHeight = window.localStorage.statutBarHeight
      ? +window.localStorage.statutBarHeight
      : 0

    marginTop += addHeight
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = div.current

    // if (
    //   "ReactNativeWebView" in window &&
    //   window.localStorage.platformNative === "android"
    // ) {
    //   let topActual =
    //     +window
    //       .getComputedStyle(curr, null)
    //       .getPropertyValue("margin-top")
    //       .split("px")[0] ?? 0

    //   let addHeight = window.localStorage.statutBarHeight
    //     ? +window.localStorage.statutBarHeight
    //     : 0
    //   let newHeight = topActual + addHeight

    //   curr.style.marginTop = newHeight + "px"
    // }

    const noMoreConfirm = e => {
      setClassConfirm("hide")
    }
    const noMoreDecl = e => {
      setClassDecl("hide")
    }

    // Change state so it stays hidden on future renders
    curr.addEventListener("noMoreConfirm", noMoreConfirm)
    // Change state so it stays hidden on future renders
    curr.addEventListener("noMoreDecl", noMoreDecl)

    return () => {
      curr.removeEventListener("noMoreConfirm", noMoreConfirm)
      curr.removeEventListener("noMoreDecl", noMoreDecl)
    }
  }, [])
  useEffect(() => {
    // it's extra code to fix certain cases the code in CustomInventaire cant handle
    let isNotifUpd = document
      .getElementById("div_topnotif_sw")
      .classList.contains("show")

    if (!isNotifUpd && classConfirm === "hide" && classDecl === "hide") {
      let invFooter = document.getElementById("inventaire_footer")
      let invTable = document.getElementById("inventaire_table")

      invFooter?.classList.remove("diff_bottom")
      invTable?.classList.remove("diff_bottom")
    }
  }, [classConfirm, classDecl])
  ///////////////
  // Functions //
  ///////////////
  // Method to direct to confirm table
  const onClickGoConfirm = () => {
    window.localStorage.choixInv = "confirmation"
    window.location.hash = "inventaire_goconfirm"
  }
  // Method to direct to declarations table
  const onClickGoDecl = () => {
    window.localStorage.choixInv = "lanaud"
    window.location.hash = "inventaire_declarations"
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  let pageElement = ""

  switch (page) {
    case "cheptel":
      pageElement = (
        <Cheptel
          dataCalculated={dataCalculated?.cheptel}
          pocketData={dataCalculated?.pocketData}
          isAdherent={dataCalculated?.isAdherent}
          pageOption={pageOption}
          cheptelName={cheptelName}
        />
      )
      break
    case "station":
      pageElement = (
        <Station
          data={dataCalculated?.station}
          pageOption={pageOption}
          moyennes={dataCalculated?.station.moyennes}
        />
      )
      break
    case "fichestation":
      let indexAni = window.location.hash.split("|")[1]

      let aniStationData = dataCalculated?.station?.animaux[indexAni]

      if (!aniStationData) window.location.hash = ""
      else
        pageElement = (
          <FicheStation
            data={aniStationData}
            moyennes={dataCalculated?.station.moyennes}
            pageOption={pageOption}
            cheptelName={cheptelName}
          />
        )
      break
    case "visites":
      pageElement = (
        <Visites
          data={dataCalculated?.pocketData}
          pageOption={pageOption}
          cheptelName={cheptelName}
        />
      )
      break
    case "inventaire":
      pageElement = (
        <Inventaire
          data={dataCalculated?.inventaire}
          isAdherent={dataCalculated?.isAdherent}
          pageOption={pageOption}
          downloading={downloading}
        />
      )
      break
    case "animal":
      let numAni = window.location.hash.split("|")[1]
      let aniData = null

      dataCalculated?.inventaire?.Custom.forEach(
        ani =>
          numAni === ani.COPAIP + ani.NUNATI && ani.passage && (aniData = ani)
      )

      if (!aniData && dataCalculated?.inventaire?.aniNotInInv) {
        if (dataCalculated?.inventaire?.aniNotInInv[numAni]) {
          aniData = dataCalculated?.inventaire?.aniNotInInv[numAni]

          let tsLastFetch = aniData?.tsFetch ?? null

          if (tsLastFetch) {
            let dateFetch = new Date(tsLastFetch)
            let dateNow = new Date()

            if (dateFetch.getFullYear() < dateNow.getFullYear()) aniData = null
            else if (dateFetch.getMonth() < dateNow.getMonth()) aniData = null
            else if (
              dateNow.getHours() >= 6 &&
              dateFetch.getDate() < dateNow.getDate()
            )
              aniData = null
            else if (
              dateNow.getHours() < 6 &&
              dateFetch.getHours() < 6 &&
              dateFetch.getDate() < dateNow.getDate()
            )
              aniData = null
            else if (
              dateNow.getHours() < 6 &&
              dateFetch.getHours() >= 6 &&
              dateFetch.getDate() < dateNow.getDate() - 1
            )
              aniData = null
          }
        }
      }

      pageElement = (
        <Animal
          dataCalculated={dataCalculated}
          aniData={aniData}
          key={numAni}
          pageOption={pageOption}
        />
      )
      break
    case "gestioninv":
      pageElement = <GestionInv pageOption={pageOption} />
      break
    case "gestioncolumns":
      let hv1 = window.location.hash.split("|")[1]
      pageElement = (
        <GestionColumns
          pageOption={pageOption}
          isAdherent={dataCalculated?.isAdherent}
          idInventaire={+hv1.replace("custom", "")}
        />
      )
      break
    case "customfilters":
      pageElement = (
        <FilterCustom
          pageOption={pageOption}
          allData={dataCalculated?.inventaire?.Custom}
        />
      )
      break
    case "comparaison":
      // Get animals to compare data
      let hash = window.location.hash
      let hVals = hash.split("|")
      let listeAni = JSON.parse(decodeURIComponent(hVals[1]))
      let animaux = []
      dataCalculated?.inventaire?.Custom.forEach(
        ani => listeAni.includes(ani.COPAIP + ani.NUNATI) && animaux.push(ani)
      )

      // Get lists of columns to check the highest value for
      const columns = getColumns()
      const columnsCompare = getColumnsCompare()
      const highestValueColList = []

      columnsCompare?.forEach(col => {
        let category = columns[col].category
        let name = columns[col].name
        if (
          (category === "index" && !name.includes("CD ")) ||
          (category === "indexpere" && !name.includes("CD ")) ||
          (category === "indexmere" && !name.includes("CD ")) ||
          col === "qualifAnimal" ||
          col === "pntp" ||
          col === "bopoat120" ||
          col === "bopoat210" ||
          col === "RADEVE" ||
          col === "IVVMOY"
        ) {
          highestValueColList.push(col)
        }
      })

      // Get highest value for each col
      const highestValues = {}
      let compareQualif = RFQUAL ? true : false
      let etqubo = -1
      let sexbov = -1

      for (let i in animaux) {
        const ani = animaux[i]

        highestValueColList.forEach(col => {
          switch (col) {
            case "qualifAnimal":
              if (compareQualif) {
                if (
                  (etqubo === -1 || etqubo === ani?.["A_bestqual_ETQUBO"]) &&
                  (sexbov === -1 || sexbov === ani?.["SEXBOV"])
                ) {
                  if (etqubo === -1) etqubo = ani?.["A_bestqual_ETQUBO"]
                  if (sexbov === -1) sexbov = ani?.["SEXBOV"]

                  // Dont commpare ETQUBO 1, only 2 and 3 !!
                  if (ani?.["A_bestqual_ETQUBO"] === "1") {
                    compareQualif = false
                    if (highestValues[col]) delete highestValues[col]
                  } else {
                    if (!highestValues[col])
                      highestValues[col] =
                        ani?.["A_bestqual_COQUBO"] || ani?.["A_bestqual_CCQUBO"]
                          ? ani?.["A_bestqual_COQUBO"] +
                            "|" +
                            ani?.["A_bestqual_CCQUBO"]
                          : ""
                    else {
                      let vals = highestValues[col].split("|")
                      let coqubo = vals[0]
                      let ccqubo = vals[1]

                      let niqubo =
                        RFQUAL?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo].NIQUBO
                      let snqubo =
                        RFQUAL?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo].SNQUBO

                      if (ani?.["A_bestqual_NIQUBO"] > niqubo)
                        highestValues[col] =
                          ani?.["A_bestqual_COQUBO"] +
                          "|" +
                          ani?.["A_bestqual_CCQUBO"]
                      else if (ani?.["A_bestqual_NIQUBO"] === niqubo) {
                        if (ani?.["A_bestqual_SNQUBO"] > snqubo)
                          highestValues[col] =
                            ani?.["A_bestqual_COQUBO"] +
                            "|" +
                            ani?.["A_bestqual_CCQUBO"]
                      }
                    }
                  }
                } else {
                  compareQualif = false
                  if (highestValues[col]) delete highestValues[col]
                }
              }
              break
            case "pntp":
              let poids = ani?.["ponaisFiche"]
                ?.replace(" kg", "")
                ?.replace(" calculé", "")

              if (poids) {
                if (!highestValues[col]) highestValues[col] = poids
                else {
                  if (columns[col].nSens === "asc") {
                    if (poids > +highestValues[col]) highestValues[col] = poids
                  } else if (columns[col].nSens === "desc") {
                    if (poids < +highestValues[col]) highestValues[col] = poids
                  }
                }
              }
              break
            default:
              if (columns[col].type === "number") {
                if (+ani?.[col]) {
                  if (!highestValues[col]) highestValues[col] = ani?.[col]
                  else {
                    if (columns[col].nSens === "asc") {
                      if (+ani?.[col] > +highestValues[col])
                        highestValues[col] = ani?.[col]
                    } else if (columns[col].nSens === "desc") {
                      if (+ani?.[col] < +highestValues[col])
                        highestValues[col] = ani?.[col]
                    }
                  }
                }
              }

              break
          }
        })
      }

      // Get differences if two animals
      const diffValues = {}

      if (Object.keys(animaux).length === 2) {
        highestValueColList.forEach(col => {
          switch (col) {
            case "qualifAnimal":
              let val1 =
                animaux?.[0]?.["A_bestqual_COQUBO"] +
                "|" +
                animaux?.[0]?.["A_bestqual_CCQUBO"]
              let val2 =
                animaux?.[1]?.["A_bestqual_COQUBO"] +
                "|" +
                animaux?.[1]?.["A_bestqual_CCQUBO"]

              if (highestValues[col] === val1 && highestValues[col] === val2) {
                diffValues[col] = {
                  diff: 0,
                  side: 0,
                }
              } else if (highestValues[col] === val1) {
                diffValues[col] = {
                  diff: "DoNotDisplayAValue",
                  side: 1,
                }
              } else if (highestValues[col] === val2) {
                diffValues[col] = {
                  diff: "DoNotDisplayAValue",
                  side: 2,
                }
              }
              break
            case "pntp":
              let v1 = +animaux?.[0]?.["ponaisFiche"]
                ?.replace(" kg", "")
                ?.replace(" calculé", "")

              let v2 = +animaux?.[1]?.["ponaisFiche"]
                ?.replace(" kg", "")
                ?.replace(" calculé", "")

              if (v1 && v2)
                diffValues[col] = {
                  symbol: "+",
                  diff: +Math.abs(v1 - v2).toFixed(2),
                  side: v1 < v2 ? 2 : 1,
                }
              break
            default:
              if (columns[col].type === "number") {
                let val1 = +animaux?.[0]?.[col]
                let val2 = +animaux?.[1]?.[col]

                if (val1 && val2) {
                  if (columns[col].nSens === "asc")
                    diffValues[col] = {
                      symbol: "+",
                      diff: +Math.abs(val1 - val2).toFixed(2),
                      side: val1 < val2 ? 2 : 1,
                    }
                  else if (columns[col].nSens === "desc")
                    diffValues[col] = {
                      symbol: "-",
                      diff: +Math.abs(val1 - val2).toFixed(2),
                      side: val1 < val2 ? 1 : 2,
                    }
                }
              }
              break
          }
        })
      }

      pageElement = (
        <Comparaison
          animaux={animaux}
          highestValues={highestValues}
          diffValues={diffValues}
        />
      )
      break
    case "account":
      pageElement = <Account data={dataCalculated.account} />
      break
    default:
      break
  }

  return (
    <div
      className={
        areWedonwloading && !downloadCrashed
          ? `page load_dl`
          : `page ${clPage} ${clOverflow} ${clFixScrollbarIOS}`
      }
      id="page"
      ref={div}
      style={{
        marginTop: `${marginTop}px`,
      }}
    >
      <NotificationUpdate
        id="undone_confirm"
        classNotif={classConfirm}
        messageNotif="Vous avez des confirmations en attente"
        messageButton="Confirmer"
        onClick={onClickGoConfirm}
      />
      <NotificationUpdate
        id="undone_declare"
        classNotif={classDecl}
        messageNotif="Vous avez des déclarations en attente"
        messageButton="Déclarer"
        onClick={onClickGoDecl}
      />

      {pageElement}
    </div>
  )
}

export default Page
