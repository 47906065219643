import "./FicheSimpleShare.scss"
import logo from "../../assets/images/logo_fiche_simple.png"
import noImg from "../../assets/images/fiche_share_no_photo.jpg"
import { formatDate } from "../../functions/formatDate"

const FicheSimpleShare = ({ aniData, b64Blur, havePicture }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let url = "https://espacepro.limousine.org/"
  if (window.location.hostname === "localhost") {
    url = "http://localhost:2765/"
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="fiche_simple_share">
      {/* Div logo + white bandereau */}
      <div className="div_logo">
        <img src={logo} className="logo" />
      </div>
      {/* Div infos animal */}
      <div className="infos_animal">
        <div className="info_cheptel">
          <p>
            {aniData.detenteurNom} ({aniData.detenteurNum.substring(2, 4)})
          </p>
        </div>
        <div className="titre">
          <h2
            className={
              aniData.SEXBOV === "2" ? "nom sexe-femelle" : "nom sexe-male"
            }
          >
            {aniData.NOBOVI}
            <i
              className="aboqual"
              title={
                aniData.qualifAnimalLIDETA
                  ? aniData.qualifAnimalLIDETA
                  : aniData.inscrAnimalLIDETA && aniData.inscrAnimalLIDETA
              }
            >
              {aniData.qualifAnimal
                ? aniData.qualifAnimal
                : aniData.inscrAnimal && aniData.inscrAnimal}
            </i>
          </h2>
          <h3 className="numtravail">{aniData.COPAIP + aniData.NUNATI}</h3>
        </div>
        <div className="infos-naissance">
          <div className="line_info">
            <p>Date de naissance :</p>
            <p>{`${aniData.DANAIS}`}</p>
          </div>
        </div>
      </div>
      {/* Div photos animal */}
      <div className="div_img_ani">
        <div className="photos">
          <img className="photo_flou_ani" src={b64Blur} />
          <div className="real_photo">
            <img
              className={
                aniData.photoHeight > aniData.photoWidth
                  ? "photo_ani portrait"
                  : "photo_ani landscape"
              }
              src={
                havePicture.current
                  ? `${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL}${
                      aniData.COPAIP + aniData.NUNATI
                    }_${aniData.ts_photos}.jpg`
                  : noImg
              }
            />
          </div>
        </div>
      </div>
      {/* Div genealogie */}
      {(aniData.Aifnais !== "" ||
        aniData.Acrsevs !== "" ||
        aniData.Admsevs !== "" ||
        aniData.Adssevs !== "" ||
        aniData.Afosevs !== "" ||
        aniData.Aisevre !== "" ||
        aniData.Aavelag !== "" ||
        aniData.Aalaits !== "" ||
        aniData.Aivmate !== "") && (
        <div className="indexes_ani">
          <div className="table_index_ani">
            <table className="table-index-parents">
              <thead>
                <tr>
                  <th>IFNAIS</th>
                  <th>CRsev</th>
                  <th>DMsev</th>
                  <th>DSsev</th>
                  <th>FOSsev</th>
                  <th>ISEVR</th>
                  <th>AVEL</th>
                  <th>ALait</th>
                  <th>IVMAT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{aniData.Aifnais}</td>
                  <td>{aniData.Acrsevs}</td>
                  <td>{aniData.Admsevs}</td>
                  <td>{aniData.Adssevs}</td>
                  <td>{aniData.Afosevs}</td>
                  <td className={aniData.SEXBOV === "2" ? "pm" : "pp"}>
                    {aniData.Aisevre}
                  </td>
                  <td>{aniData.Aavelag}</td>
                  <td>{aniData.Aalaits}</td>
                  <td className={aniData.SEXBOV === "2" ? "pm" : "pp"}>
                    {aniData.Aivmate}
                  </td>
                </tr>
                <tr className="d">
                  <td>{aniData.Acdifna !== "" && aniData.Acdifna}</td>
                  <td>{aniData.Acdcrse !== "" && aniData.Acdcrse}</td>
                  <td>{aniData.Acddmse !== "" && aniData.Acddmse}</td>
                  <td>{aniData.Acddsse !== "" && aniData.Acddsse}</td>
                  <td>{aniData.Acdfose !== "" && aniData.Acdfose}</td>
                  <td className={aniData.SEXBOV === "2" ? "pm" : "pp"}>
                    {aniData.Acdisev !== "" && aniData.Acdisev}
                  </td>
                  <td>{aniData.Acdavel !== "" && aniData.Acdavel}</td>
                  <td>{aniData.Acdalai !== "" && aniData.Acdalai}</td>
                  <td className={aniData.SEXBOV === "2" ? "pm" : "pp"}>
                    {aniData.Acdivma !== "" && aniData.Acdivma}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default FicheSimpleShare
