import "./Visites.scss"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalLoader from "../components/ModalLoader"
import { useEffect, useRef, useState } from "react"
import { getActivitesName } from "../functions/objectColums"
import ModalVisite from "../components/visites/ModalVisite"
import { useCenterModal } from "../hooks/useCenterModal"
import { goBack } from "../functions/handleNavigation"

const Visites = ({ data, pageOption, cheptelName }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  let divWrap = useRef()

  let namesActivites = getActivitesName()
  let indexVisite = window.location.hash.split("|")[1] ?? 0

  const [filterAct, setFilterAct] = useState(
    JSON.parse(localStorage.filterAct ?? "[]")
  )

  // Handle display filter modal
  const [clFilter, setClFilter] = useState(false)
  pageOption === "filter" && !clFilter && setClFilter(true)
  pageOption !== "filter" && clFilter && setClFilter(false)
  // Handle display recap modal
  const [clRecap, setClRecap] = useState(false)
  if ((pageOption === "recap" || pageOption === "details") && !clRecap)
    setClRecap(true)
  if (pageOption !== "recap" && pageOption !== "details" && clRecap)
    setClRecap(false)

  let actiInData = new Set()

  data.forEach(visite => {
    if (new Date(visite.dateVisite.replace(" ", "T")).getTime() >= Date.now())
      return false

    for (let codeAct in visite.postes) {
      actiInData.add(codeAct)
    }
    if (
      new Date(visite.dateVisite.replace(" ", "T")).getTime() < Date.now() &&
      visite.postes.length === 0 &&
      visite.codeStatut === "4"
    )
      actiInData.add("CONSEIL")
  })

  let visites = data && data.constructor === Array ? data : []

  visites.forEach((v, index) => {
    let dVisite = new Date(v.dateVisite.replace(" ", "T"))

    v.indexVisite = index
    v.heureVisite = dVisite.getHours()
  })
  visites = visites.filter(
    v =>
      new Date(v.dateVisite.replace(" ", "T")).getTime() >= Date.now() ||
      v.postes.length !== 0 ||
      v.codeStatut === "4"
  )

  if (filterAct.length !== 0) {
    visites = visites.filter(v => {
      let inVisite = false

      if (new Date(v.dateVisite.replace(" ", "T")).getTime() >= Date.now())
        return true

      for (let codeAct in v.postes) {
        if (filterAct.includes(codeAct)) inVisite = true
      }

      if (v.postes.length === 0 && filterAct.includes("CONSEIL"))
        inVisite = true

      return inVisite
    })
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    localStorage.filterAct = JSON.stringify(filterAct)

    const event = new CustomEvent("filterNumberVisite", {
      detail: {
        nbFilters: filterAct.length,
      },
    })

    let header = document.getElementById("header")
    if (header && divWrap.current)
      setTimeout(() => header.dispatchEvent(event), 0)
  }, [filterAct])
  ///////////////
  // Functions //
  ///////////////
  const goModalVisite = index => {
    window.location.hash = `visites_recap|${index}`
  }
  const getActivityClass = act => {
    let className = "nom_activite "
    switch (act) {
      case "CERTIF":
        className += "certif"
        break
      case "QUALIF":
      case "QUALIF2":
        className += "qualif"
        break
      case "PTGE_A":
        className += "ptge_A"
        break
      case "PTGE_S":
        className += "ptge_S"
        break
      case "TRI":
        className += "tri_hbl"
        break
      case "DGLOC":
        className += "dgloc"
        break
      case "DG":
        className += "pointage_mamelle"
        break
      case "PELV2":
        className += "ouverture_pelvienne"
        break
      case "POEN3":
        className += "sabots_docilites"
        break
      case "MH":
        className += "mh_filiat"
        break
      case "POSO1 34":
        className += "SECi_point_sortie"
        break
      case "MORPHO1":
        className += "formation_pointage"
        break

      default:
        break
    }

    return className
  }
  const updateFilter = selectedVals => {
    setFilterAct(selectedVals)
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div id="page_visites" ref={divWrap} className="page_visites">
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_pocket_data"
        message="Mise à jour des données en cours..."
      />
      <div className="liste_visites">
        {visites?.map(visite => (
          <div
            className={
              new Date(visite.dateVisite.replace(" ", "T")).getTime() <
                Date.now() && visite.postes.length !== 0
                ? "card_visit pointer"
                : "card_visit"
            }
            key={visite.idVisite}
            onClick={() =>
              new Date(visite.dateVisite.replace(" ", "T")).getTime() <
                Date.now() &&
              visite.postes.length !== 0 &&
              goModalVisite(visite.indexVisite)
            }
          >
            <p className="date_visite">
              {visite.dateVisiteFormate.substring(
                0,
                visite.dateVisiteFormate.length - 5
              )}
              {visite?.heureVisite > 12 ? "après-midi" : "matin"}
            </p>
            <div className="div_activity">
              {Object.keys(visite?.postes).length !== 0 &&
                new Date(visite.dateVisite.replace(" ", "T")).getTime() <
                  Date.now() && (
                  <ul>
                    {Object.keys(visite?.postes).map(act => (
                      <li className={getActivityClass(act)} key={act}>
                        {namesActivites[act]}
                      </li>
                    ))}
                  </ul>
                )}
              {new Date(visite.dateVisite.replace(" ", "T")).getTime() <
                Date.now() &&
                Object.keys(visite?.postes).length === 0 && (
                  <ul>
                    <li className="nom_activite conseil">CONSEIL</li>
                  </ul>
                )}
              {new Date(visite.dateVisite.replace(" ", "T")).getTime() >=
                Date.now() && <div className="a_venir">Visite à venir</div>}
            </div>
            <p className="name_pointeur">{`${visite?.prenomPointeur} ${visite?.nomPointeur}`}</p>
          </div>
        ))}
      </div>
      {clFilter && (
        <ModalFilterVisite
          vals={[...actiInData]}
          selectedVals={filterAct}
          callback={updateFilter}
        />
      )}
      {clRecap && (
        <ModalVisite
          data={data[indexVisite]}
          page={"visites"}
          pageOption={pageOption}
          cheptelName={cheptelName}
        />
      )}
    </div>
  )
}

const ModalFilterVisite = ({ vals, selectedVals, callback }) => {
  ///////////
  // HOOKS //
  ///////////
  let divModal = useRef()
  useCenterModal(divModal)

  let namesActivites = getActivitesName()

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const cocheCase = val => {
    let laCase = document.getElementById("live_val_" + val)
    laCase.firstChild?.classList.toggle("on")

    if (selectedVals.includes(val)) {
      selectedVals = selectedVals.filter(v => v !== val)
    } else {
      selectedVals.push(val)
    }

    callback([...selectedVals].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)))
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div ref={divModal} className="modal_filter_visite">
        <div className="div_choices_input">
          {vals
            .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
            .map(val => (
              <div
                className="div_line_val pointer ripple2"
                id={"live_val_" + val}
                onClick={() => cocheCase(val)}
                key={val}
              >
                <div
                  className={
                    selectedVals?.includes(val)
                      ? "cocheConfirmable on"
                      : "cocheConfirmable"
                  }
                ></div>
                <p>{val === "CONSEIL" ? val : namesActivites[val]}</p>
              </div>
            ))}
        </div>
        <div className="div_button_input_choices">
          <button className="ripple2 pointer" onClick={goBack}>
            confirmer
          </button>
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default Visites
