import { fetchUrlEncoded } from "./fetch"

// Function to update cheptels OP
export const fetchUpdateCheptelsOp = async cheptels => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "updateCheptelsOp"

  let params = {
    a: action,
    cheptels: JSON.stringify(cheptels),
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    noEmptyObjectOnError: true,
  })
}
