import { useEffect, useRef, useState } from "react"
import { goBack } from "../../functions/handleNavigation"
import "./ModalRegister.scss"
import { useCenterModal } from "../../hooks/useCenterModal"
import BigGreenButton from "../BigGreenButton"
import { validateEmail } from "../../functions/validateEmail"
import { fetchRegister } from "../../functions/fetches/fetchRegister"
import { fetchLastAnimal } from "../../functions/fetches/fetchLastAnimal"

const ModalRegister = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let divRef = useRef()
  useCenterModal(divRef)
  let divInputs = useRef()

  const [lastAnimal, setLastAnimal] = useState(null)
  const [registered, setRegistered] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)

  const [errorMessage, setErrorMessage] = useState(null)
  const [errorNumCheptel, setErrorNumCheptel] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPass, setErrorPass] = useState(false)
  const [errorConfirmPass, setErrorConfirmPass] = useState(false)
  const [errorAnswer, setErrorAnswer] = useState(false)

  let fieldNumCheptel = useRef()
  let fieldPhone = useRef()
  let fieldEmail = useRef()
  let fieldPass = useRef()
  let fieldConfirmPass = useRef()
  let fieldAnswer = useRef()
  let refNumCheptel = useRef()
  let refPhone = useRef()
  let refEmail = useRef()
  let refPass = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    const onResize = () => {
      let div = divRef.current
      if (!div) return

      let halfHeightScreen = window.innerHeight / 2
      let halfHeightModal = div.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal

      div.style.top = `${topHeight}px`

      let halfWidthScreen = window.innerWidth / 2
      let halWidthModal = div.offsetWidth / 2
      let leftWidth = halfWidthScreen - halWidthModal

      div.style.left = `${leftWidth}px`

      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0

        let inputs = divInputs.current
        if (inputs) {
          let newHeight = 121 + addHeight
          inputs.style.maxHeight = `calc(calc(var(--vh) * 96) - ${newHeight}px)`
        }

        let halfHeightScreen = (window.innerHeight - addHeight) / 2
        let halfHeightModal = div.offsetHeight / 2
        let topHeight = halfHeightScreen - halfHeightModal
        topHeight += addHeight

        div.style.top = topHeight + "px"
      }
    }

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])
  useEffect(() => {
    let div = divRef.current

    if (!div) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = div.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    div.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = div.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    div.style.left = `${leftWidth}px`

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = div.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      div.style.top = topHeight + "px"
    }
  }, [registered, lastAnimal])
  ///////////////
  // Functions //
  ///////////////
  const errorReturn = ({
    errorMessage = null,
    errorNumCheptel = false,
    errorPhone = false,
    errorEmail = false,
    errorPass = false,
    errorConfirmPass = false,
  }) => {
    setErrorMessage(errorMessage)

    setErrorNumCheptel(errorNumCheptel)
    setErrorPhone(errorPhone)
    setErrorEmail(errorEmail)
    setErrorPass(errorPass)
    setErrorConfirmPass(errorConfirmPass)
    setLastAnimal(false)

    let divModal = document.getElementById("laoder_reinit_pass")
    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }

  const registerAccount = async () => {
    let divModal = document.getElementById("laoder_reinit_pass")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let numCheptel = fieldNumCheptel.current?.value.trim()
    let phoneNumber = fieldPhone.current?.value
    let email = fieldEmail.current?.value.trim()
    let pass = fieldPass.current?.value.trim()
    let confirmPass = fieldConfirmPass.current?.value.trim()

    if (!numCheptel) {
      errorReturn({
        errorMessage: "Veuillez renseigner votre numéro de cheptel s.v.p.",
        errorNumCheptel: true,
      })

      return false
    }

    if (!phoneNumber) {
      errorReturn({
        errorMessage:
          "Veuillez renseigner votre numéro de téléphone mobile s.v.p.",
        errorPhone: true,
      })

      return false
    }

    if (!checkPhoneNumber(phoneNumber)) {
      errorReturn({
        errorMessage: "Le numéro de téléphone mobile n'est pas valide.",
        errorPhone: true,
      })

      return false
    }

    if (!email) {
      errorReturn({
        errorMessage: "Veuillez renseigner votre adresse email s.v.p.",
        errorEmail: true,
      })

      return false
    }

    if (!validateEmail(email)) {
      errorReturn({
        errorMessage:
          "Le format de l'adresse email est incorrecte. Ex: mon.email@email.com",
        errorEmail: true,
      })

      return false
    }

    if (!pass) {
      errorReturn({
        errorMessage: "Veuillez renseigner un mot de passe s.v.p.",
        errorPass: true,
      })

      return false
    }

    if (!confirmPass) {
      errorReturn({
        errorMessage: "Veuillez confirmer votre mot de passe s.v.p.",
        errorConfirmPass: true,
      })

      return false
    }

    if (pass !== confirmPass) {
      errorReturn({
        errorMessage:
          "La confirmation du mot de passe ne correspond pas au mot de passe.",
        errorPass: true,
        errorConfirmPass: true,
      })

      return false
    }

    phoneNumber = phoneNumber.replaceAll(" ", "")

    let lastAnimalCheptel = await fetchLastAnimal(numCheptel)

    if (lastAnimalCheptel && lastAnimalCheptel.code_error) {
      if (lastAnimalCheptel.code_error === "no_34") {
        errorReturn({
          errorMessage:
            "Aucun animal de code race 34 n'est enregistré dans ce cheptel.",
          errorNumCheptel: true,
        })

        return false
      }
      if (lastAnimalCheptel.code_error === "too_many_attemps") {
        errorReturn({
          errorMessage:
            "Limite de tentative de création de compte atteinte. Veuillez contacter le pôle de Lanaud pour créer votre compte s.v.p.",
          errorNumCheptel: true,
        })

        return false
      }
    }

    if (
      !lastAnimalCheptel ||
      !lastAnimalCheptel.copaip ||
      !lastAnimalCheptel.nunati
    ) {
      errorReturn({
        errorMessage:
          "Une erreur est survenue lors de l'inscription. Veuillez vérifier votre connexion internet et réessayer s.v.p.",
      })

      return false
    }

    setErrorMessage(null)
    setLastAnimal(lastAnimalCheptel)

    refNumCheptel.current = fieldNumCheptel.current?.value
    refPhone.current = fieldPhone.current?.value
    refEmail.current = fieldEmail.current?.value
    refPass.current = fieldPass.current?.value

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const sendAnswer = async () => {
    let divModal = document.getElementById("laoder_reinit_pass")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let numCheptel = refNumCheptel.current
    let phoneNumber = refPhone.current
    let email = refEmail.current
    let pass = refPass.current
    let answer = fieldAnswer?.current.value

    if (!answer) {
      setErrorAnswer(true)
      setErrorMessage("Veuillez renseigner la date de naissance")

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      return false
    }

    let register = await fetchRegister(
      numCheptel,
      email,
      pass,
      phoneNumber,
      answer
    )

    if (register !== true) {
      if (register.code_error) {
        if (register.code_error === "invalid_email") {
          errorReturn({
            errorMessage:
              "Le format de l'adresse email est incorrecte. Ex: mon.email@email.com",
            errorEmail: true,
          })

          return false
        }
        if (register.code_error === "duplicate_email") {
          errorReturn({
            errorMessage:
              "L'email est déjà enregistrée sur un compte EspacePro.",
            errorEmail: true,
          })

          return false
        }
        if (register.code_error === "duplicate_cheptel") {
          errorReturn({
            errorMessage: "Il existe déjà un compte pour ce cheptel.",
            errorNumCheptel: true,
          })

          return false
        }
        if (register.code_error === "invalid_answer") {
          errorReturn({
            errorMessage:
              "La réponse pour confirmer votre cheptel est erronée.",
            errorNumCheptel: true,
          })

          return false
        }
      }

      errorReturn({
        errorMessage:
          "Une erreur est survenue lors de l'inscription. Veuillez réessayer s.v.p.",
      })

      return false
    }

    setRegistered(true)

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const formatPhoneNumber = () => {
    let phoneNumber = fieldPhone.current?.value
    let formatedPhoneNumber = ""
    let countNumber = 0
    let totalNumber = 0

    for (let i = 0; i < phoneNumber.length; i++) {
      let char = phoneNumber.charAt(i)
      if (totalNumber >= 10) break

      if (countNumber === 2 && totalNumber < 10) {
        formatedPhoneNumber += " "
        countNumber = 0
      }

      if (/^\d+$/.test(char)) {
        formatedPhoneNumber += char
        countNumber++
        totalNumber++
      }
    }

    fieldPhone.current.value = formatedPhoneNumber
  }
  const checkPhoneNumber = phoneNumber => {
    let isCorrect = true
    let totalNumber = 0

    for (let i = 0; i < phoneNumber.length; i++) {
      let char = phoneNumber.charAt(i)

      if (!/^\d+$/.test(char) && !/\s/.test(char)) {
        isCorrect = false
      }

      if (/^\d+$/.test(char)) {
        totalNumber++
      }
    }

    if (totalNumber !== 10) isCorrect = false

    return isCorrect
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div className="modal_register" ref={divRef}>
        <div className="div_modal_header">
          <h3>Inscription</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2 pointer material-symbols-outlined"
          >
            close
          </span>
        </div>
        <div className="modal-content">
          {registered ? (
            <div className="successMessage">Votre compte a bien été créé!</div>
          ) : lastAnimal ? (
            <div className="question_animal">
              <div>
                Nous avons besoin de vérifier votre identité pour créer votre
                compte.
              </div>
              <div>
                Veuillez indiquer la{" "}
                <span className="name_data_to_check">date de naissance</span>{" "}
                du:
              </div>
              <div className="animal_question">
                {lastAnimal?.copaip +
                  lastAnimal?.nunati?.substring(
                    0,
                    lastAnimal?.nunati.length - 4
                  )}
                <span className="numTrav">
                  {lastAnimal?.nunati?.substring(lastAnimal?.nunati.length - 4)}
                </span>
                {lastAnimal?.nobovi && <> - {lastAnimal?.nobovi}</>}
              </div>
              {errorMessage && (
                <p className="errorMessageQuestion">{errorMessage}</p>
              )}
              <input
                type="date"
                ref={fieldAnswer}
                className={
                  errorAnswer ? "erreur_input input_date" : "input_date"
                }
                onFocus={() => errorAnswer && setErrorAnswer(false)}
              />
              <div className="div_btn_reinit">
                <BigGreenButton
                  text="Vérifier mon identité"
                  onClick={sendAnswer}
                />
              </div>
            </div>
          ) : (
            <>
              {errorMessage && <p className="errorMessage">{errorMessage}</p>}
              <div className="inputs_register" ref={divInputs}>
                <input
                  className={errorNumCheptel ? "erreur_input" : ""}
                  onFocus={() => errorNumCheptel && setErrorNumCheptel(false)}
                  ref={fieldNumCheptel}
                  defaultValue={refNumCheptel?.current ?? ""}
                  type="text"
                  placeholder="Votre numéro de cheptel"
                  required
                  onChange={e =>
                    (e.target.value = e.target.value.toUpperCase().trim())
                  }
                />
                <input
                  className={errorPhone ? "erreur_input" : ""}
                  onFocus={() => errorPhone && setErrorPhone(false)}
                  onChange={formatPhoneNumber}
                  defaultValue={refPhone?.current ?? ""}
                  ref={fieldPhone}
                  type="tel"
                  placeholder="Votre téléphone mobile"
                  required
                />
                <input
                  className={errorEmail ? "erreur_input" : ""}
                  onFocus={() => errorEmail && setErrorEmail(false)}
                  ref={fieldEmail}
                  defaultValue={refEmail?.current ?? ""}
                  type="email"
                  placeholder="Votre adresse email"
                  required
                />
                <div className="div_pass">
                  <input
                    className={errorPass ? "erreur_input" : ""}
                    onFocus={() => errorPass && setErrorPass(false)}
                    ref={fieldPass}
                    defaultValue={refPass?.current ?? ""}
                    autoComplete="new-password"
                    type={showPass ? "text" : "password"}
                    placeholder="Votre mot de passe"
                    onChange={e => (e.target.value = e.target.value.trim())}
                  />
                  <div
                    className="icon_visibility pointer ripple2"
                    onClick={() => setShowPass(v => !v)}
                  >
                    {showPass ? (
                      <span className="material-symbols-outlined">
                        visibility_off
                      </span>
                    ) : (
                      <span className="material-symbols-outlined">
                        visibility
                      </span>
                    )}
                  </div>
                </div>
                <div className="div_pass">
                  <input
                    className={errorConfirmPass ? "erreur_input" : ""}
                    onFocus={() =>
                      errorConfirmPass && setErrorConfirmPass(false)
                    }
                    ref={fieldConfirmPass}
                    defaultValue={refPass?.current ?? ""}
                    autoComplete="new-password"
                    type={showConfirmPass ? "text" : "password"}
                    placeholder="Confirmer votre mot de passe"
                    onChange={e => (e.target.value = e.target.value.trim())}
                  />
                  <div
                    className="icon_visibility pointer ripple2"
                    onClick={() => setShowConfirmPass(v => !v)}
                  >
                    {showConfirmPass ? (
                      <span className="material-symbols-outlined">
                        visibility_off
                      </span>
                    ) : (
                      <span className="material-symbols-outlined">
                        visibility
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="div_btn_reinit">
                <BigGreenButton
                  text="Créer mon compte"
                  onClick={registerAccount}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalRegister
