import "./ModalStatutDl.scss"
import { useEffect, useRef, useState } from "react"
import { useCenterModal } from "../hooks/useCenterModal"
import Loader from "./Loader"
import { goBack } from "../functions/handleNavigation"

const ModalStatutDl = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////

  return (
    <>
      <div id="loader_dl" ref={divModal} className="modal_statut_dl">
        <span
          onClick={goBack}
          className="close_modal ripple2 pointer material-symbols-outlined"
        >
          close
        </span>
        <div className="div_statut">
          <div className="loader_default">
            <Loader
              loadingMessage="Chargement des données en cours..."
              horizontal={true}
            />
          </div>
        </div>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalStatutDl
