import "./CheptelSectionTitle.scss"

const CheptelSectionTitle = ({
  title,
  onClick,
  show,
  id,
  noMargin,
  cheptelPage,
  showCase = false,
  idCaseCochee,
  isCaseCochee,
  onClickCase,
}) => {
  let ripple = show !== undefined ? "ripple2 pointer" : ""
  let clNoMargin = noMargin ? "no_margin" : ""
  let clCheptelPage = cheptelPage ? "cheptelPage" : ""
  let clCase = showCase ? "case_at_left" : ""

  return (
    <div className="div_section_title">
      {showCase && (
        <div
          onClick={onClickCase}
          id={idCaseCochee}
          className={
            isCaseCochee
              ? "cocheConfirmable pointer ripple2 on"
              : "cocheConfirmable pointer ripple2"
          }
        ></div>
      )}
      <h2
        className={`CheptelSectionTitle ${ripple} ${clNoMargin} ${clCase} ${clCheptelPage}`}
        onClick={onClick}
        id={id}
      >
        <p>{title}</p>
        {show !== undefined && (
          <span className="icon_hideshow">{show ? "-" : "+"}</span>
        )}
      </h2>{" "}
    </div>
  )
}

export default CheptelSectionTitle
