import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "./GestionInv.scss"
import { useEffect, useRef, useState } from "react"
import { StrictModeDroppable } from "../components/StrictModeDroppable"
import BigGreenButton from "../components/BigGreenButton"
import { getDefaultInventories } from "../functions/objectColums"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import Confirm from "../components/Confirm"
import { fetchSaveCustInv } from "../functions/fetches/fetchSaveCustInv"
import { fetchSaveOrdInv } from "../functions/fetches/fetchSaveOrdInv"
import { goBack } from "../functions/handleNavigation"

let isMobile = !!navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
)

const GestionInv = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()
  const [invToDelete, setInvToDelete] = useState()

  const defaultList = getDefaultInventories()
  let listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")

  let orderedList = JSON.parse(localStorage.orderedList ?? "[]")
  if (orderedList.length === 0) {
    for (let id in defaultList) {
      orderedList.push(id)
    }
  } else {
    for (let id in defaultList) {
      if (!orderedList.includes(id)) orderedList.push(id)
    }
  }

  const [clAdd, setClAdd] = useState(false)
  pageOption === "addinv" && !clAdd && setClAdd(true)
  pageOption !== "addinv" && clAdd && setClAdd(false)
  const [clErreur, setClErreur] = useState("")

  const inputAdd = useRef()
  const wrapperListInv = useRef()
  const modalAddInv = useRef()

  const [itemList, setItemList] = useState([...orderedList])
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = wrapperListInv.current

    if (curr) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newVal = 132 + addHeight

        curr.style.maxHeight = `calc(calc(var(--vh) * 100) - ${newVal}px)`
      }
    }
  }, [])
  useEffect(() => {
    if (clAdd) {
      let halfWidthScreen = window.innerWidth / 2
      let halWidthModal = modalAddInv.current.offsetWidth / 2
      let leftWidth = halfWidthScreen - halWidthModal

      modalAddInv.current.style.left = `${leftWidth}px`

      inputAdd.current.focus()
    }
  }, [clAdd])
  ///////////////
  // Functions //
  ///////////////
  // Function to update list on drop
  const handleDrop = droppedItem => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return
    var updatedList = [...itemList]
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update my localStorage for save + my var in memory !!
    orderedList = updatedList
    let strOrdList = JSON.stringify(updatedList)
    localStorage.orderedList = strOrdList
    fetchSaveOrdInv(strOrdList)
    // Update State
    setItemList(updatedList)
  }
  const addInv = () => {
    let invName = inputAdd.current.value
    if (invName === "") {
      setClErreur("error")
      return
    }
    let numInv =
      Object.keys(listCustInv).length !== 0
        ? Math.max(...Object.keys(listCustInv)) + 1
        : 1

    listCustInv[numInv] = {
      name: invName,
      columns: [],
      filters: {},
    }

    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)

    orderedList.push("custom" + numInv)
    window.localStorage.choixInv = "custom" + numInv
    let strOrdList = JSON.stringify(orderedList)
    localStorage.orderedList = strOrdList
    fetchSaveOrdInv(strOrdList)

    setItemList([...orderedList])
    inputAdd.current.value = ""
    goBack()
    setTimeout(() => {
      let objDiv = wrapperListInv.current
      objDiv.scrollTop = objDiv.scrollHeight
    })
  }
  const deleteInv = item => {
    let name = listCustInv[+item.replace("custom", "")].name
    setInvToDelete(item)

    doConfirm(`Etes-vous sur de voulour supprimer l'inventaire "${name}"?`)
  }
  const doDeleteInv = confirmed => {
    hideConfirm()

    if (confirmed) {
      orderedList = orderedList.filter(el => el !== invToDelete)
      let strOrdList = JSON.stringify(orderedList)
      localStorage.orderedList = strOrdList
      fetchSaveOrdInv(strOrdList)

      delete listCustInv[+invToDelete.replace("custom", "")]
      let strCustInv = JSON.stringify(listCustInv)
      localStorage.listCustInv = strCustInv
      fetchSaveCustInv(strCustInv)

      setItemList([...orderedList])
    }
  }
  const editInv = item => {
    window.location.hash = `gestioncolumns|${item}`
  }
  const addAnimation = index => {
    if (!isMobile) return
    let el = document.getElementById("item_container" + index)

    el.classList.add("animate")
  }
  const removeAnimation = index => {
    if (!isMobile) return
    let el = document.getElementById("item_container" + index)

    el.classList.remove("animate")
  }
  const inputOnkeyDown = e => {
    if (e.key === "Enter") addInv()
    else setClErreur("")
  }

  return (
    <>
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={doDeleteInv} />
      )}
      <div className="wrapper_button_add">
        <p>Vous pouvez changer l'ordre des inventaires en les déplaçant.</p>
      </div>
      {clAdd && (
        <>
          <div ref={modalAddInv} className={`modal_addinv`}>
            <div className="div_modal_header">
              <span
                className="close_modal ripple2  material-symbols-outlined"
                onClick={goBack}
              >
                close
              </span>
              <h3>Ajouter un inventaire</h3>
            </div>
            <div className="modal_content">
              <div className="wrapper_button">
                <input
                  ref={inputAdd}
                  className={"input_add " + clErreur}
                  placeholder="Nom de l'inventaire"
                  onKeyDown={inputOnkeyDown}
                ></input>
                {clErreur === "error" && inputAdd.current.value === "" && (
                  <p className="erreur_message">
                    Veuillez saisir un nom à votre inventaire s.v.p
                  </p>
                )}
                <BigGreenButton onClick={addInv} text="Ajouter un inventaire" />
              </div>
            </div>
          </div>
          <button className={`background-modal`} onClick={goBack}></button>
        </>
      )}

      <div className="wrapper_list_inv" ref={wrapperListInv}>
        <DragDropContext onDragEnd={handleDrop}>
          <StrictModeDroppable droppableId="list_container">
            {provided => (
              <div
                className="list_container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemList.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {provided => (
                      <div
                        id={"item_container" + index}
                        className="item_container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        onMouseDown={() => addAnimation(index)}
                        onMouseUp={() => removeAnimation(index)}
                        onTouchStart={() => addAnimation(index)}
                        onTouchEnd={() => removeAnimation(index)}
                      >
                        <div className="left_side_inv_cust">
                          <span
                            className="icon_grab material-symbols-outlined"
                            onMouseDown={() => addAnimation(index)}
                            onMouseUp={() => removeAnimation(index)}
                            onTouchStart={() => addAnimation(index)}
                            onTouchEnd={() => removeAnimation(index)}
                          >
                            drag_indicator
                          </span>
                          <p
                            className="inv_name"
                            onMouseDown={() => addAnimation(index)}
                            onMouseUp={() => removeAnimation(index)}
                            onTouchStart={() => addAnimation(index)}
                            onTouchEnd={() => removeAnimation(index)}
                          >
                            {item.includes("custom")
                              ? listCustInv[+item.replace("custom", "")]?.name
                              : defaultList[item].name}
                          </p>
                        </div>
                        {item.includes("custom") && (
                          <div className="icons">
                            <span
                              className=" material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                              onClick={() => editInv(item)}
                            >
                              edit
                            </span>
                            <span
                              className=" material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                              onClick={() => deleteInv(item)}
                            >
                              delete
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </>
  )
}

export default GestionInv
