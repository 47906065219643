import "./AscMat.scss"
import CheptelSectionTitle from "../CheptelSectionTitle"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { useState } from "react"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const AscMat = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.ascmat === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  ////////////////
  // useEffects //
  ////////////////
  const optionIndex = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "IBOVAL",
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Annees",
        },
      },
      y: {
        title: {
          display: true,
          text: "Index",
        },
      },
    },
  }
  const dataIndex = {
    labels: data.campArray,
    datasets: [
      {
        label: "IFNAIS",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: data.nivgenmatIfnais,
        fill: false,
        tension: 0.4,
      },
      {
        label: "CRsev",
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgb(54, 162, 235)",
        data: data.nivgenmatCrsev,
        tension: 0.4,
      },
      {
        label: "DMsev",
        fill: false,
        backgroundColor: "rgb(255, 205, 86)",
        borderColor: "rgb(255, 205, 86)",
        data: data.nivgenmatDmsev,
        tension: 0.4,
      },
      {
        label: "DSsev",
        fill: false,
        backgroundColor: "rgb(255, 159, 64)",
        borderColor: "rgb(255, 159, 64)",
        data: data.nivgenmatDssev,
        tension: 0.4,
      },
      {
        label: "FOSsev",
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgb(75, 192, 192)",
        data: data.nivgenmatFossev,
        tension: 0.4,
      },
      {
        label: "ISEVR",
        fill: false,
        backgroundColor: "rgb(153, 102, 255)",
        borderColor: "rgb(153, 102, 255)",
        data: data.nivgenmatIsevr,
        tension: 0.4,
      },
      {
        label: "AVEL",
        fill: false,
        backgroundColor: "rgb(204,0,0)",
        borderColor: "rgb(204,0,0)",
        data: data.nivgenmatAvel,
        tension: 0.4,
      },
      {
        label: "ALait",
        fill: false,
        backgroundColor: "rgb(12, 192, 0)",
        borderColor: "rgb(12, 192, 0)",
        data: data.nivgenmatAlait,
        tension: 0.4,
      },
      {
        label: "IVMAT",
        fill: false,
        backgroundColor: "rgb(12, 0, 192)",
        borderColor: "rgb(12, 0, 192)",
        data: data.nivgenmatIvmat,
        tension: 0.4,
      },
    ],
  }
  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.ascmat = !show
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div>
      <CheptelSectionTitle
        title={
          screenWide
            ? "Niveau génétique de l'ascendance maternelle"
            : "Niveau génétique de l'asc. maternelle"
        }
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="asc_mat">
          <Line options={optionIndex} data={dataIndex} />
          <div className="infos-prebottom">
            Niveau génétique de l'ascendance maternelle
          </div>
          <div className="infos-bottom">Données BGTA</div>
        </div>
      )}
    </div>
  )
}

export default AscMat
