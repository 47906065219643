import { useEffect, useRef, useState } from "react"
import CheptelSectionTitle from "../components/CheptelSectionTitle"
import "./Station.scss"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalLoader from "../components/ModalLoader"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { todayDate } from "../functions/todayDate"
import { formatDate2, formatDate5 } from "../functions/formatDate"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
)

let isScrolling // Setup isScrolling variable
let clickHeld = false
let hasMoved = false
let mouseX = 0
let mouseY = 0
let originMouseX = 0
let originMouseY = 0

const Station = ({ data, pageOption, moyennes }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let clScroll = ""
  if (window.localStorage.platformNative === "ios") clScroll = "ios_scroll"

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  const [showLanaud, setShowLanaud] = useState(
    (localStorage.stShowLanaud ?? "true") === "true"
  )
  let tmpShowAbl = data.nbABL
    ? (localStorage.stShowABL ?? "true") === "true"
    : false
  const [showABL, setShowABL] = useState(tmpShowAbl)
  let tmpShowGelioc = data.nbGelioc
    ? (localStorage.stShowGelioc ?? "true") === "true"
    : false
  const [showGelioc, setShowGelioc] = useState(tmpShowGelioc)
  let tmpShowGevial = data.nbGevial
    ? (localStorage.stShowGevial ?? "true") === "true"
    : false
  const [showGevial, setShowGevial] = useState(tmpShowGevial)
  let tmpNbStation = 0
  if (showLanaud) tmpNbStation++
  if (showABL) tmpNbStation++
  if (showGelioc) tmpNbStation++
  if (showGevial) tmpNbStation++

  const [nbStation, setNbStation] = useState(tmpNbStation)

  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  let divTitleDeclar = useRef()
  let divWrap = useRef()
  let divDeclar = useRef()
  let divActuel = useRef()
  let divHisto = useRef()
  let aniDeclar = []
  let aniHistorique = []
  let aniStation = []

  sortAniInCorrectInvnetory(showLanaud, showABL, showGelioc, showGevial)

  let displayLegend = false

  if (
    (showLanaud && (showABL || showGelioc || showGevial)) ||
    (showABL && (showLanaud || showGelioc || showGevial)) ||
    (showGelioc && (showABL || showLanaud || showGevial)) ||
    (showGevial && (showABL || showGelioc || showLanaud))
  )
    displayLegend = true

  const optionsGMQ = {
    responsive: true,
    spanGaps: true,
    plugins: {
      title: {
        display: false,
        text: "GMQ",
      },
      legend: {
        display: displayLegend,
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
        onClick: null,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
          text: "Date",
        },
      },
      y: {
        title: {
          display: false,
          text: "Kg",
        },
      },
    },
  }

  let datasets = []

  if (showLanaud && data.moyPoidsEntreeLanaud > 0) {
    let dataOfSet

    if (!showABL && !showGelioc && !showGevial)
      dataOfSet = [
        data.moyPoidsEntreeLanaud,
        data.moyPoidsDDLanaud,
        data.moyPoidsP1Lanaud,
        data.moyPoidsDFLanaud,
      ]
    else
      dataOfSet = [
        data.moyPoidsEntreeLanaud,
        data.moyPoidsDDLanaud,
        data.moyPoidsP1Lanaud,
        null,
        data.moyPoidsDFLanaud,
      ]

    datasets.push({
      label: "Lanaud",
      backgroundColor: "#0075fe",
      borderColor: "#0075fe",
      data: dataOfSet,
      tension: 0.4,
    })
  }
  if (showABL && data.moyPoidsEntreeABL > 0) {
    datasets.push({
      label: "ABL",
      backgroundColor: "#cc9900",
      borderColor: "#cc9900",
      data: [
        data.moyPoidsEntreeABL,
        data.moyPoidsDDABL,
        data.moyPoidsP1ABL,
        data.moyPoidsP2ABL,
        data.moyPoidsDFABL,
      ],
      tension: 0.4,
    })
  }
  if (showGelioc && data.moyPoidsEntreeGelioc > 0) {
    datasets.push({
      label: "Gelioc",
      backgroundColor: "#54A861",
      borderColor: "#54A861",
      data: [
        data.moyPoidsEntreeGelioc,
        data.moyPoidsDDGelioc,
        data.moyPoidsP1Gelioc,
        data.moyPoidsP2Gelioc,
        data.moyPoidsDFGelioc,
      ],
      tension: 0.4,
    })
  }
  if (showGevial && data.moyPoidsEntreeGevial > 0) {
    datasets.push({
      label: "Gevial",
      backgroundColor: "#aa611a",
      borderColor: "#aa611a",
      data: [
        data.moyPoidsEntreeGevial,
        data.moyPoidsDDGevial,
        data.moyPoidsP1Gevial,
        data.moyPoidsP2Gevial,
        data.moyPoidsDFGevial,
      ],
      tension: 0.4,
    })
  }

  let labels =
    !showABL && !showGelioc && !showGevial
      ? [
          "Poids entree",
          "Poids début de contrôle",
          "Poids P1",
          "Poids fin de contrôle",
        ]
      : [
          "Poids entree",
          "Poids début de contrôle",
          "Poids P1",
          "Poids P2",
          "Poids fin de contrôle",
        ]

  const dataGMQ = {
    labels: labels,
    datasets: datasets,
  }

  const [showDeclar, setShowDeclar] = useState(
    (localStorage.showDeclar ?? "true") === "true"
  )
  const [showStation, setShowStation] = useState(
    (localStorage.showStation ?? "true") === "true"
  )
  const [showHisto, setShowHisto] = useState(
    (localStorage.showHisto ?? "true") === "true"
  )

  let cacheColDeclar = localStorage.customsortAniDeclar ?? "serie"
  let cacheColStation = localStorage.customsortAniStation ?? "serie"
  let cacheColHisto = localStorage.customsortAniHisto ?? "serie"

  let cacheClSensDeclar =
    (localStorage.customsensAniDeclar ?? "up") === "up" ? "sortDown" : "sortUp"
  let clStationD = cacheColDeclar === "station" ? cacheClSensDeclar : ""
  let clSerieD = cacheColDeclar === "serie" ? cacheClSensDeclar : ""
  let clNumD = cacheColDeclar === "num_animal" ? cacheClSensDeclar : ""
  let clExamD = cacheColDeclar === "exam" ? cacheClSensDeclar : ""
  let clPereD = cacheColDeclar === "num_pere" ? cacheClSensDeclar : ""
  let clMereD = cacheColDeclar === "num_mere" ? cacheClSensDeclar : ""

  let cacheClSensStation =
    (localStorage.customsensAniStation ?? "up") === "up" ? "sortDown" : "sortUp"
  let clStationS = cacheColStation === "station" ? cacheClSensStation : ""
  let clSerieS = cacheColStation === "serie" ? cacheClSensStation : ""
  let clNumS = cacheColStation === "num_animal" ? cacheClSensStation : ""
  let clTagS = cacheColStation === "tag" ? cacheClSensStation : ""
  let clQualS = cacheColStation === "qualif" ? cacheClSensStation : ""
  let clPereS = cacheColStation === "num_pere" ? cacheClSensStation : ""
  let clMereS = cacheColStation === "num_mere" ? cacheClSensStation : ""

  let cacheClSensHisto =
    (localStorage.customsensAniHisto ?? "up") === "up" ? "sortDown" : "sortUp"
  let clStationH = cacheColHisto === "station" ? cacheClSensHisto : ""
  let clSerieH = cacheColHisto === "serie" ? cacheClSensHisto : ""
  let clNumH = cacheColHisto === "num_animal" ? cacheClSensHisto : ""
  let clTagH = cacheColHisto === "tag" ? cacheClSensHisto : ""
  let clQualH = cacheColHisto === "qualif" ? cacheClSensHisto : ""
  let clPereH = cacheColHisto === "num_pere" ? cacheClSensHisto : ""
  let clMereH = cacheColHisto === "num_mere" ? cacheClSensHisto : ""
  let clPdveH = cacheColHisto === "prix_de_vente" ? cacheClSensHisto : ""
  let clAchH = cacheColHisto === "acheteur" ? cacheClSensHisto : ""

  const [sensStationDeclar, setSensStationDeclar] = useState(clStationD)
  const [sensSerieDeclar, setSensSerieDeclar] = useState(clSerieD)
  const [sensNumDeclar, setSensNumDeclar] = useState(clNumD)
  const [sensExamDeclar, setSensExamDeclar] = useState(clExamD)
  const [sensPereDeclar, setSensPereDeclar] = useState(clPereD)
  const [sensMereDeclar, setSensMereDeclar] = useState(clMereD)

  const [sensStationStation, setSensStationStation] = useState(clStationS)
  const [sensSerieStation, setSensSerieStation] = useState(clSerieS)
  const [sensNumStation, setSensNumStation] = useState(clNumS)
  const [sensTagStation, setSensTagStation] = useState(clTagS)
  const [sensQualStation, setSensQualStation] = useState(clQualS)
  const [sensPereStation, setSensPereStation] = useState(clPereS)
  const [sensMereStation, setSensMereStation] = useState(clMereS)

  const [sensStationHisto, setSensStationHisto] = useState(clStationH)
  const [sensSerieHisto, setSensSerieHisto] = useState(clSerieH)
  const [sensNumHisto, setSensNumHisto] = useState(clNumH)
  const [sensTagHisto, setSensTagHisto] = useState(clTagH)
  const [sensQualHisto, setSensQualHisto] = useState(clQualH)
  const [sensPereHisto, setSensPereHisto] = useState(clPereH)
  const [sensMereHisto, setSensMereHisto] = useState(clMereH)
  const [sensPdvHisto, setSensPdvHisto] = useState(clPdveH)
  const [sensAcheteurHisto, setSensAcheteurHisto] = useState(clAchH)

  const [declarSorted, setDeclarSorted] = useState(
    sortDeclar(cacheColDeclar, false, false)
  )
  const [stationSorted, setStationSorted] = useState(
    sortStation(cacheColStation, false, false)
  )
  const [histoSorted, setHistoSorted] = useState(
    sortHisto(cacheColHisto, false, false)
  )

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = divWrap.current

    if (div) {
      div.scrollLeft = localStorage.scrollXPageStation ?? 0
      div.scrollTop = localStorage.scrollYPageStation ?? 0
    }

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = addHeight + 60

      div.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }
  }, [])
  useEffect(() => {
    window.localStorage.showDeclar = showDeclar
  }, [showDeclar])
  useEffect(() => {
    window.localStorage.showStation = showStation
  }, [showStation])
  useEffect(() => {
    window.localStorage.showHisto = showHisto
  }, [showHisto])
  useEffect(() => {
    window.localStorage.stShowLanaud = showLanaud
    window.localStorage.stShowABL = showABL
    window.localStorage.stShowGelioc = showGelioc
    window.localStorage.stShowGevial = showGevial
  }, [showLanaud, showABL, showGelioc, showGelioc])
  useEffect(() => {
    let hdr = document.getElementById("header")

    const event = new CustomEvent("nombreStation", {
      detail: {
        nbStation: nbStation,
      },
    })

    if (hdr) hdr.dispatchEvent(event)
  }, [nbStation])
  ///////////////
  // Functions //
  ///////////////
  const hideShowDeclar = () => {
    setShowDeclar(!showDeclar)
  }
  const hideShowStation = () => {
    setShowStation(!showStation)
  }
  const hideShowHisto = () => {
    setShowHisto(!showHisto)
  }
  function sortDeclar(col, changeSens = true, setVisual = true) {
    let sens = localStorage.getItem("customsensAniDeclar") ?? ""

    if (col !== localStorage.getItem("customsortAniDeclar")) {
      localStorage.setItem("customsortAniDeclar", col)
      localStorage.setItem("customsensAniDeclar", "")
      sens = ""
    }

    if (changeSens) {
      if (sens === "up") {
        localStorage.setItem("customsensAniDeclar", "down")
        sens = "down"
      } else {
        localStorage.setItem("customsensAniDeclar", "up")
        sens = "up"
      }
    }

    let datatmp = structuredClone(aniDeclar)

    if (col === "station") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationDeclar("sortUp")
      }

      if (setVisual) {
        setSensSerieDeclar("")
        setSensNumDeclar("")
        setSensExamDeclar("")
        setSensPereDeclar("")
        setSensMereDeclar("")
      }
    } else if (col === "serie") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieDeclar("sortUp")
      }

      if (setVisual) {
        setSensStationDeclar("")
        setSensNumDeclar("")
        setSensExamDeclar("")
        setSensPereDeclar("")
        setSensMereDeclar("")
      }
    } else if (col === "num_animal") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumDeclar("sortUp")
      }

      if (setVisual) {
        setSensStationDeclar("")
        setSensSerieDeclar("")
        setSensExamDeclar("")
        setSensPereDeclar("")
        setSensMereDeclar("")
      }
    } else if (col === "exam") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) =>
          frCollator.compare(a["resexa"] ?? "", b["resexa"] ?? "")
        )

        if (setVisual) setSensExamDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          frCollator.compare(a["resexa"] ?? "", b["resexa"] ?? "")
        )

        if (setVisual) setSensExamDeclar("sortUp")
      }

      if (setVisual) {
        setSensStationDeclar("")
        setSensSerieDeclar("")
        setSensNumDeclar("")
        setSensPereDeclar("")
        setSensMereDeclar("")
      }
    } else if (col === "num_pere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereDeclar("sortUp")
      }

      if (setVisual) {
        setSensStationDeclar("")
        setSensSerieDeclar("")
        setSensNumDeclar("")
        setSensExamDeclar("")
        setSensMereDeclar("")
      }
    } else if (col === "num_mere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereDeclar("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereDeclar("sortUp")
      }

      if (setVisual) {
        setSensStationDeclar("")
        setSensSerieDeclar("")
        setSensNumDeclar("")
        setSensExamDeclar("")
        setSensPereDeclar("")
      }
    }

    return datatmp
  }
  const applySortDeclar = col => {
    setDeclarSorted(sortDeclar(col, true, true))
  }
  function sortStation(col, changeSens = true, setVisual = true) {
    let sens = localStorage.getItem("customsensAniStation") ?? ""

    if (col !== localStorage.getItem("customsortAniStation")) {
      localStorage.setItem("customsortAniStation", col)
      localStorage.setItem("customsensAniStation", "")
      sens = ""
    }

    if (changeSens) {
      if (sens === "up") {
        localStorage.setItem("customsensAniStation", "down")
        sens = "down"
      } else {
        localStorage.setItem("customsensAniStation", "up")
        sens = "up"
      }
    }

    let datatmp = structuredClone(aniStation)

    if (col === "station") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationStation("sortUp")
      }

      if (setVisual) {
        setSensSerieStation("")
        setSensNumStation("")
        setSensTagStation("")
        setSensQualStation("")
        setSensPereStation("")
        setSensMereStation("")
      }
    } else if (col === "serie") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensNumStation("")
        setSensTagStation("")
        setSensQualStation("")
        setSensPereStation("")
        setSensMereStation("")
      }
    } else if (col === "num_animal") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensSerieStation("")
        setSensTagStation("")
        setSensQualStation("")
        setSensPereStation("")
        setSensMereStation("")
      }
    } else if (col === "tag") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nuinst"] > +b["nuinst"] ? 1 : +b["nuinst"] > +a["nuinst"] ? -1 : 0
        )

        if (setVisual) setSensTagStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nuinst"] > +b["nuinst"] ? 1 : +b["nuinst"] > +a["nuinst"] ? -1 : 0
        )

        if (setVisual) setSensTagStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensSerieStation("")
        setSensNumStation("")
        setSensQualStation("")
        setSensPereStation("")
        setSensMereStation("")
      }
    } else if (col === "qualif") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) =>
          frCollator.compare(
            a?.["A_boqual_2_liqure"] ?? "",
            b?.["A_boqual_2_liqure"] ?? ""
          )
        )

        if (setVisual) setSensQualStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          frCollator.compare(
            a?.["A_boqual_2_liqure"] ?? "",
            b?.["A_boqual_2_liqure"] ?? ""
          )
        )

        if (setVisual) setSensQualStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensSerieStation("")
        setSensNumStation("")
        setSensTagStation("")
        setSensPereStation("")
        setSensMereStation("")
      }
    } else if (col === "num_pere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensSerieStation("")
        setSensNumStation("")
        setSensTagStation("")
        setSensQualStation("")
        setSensMereStation("")
      }
    } else if (col === "num_mere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereStation("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereStation("sortUp")
      }

      if (setVisual) {
        setSensStationStation("")
        setSensSerieStation("")
        setSensNumStation("")
        setSensTagStation("")
        setSensQualStation("")
        setSensPereStation("")
      }
    }

    return datatmp
  }
  const applySortStation = col => {
    setStationSorted(sortStation(col, true, true))
  }
  function sortHisto(col, changeSens = true, setVisual = true) {
    let sens = localStorage.getItem("customsensAniHisto") ?? ""

    if (col !== localStorage.getItem("customsortAniHisto")) {
      localStorage.setItem("customsortAniHisto", col)
      localStorage.setItem("customsensAniHisto", "")
      sens = ""
    }

    if (changeSens) {
      if (sens === "up") {
        localStorage.setItem("customsensAniHisto", "down")
        sens = "down"
      } else {
        localStorage.setItem("customsensAniHisto", "up")
        sens = "up"
      }
    }

    let datatmp = structuredClone(aniHistorique)

    if (col === "station") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let valA = ""
          let valB = ""

          switch (a["costat"]) {
            case "871":
              valA = "Lanaud"
              break
            case "191":
              valA = "ABL"
              break
            case "122":
              valA = "Gelioc"
              break
            case "232":
              valA = "Gevial"
              break

            default:
              break
          }
          switch (b["costat"]) {
            case "871":
              valB = "Lanaud"
              break
            case "191":
              valB = "ABL"
              break
            case "122":
              valB = "Gelioc"
              break
            case "232":
              valB = "Gevial"
              break

            default:
              break
          }

          return frCollator.compare(valA ?? "", valB ?? "")
        })

        if (setVisual) setSensStationHisto("sortUp")
      }

      if (setVisual) {
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "serie") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +(a["caenst"] + a["nuseri"]) > +(b["caenst"] + b["nuseri"])
            ? 1
            : +(b["caenst"] + b["nuseri"]) > +(a["caenst"] + a["nuseri"])
            ? -1
            : 0
        )

        if (setVisual) setSensSerieHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "num_animal") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nunati"].substr(a["nunati"].length - 4) >
          +b["nunati"].substr(b["nunati"].length - 4)
            ? 1
            : +b["nunati"].substr(b["nunati"].length - 4) >
              +a["nunati"].substr(a["nunati"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensNumHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "tag") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nuinst"] > +b["nuinst"] ? 1 : +b["nuinst"] > +a["nuinst"] ? -1 : 0
        )

        if (setVisual) setSensTagHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nuinst"] > +b["nuinst"] ? 1 : +b["nuinst"] > +a["nuinst"] ? -1 : 0
        )

        if (setVisual) setSensTagHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "qualif") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) =>
          frCollator.compare(
            a?.["A_boqual_2_liqure"] ?? "",
            b?.["A_boqual_2_liqure"] ?? ""
          )
        )

        if (setVisual) setSensQualHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          frCollator.compare(
            a?.["A_boqual_2_liqure"] ?? "",
            b?.["A_boqual_2_liqure"] ?? ""
          )
        )

        if (setVisual) setSensQualHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "num_pere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["nupere"].substr(a["nupere"].length - 4) >
          +b["nupere"].substr(b["nupere"].length - 4)
            ? 1
            : +b["nupere"].substr(b["nupere"].length - 4) >
              +a["nupere"].substr(a["nupere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensPereHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "num_mere") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a["numere"].substr(a["numere"].length - 4) >
          +b["numere"].substr(b["numere"].length - 4)
            ? 1
            : +b["numere"].substr(b["numere"].length - 4) >
              +a["numere"].substr(a["numere"].length - 4)
            ? -1
            : 0
        )

        if (setVisual) setSensMereHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensPdvHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "prix_de_vente") {
      if (sens === "up") {
        datatmp.sort((a, b) => {
          let val1 = a["prix_vente"]
          let val2 = b["prix_vente"]
          if (!val1) val1 = 0
          else val1 = parseInt(val1)
          if (!val2) val2 = 0
          else val2 = parseInt(val2)

          return val1 > val2 ? 1 : val1 < val2 ? -1 : 1
        })

        if (setVisual) setSensPdvHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let val1 = a["prix_vente"]
          let val2 = b["prix_vente"]
          if (!val1) val1 = 0
          else val1 = parseInt(val1)
          if (!val2) val2 = 0
          else val2 = parseInt(val2)

          return val1 > val2 ? 1 : val1 < val2 ? -1 : 1
        })

        if (setVisual) setSensPdvHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensAcheteurHisto("")
      }
    } else if (col === "acheteur") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) =>
          frCollator.compare(
            a?.["acheteur_nom"] ?? "",
            b?.["acheteur_nom"] ?? ""
          )
        )

        if (setVisual) setSensAcheteurHisto("sortDown")
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          frCollator.compare(
            a?.["acheteur_nom"] ?? "",
            b?.["acheteur_nom"] ?? ""
          )
        )

        if (setVisual) setSensAcheteurHisto("sortUp")
      }

      if (setVisual) {
        setSensStationHisto("")
        setSensSerieHisto("")
        setSensNumHisto("")
        setSensTagHisto("")
        setSensQualHisto("")
        setSensPereHisto("")
        setSensMereHisto("")
        setSensPdvHisto("")
      }
    }

    return datatmp
  }
  const applySortHisto = col => {
    setHistoSorted(sortHisto(col, true, true))
  }
  const cocheCase = station => {
    let tmpLanaud = showLanaud
    let tmpABL = showABL
    let tmpGelioc = showGelioc
    let tmpGevial = showGevial

    switch (station) {
      case "lanaud":
        setShowLanaud(!showLanaud)
        tmpLanaud = !showLanaud
        break
      case "abl":
        setShowABL(!showABL)
        tmpABL = !showABL
        break
      case "gelioc":
        setShowGelioc(!showGelioc)
        tmpGelioc = !showGelioc
        break
      case "gevial":
        setShowGevial(!showGevial)
        tmpGevial = !showGevial
        break

      default:
        break
    }

    let tmpNbStation = 0

    if (tmpLanaud) tmpNbStation++
    if (tmpABL) tmpNbStation++
    if (tmpGelioc) tmpNbStation++
    if (tmpGevial) tmpNbStation++

    setNbStation(tmpNbStation)

    if (
      !(
        (tmpLanaud && (tmpABL || tmpGelioc || tmpGevial)) ||
        (tmpABL && (tmpLanaud || tmpGelioc || tmpGevial)) ||
        (tmpGelioc && (tmpABL || tmpLanaud || tmpGevial)) ||
        (tmpGevial && (tmpABL || tmpGelioc || tmpLanaud))
      )
    ) {
      if (sensStationStation !== "") {
        setSensStationStation("")
        setSensSerieStation("sortUp")
        localStorage.setItem("customsortAniStation", "serie")
        localStorage.setItem("customsensAniStation", "down")
      }
      if (sensStationHisto !== "") {
        setSensStationHisto("")
        setSensSerieHisto("sortUp")
        localStorage.setItem("customsortAniHisto", "serie")
        localStorage.setItem("customsensAniHisto", "down")
      }
    }

    sortAniInCorrectInvnetory(tmpLanaud, tmpABL, tmpGelioc, tmpGevial)

    setDeclarSorted(
      sortDeclar(localStorage.getItem("customsortAniDeclar"), false, false)
    )
    setStationSorted(
      sortStation(localStorage.getItem("customsortAniStation"), false, false)
    )
    setHistoSorted(
      sortHisto(localStorage.getItem("customsortAniHisto"), false, false)
    )
  }
  function sortAniInCorrectInvnetory(tmpLanaud, tmpABL, tmpGelioc, tmpGevial) {
    aniHistorique = []
    aniStation = []
    aniDeclar = []

    data.animaux.forEach((ani, index) => {
      ani.indexTable = index

      if (
        (tmpLanaud && ani.costat === "871") ||
        (tmpABL && ani.costat === "191") ||
        (tmpGelioc && ani.costat === "122") ||
        (tmpGevial && ani.costat === "232")
      ) {
        if (ani.coentr === "E") {
          if (!ani.dasort) {
            if (todayDate("numeric") > +formatDate5(ani.date_vente))
              aniHistorique.push(ani)
            else aniStation.push(ani)
          } else aniHistorique.push(ani)
        } else if (ani.coentr === "") {
          let date_pa =
            moyennes?.[ani.costat]?.[ani.caenst]?.[ani.nuseri]?.["PA"].date

          if (!date_pa) {
            aniDeclar.push(ani)
          } else {
            let annee = date_pa.substring(0, 4)
            let mois = date_pa.substring(4, 6)
            let jour = date_pa.substring(6, 8)
            let date_pa_format = `${annee}-${mois}-${jour}T00:00:00`

            let date = new Date(date_pa_format)
            let ts = date.getTime()

            if (ts > Date.now()) aniDeclar.push(ani)
          }
        }
      }
    })
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Methods to handle moving the table by holding the mouse (kinda like touch behaviour but with a mouse) //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const tbodyMouseDown = (e, div) => {
    if (e.button !== 0) return

    clickHeld = true
    if (!isMobile) div.current.classList.add("cursor_grab")

    mouseX = e.nativeEvent.pageX
    mouseY = e.nativeEvent.pageY
    originMouseX = e.nativeEvent.pageX
    originMouseY = e.nativeEvent.pageY
  }
  const tbodyMouseMove = (e, div) => {
    if (clickHeld) {
      div.current.scrollBy(mouseX - e.nativeEvent.pageX, 0)
      divWrap.current.scrollBy(0, mouseY - e.nativeEvent.pageY)

      if (Math.abs(originMouseX - e.nativeEvent.pageX) > 10) hasMoved = true
      if (Math.abs(originMouseY - e.nativeEvent.pageY) > 10) hasMoved = true

      mouseX = e.nativeEvent.pageX
      mouseY = e.nativeEvent.pageY
    }
  }
  const tbodyMouseUp = (e, div) => {
    if (clickHeld) {
      if (!isMobile) div.current.classList.remove("cursor_grab")
      clickHeld = false
      setTimeout(() => {
        hasMoved = false
      }, 0)
    }
  }
  // Method to save scroll pos in inventaire
  const checkScroll = () => {
    let div = divWrap.current
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling)

    let titleDeclar = divTitleDeclar.current

    if (titleDeclar) {
      let wrapRects = div.getBoundingClientRect()
      let rects = titleDeclar.getBoundingClientRect()

      if (wrapRects.top + 20 >= rects.top) {
        titleDeclar.classList.add("one_line")
      } else {
        titleDeclar.classList.remove("one_line")
      }
    }

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(() => {
      if (div) {
        localStorage.setItem("scrollXPageStation", div.scrollLeft)
        localStorage.setItem("scrollYPageStation", div.scrollTop)
      }
    }, 250)
  }

  return (
    <div
      id="page_station"
      className={
        window.localStorage.platformNative === "ios"
          ? "page_station fix_scrollbar_ios"
          : "page_station"
      }
      ref={divWrap}
      onScroll={checkScroll}
    >
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_station_data"
        message="Mise à jour des données en cours..."
      />

      <div className="top_side">
        <div className="line_case_station">
          <div
            className="case_station pointer"
            onClick={() => cocheCase("lanaud")}
          >
            <div
              className={
                showLanaud ? "cocheConfirmable on" : "cocheConfirmable"
              }
            ></div>
            <p>Lanaud</p>
          </div>
          {data.nbABL !== 0 && data.nbABL && (
            <div
              className="case_station pointer"
              onClick={() => cocheCase("abl")}
            >
              <div
                className={showABL ? "cocheConfirmable on" : "cocheConfirmable"}
              ></div>
              <p>ABL</p>
            </div>
          )}
          {data.nbGelioc !== 0 && data.nbGelioc && (
            <div
              className="case_station pointer"
              onClick={() => cocheCase("gelioc")}
            >
              <div
                className={
                  showGelioc ? "cocheConfirmable on" : "cocheConfirmable"
                }
              ></div>
              <p>Gelioc</p>
            </div>
          )}
          {data.nbGevial !== 0 && data.nbGevial && (
            <div
              className="case_station pointer"
              onClick={() => cocheCase("gevial")}
            >
              <div
                className={
                  showGevial ? "cocheConfirmable on" : "cocheConfirmable"
                }
              ></div>
              <p>Gevial</p>
            </div>
          )}
        </div>
        <div className="graph_moy_gmq">
          <div className="legend_y_axis">kg</div>
          <Line options={optionsGMQ} data={dataGMQ} />
          <div className="legend_graph_moy_gmq">
            Résultats moyens de chaque pesées de vos animaux
          </div>
        </div>
      </div>

      {declarSorted.length !== 0 && (
        <>
          <div
            ref={divTitleDeclar}
            className={
              pageOption === "statutdl"
                ? `div_title_section inBackground ${clScroll}`
                : `div_title_section ${clScroll}`
            }
          >
            <CheptelSectionTitle
              title={
                aniDeclar.length +
                " animaux déclarés pour les prochaines séries"
              }
              onClick={hideShowDeclar}
              show={showDeclar}
              noMargin={true}
            />
          </div>

          {showDeclar && (
            <>
              {declarSorted.length !== 0 ? (
                <div
                  className="div_station_table"
                  onMouseDown={e => tbodyMouseDown(e, divDeclar)}
                  onMouseUp={e => tbodyMouseUp(e, divDeclar)}
                  onMouseMove={e => tbodyMouseMove(e, divDeclar)}
                  onMouseLeave={e => tbodyMouseUp(e, divDeclar)}
                  ref={divDeclar}
                >
                  <table className="station_table">
                    <thead>
                      <tr>
                        {((showLanaud &&
                          (showABL || showGelioc || showGevial)) ||
                          (showABL &&
                            (showLanaud || showGelioc || showGevial)) ||
                          (showGelioc &&
                            (showABL || showLanaud || showGevial)) ||
                          (showGevial &&
                            (showABL || showGelioc || showLanaud))) && (
                          <th
                            className={sensStationDeclar}
                            onClick={() => applySortDeclar("station")}
                          >
                            Station
                          </th>
                        )}
                        <th
                          className={sensSerieDeclar}
                          onClick={() => applySortDeclar("serie")}
                        >
                          Série
                        </th>
                        <th
                          className={sensNumDeclar}
                          onClick={() => applySortDeclar("num_animal")}
                        >
                          Numéro animal
                        </th>
                        <th
                          className={sensExamDeclar}
                          onClick={() => applySortDeclar("exam")}
                        >
                          Résulat examen
                        </th>
                        <th
                          className={sensPereDeclar}
                          onClick={() => applySortDeclar("num_pere")}
                        >
                          Numéro père
                        </th>
                        <th
                          className={sensMereDeclar}
                          onClick={() => applySortDeclar("num_mere")}
                        >
                          Numéro mère
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {declarSorted.map(ani => (
                        <tr
                          key={ani.copaip + ani.nunati}
                          onClick={() =>
                            !hasMoved &&
                            (window.location.hash = `fichestation|${ani.indexTable}`)
                          }
                          className="pointer"
                        >
                          {((showLanaud &&
                            (showABL || showGelioc || showGevial)) ||
                            (showABL &&
                              (showLanaud || showGelioc || showGevial)) ||
                            (showGelioc &&
                              (showABL || showLanaud || showGevial)) ||
                            (showGevial &&
                              (showABL || showGelioc || showLanaud))) && (
                            <td>
                              {(() => {
                                if (ani.costat === "871") return "Lanaud"
                                if (ani.costat === "191") return "ABL"
                                if (ani.costat === "122") return "Gelioc"
                                if (ani.costat === "232") return "Gevial"
                              })()}
                            </td>
                          )}
                          <td>{`${ani.caenst}/${ani.nuseri}`}</td>
                          <td>
                            <div className="ani_station">
                              <div className="num_nom_station">
                                <p>
                                  {ani.copaip + ani.nunati.substr(0, 6)}
                                  <span className="numtrav">
                                    {ani.nunati.substr(6)}
                                  </span>
                                </p>
                                <p>{ani.nobovi}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            {ani.resexa === "R"
                              ? "Retenu"
                              : ani.resexa === "N"
                              ? "Non retenu"
                              : ""}
                          </td>
                          <td>
                            <div className="ani_station">
                              <div className="num_nom_station">
                                <p>{ani.nobovi_p}</p>
                                <p>
                                  {ani.copape + ani.nupere.substr(0, 6)}
                                  <span className="numtrav">
                                    {ani.nupere.substr(6)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ani_station">
                              <div className="num_nom_station">
                                <p>{ani.nobovi_m}</p>
                                <p>
                                  {ani.copame + ani.numere.substr(0, 6)}
                                  <span className="numtrav">
                                    {ani.numere.substr(6)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="alert">
                  Pas d'animaux déclarés pour les prochaines séries
                </div>
              )}
            </>
          )}
          <div className="inbetween"></div>
        </>
      )}

      <div
        className={
          pageOption === "statutdl"
            ? `div_title_section inBackground ${clScroll}`
            : `div_title_section ${clScroll}`
        }
      >
        <CheptelSectionTitle
          title={
            aniStation.length +
            " animaux actuellement en " +
            ((showLanaud && (showABL || showGelioc || showGevial)) ||
            (showABL && (showLanaud || showGelioc || showGevial)) ||
            (showGelioc && (showABL || showLanaud || showGevial)) ||
            (showGevial && (showABL || showGelioc || showLanaud))
              ? "stations"
              : "station")
          }
          onClick={hideShowStation}
          show={showStation}
          noMargin={true}
        />
      </div>
      {showStation && (
        <>
          {stationSorted.length !== 0 ? (
            <div
              className="div_station_table"
              onMouseDown={e => tbodyMouseDown(e, divActuel)}
              onMouseUp={e => tbodyMouseUp(e, divActuel)}
              onMouseMove={e => tbodyMouseMove(e, divActuel)}
              onMouseLeave={e => tbodyMouseUp(e, divActuel)}
              ref={divActuel}
            >
              <table className="station_table">
                <thead>
                  <tr>
                    {((showLanaud && (showABL || showGelioc || showGevial)) ||
                      (showABL && (showLanaud || showGelioc || showGevial)) ||
                      (showGelioc && (showABL || showLanaud || showGevial)) ||
                      (showGevial &&
                        (showABL || showGelioc || showLanaud))) && (
                      <th
                        className={sensStationStation}
                        onClick={() => applySortStation("station")}
                      >
                        Station
                      </th>
                    )}
                    <th
                      className={sensSerieStation}
                      onClick={() => applySortStation("serie")}
                    >
                      Série
                    </th>
                    <th
                      className={sensNumStation}
                      onClick={() => applySortStation("num_animal")}
                    >
                      Numéro animal
                    </th>
                    <th
                      className={sensTagStation}
                      onClick={() => applySortStation("tag")}
                    >
                      TAG
                    </th>
                    <th
                      className={sensQualStation}
                      onClick={() => applySortStation("qualif")}
                    >
                      Qualif.
                    </th>
                    <th
                      className={sensPereStation}
                      onClick={() => applySortStation("num_pere")}
                    >
                      Numéro père
                    </th>
                    <th
                      className={sensMereStation}
                      onClick={() => applySortStation("num_mere")}
                    >
                      Numéro mère
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stationSorted.map(ani => (
                    <tr
                      key={ani.copaip + ani.nunati}
                      onClick={() =>
                        !hasMoved &&
                        (window.location.hash = `fichestation|${ani.indexTable}`)
                      }
                      className="pointer"
                    >
                      {((showLanaud && (showABL || showGelioc || showGevial)) ||
                        (showABL && (showLanaud || showGelioc || showGevial)) ||
                        (showGelioc && (showABL || showLanaud || showGevial)) ||
                        (showGevial &&
                          (showABL || showGelioc || showLanaud))) && (
                        <td>
                          {(() => {
                            if (ani.costat === "871") return "Lanaud"
                            if (ani.costat === "191") return "ABL"
                            if (ani.costat === "122") return "Gelioc"
                            if (ani.costat === "232") return "Gevial"
                          })()}
                        </td>
                      )}
                      <td>{`${ani.caenst}/${ani.nuseri}`}</td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>
                              {ani.copaip + ani.nunati.substr(0, 6)}
                              <span className="numtrav">
                                {ani.nunati.substr(6)}
                              </span>
                            </p>
                            <p>{ani.nobovi}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {Number.isNaN(+ani.nuinst) ? ani.nuinst : +ani.nuinst}
                      </td>
                      <td>{ani?.A_boqual_2_liqure}</td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>{ani.nobovi_p}</p>
                            <p>
                              {ani.copape + ani.nupere.substr(0, 6)}
                              <span className="numtrav">
                                {ani.nupere.substr(6)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>{ani.nobovi_m}</p>
                            <p>
                              {ani.copame + ani.numere.substr(0, 6)}
                              <span className="numtrav">
                                {ani.numere.substr(6)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert">Pas d'animaux actuellement en station</div>
          )}
        </>
      )}
      <div className="inbetween"></div>
      <div
        className={
          pageOption === "statutdl"
            ? `div_title_section inBackground ${clScroll}`
            : `div_title_section ${clScroll}`
        }
      >
        <CheptelSectionTitle
          title={
            aniHistorique.length +
            " animaux passés en " +
            ((showLanaud && (showABL || showGelioc || showGevial)) ||
            (showABL && (showLanaud || showGelioc || showGevial)) ||
            (showGelioc && (showABL || showLanaud || showGevial)) ||
            (showGevial && (showABL || showGelioc || showLanaud))
              ? "stations"
              : "station")
          }
          onClick={hideShowHisto}
          show={showHisto}
          noMargin={true}
        />
      </div>
      {showHisto && (
        <>
          {histoSorted.length !== 0 ? (
            <div
              className="div_station_table"
              onMouseDown={e => tbodyMouseDown(e, divHisto)}
              onMouseUp={e => tbodyMouseUp(e, divHisto)}
              onMouseMove={e => tbodyMouseMove(e, divHisto)}
              onMouseLeave={e => tbodyMouseUp(e, divHisto)}
              ref={divHisto}
            >
              <table className="station_table">
                <thead>
                  <tr>
                    {((showLanaud && (showABL || showGelioc || showGevial)) ||
                      (showABL && (showLanaud || showGelioc || showGevial)) ||
                      (showGelioc && (showABL || showLanaud || showGevial)) ||
                      (showGevial &&
                        (showABL || showGelioc || showLanaud))) && (
                      <th
                        className={sensStationHisto}
                        onClick={() => applySortHisto("station")}
                      >
                        Station
                      </th>
                    )}
                    <th
                      className={sensSerieHisto}
                      onClick={() => applySortHisto("serie")}
                    >
                      Série
                    </th>
                    <th
                      className={sensNumHisto}
                      onClick={() => applySortHisto("num_animal")}
                    >
                      Numéro animal
                    </th>
                    <th
                      className={sensTagHisto}
                      onClick={() => applySortHisto("tag")}
                    >
                      TAG
                    </th>
                    <th
                      className={sensQualHisto}
                      onClick={() => applySortHisto("qualif")}
                    >
                      Qualif.
                    </th>
                    <th
                      className={sensPereHisto}
                      onClick={() => applySortHisto("num_pere")}
                    >
                      Numéro père
                    </th>
                    <th
                      className={sensMereHisto}
                      onClick={() => applySortHisto("num_mere")}
                    >
                      Numéro mère
                    </th>
                    <th
                      className={sensPdvHisto}
                      onClick={() => applySortHisto("prix_de_vente")}
                    >
                      <nobr>Prix de</nobr> vente
                    </th>
                    <th
                      className={sensAcheteurHisto}
                      onClick={() => applySortHisto("acheteur")}
                    >
                      Acheteur
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {histoSorted.map(ani => (
                    <tr
                      key={ani.copaip + ani.nunati}
                      onClick={() =>
                        !hasMoved &&
                        (window.location.hash = `fichestation|${ani.indexTable}`)
                      }
                      className="pointer"
                    >
                      {((showLanaud && (showABL || showGelioc || showGevial)) ||
                        (showABL && (showLanaud || showGelioc || showGevial)) ||
                        (showGelioc && (showABL || showLanaud || showGevial)) ||
                        (showGevial &&
                          (showABL || showGelioc || showLanaud))) && (
                        <td>
                          {(() => {
                            if (ani.costat === "871") return "Lanaud"
                            if (ani.costat === "191") return "ABL"
                            if (ani.costat === "122") return "Gelioc"
                            if (ani.costat === "232") return "Gevial"
                          })()}
                        </td>
                      )}
                      <td>{`${ani.caenst}/${ani.nuseri}`}</td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>
                              {ani.copaip + ani.nunati.substr(0, 6)}
                              <span className="numtrav">
                                {ani.nunati.substr(6)}
                              </span>
                            </p>
                            <p>{ani.nobovi}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {Number.isNaN(+ani.nuinst) ? ani.nuinst : +ani.nuinst}
                      </td>
                      <td>{ani?.A_boqual_2_liqure}</td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>{ani.nobovi_p}</p>
                            <p>
                              {ani.copape + ani.nupere.substr(0, 6)}
                              <span className="numtrav">
                                {ani.nupere.substr(6)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="ani_station">
                          <div className="num_nom_station">
                            <p>{ani.nobovi_m}</p>
                            <p>
                              {ani.copame + ani.numere.substr(0, 6)}
                              <span className="numtrav">
                                {ani.numere.substr(6)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {ani.prix_vente &&
                          ani.etat_animal === "3" &&
                          ani.prix_vente + "€"}
                      </td>
                      <td>{ani.acheteur_nom ?? ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert">Pas d'animaux passés en station</div>
          )}
        </>
      )}
    </div>
  )
}

export default Station
