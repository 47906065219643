import { useEffect, useMemo, useRef, useState } from "react"
import "./GestionColumns.scss"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { StrictModeDroppable } from "../components/StrictModeDroppable"
import {
  colsByCats,
  getColumns,
  getColumnsCategories,
} from "../functions/objectColums"
import CheptelSectionTitle from "../components/CheptelSectionTitle"
import { fetchSaveCustInv } from "../functions/fetches/fetchSaveCustInv"
import logo_hbl from "../assets/images/logo_hbl.png"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import { goBack } from "../functions/handleNavigation"
import { goToHashWithParams } from "../functions/hash"

let caseCocheesTmp = []

const GestionColumns = ({ pageOption, isAdherent, idInventaire }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const columnsAvailable = getColumns()
  const categories = getColumnsCategories()

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert() // Handle custom alert display

  let listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")
  let thisInv = listCustInv[idInventaire]

  const baseDisplayCats = []
  const catsSelected = {}

  for (let cat in categories) {
    baseDisplayCats[cat] = true
    catsSelected[cat] = true
  }

  const [itemList, setItemList] = useState([...thisInv.columns])
  const [colAvSorted, setColAvSorted] = useState(colsByCats(columnsAvailable))
  const [displayCats, setDisplayCats] = useState(baseDisplayCats)
  const [searchInput, setSearchInput] = useState("")
  const [clErreur, setClErreur] = useState("")
  const [nameInventory, setNameInventory] = useState(thisInv.name)
  const originalName = useMemo(() => thisInv.name, [])

  const wrapperColumn = useRef()
  const divModal = useRef()
  const listColumnsModal = useRef()
  const modalSettings = useRef()
  const inputAdd = useRef()
  const inputName = useRef()

  const [clAdd, setClAdd] = useState(false)
  pageOption === "addcol" && !clAdd && setClAdd(true)
  pageOption !== "addcol" && clAdd && setClAdd(false)

  const [clSettings, setClSettings] = useState(false)
  pageOption === "settings" && !clSettings && setClSettings(true)
  pageOption !== "settings" && clSettings && setClSettings(false)
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    const event = new CustomEvent("filterNumberCust", {
      detail: {
        nbFilters: Object.keys(thisInv.filters).length,
      },
    })

    let header = document.getElementById("header")
    if (header) setTimeout(() => header.dispatchEvent(event), 0)

    let curr = wrapperColumn.current

    if (curr) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let oldVal = window.innerWidth < 430 ? 242 : 226
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newVal = oldVal + addHeight

        curr.style.maxHeight = `calc(calc(var(--vh) * 100) - ${newVal}px)`
      }
    }
  }, [])
  useEffect(() => {
    let search = searchInput.trim().toUpperCase()

    if (search) {
      let tmpObj = {}

      for (let col in columnsAvailable) {
        let name = columnsAvailable[col]?.name.toUpperCase()

        if (name.includes(search)) tmpObj[col] = columnsAvailable[col]
      }

      setColAvSorted(colsByCats(tmpObj))
    } else setColAvSorted(colsByCats(columnsAvailable))
  }, [searchInput])
  useEffect(() => {
    caseCocheesTmp = [...itemList]
  }, [itemList])
  useEffect(() => {
    if (clAdd) {
      let div = divModal.current
      let cols = listColumnsModal.current

      let halfWidthScreen = window.innerWidth / 2
      let halWidthModal = divModal.current.offsetWidth / 2
      let leftWidth = halfWidthScreen - halWidthModal

      div.style.left = `${leftWidth}px`

      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = 174 + addHeight

        cols.style.height = `calc(calc(var(--vh) * 96) - ${newHeight}px)`

        let halfHeightScreen = (window.innerHeight - addHeight) / 2
        let halfHeightModal = div.offsetHeight / 2
        let topHeight = halfHeightScreen - halfHeightModal
        topHeight += addHeight

        div.style.top = topHeight + "px"
      }
    }
  }, [clAdd])
  useEffect(() => {
    if (clSettings) {
      let halfHeightScreen = window.innerHeight / 2
      let halfHeightModal = modalSettings.current.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal

      modalSettings.current.style.top = `${topHeight}px`

      let halfWidthScreen = window.innerWidth / 2
      let halWidthModal = modalSettings.current.offsetWidth / 2
      let leftWidth = halfWidthScreen - halWidthModal

      modalSettings.current.style.left = `${leftWidth}px`
    }
  }, [clSettings])
  ///////////////
  // Functions //
  ///////////////
  // Function to update list on drop
  const handleDrop = droppedItem => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return
    var updatedList = [...itemList]
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update my localStorage for save + my var in memory !!
    thisInv.columns = updatedList
    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)
    // Update State
    setItemList(updatedList)
  }
  const altGoBack = () => {
    caseCocheesTmp = [...itemList]
    goBack()
  }
  const cancelAdds = () => {
    setColAvSorted(colsByCats(columnsAvailable))
    caseCocheesTmp = [...itemList]
    goBack()
  }
  const cocheeCase = key => {
    let target = document.getElementById("line_col" + key)

    if (target.getAttribute("ishbl") === "hbl" && !isAdherent) {
      if (caseCocheesTmp.includes(key)) {
        target?.firstChild?.firstChild?.classList?.toggle("on")
        caseCocheesTmp = caseCocheesTmp.filter(f => f !== key)
      } else {
        doAlert(
          "Vous devez être ahdérent HBL pour accéder à cette information."
        )
      }

      return
    }

    target?.firstChild?.firstChild?.classList?.toggle("on")
    let cat = columnsAvailable[key].category
    let caseCategory = document.getElementById("casetitle" + cat)

    if (caseCocheesTmp.includes(key)) {
      caseCocheesTmp = caseCocheesTmp.filter(f => f !== key)

      caseCategory?.classList.remove("on")
    } else {
      caseCocheesTmp.push(key)

      let selectCat = true
      for (let col in columnsAvailable) {
        let catCol = columnsAvailable[col].category

        if (cat === catCol && !caseCocheesTmp.includes(col)) selectCat = false
      }

      if (selectCat) caseCategory?.classList.add("on")
    }
  }
  const cocheeCaseCat = (e, cat) => {
    if (e.target.classList.contains("on")) {
      e.target.classList.remove("on")

      caseCocheesTmp = caseCocheesTmp.filter(f => !colAvSorted[cat].includes(f))

      colAvSorted[cat].forEach(col => {
        let target = document.getElementById("line_col" + col)?.firstChild
          ?.firstChild
        target?.classList.remove("on")
      })
    } else {
      e.target.classList.add("on")

      colAvSorted[cat].forEach(col => {
        if (!caseCocheesTmp.includes(col)) {
          caseCocheesTmp.push(col)

          let target = document.getElementById("line_col" + col)?.firstChild
            ?.firstChild
          target?.classList.add("on")
        }
      })
    }
  }
  const removeFiltersOfDeletedCols = () => {
    Object.keys(thisInv.filters).forEach(key => {
      let doNotDelete = [
        "SEXBOV",
        "age_month",
        "prelevements",
        "analyses",
        "confirmation",
      ]
      if (doNotDelete.includes(key)) return

      if (!thisInv.columns.includes(key)) delete thisInv.filters[key]
    })
  }
  const addColumns = () => {
    thisInv.columns = [...caseCocheesTmp]

    // Reset sort if we remove the column that is used to sort
    let sort = localStorage.getItem("customsort" + idInventaire) ?? ""
    if (!thisInv.columns.includes(sort)) {
      localStorage.setItem("customsort" + idInventaire, "numtravail")
      localStorage.setItem("customsortvisual" + idInventaire, "numtravail")
      localStorage.setItem("customsens" + idInventaire, "up")
    }

    removeFiltersOfDeletedCols()

    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)
    setItemList([...caseCocheesTmp])
    altGoBack()
  }
  const deleteCol = col => {
    caseCocheesTmp = caseCocheesTmp.filter(a => a !== col)
    thisInv.columns = [...caseCocheesTmp]

    removeFiltersOfDeletedCols()

    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)
    setItemList([...caseCocheesTmp])
  }
  const setAction = key => {
    let target = document.getElementById("case_action_" + key)
    target?.classList?.toggle("on")

    if (target?.classList?.contains("on")) thisInv.action = key
    else thisInv.action = null

    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)

    document.querySelectorAll(`[datadef='case_action'`).forEach(el => {
      if (el.id !== "case_action_" + key && el?.classList?.contains("on"))
        el.classList.remove("on")
    })
  }
  const addAnimation = index => {
    if (!isMobile) return
    let el = document.getElementById("item_container" + index)

    el.classList.add("animate")
  }
  const removeAnimation = index => {
    if (!isMobile) return
    let el = document.getElementById("item_container" + index)

    el.classList.remove("animate")
  }
  const showHideCat = cat => {
    displayCats[cat] = !displayCats[cat]
    setDisplayCats(structuredClone(displayCats))
  }
  const onNameChange = e => {
    let newDisplayedName = e?.target?.value ?? ""
    let newName =
      newDisplayedName && newDisplayedName !== ""
        ? newDisplayedName
        : originalName

    setNameInventory(newDisplayedName)

    if (newDisplayedName === "") {
      setClErreur("error")
    } else {
      setClErreur("")
    }

    thisInv.name = newName
    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  for (let col in columnsAvailable) {
    let cat = columnsAvailable[col].category

    if (!itemList.includes(col)) catsSelected[cat] = false
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      {clAdd && (
        <>
          <div className={`modal_addcol`} ref={divModal}>
            <div className="div_header_filter">
              <h3 className="titre_filtrer">Ajouter des colonnes</h3>
            </div>
            <div className="modal_content">
              <div className="div_input_add">
                <input
                  ref={inputAdd}
                  className="input_add"
                  placeholder="Rechercher une colonne par son nom"
                  value={searchInput}
                  onChange={({ target }) => setSearchInput(target.value)}
                ></input>
              </div>
              <div
                className="wrapper_list_columns_modal"
                ref={listColumnsModal}
              >
                {Object.keys(colAvSorted).map(cat => (
                  <div key={cat}>
                    <CheptelSectionTitle
                      title={categories[cat]?.name}
                      noMargin={true}
                      show={displayCats[cat]}
                      onClick={() => showHideCat(cat)}
                      showCase={true}
                      idCaseCochee={"casetitle" + cat}
                      isCaseCochee={catsSelected[cat]}
                      onClickCase={e => cocheeCaseCat(e, cat)}
                    />
                    <div className="cat_columns">
                      {displayCats[cat] &&
                        colAvSorted[cat].map(key => (
                          <div
                            key={key}
                            id={`line_col${key}`}
                            className="line_col ripple2"
                            onClick={() => cocheeCase(key)}
                            ishbl={columnsAvailable[key]?.isHBL ? "hbl" : "all"}
                          >
                            <div
                              className={
                                columnsAvailable[key]?.isHBL && !isAdherent
                                  ? "left_side_line_col greyedout"
                                  : "left_side_line_col"
                              }
                            >
                              <div
                                className={
                                  caseCocheesTmp.includes(key)
                                    ? "cocheConfirmable on"
                                    : "cocheConfirmable"
                                }
                              ></div>
                              <p>{columnsAvailable[key]?.name}</p>
                            </div>
                            {columnsAvailable[key]?.isHBL && !isAdherent && (
                              <img
                                src={logo_hbl}
                                alt="HBL"
                                className="col_hbl"
                              ></img>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="div_close_modal_sort">
              <button
                className="validate_btn_add ripple2 pointer"
                onClick={addColumns}
              >
                Valider
              </button>
              <button
                className="cancel_btn_add ripple2 pointer"
                onClick={cancelAdds}
              >
                Annuler
              </button>
            </div>
          </div>
          <button className={`background-modal`}></button>
        </>
      )}
      {clSettings && (
        <>
          <div className={`modal_settings`} ref={modalSettings}>
            <div className="div_modal_header">
              <span
                className="close_modal ripple2  material-symbols-outlined"
                onClick={altGoBack}
              >
                close
              </span>
              <h3>Options de l'inventaire</h3>
            </div>
            <div className="modal_content">
              <div className="wrapper_list_columns_modal">
                <CheptelSectionTitle title="Nom" noMargin={true} />
                <div className="div_input_name">
                  <input
                    ref={inputName}
                    className={"input_name " + clErreur}
                    placeholder="Nom de l'inventaire"
                    value={nameInventory}
                    onChange={onNameChange}
                  ></input>
                  {clErreur === "error" && inputName?.current?.value === "" && (
                    <p className="erreur_message">
                      Veuillez saisir un nom à votre inventaire s.v.p
                    </p>
                  )}
                </div>
                <CheptelSectionTitle title="Action" noMargin={true} />
                <div className="cat_columns">
                  <div
                    className="line_col ripple2"
                    onClick={() =>
                      isAdherent
                        ? setAction("confirm")
                        : doAlert(
                            "Vous devez être ahdérent HBL pour sélectionner cette action."
                          )
                    }
                  >
                    <div
                      className={
                        !isAdherent
                          ? "left_side_line_col greyedout"
                          : "left_side_line_col"
                      }
                    >
                      <div
                        id="case_action_confirm"
                        datadef="case_action"
                        className={
                          thisInv?.action === "confirm"
                            ? "cocheConfirmable on"
                            : "cocheConfirmable"
                        }
                      ></div>
                      <p>Confirmations</p>
                    </div>
                    {!isAdherent && (
                      <img src={logo_hbl} alt="HBL" className="col_hbl"></img>
                    )}
                  </div>
                  <div
                    className="line_col ripple2"
                    onClick={() =>
                      isAdherent
                        ? setAction("declar")
                        : doAlert(
                            "Vous devez être ahdérent HBL pour sélectionner cette action."
                          )
                    }
                  >
                    <div
                      className={
                        !isAdherent
                          ? "left_side_line_col greyedout"
                          : "left_side_line_col"
                      }
                    >
                      <div
                        id="case_action_declar"
                        datadef="case_action"
                        className={
                          thisInv?.action === "declar"
                            ? "cocheConfirmable on"
                            : "cocheConfirmable"
                        }
                      ></div>
                      <p>Déclarations</p>
                    </div>
                    {!isAdherent && (
                      <img src={logo_hbl} alt="HBL" className="col_hbl"></img>
                    )}
                  </div>
                  <div
                    className="line_col ripple2"
                    onClick={() => setAction("compare")}
                  >
                    <div
                      id="case_action_compare"
                      datadef="case_action"
                      className={
                        thisInv?.action === "compare"
                          ? "cocheConfirmable on"
                          : "cocheConfirmable"
                      }
                    ></div>
                    <p>Comparaison</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="div_close_modal_sort">
              <button
                className="close_modal_sort pointer ripple2"
                onClick={() =>
                  nameInventory !== ""
                    ? altGoBack()
                    : doAlert(
                        "Veuillez saisir un nom à votre inventaire s.v.p !"
                      )
                }
              >
                Valider
              </button>
            </div>
          </div>
          <button className={`background-modal`} onClick={altGoBack}></button>
        </>
      )}
      <div className="custom_inv_name">{thisInv.name}</div>
      <div className="wrapper_button_add">
        <p>Vous pouvez changer l'ordre des colonnes en les déplaçant.</p>
      </div>
      <div className="wrapper_list_columns" ref={wrapperColumn}>
        <DragDropContext onDragEnd={handleDrop}>
          <StrictModeDroppable droppableId="list_container">
            {provided => (
              <div
                className="list_container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="item_container locked">
                  {" "}
                  <p className="col_name">N° de travail</p>
                </div>
                {itemList.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {provided => (
                      <div
                        id={"item_container" + index}
                        className="item_container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        onMouseDown={() => addAnimation(index)}
                        onMouseUp={() => removeAnimation(index)}
                        onTouchStart={() => addAnimation(index)}
                        onTouchEnd={() => removeAnimation(index)}
                      >
                        <div className="left_side_inv_cust">
                          <span
                            className="icon_grab material-symbols-outlined"
                            onMouseDown={() => addAnimation(index)}
                            onMouseUp={() => removeAnimation(index)}
                            onTouchStart={() => addAnimation(index)}
                            onTouchEnd={() => removeAnimation(index)}
                          >
                            drag_indicator
                          </span>
                          <p
                            className="col_name"
                            onMouseDown={() => addAnimation(index)}
                            onMouseUp={() => removeAnimation(index)}
                            onTouchStart={() => addAnimation(index)}
                            onTouchEnd={() => removeAnimation(index)}
                          >
                            {!columnsAvailable[item] && deleteCol(item)}
                            {columnsAvailable[item]?.name}
                          </p>
                        </div>
                        <div className="icons">
                          <span
                            className=" material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                            onClick={() => deleteCol(item)}
                          >
                            delete
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
      <div className="div_btn_add_col">
        <button onClick={() => goToHashWithParams("gestioncolumns_addcol")}>
          Ajouter une colonne
        </button>
      </div>
    </>
  )
}

export default GestionColumns
