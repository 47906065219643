import "./AscPat.scss"
import CheptelSectionTitle from "../CheptelSectionTitle"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { useEffect, useRef, useState } from "react"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const AscPat = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.ascpat === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const optionIndex = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "IBOVAL",
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Annees",
        },
      },
      y: {
        title: {
          display: true,
          text: "Index",
        },
      },
    },
  }
  const dataIndex = {
    labels: data.campArray,
    datasets: [
      {
        label: "IFNAIS",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: data.nivgenpatIfnais,
        fill: false,
        tension: 0.4,
      },
      {
        label: "CRsev",
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgb(54, 162, 235)",
        data: data.nivgenpatCrsev,
        tension: 0.4,
      },
      {
        label: "DMsev",
        fill: false,
        backgroundColor: "rgb(255, 205, 86)",
        borderColor: "rgb(255, 205, 86)",
        data: data.nivgenpatDmsev,
        tension: 0.4,
      },
      {
        label: "DSsev",
        fill: false,
        backgroundColor: "rgb(255, 159, 64)",
        borderColor: "rgb(255, 159, 64)",
        data: data.nivgenpatDssev,
        tension: 0.4,
      },
      {
        label: "FOSsev",
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgb(75, 192, 192)",
        data: data.nivgenpatFossev,
        tension: 0.4,
      },
      {
        label: "ISEVR",
        fill: false,
        backgroundColor: "rgb(153, 102, 255)",
        borderColor: "rgb(153, 102, 255)",
        data: data.nivgenpatIsevr,
        tension: 0.4,
      },
      {
        label: "AVEL",
        fill: false,
        backgroundColor: "rgb(204,0,0)",
        borderColor: "rgb(204,0,0)",
        data: data.nivgenpatAvel,
        tension: 0.4,
      },
      {
        label: "ALait",
        fill: false,
        backgroundColor: "rgb(12, 192, 0)",
        borderColor: "rgb(12, 192, 0)",
        data: data.nivgenpatAlait,
        tension: 0.4,
      },
      {
        label: "IVMAT",
        fill: false,
        backgroundColor: "rgb(12, 0, 192)",
        borderColor: "rgb(12, 0, 192)",
        data: data.nivgenpatIvmat,
        tension: 0.4,
      },
    ],
  }

  let divRef = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = divRef.current

    if (div) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = 20 + addHeight

        div.style.paddingBottom = newHeight + "px"
      }
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.ascpat = !show
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let divTopNotif = document.getElementById("div_topnotif_sw")
  let wrapNotif = document.getElementById("wrapper_notification_new_data")
  let wrapNotifConf = document.getElementById(
    "wrapper_notification_undone_confirm"
  )
  let wrapNotifDecl = document.getElementById(
    "wrapper_notification_undone_declare"
  )
  let needMargin = divTopNotif.classList.contains("show_notif")

  if (!needMargin && wrapNotif)
    needMargin = wrapNotif.classList.contains("show")
  if (!needMargin && wrapNotifConf)
    needMargin = wrapNotifConf.classList.contains("show")
  if (!needMargin && wrapNotifDecl)
    needMargin = wrapNotifDecl.classList.contains("show")

  if (needMargin) {
    divTopNotif.classList.remove("bg_topnotif_blue_sw")
    if (wrapNotif) wrapNotif.classList.remove("bg_notif_blue_sw")
    if (wrapNotifConf) wrapNotifConf.classList.remove("bg_notif_blue_sw")
    if (wrapNotifDecl) wrapNotifDecl.classList.remove("bg_notif_blue_sw")
  }

  return (
    <div ref={divRef} className="wrap_asc_pat">
      <CheptelSectionTitle
        title={
          screenWide
            ? "Niveau génétique de l'ascendance paternelle"
            : "Niveau génétique de l'asc. paternelle"
        }
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className={needMargin ? "asc_pat notif_on" : "asc_pat"}>
          <Line options={optionIndex} data={dataIndex} />
          <div className="infos-prebottom">
            Niveau génétique de l'ascendance paternelle
          </div>
          <div className="infos-bottom">Données BGTA</div>
        </div>
      )}
    </div>
  )
}

export default AscPat
