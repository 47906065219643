import CheptelSectionTitle from "../CheptelSectionTitle"
import "./Performances.scss"
import Comportement from "./performances/Comportement"
import IndexIboval from "./performances/IndexIboval"
import PointagePostSevrage from "./performances/PointagePostSevrage"
import PointageSevrage from "./performances/PointageSevrage"
import PostSevrageFerme from "./performances/PostSevrageFerme"
import ScoresEvalim from "./performances/ScoresEvalim"
import { useState } from "react"

const Performances = ({ animal, graphISU }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showperf === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showperf = !show
  }

  return (
    <>
      <CheptelSectionTitle
        title="Performances"
        id="performance_title"
        onClick={hideOrShow}
        show={show}
      />
      {show && (
        <div className="performances" id="performances">
          {!animal.Aifnais === "" &&
            animal.Acrsevs === "" &&
            animal.Admsevs === "" &&
            animal.Adssevs === "" &&
            animal.Aalaits === "" &&
            animal.Afosevs === "" &&
            animal.Aisevre === "" &&
            animal.Aivmate === "" &&
            animal.bopoalNoetpo === "" &&
            animal.bopoalDevmus === "" &&
            animal.bopoalDevsqe === "" &&
            animal.bopoalAptfon === "" && (
              <div className="alert">
                Pas de performances connues pour le moment
              </div>
            )}

          <IndexIboval animal={animal} graphISU={graphISU} />
          <div className="wrap_table_indexes">
            <Comportement animal={animal} />
            <PostSevrageFerme animal={animal} />
          </div>
          <div className="wrap_table_indexes">
            <PointageSevrage animal={animal} />
            <PointagePostSevrage animal={animal} />
          </div>
          {(animal.BASREF ||
            animal.CALAIT ||
            animal.CAVELS ||
            animal.CCRSEV ||
            animal.CDMSEV ||
            animal.CDSSEV ||
            animal.CFOSSE ||
            animal.CIFNAI ||
            animal.COPSEV) && <ScoresEvalim animal={animal} />}
        </div>
      )}
    </>
  )
}

export default Performances
