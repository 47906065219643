import localforage from "localforage"
import { fetchGameRankings } from "./fetches/fetchGameRankings"

export const updateGameRankings = async () => {
  let oldRankings = []

  await localforage.getItem("rankings", (err, value) => {
    if (value) oldRankings = value
  })

  let rankings = await fetchGameRankings()

  if (!rankings || rankings.error) rankings = oldRankings

  if (rankings.length !== 0) {
    localforage.setItem("rankings", rankings)
    let today = new Date()
    let month = today.getMonth() + 1
    if (month < 10) month = "0" + month
    let year = today.getFullYear()
    localStorage.rankingMonth = month + "-" + year
  }

  return rankings
}
