import "./PostSevrageFerme.scss"
import FillingBar from "../../FillingBar"
import { useState } from "react"

let width = {
  crpsf: 2,
  dmpsf: 2,
  dspsf: 2,
  baspsf: 2,
  afpsf: 2,
  fospsf: 2,
}

let margin = {
  crpsf: 42,
  dmpsf: 42,
  dspsf: 42,
  baspsf: 42,
  afpsf: 42,
  fospsf: 42,
}

const PostSevrageFerme = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow =
    window.localStorage.showpostsevrageferme === "false" ? false : true
  const [show, setShow] = useState(savedShow)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showpostsevrageferme = !show
  }
  const calculateWidthMargin = (key, value) => {
    if (value === "") {
      width[key] = 0
      margin[key] = 0
    } else if (value > 100) {
      margin[key] = 43
      let tmpValue = value - 100
      tmpValue = (tmpValue * 100) / 40
      tmpValue = (tmpValue * 57) / 100
      width[key] = tmpValue
    } else if (value < 100) {
      let tmpValue = 100 - value
      tmpValue = (tmpValue * 100) / 30
      tmpValue = (tmpValue * 43) / 100
      width[key] = tmpValue
      margin[key] = 43 - tmpValue
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  calculateWidthMargin("crpsf", animal.Acrpsfs)
  calculateWidthMargin("dmpsf", animal.Admpsfs)
  calculateWidthMargin("dspsf", animal.Adspsfs)
  calculateWidthMargin("baspsf", animal.Abaspss)
  calculateWidthMargin("afpsf", animal.Aafpsfs)
  calculateWidthMargin("fospsf", animal.Afospss)

  return (
    <div className="postsevrageferme" id="div_postsevrageferme">
      <h3 onClick={hideOrShow} className="ripple2">
        Post-sevrage ferme{" "}
        <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>

      {show &&
        (animal.Acrpsfs !== "" ||
        animal.Admpsfs !== "" ||
        animal.Adspsfs !== "" ||
        animal.Abaspss !== "" ||
        animal.Aafpsfs !== "" ||
        animal.Afospss !== "" ? (
          <table id="postsevrageferme">
            <thead>
              <tr>
                <th colSpan="3">Index</th>
                <th>CD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CRpsf</td>
                <td>{animal.Acrpsfs}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["crpsf"]}%`,
                      margin: `${margin["crpsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdcpsf !== "" && animal.Acdcpsf}</td>
              </tr>
              <tr>
                <td>DMpsf</td>
                <td>{animal.Admpsfs}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["dmpsf"]}%`,
                      margin: `${margin["dmpsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acddmps !== "" && animal.Acddmps}</td>
              </tr>
              <tr>
                <td>DSpsf</td>
                <td>{animal.Adspsfs}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["dspsf"]}%`,
                      margin: `${margin["dspsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acddsps !== "" && animal.Acddsps}</td>
              </tr>
              <tr>
                <td>BASpsf</td>
                <td>{animal.Abaspss}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["baspsf"]}%`,
                      margin: `${margin["baspsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdbasp !== "" && animal.Acdbasp}</td>
              </tr>
              <tr>
                <td>AFpsf</td>
                <td>{animal.Aafpsfs}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["afpsf"]}%`,
                      margin: `${margin["afpsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdafps !== "" && animal.Acdafps}</td>
              </tr>
              <tr>
                <td>FOSpsf</td>
                <td>{animal.Afospss}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["fospsf"]}%`,
                      margin: `${margin["fospsf"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdfosp !== "" && animal.Acdfosp}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="alert">Pas d'index connus</div>
        ))}
    </div>
  )
}

export default PostSevrageFerme
