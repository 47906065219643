import { useEffect, useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./Analyses.scss"

const Analyses = ({ data }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [showGenetique, setShowGenetique] = useState(
    window.localStorage.showGenetiqueFS ?? "true" === "true"
  )

  let analyseHpf = data.A_analyse_HPF
  if (data.AanalysePAF !== "") analyseHpf = data.A_analyse_PAF

  let isAnalyse =
    [1, 2, 4, 5, 8, 9].includes(+data.boange_codeut) ||
    data.retyup_sc ||
    data.retyup_mh ||
    data.retyup_hpf ||
    data.retyup_paf ||
    data.retyup_pro
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    window.localStorage.showGenetiqueFS = showGenetique
  }, [showGenetique])
  ///////////////
  // Functions //
  ///////////////

  return (
    <div className="analyses_fiche_station" id="analyses_fiche_station">
      <CheptelSectionTitle
        title={"Analyses"}
        show={showGenetique}
        onClick={() => setShowGenetique(!showGenetique)}
        id="analyse_gen_title"
      />
      {showGenetique && (
        <>
          {isAnalyse ? (
            <div className="analyse_genetique" id="analyse_genetique">
              <table>
                <thead>
                  <tr>
                    <th>ADN</th>
                    <th>Sans Cornes</th>
                    <th>Culard MH</th>
                    <th>Palais fendu</th>
                    <th>Protoporphyrie</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {[1, 2, 4, 5, 8, 9].includes(+data.boange_codeut) && (
                        <div
                          className={
                            [2, 9].includes(+data.boange_codeut)
                              ? "prelev_attente"
                              : "prelev_ok"
                          }
                        >
                          {+data.boange_typana === 1 && "GS "}
                          {+data.boange_typana === 2 && "µSat "}
                          {+data.boange_typana === 3 && "SNP "}
                          {[1, 4, 5, 8].includes(+data.boange_codeut) &&
                            "utilisable"}
                          {+data.boange_codeut === 2 && "inutilisable"}
                          {+data.boange_codeut === 9 && "inconnu en bdnsat"}
                        </div>
                      )}
                    </td>
                    <td>
                      {data.retyup_sc === "+/+" && (
                        <div className="prelev_ok">Non porteur</div>
                      )}
                      {data.retyup_sc === "P/+" && (
                        <div className="prelev_attente">
                          Porteur hétérozygote
                        </div>
                      )}
                      {data.retyup_sc === "P/P" && (
                        <div className="prelev_attente">Porteur homozygote</div>
                      )}
                    </td>
                    <td>
                      {data.retyup_mh === "+/+" && (
                        <div className="prelev_ok">Non porteur</div>
                      )}
                      {data.retyup_mh === "MH/+" && (
                        <div className="prelev_attente">
                          Porteur hétérozygote
                        </div>
                      )}
                      {data.retyup_mh === "MH/MH" && (
                        <div className="prelev_attente">Porteur homozygote</div>
                      )}
                    </td>
                    <td>
                      {data.retyup_paf !== "" ? (
                        <>
                          {data.retyup_paf === "+/+" && (
                            <div className="prelev_ok">Non porteur</div>
                          )}
                          {data.retyup_paf === "PAL/+" && (
                            <div className="prelev_attente">
                              Porteur hétérozygote
                            </div>
                          )}
                          {data.retyup_paf === "PAL/PAL" && (
                            <div className="prelev_attente">
                              Porteur homozygote
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {data.retyup_hpf == "0" && (
                            <div className="prelev_ok">Non porteur (hpf)</div>
                          )}
                          {data.retyup_hpf == "1" && (
                            <div className="prelev_attente">
                              Porteur hétérozygote (hpf)
                            </div>
                          )}
                          {data.retyup_hpf == "2" && (
                            <div className="prelev_attente">
                              Porteur homozygote (hpf)
                            </div>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {data.retyup_pro === "+/+" && (
                        <div className="prelev_ok">Non porteur</div>
                      )}
                      {data.retyup_pro === "PRO/+" && (
                        <div className="prelev_attente">
                          Porteur hétérozygote
                        </div>
                      )}
                      {data.retyup_pro === "PRO/PRO" && (
                        <div className="prelev_attente">Porteur homozygote</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert">Pas d'analyse connue</div>
          )}
        </>
      )}
    </div>
  )
}

export default Analyses
