// Method to handle swaps
// elementToWatch: the element we will listen for swipes on
// callback: callback to do when swipes are detected
// fromLeftBorder: detect only swipe done close to the border of the screen (left border)
// return: a method to remove the eventListener
export const handleSwipe = (
  elementToWatch,
  callback,
  fromLeftBorder = false
) => {
  /* Feature detection */
  let passiveIfSupported = false

  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get() {
          passiveIfSupported = { passive: true }
        },
      })
    )
  } catch (err) {}

  let touchstartX = 0
  let touchendX = 0
  let touchstartY = 0
  let touchendY = 0

  const fnTouchStart = e => {
    touchstartX = e.changedTouches[0].screenX
    touchstartY = e.changedTouches[0].screenY
  }

  // EventListeners to detect "touches" to detect swipes
  elementToWatch.addEventListener(
    "touchstart",
    fnTouchStart,
    passiveIfSupported
  )

  const fnTouchEnd = e => {
    touchendX = e.changedTouches[0].screenX
    touchendY = e.changedTouches[0].screenY
    checkDirection()
  }

  elementToWatch.addEventListener("touchend", fnTouchEnd, passiveIfSupported)

  // Method to detect swipe direction
  const checkDirection = () => {
    let swipeDirection = ""

    if (fromLeftBorder && touchstartX > 30) return false

    if (
      Math.abs(touchendX - touchstartX) > 80 &&
      Math.abs(touchendX - touchstartX) > Math.abs(touchendY - touchstartY)
    ) {
      if (touchendX < touchstartX) swipeDirection = "left"
      if (touchendX > touchstartX) swipeDirection = "right"

      callback(swipeDirection, touchendX, touchendY)
    }
  }

  const removeEventListeners = () => {
    // EventListeners to detect "touches" to detect swipes
    elementToWatch.removeEventListener(
      "touchstart",
      fnTouchStart,
      passiveIfSupported
    )

    elementToWatch.removeEventListener(
      "touchend",
      fnTouchEnd,
      passiveIfSupported
    )
  }

  return removeEventListeners
}
