import "./BigGreenButton.scss"

const BigGreenButton = ({ onClick, text, linkTo }) => {
  const passOnClick = event => {
    event.target.disabled = true
    if (typeof onClick === "function") onClick(event)
    event.target.disabled = false
  }

  const moveTo = () => {
    window.location.hash = linkTo
  }

  let actionOnClick = linkTo ? moveTo : passOnClick

  return (
    <button className="biggreenbutton ripple6" onClick={actionOnClick}>
      {text}
    </button>
  )
}

export default BigGreenButton
