import { fetchUrlEncoded } from "./fetch"

// Function to fetch the data of the cheptel
export const fetchAdmChep = async (valeur, signal = null) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "adm_rebond_numchep"

  let params = {
    a: action,
    v: valeur,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    signal: signal,
    fetchAdmChep: true,
  })
}
