import { useEffect, useRef, useState } from "react"
import "./LoaderDl.scss"
import Loader from "../components/Loader"
import Confirm from "../components/Confirm"
import { useCustomConfirm } from "../hooks/useCustomConfrm"

const LoaderDl = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const div = useRef()

  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  let valA = window.localStorage.valA === "true" ? true : false
  let isThereData = true
  let numCheptel = window.localStorage.numCheptel ?? ""
  // Get time of latest data update for this cheptel
  let lastUpdate = window.localStorage.getItem("dateOfLatestData" + numCheptel)
  if (!lastUpdate) isThereData = false

  let timeNow = Date.now()
  let diffTime = timeNow - lastUpdate

  // If data are more than 7 days old, dont load them and just dl again
  if (diffTime > 7 * 24 * 60 * 60 * 1000) {
    isThereData = false
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let fullDiv = document.getElementById("fullpage_loaderdl")
    if (fullDiv) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual =
          +window
            .getComputedStyle(fullDiv, null)
            .getPropertyValue("margin-top")
            .split("px")[0] ?? 0

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        fullDiv.style.marginTop = newHeight + "px"
        fullDiv.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`

        let dinoDiv = document.getElementById("div_dino_game")
        let loaderDl = document.getElementById("loaderDl")

        if (dinoDiv && loaderDl) {
          var rect2 = loaderDl.getBoundingClientRect()
          let space = window.innerHeight - rect2.bottom
          let remaining = Math.floor((space - dinoDiv.offsetHeight) / 2)
          dinoDiv.style.bottom = remaining + "px"
        }
      }
    }
  }, [])

  ///////////////
  // Functions //
  ///////////////
  const cancelDownload = confirmed => {
    if (confirmed) {
      if (isThereData) {
        document
          .getElementById("app")
          ?.dispatchEvent(new CustomEvent("cancelDownload"))
      } else {
        if (valA) {
          document
            .getElementById("app")
            ?.dispatchEvent(new CustomEvent("cancelDownload"))
        }
      }
    } else {
      hideConfirm()
    }
  }

  return (
    <div className="fullpage_loaderdl" id="fullpage_loaderdl">
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={cancelDownload} />
      )}
      <div id="loader_dl" className="wraper_loader_dl" ref={div}>
        <div className="loader_page">
          <Loader loadingMessage="Chargement en cours..." />
          {(isThereData || valA) && (
            <div className="cancel_download">
              <p
                className="ripple2 pointer"
                onClick={() =>
                  doConfirm("Voulez-vous arréter le téléchargement ?")
                }
              >
                Annuler le téléchargement
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoaderDl
