export const getDefaultInventories = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.defaultInventories
}

export const defaultInventoriesDefinition = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.defaultInventoriesDefinition
}

export const getColumnsCategories = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.columnsCategories
}

export const getColumns = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.columns
}

export const colsByCats = cols => {
  let tmpObj = {}

  for (let col in cols) {
    let category = cols[col].category

    if (tmpObj[category] === undefined) tmpObj[category] = []

    tmpObj[category].push(col)
  }
  return tmpObj
}

export const getColumnsCompare = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.columnsCompare
}

export const getRFQUAL = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.refQualif
}

export const getParamsActivities = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")

  return params?.activites
}

export const getActivitesName = () => {
  let params = JSON.parse(localStorage.appParams ?? "{}")
  let names = {}

  params?.activites?.activites?.forEach(act => {
    names[act.codeActivite] = act.nom
  })

  return names
}
