import { useRef } from "react"
import "./ModalFiliation.scss"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack } from "../../../functions/handleNavigation"
import { formatDate2 } from "../../../functions/formatDate"

const ModalFiliation = ({ aniData }) => {
  !aniData && goBack()
  let filiations = aniData?.filiation ?? []
  console.log(aniData)
  filiations?.length < 1 && goBack()
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  let nbFiliations = aniData?.filiation.length

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const classCordcg = cordcg => {
    let cl = "prelev_attente"

    if (cordcg === "1" || cordcg === "C" || cordcg === "D" || cordcg === "E")
      cl = ""

    return cl
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div className={"modal_filiation"} ref={divModal}>
        <div className="div_modal_header">
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <div className="title_modal_fillitation">
            <h2>
              Résultat{nbFiliations > 1 && "s"} de filiation pour{" "}
              {aniData.COPAIP + aniData.NUNATI}
            </h2>
          </div>
        </div>
        <div className="modal-content">
          <div className="table_filiation">
            <table
              className="hbl_confirm_table"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <thead>
                <tr>
                  <th colspan="3">Analyse</th>
                  <th colspan="3">Animal</th>
                  <th>Père</th>
                  <th>Mère</th>
                </tr>
                <tr>
                  <th>Résultat</th>
                  <th>Labo</th>
                  <th>Date</th>
                  <th>Labo</th>
                  <th>Echantillon</th>
                  <th>Type d'analyse</th>
                  <th>Numéro</th>
                  <th>Numéro</th>
                </tr>
              </thead>
              <tbody>
                {filiations.map(f => (
                  <tr>
                    <td className={classCordcg(f.cordcg)}>
                      <nobr>{f.cordcg_txt}</nobr>
                    </td>
                    <td>{f.colrcg}</td>
                    <td>
                      <nobr>{formatDate2(f.dartcg)}</nobr>
                    </td>
                    <td>{f.colaec}</td>
                    <td>{f.nuecha}</td>
                    <td>{f.typana_txt}</td>
                    <td>{f.copapt + f.nunapt}</td>
                    <td>{f.copamt + f.nunamt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalFiliation
