import { fetchUrlEncoded } from "./fetch"
import platform from "platform"

// Function to login
export const fetchLogActionFiche = async (type, actionFiche, animal) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "logActionFiche"

  let params = {
    a: action,
    type: type,
    action: actionFiche,
    animal: animal,
    v: localStorage.app_version,
    d: platform.description,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    fromLogError: true,
  })
}
