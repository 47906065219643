import "./FicheIndexShare.scss"
import background from "../../assets/images/background_fiche_export.png"
import { formatDate } from "../../functions/formatDate"
import CheptelSectionTitle from "../CheptelSectionTitle"

const FicheIndexShare = ({ aniData, b64Blur, b64Graph }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let aneeBaseRefPere
  if (aniData?.Pannein) {
    aneeBaseRefPere = Math.round(aniData.Pannein - 1)?.toString()
    aneeBaseRefPere = aneeBaseRefPere.substring(2, 4)
  }
  let rangBaseRefPere
  if (aniData?.Prangin) {
    rangBaseRefPere = aniData.Prangin?.toString()
    rangBaseRefPere = rangBaseRefPere.substring(1, 2)
  }
  let aneeBaseRefMere
  if (aniData?.Mannein) {
    aneeBaseRefMere = Math.round(aniData.Mannein - 1)?.toString()
    aneeBaseRefMere = aneeBaseRefMere.substring(2, 4)
  }
  let rangBaseRefMere
  if (aniData?.Mrangin) {
    rangBaseRefMere = aniData.Mrangin?.toString()
    rangBaseRefMere = rangBaseRefMere.substring(1, 2)
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const genalogieBulle = (nom, copaip, numati, qualif, sexe, degre) => {
    return (
      <div
        className={
          sexe === 2 ? "genalogie-bulle femelle" : "genalogie-bulle male"
        }
      >
        {nom}
        <i> {qualif?.[0]}</i>
        {qualif?.[2] && qualif?.[2] !== qualif?.[0] && (
          <i className="qualifSecond">{" " + qualif?.[2]}</i>
        )}
        <div className="numTravail">
          {copaip && numati ? copaip + numati : ""}
        </div>
      </div>
    )
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  let isAnalyse =
    aniData.retyupsc ||
    aniData.retyupmh ||
    aniData.RETYUP_HPF ||
    aniData.retyuppaf ||
    aniData.retyuppro

  return (
    <div
      className={
        "ReactNativeWebView" in window
          ? "fiche_index_share native_web_view"
          : "fiche_index_share"
      }
    >
      {/* Div background */}
      <div className="div_background">
        <img src={background} />
      </div>
      {/* Div photo animal */}
      <div className="div_img_ani">
        <div className="photos">
          <img src={b64Blur} className="photo_flou_ani" />
          <div className="real_photo">
            <img
              className={
                aniData.photoHeight > aniData.photoWidth
                  ? "photo_ani portrait"
                  : "photo_ani landscape"
              }
              src={`${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL}${
                aniData.COPAIP + aniData.NUNATI
              }_${aniData.ts_photos}.jpg`}
            />
          </div>
        </div>
      </div>
      {/* Div info cheptel */}
      <div className="info_cheptel">
        <p>
          {aniData.detenteurNom} ({aniData.detenteurNum.substring(2, 4)})
        </p>
      </div>
      {/* Div infos animal */}
      <div className="infos_animal">
        <div className="titre">
          <h2
            className={
              aniData.SEXBOV === "2" ? "nom sexe-femelle" : "nom sexe-male"
            }
          >
            {aniData.NOBOVI}
            <i
              className="aboqual"
              title={
                aniData.qualifAnimalLIDETA
                  ? aniData.qualifAnimalLIDETA
                  : aniData.inscrAnimalLIDETA && aniData.inscrAnimalLIDETA
              }
            >
              {aniData.qualifAnimal
                ? aniData.qualifAnimal
                : aniData.inscrAnimal && aniData.inscrAnimal}
            </i>
          </h2>
          <h3 className="numtravail">{aniData.COPAIP + aniData.NUNATI}</h3>
        </div>
        <div className="infos-naissance">
          <div className="line_info">
            <p>Date de naissance :</p>
            <p>{`${aniData.DANAIS}`}</p>
          </div>
        </div>
        <div className="table_header">
          {(aniData.PONAIS !== "" ||
            aniData.bopoat120 !== "" ||
            aniData.bopoat210 !== "") && (
            <table>
              <thead>
                <tr>
                  <th>Poids de naissance</th>
                  <th>{aniData.PONAIS}</th>
                </tr>
              </thead>
              <tbody>
                {aniData.bopoat120 !== "" && (
                  <tr>
                    <td>à 120 jours</td>
                    <td className="right">{`${aniData.bopoat120} kg`}</td>
                  </tr>
                )}
                {aniData.bopoat210 !== "" && (
                  <tr>
                    <td>à 210 jours</td>
                    <td className="right">{`${aniData.bopoat210} kg`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {/* {(aniData.bopoalDevmus !== "" ||
            aniData.bopoalDevsqe !== "" ||
            aniData.bopoalAptfon !== "" ||
            aniData.bopoalNoetpo !== "") && (
            <table>
              <thead>
                <tr>
                  <th>DM</th>
                  <th>DS</th>
                  <th>AF</th>
                  <th>Etat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="center">{aniData.bopoalDevmus}</td>
                  <td className="center">{aniData.bopoalDevsqe}</td>
                  <td className="center">{aniData.bopoalAptfon}</td>
                  <td className="center">{aniData.bopoalNoetpo}</td>
                </tr>
              </tbody>
            </table>
          )} */}
        </div>
      </div>
      {/* Div performance */}
      <div className="bloc_performance">
        <CheptelSectionTitle title="Performance" />
        <div className="indexiboval_share_full">
          {b64Graph ? (
            <img src={b64Graph} className="graph_isu" />
          ) : (
            <div className="alert">Pas d'index connus</div>
          )}
        </div>
      </div>
      {/* Div donnees repro */}
      {aniData.SEXBOV === "2" && (
        <div className="blocs_reproduction">
          <CheptelSectionTitle title="Données de reproduction" />
          <div className="infos_repro">
            <div className="line_info_repro">
              <p className="lib_info_repro">Rang de vêlage:</p>
              <p className="value_info_repro">{aniData.RADEVE}</p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Age au premier vêlage:</p>
              <p className="value_info_repro">
                {aniData.AGE1VE && `${Math.floor(aniData.AGE1VE / 30)} mois`}
              </p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Conditions 1er vêlage:</p>
              <p className="value_info_repro">{aniData.COV1VE}</p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Dernier vêlage:</p>
              <p className="value_info_repro">
                {aniData.DADEVE && aniData.DADEVE !== "--" && aniData.DADEVE}
              </p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">IVV moyen:</p>
              <p className="value_info_repro">
                {aniData.IVVMOY && `${aniData.IVVMOY} jrs `}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Div analyses genetiques */}
      <div
        className={
          aniData.SEXBOV === "2" ? "blocs_analyses" : "blocs_analyses male"
        }
      >
        <CheptelSectionTitle title="Analyses génétiques" />
        {isAnalyse ? (
          <div className="blocs_adn">
            <div className="bloc_adn">
              <p className="title_adn">Sans cornes</p>
              <div
                className={
                  aniData.retyupsc === "+/+"
                    ? "resultat green"
                    : aniData.retyupsc === "P/+" || aniData.retyupsc === "P/P"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyupsc === "+/+" && "Non porteur"}
                {aniData.retyupsc === "P/+" && "Porteur hétérozygote"}
                {aniData.retyupsc === "P/P" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Culard</p>
              <div
                className={
                  aniData.retyupmh === "+/+"
                    ? "resultat green"
                    : aniData.retyupmh === "MH/+" ||
                      aniData.retyupmh === "MH/MH"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyupmh === "+/+" && "Non porteur"}
                {aniData.retyupmh === "MH/+" && "Porteur hétérozygote"}
                {aniData.retyupmh === "MH/MH" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Palais fendu</p>
              {aniData.retyuppaf !== "" ? (
                <div
                  className={
                    aniData.retyuppaf === "+/+"
                      ? "resultat green"
                      : aniData.retyuppaf === "PAL/+" ||
                        aniData.retyuppaf === "PAL/PAL"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {aniData.retyuppaf === "+/+" && "Non porteur"}
                  {aniData.retyuppaf === "PAL/+" && "Porteur hétérozygote"}
                  {aniData.retyuppaf === "PAL/PAL" && "Porteur homozygote"}
                </div>
              ) : (
                <div
                  className={
                    aniData.RETYUP_HPF == "0"
                      ? "resultat green"
                      : aniData.RETYUP_HPF == "1" || aniData.RETYUP_HPF == "2"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {aniData.RETYUP_HPF == "0" && "Non porteur (hpf)"}
                  {aniData.RETYUP_HPF == "1" && "Porteur hétérozygote (hpf)"}
                  {aniData.RETYUP_HPF == "2" && "Porteur homozygote (hpf)"}
                </div>
              )}
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Protoporphyrie</p>
              <div
                className={
                  aniData.retyuppro === "+/+"
                    ? "resultat green"
                    : aniData.retyuppro === "PRO/+" ||
                      aniData.retyuppro === "PRO/PRO"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyuppro === "+/+" && "Non porteur"}
                {aniData.retyuppro === "PRO/+" && "Porteur hétérozygote"}
                {aniData.retyuppro === "PRO/PRO" && "Porteur homozygote"}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert">Pas d'analyse connue</div>
        )}
      </div>
      {/* Bottom message */}
      {/* <div className="bottom_message">
        Informations et données issues de l'application EspacePro © France
        Limousin Sélection
      </div> */}
    </div>
  )
}

export default FicheIndexShare
