import FillingBar from "../../FillingBar"
import "./IndexIboval.scss"
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js"
import { color } from "chart.js/helpers"
import { PolarArea } from "react-chartjs-2"
import { useState } from "react"

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

let width = {
  ifnais: 2,
  crsev: 2,
  dmsev: 2,
  dssev: 2,
  alait: 2,
  avel: 2,
  fossev: 2,
  isevr: 2,
  ivmat: 2,
}

let margin = {
  ifnais: 42,
  crsev: 42,
  dmsev: 42,
  dssev: 42,
  alait: 42,
  avel: 42,
  fossev: 42,
  isevr: 42,
  ivmat: 42,
}

const IndexIboval = ({ animal, graphISU }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showindexibo === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
    },
    scales: {
      r: {
        ticks: {
          display: true,
        },
        pointLabels: {
          display: true,
          font: {
            size: 14,
          },
        },
        grid: {
          display: true,
          circular: false,
          color: "rgb(192,192,192)",
        },
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    },
  }
  const data = {
    labels: [
      "IFNAIS",
      "CRsev",
      "DMsev",
      "DSsev",
      "ALait",
      "AVEL",
      "FOSsev",
      "ISEVR",
      "IVMAT",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [
          animal.Aifnais,
          animal.Acrsevs,
          animal.Admsevs,
          animal.Adssevs,
          animal.Aalaits,
          animal.Aavelag,
          animal.Afosevs,
          animal.Aisevre,
          animal.Aivmate,
        ],
        backgroundColor: [
          color("rgb(255, 99, 132)").alpha(0.8).rgbString(),
          color("rgb(255, 159, 64)").alpha(0.8).rgbString(),
          color("rgb(255, 205, 86)").alpha(0.8).rgbString(),
          color("rgb(75, 192, 192)").alpha(0.8).rgbString(),
          color("rgb(54, 162, 235)").alpha(0.8).rgbString(),
          color("rgb(153, 102, 255)").alpha(0.8).rgbString(),
          color("rgb(12, 0, 192)").alpha(0.8).rgbString(),
          color("rgb(12, 192, 0)").alpha(0.8).rgbString(),
          color("rgb(204,0,0)").alpha(0.8).rgbString(),
        ],
        borderWidth: 1,
      },
    ],
  }

  let anneeBaseRef = Math.round(animal.Aannein - 1).toString()
  anneeBaseRef = anneeBaseRef.substring(2, 4)
  let rangBaseRef = animal.Arangin.toString()
  rangBaseRef = rangBaseRef.substring(1, 2)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showindexibo = !show
  }
  const calculateWidthMargin = (key, value) => {
    if (value === "") {
      width[key] = 0
      margin[key] = 0
    } else if (value > 100) {
      margin[key] = 43
      let tmpValue = value - 100
      tmpValue = (tmpValue * 100) / 40
      tmpValue = (tmpValue * 57) / 100
      width[key] = tmpValue
    } else if (value < 100) {
      let tmpValue = 100 - value
      tmpValue = (tmpValue * 100) / 30
      tmpValue = (tmpValue * 43) / 100
      width[key] = tmpValue
      margin[key] = 43 - tmpValue
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  calculateWidthMargin("ifnais", animal.Aifnais)
  calculateWidthMargin("crsev", animal.Acrsevs)
  calculateWidthMargin("dmsev", animal.Admsevs)
  calculateWidthMargin("dssev", animal.Adssevs)
  calculateWidthMargin("alait", animal.Aalaits)
  calculateWidthMargin("avel", animal.Aavelag)
  calculateWidthMargin("fossev", animal.Afosevs)
  calculateWidthMargin("isevr", animal.Aisevre)
  calculateWidthMargin("ivmat", animal.Aivmate)

  return (
    <div className="indexiboval" id="div_index_iboval">
      <h3 onClick={hideOrShow} className="ripple2">
        Index IBOVAL{" "}
        {animal.Atypein === "G" && (
          <span className="ibovalG" title="IBOVAL Génomique">
            G
          </span>
        )}
        <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>
      {show &&
        (animal.Aifnais !== "" ||
        animal.Acrsevs !== "" ||
        animal.Admsevs !== "" ||
        animal.Adssevs !== "" ||
        animal.Aalaits !== "" ||
        animal.Afosevs !== "" ||
        animal.Aisevre !== "" ||
        animal.Aivmate !== "" ? (
          <div id="indexiboval" className="div_wrap_iboval">
            <div>
              <table>
                <thead>
                  <tr>
                    <th colSpan="3">Index</th>
                    <th>CD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>IFNAIS</td>
                    <td>{animal.Aifnais}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["ifnais"]}%`,
                          margin: `${margin["ifnais"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdifna !== "" && animal.Acdifna}</td>
                  </tr>
                  <tr>
                    <td>CRsev</td>
                    <td>{animal.Acrsevs}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["crsev"]}%`,
                          margin: `${margin["crsev"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdcrse !== "" && animal.Acdcrse}</td>
                  </tr>
                  <tr>
                    <td>DMsev</td>
                    <td>{animal.Admsevs}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["dmsev"]}%`,
                          margin: `${margin["dmsev"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acddmse !== "" && animal.Acddmse}</td>
                  </tr>
                  <tr>
                    <td>DSsev</td>
                    <td>{animal.Adssevs}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["dssev"]}%`,
                          margin: `${margin["dssev"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acddsse !== "" && animal.Acddsse}</td>
                  </tr>
                  <tr>
                    <td>ALait</td>
                    <td>{animal.Aalaits}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["alait"]}%`,
                          margin: `${margin["alait"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdalai !== "" && animal.Acdalai}</td>
                  </tr>
                  <tr>
                    <td>AVEL</td>
                    <td>{animal.Aavelag}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["avel"]}%`,
                          margin: `${margin["avel"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdavel !== "" && animal.Acdavel}</td>
                  </tr>
                  <tr>
                    <td>FOSsev</td>
                    <td>{animal.Afosevs}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["fossev"]}%`,
                          margin: `${margin["fossev"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdfose !== "" && animal.Acdfose}</td>
                  </tr>
                  <tr>
                    <td>ISEVR</td>
                    <td>{animal.Aisevre}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["isevr"]}%`,
                          margin: `${margin["isevr"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdisev !== "" && animal.Acdisev}</td>
                  </tr>
                  <tr>
                    <td>IVMAT</td>
                    <td>{animal.Aivmate}</td>
                    <td className="full_width">
                      <FillingBar
                        styleValues={{
                          width: `${width["ivmat"]}%`,
                          margin: `${margin["ivmat"]}%`,
                          point1: "calc(21.5% - 5px)",
                          point2: "calc(43% - 7px)",
                          point3: "calc(71.5% - 7px)",
                          point4: "calc(100% - 17px)",
                          txtPoint0: "70",
                          txtPoint1: "85",
                          txtPoint2: "100",
                          txtPoint3: "120",
                          txtPoint4: "140",
                        }}
                      />
                    </td>
                    <td>{animal.Acdivma !== "" && animal.Acdivma}</td>
                  </tr>
                </tbody>
              </table>
              <div className="base-ref">
                {`Base ref : LI.PF.${anneeBaseRef}.${rangBaseRef}`}
              </div>
              {animal.Aboisuf !== "" && (
                <table>
                  <thead>
                    <tr>
                      <th>ISU</th>
                      <th className="width25">{animal.Aboisuf}</th>
                    </tr>
                  </thead>
                </table>
              )}
            </div>
            <PolarArea
              ref={graphISU}
              id="graph_isu"
              className="graph_isu"
              options={options}
              data={data}
            />
          </div>
        ) : (
          <div className="alert">Pas d'index connus</div>
        ))}
    </div>
  )
}

export default IndexIboval
