import { useEffect, useRef, useState } from "react"
import "./Admin.scss"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalLoader from "../components/ModalLoader"
import { fetchNameChep } from "../functions/fetches/fetchNameChep"
import localforage from "localforage"
import Loader from "../components/Loader"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import Confirm from "../components/Confirm"
import ModalChangeLogs from "../components/ModalChangeLogs"
import { fetchIsAnimal } from "../functions/fetches/fetchIsAnimal"

const Admin = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const divAdm = useRef()
  const [listeCheptels, setListeCheptels] = useState(null)

  const [searchAdmin, setSearchAdmin] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const [clChangeLog, setChangeLog] = useState(false)
  pageOption === "changelog" && !clChangeLog && setChangeLog(true)
  pageOption !== "changelog" && clChangeLog && setChangeLog(false)

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  let tmpListSaved = JSON.parse(localStorage.ldlCheptel ?? "[]")
  const chepToDel = useRef("")

  const centerModal = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divAdm.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("margin-top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.marginTop = newHeight + "px"
      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }

    const searchUpdated = e => {
      setSearchAdmin(e.detail.searchAdmin)
      if (e.detail.searchAdmin === "") {
        setIsLoading(true)
        getSavedCheptels()
      }
    }

    curr.addEventListener("searchUpdated", searchUpdated)

    getSavedCheptels()

    window.onresize = () =>
      typeof centerModal.current === "function" && centerModal.current()

    return () => {
      curr.removeEventListener("searchUpdated", searchUpdated)
      window.onresize = null
    }
  }, [])
  useEffect(() => {
    let curr = divAdm.current

    const doSearch = () => {
      checkAdmin()
    }
    curr.addEventListener("doSearch", doSearch)

    return () => {
      curr.removeEventListener("doSearch", doSearch)
    }
  }, [searchAdmin])

  ///////////////
  // Functions //
  ///////////////
  const goCheptel = numChep => {
    if (!numChep) return false

    window.localStorage.numCheptel = numChep

    document.getElementById("app")?.dispatchEvent(new CustomEvent("loadData"))
  }
  const checkAdmin = async () => {
    let divModal = document.getElementById("loader_search_ani")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let searchV = searchAdmin.trim()

    let isAnimal = await fetchIsAnimal(searchV)

    if (isAnimal) {
      window.location.hash = `animal|${searchV}`
    } else if (
      searchV.length == 10 &&
      (searchV.match("^[A-Z]{2}[0-9]{8}$") ||
        searchV.match("^[A-Z]{4}[0-9]{6}$"))
    ) {
      goCheptel(searchV)
    } else if (searchV.length > 0) {
      let result = await fetchNameChep(searchV)
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

      if (result.length === 0)
        doAlert("Aucune donnée trouvée pour la recherche.")
      else {
        let tmpListe = result
        if (tmpListe.constructor !== Array) tmpListe = []

        setListeCheptels(tmpListe)
      }
    } else {
      getSavedCheptels()
      doAlert(
        "Veuillez saisir au moins un caractère dans la barre de recherche s.v.p"
      )
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const getSavedCheptels = async () => {
    let savedCheptels = []

    for (let index in tmpListSaved) {
      let numChep = tmpListSaved[index]

      await localforage.getItem("dataCalculated" + numChep).then(res => {
        if (res) {
          let data = res.account

          let vals = {
            payscheptel_membres: data?.cheptel_pays,
            numrodecheptel_membres: data?.cheptel_num,
            fullnumchep: numChep,
            hbl_membres: data?.hbl_adherent,
            id_membres: data?.id,
            nomducheptelraisonsociale_membres: data?.societe_nom,
            nomducheptelpersonne_membres: data?.nom,
            prenomducheptelpersonne_membres: data?.prenom,
            codepostal_membres: data?.societe_code_postal,
            commune_membres: data?.societe_commune,
            adresse_membres: data?.societe_adresse,
            telphone_membres: data?.telephone,
            email_membres: data?.email,
          }

          savedCheptels.push(vals)
        }
      })
    }

    savedCheptels.sort((a, b) =>
      a["payscheptel_membres"] + a["numrodecheptel_membres"] >
      b["payscheptel_membres"] + b["numrodecheptel_membres"]
        ? 1
        : a["payscheptel_membres"] + a["numrodecheptel_membres"] <
          b["payscheptel_membres"] + b["numrodecheptel_membres"]
        ? -1
        : 0
    )

    setListeCheptels(savedCheptels)
    setIsLoading(false)
  }
  const askDeleteCheptel = () => {
    doConfirm(
      "Voulez-vous supprimer les données du cheptel de votre appareil ?"
    )
  }
  const deleteCheptel = async chep => {
    tmpListSaved = tmpListSaved.filter(num => num !== chep)
    localStorage.ldlCheptel = JSON.stringify(tmpListSaved)

    let newArray = listeCheptels.filter(data => chep !== data["fullnumchep"])
    setListeCheptels([...newArray])

    await localforage.keys().then(async keys => {
      for (let indexKey in keys) {
        let nameKey = keys[indexKey]
        if (nameKey.includes(chep)) await localforage.removeItem(nameKey)
      }
    })
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="admin_content" id="admin_content" ref={divAdm}>
      <ModalLoader
        id="loader_search_ani"
        message="Recherche en cours..."
        parentCenterModal={centerModal}
      />
      {clChangeLog && <ModalChangeLogs />}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm
          message={messageConfirm}
          giveConfirm={confirmed => {
            hideConfirm()
            if (confirmed) deleteCheptel(chepToDel.current)
          }}
        />
      )}

      {isLoading ? (
        <div className="loader_liste">
          <Loader loadingMessage={"Chargement en cours..."} />
        </div>
      ) : (
        <>
          {!listeCheptels ? (
            <h2>Veuillez rechercher un cheptel ou un animal s.v.p</h2>
          ) : listeCheptels.length === 0 ? (
            <h2>Aucun résultat ne correspond à votre recherche.</h2>
          ) : (
            <div className="liste_cheptels">
              {listeCheptels?.map(chep => {
                if (chep?.fullnumchep)
                  return (
                    <div
                      key={chep?.fullnumchep + chep?.id_membres}
                      className="chep_card pointer"
                      onClick={() => goCheptel(chep?.fullnumchep)}
                    >
                      <div className="first_line">
                        <p className="nom_cheptel">
                          {chep?.nomducheptelraisonsociale_membres}
                        </p>
                        {chep.hbl_membres === "1" && (
                          <div className="adherent">HBL</div>
                        )}
                      </div>
                      {chep?.nomducheptelraisonsociale_membres &&
                        !chep?.nomducheptelraisonsociale_membres.includes(
                          chep?.nomducheptelpersonne_membres
                        ) && (
                          <p>
                            {chep?.nomducheptelpersonne_membres}{" "}
                            {chep?.prenomducheptelpersonne_membres}
                          </p>
                        )}

                      <p className="numchep">{chep?.fullnumchep}</p>

                      <p className="text">
                        {chep?.codepostal_membres}{" "}
                        {chep?.commune_membres && `- ${chep?.commune_membres}`}
                      </p>
                      <p className="text">{chep?.adresse_membres}</p>
                      <p className="text">{chep?.telphone_membres}</p>
                      <p className="text">{chep?.email_membres}</p>
                      {tmpListSaved.includes(chep?.fullnumchep) && (
                        <>
                          <div className="in_app">
                            <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                              mobile_friendly
                            </span>
                          </div>
                          <div
                            onClick={e => {
                              e.stopPropagation()
                              chepToDel.current = chep?.fullnumchep
                              askDeleteCheptel()
                            }}
                            className="btn_delete ripple12"
                          >
                            <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                              delete
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  )
              })}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Admin
