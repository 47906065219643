import { fetchUrlEncoded } from "./fetch"

// Fetch for account registration
export const fetchRegister = async (
  numCheptel,
  email,
  password,
  phoneNumber,
  answer
) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "register_account"

  let params = {
    a: action,
    numCheptel: numCheptel,
    email: email,
    password: password,
    phoneNumber: phoneNumber,
    answer: answer,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
