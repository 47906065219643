import { useRef } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import "./ModalSelectVals.scss"
import { goBack } from "../../../functions/handleNavigation"

const ModalSelectVals = ({ vals, selectedVals, col, callback }) => {
  ///////////
  // HOOKS //
  ///////////
  let divModal = useRef()
  useCenterModal(divModal)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const cocheCase = val => {
    let laCase = document.getElementById("live_val_" + val)
    laCase.firstChild?.classList.toggle("on")

    if (selectedVals.includes(val)) {
      selectedVals = selectedVals.filter(v => v !== val)
    } else {
      selectedVals.push(val)
    }

    callback(
      col,
      [...selectedVals].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    )
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div ref={divModal} className="modal_select_vals">
        <div className="div_choices_input">
          {vals
            .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
            .map(val => (
              <div
                className="div_line_val ripple2 pointer"
                id={"live_val_" + val}
                onClick={() => cocheCase(val)}
                key={val}
              >
                <div
                  className={
                    selectedVals?.includes(val)
                      ? "cocheConfirmable on"
                      : "cocheConfirmable"
                  }
                ></div>
                <p>{val ? val : "Aucun(e)"}</p>
              </div>
            ))}
        </div>
        <div className="div_button_input_choices">
          <button className="ripple2 pointer" onClick={goBack}>
            Valider
          </button>
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalSelectVals
