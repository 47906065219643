import { useEffect, useRef } from "react"
import "./ModalChangeLogs.scss"
import { useCenterModal } from "../hooks/useCenterModal"
import { goBack } from "../functions/handleNavigation"

const ModalChangeLogs = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  let changeLogs = []

  let appVers = window.localStorage.app_version
  let cache_changelogs = window.localStorage.changeLogs ?? ""
  changeLogs = cache_changelogs !== "" ? JSON.parse(cache_changelogs) : []
  let lastVers = changeLogs.length > 0 ? changeLogs[0].version : ""
  let colorVersion = appVers === lastVers ? "green_text" : "red_text"

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divModal.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0

      curr.style.maxHeight = `calc(calc(var(--vh) * 90) - ${addHeight}px)`

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = curr.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      curr.style.top = `${topHeight}px`
    }
  }, [])

  ///////////////
  // Functions //
  ///////////////
  const goToReglesConf = () => (window.location.hash = "reglesconf|inApp")

  return (
    <>
      <div className={`modal_changelog`} ref={divModal}>
        <div className="div_header_version">
          <span
            onClick={goBack}
            className="close_modal_version ripple2  material-symbols-outlined"
          >
            close
          </span>
          <div className="div_content_version">
            <h3>Version actuelle</h3>
            <p className={`version_modal version_number ${colorVersion}`}>
              {appVers}
            </p>
          </div>
        </div>
        <div className={`div_body_version`}>
          {Array.isArray(changeLogs) &&
            changeLogs.length !== 0 &&
            changeLogs?.map(c => (
              <div className="div_log_version" key={c?.version}>
                <h3>{c?.version}</h3>
                <ul>
                  {Array.isArray(c?.logs) &&
                    c?.logs.length !== 0 &&
                    c?.logs.map(l => <li key={l.displayOrder}>{l.message}</li>)}
                </ul>
              </div>
            ))}
        </div>
        <div
          className="div_regles_conf ripple2 pointer"
          onClick={goToReglesConf}
        >
          Voir les règles de confidentialité
        </div>
      </div>
      <button className={`background-modal`} onClick={goBack}></button>
    </>
  )
}

export default ModalChangeLogs
