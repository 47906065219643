import "./Repartition.scss"
import CheptelSectionTitle from "../CheptelSectionTitle"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { useState } from "react"

ChartJS.register(ArcElement, Tooltip, Legend)

const Repartition = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showevolution === "false" ? false : true
  const [show, setShow] = useState(savedShow)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showrepartition = !show
  }
  const pieDataSexes = {
    labels: [`Mâles (${data.nbMale})`, `Femelles (${data.nbFemale})`],
    datasets: [
      {
        label: "Répartition mâles/femelles",
        data: [data.nbMale, data.nbFemale],
        backgroundColor: ["#00b4e3", "#ffabf2"],
        borderWidth: 3,
        borderColor: "#FFFFFF",
      },
    ],
  }
  const pieDataFemales = {
    labels: [`Vaches (${data.nbVaches})`, `Génisses (${data.nbGenisses})`],
    datasets: [
      {
        label: "Répartition vaches/génisses",
        data: [data.nbVaches, data.nbGenisses],
        backgroundColor: ["#ae66ca", "#ffabf2"],
        borderWidth: 3,
        borderColor: "#FFFFFF",
      },
    ],
  }
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 25,
          boxHeight: 15,
        },
      },
    },
  }

  return (
    <div>
      <CheptelSectionTitle
        title="Répartition"
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="repartition">
          <div className="piesexes">
            <Doughnut data={pieDataSexes} options={pieOptions} />
          </div>
          <div className="piefemales">
            <Doughnut data={pieDataFemales} options={pieOptions} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Repartition
