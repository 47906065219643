import "./Header.scss"
import SlidingMenu from "../components/SlidingMenu"
import { useEffect, useRef, useState } from "react"
import localforage from "localforage"
import { handleSwipe } from "../functions/handleSwipe"
import { updateMessages } from "../functions/updateMessages"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import Confirm from "../components/Confirm"
import ModalStatutDl from "../components/ModalStatutDl"
import {
  statutBarColor,
  statutBarDefaultColor,
  statutBarN1Color,
} from "../functions/statutBarColor"
import { goBack, goBackXAmount } from "../functions/handleNavigation"
import { goToHashWithParams } from "../functions/hash"

let intervalMessages

const Header = ({ page, pageOption, name, downloading }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const [classMenu, setClassMenu] = useState("hide")
  const [showBtnMenu, setShowBtnMenu] = useState(false)
  const [searchAdmin, setSearchAdmin] = useState("")
  const [searchOp, setSearchOp] = useState("")
  const [searchInv, setSearchInv] = useState("")
  const [hide, setHide] = useState("")
  const [nbMessagesUnread, setNbMessagesUnread] = useState(0)
  const [nbFilters, setNbFilters] = useState(0)
  const [nbFiltersCust, setNbFiltersCust] = useState(0)
  const [nbFiltersVisite, setNbFiltersVisite] = useState(0)
  const [nbStation, setNbStation] = useState(1)
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()
  const [isDetenteurAni, setIsDententeurAni] = useState("non")

  // Handle display statut DL modal
  const [clSatutDL, setClStatutDL] = useState(false)
  pageOption === "statutdl" && !clSatutDL && setClStatutDL(true)
  pageOption !== "statutdl" && clSatutDL && setClStatutDL(false)
  !downloading && pageOption === "statutdl" && goBack()

  const elSearchAdmn = useRef()
  const elSearchOp = useRef()

  let param1 = window.location.hash.split("|")[1]

  if (page && page.includes("_")) page = page.split("_")[0]

  page === "inventaire" &&
    pageOption !== "search" &&
    searchInv !== "" &&
    window.innerWidth < 770 &&
    setSearchInv("") &&
    (localStorage.searchInv = "")

  page !== "inventaire" && nbFilters !== 0 && setNbFilters(0)
  page !== "gestioncolumns" && nbFiltersCust !== 0 && setNbFiltersCust(0)
  page !== "visites" && nbFiltersVisite !== 0 && setNbFiltersVisite(0)

  page !== "admin" && searchAdmin !== "" && setSearchAdmin("")

  let cacheTooltipShare = window.localStorage.tooltipShare ?? "display"
  const [tooltipShare, setTooltipShare] = useState(cacheTooltipShare)

  let customInvName = ""
  if (page === "customfilters") {
    let listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")
    customInvName = listCustInv[+param1.replace("custom", "")].name
  }

  const inputSearchMobile = useRef()

  let valA = window.localStorage.valA === "true" ? true : false
  let valO = window.localStorage.valO === "true" ? true : false
  let stringifiedDroitsOP = window.localStorage?.droitsOP ?? "{}"
  if (stringifiedDroitsOP === undefined) stringifiedDroitsOP = "{}"
  let droitsOP = JSON.parse(stringifiedDroitsOP)
  let numChep = window.localStorage.numCheptel ?? ""

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    window.addEventListener("resize", resizeTextHeader)

    const hideHeader = () => {
      setHide("hidden")
    }
    const showHeader = () => {
      setHide("")
    }
    const messagesRead = () => {
      setNbMessagesUnread(0)
    }
    const filterNumber = e => {
      setNbFilters(e.detail.nbFilters)
    }
    const filterNumberCust = e => {
      setNbFiltersCust(e.detail.nbFilters)
    }
    const filterNumberVisite = e => {
      setNbFiltersVisite(e.detail.nbFilters)
    }
    const nombreStation = e => {
      setNbStation(e.detail.nbStation)
    }
    const getNbUnreadMessages = async () => {
      let messages = await updateMessages()

      let nbUnread = 0
      messages?.forEach(m => m.read === "0" && nbUnread++)

      setNbMessagesUnread(nbUnread)
    }
    const setFlagDetenteur = e => {
      setIsDententeurAni(e.detail.isDetenteur)
    }
    const emptySearchOp = () => setSearchOp("")

    let hdr = document.getElementById("header")
    if (hdr) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual =
          +window
            .getComputedStyle(hdr, null)
            .getPropertyValue("top")
            .split("px")[0] ?? 0

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        hdr.style.top = newHeight + "px"
      }

      hdr.addEventListener("hideHeader", hideHeader)
      hdr.addEventListener("showHeader", showHeader)
      hdr.addEventListener("getNbUnreadMessages", getNbUnreadMessages)
      hdr.addEventListener("messagesRead", messagesRead)
      hdr.addEventListener("filterNumber", filterNumber)
      hdr.addEventListener("filterNumberCust", filterNumberCust)
      hdr.addEventListener("filterNumberVisite", filterNumberVisite)
      hdr.addEventListener("nombreStation", nombreStation)
      hdr.addEventListener("setFlagDetenteur", setFlagDetenteur)
      hdr.addEventListener("emptySearchOp", emptySearchOp)
    }

    getNbUnreadMessages()

    intervalMessages = setInterval(() => {
      getNbUnreadMessages()
    }, 5 * 60 * 1000)

    // Swipe on the side to have menu open
    let removeSwipe = handleSwipe(
      document.getElementById("root"),
      swipeDirection => {
        if (
          swipeDirection === "right" &&
          classMenu === "hide" &&
          (window.location.hash === "#cheptel" ||
            window.location.hash === "#admin")
        ) {
          console.log("la")
          showMenu()
        }
      },
      true
    )

    return () => {
      window.removeEventListener("resize", resizeTextHeader)

      hdr.removeEventListener("hideHeader", hideHeader)
      hdr.removeEventListener("showHeader", showHeader)
      hdr.removeEventListener("getNbUnreadMessages", getNbUnreadMessages)
      hdr.removeEventListener("messagesRead", messagesRead)
      hdr.removeEventListener("filterNumber", filterNumber)
      hdr.removeEventListener("filterNumberCust", filterNumberCust)
      hdr.removeEventListener("filterNumberVisite", filterNumberVisite)
      hdr.removeEventListener("nombreStation", nombreStation)
      hdr.removeEventListener("setFlagDetenteur", setFlagDetenteur)
      hdr.removeEventListener("emptySearchOp", emptySearchOp)

      clearInterval(intervalMessages)

      removeSwipe()
    }
  }, [])
  useEffect(() => {
    if (page === "inventaire") {
      window.localStorage.searchInv = searchInv

      const event = new CustomEvent("searchUpdated", {
        detail: {
          searchInv: searchInv.trim(),
        },
      })

      let divInv = document.getElementById("div_inventaire")

      if (divInv) divInv.dispatchEvent(event)
    }

    localStorage.searchInv = searchInv
  }, [searchInv])
  useEffect(() => {
    if (page === "admin") {
      window.localStorage.searchAdmin = searchAdmin

      const event = new CustomEvent("searchUpdated", {
        detail: {
          searchAdmin: searchAdmin,
        },
      })

      let divAdmn = document.getElementById("admin_content")

      if (divAdmn) divAdmn.dispatchEvent(event)
    }
  }, [searchAdmin])
  useEffect(() => {
    if (page === "adherentsop") {
      window.localStorage.searchOp = searchOp

      const event = new CustomEvent("searchUpdated", {
        detail: {
          searchOp: searchOp,
        },
      })

      let divOp = document.getElementById("op_content")

      if (divOp) divOp.dispatchEvent(event)
    }
  }, [searchOp])
  useEffect(() => {
    page === "inventaire" &&
      pageOption === "search" &&
      searchInv !== "" &&
      inputSearchMobile.current.select()
  }, [pageOption])
  useEffect(() => {
    resizeTextHeader()
  }, [page])
  ///////////////
  // Functions //
  ///////////////
  // Hide the tooltip in fiche animal to tell you can share the fiche animal
  const hideTooltipShare = () => {
    window.localStorage.tooltipShare = "donotdisplay"
    setTooltipShare("donotdisplay")
  }
  // Show side menu
  const showMenu = () => {
    localforage.getItem("login", (err, value) => {
      if (value) {
        statutBarColor(statutBarN1Color)

        setClassMenu("show")
        setShowBtnMenu(true)
        document.getElementById("header").classList.add("push")
        document.getElementById("app").classList.add("push")
      }
    })
  }
  // Hide side menu
  const hideMenu = () => {
    statutBarColor(statutBarDefaultColor)

    setClassMenu("hide")
    setShowBtnMenu(false)
    document.getElementById("header").classList.remove("push")
    document.getElementById("app").classList.remove("push")
  }
  // Hide menu then ask for change cheptel logic
  const changeCheptel = async () => {
    hideMenu()

    document.getElementById("app")?.dispatchEvent(new CustomEvent("changeChep"))
  }
  // Go back that empty the search
  const goBackSearch = () => {
    setSearchInv("")
    localStorage.searchInv = ""
    goBack()
  }
  const goBackMultiSearch = nb => {
    setSearchInv("")
    localStorage.searchInv = ""
    goBackXAmount(nb)
  }
  // Go do the admin search
  const goSearchAdmn = () => {
    const event = new CustomEvent("doSearch")

    let divAdmn = document.getElementById("admin_content")

    if (divAdmn) divAdmn.dispatchEvent(event)
  }
  // Go do the op search
  // const goSearchOp = () => {
  //   const event = new CustomEvent("doSearch")

  //   let divAdmn = document.getElementById("op_content")

  //   if (divAdmn) divAdmn.dispatchEvent(event)
  // }
  // Go to specified hash
  const goToHash = hash => (window.location.hash = hash)
  // Particular go search from Animal page to keep app like navigation flow
  const goSearchFromAnimal = () => {
    let firstSearchAniMobile = localStorage.firstSearchAniMobile ?? ""

    if (firstSearchAniMobile !== "notFirst") {
      localStorage.firstSearchAniMobile = "notFirst"

      window.location.hash = "inventaire_search"
    } else {
      localStorage.needGoSearch = "goSearch" // Fix for mobile so we keep app like navigation
      goBack()
    }
  }
  const checkGoTo = e => {
    if (e.key === "Enter") {
      window.localStorage.searchInv = searchInv

      const event = new CustomEvent("searchValidated")

      let divInv = document.getElementById("div_inventaire")

      if (divInv) divInv.dispatchEvent(event)
    }
  }
  const reloadButton = confirmed => {
    hideConfirm()

    if (confirmed)
      document
        .getElementById("app")
        ?.dispatchEvent(new CustomEvent("refreshData"))
  }
  // Share fiche animal functionnality coded here
  const shareFiche = async () => {
    document
      .getElementById("fiche_animal")
      ?.dispatchEvent(new CustomEvent("shareFiche"))
  }
  const ficheAniCamera = async () => {
    document
      .getElementById("fiche_animal")
      ?.dispatchEvent(new CustomEvent("ficheAniCamera"))
  }
  const shareFicheStation = async () => {
    document
      .getElementById("fiche_station")
      ?.dispatchEvent(new CustomEvent("shareFiche"))
  }
  const editCheptelOp = () => {
    window.location.hash = "adherentsop_cheptels"
  }
  const resizeTextHeader = () => {
    if (
      page === "cheptel" ||
      page === "inventaire" ||
      page === "animal" ||
      page === "station" ||
      page === "visites" ||
      page === "fichestation"
    ) {
      let textHeader = document.getElementById("text_header_inv")

      if (window.innerWidth > 770) {
        if (page === "cheptel" || page === "inventaire" || page === "animal") {
          let emptyLeftSpace = 0

          let divSearchBar = document.getElementById("div_search_desktop")
          let divInputSearch = document.getElementById("div_input_search_desk")

          if (divSearchBar && divInputSearch) {
            let widthParetnDiv = divSearchBar.offsetWidth
            let widthChildDiv = divInputSearch.offsetWidth

            let emptySpace = widthParetnDiv - widthChildDiv
            emptyLeftSpace = emptySpace / 2
          }

          if (textHeader) {
            let availableSpace = emptyLeftSpace + 110

            textHeader.style.width = `${availableSpace}px`
          }
        } else if (page === "station" || page === "fichestation") {
          if (page === "station") textHeader.style.width = "calc(100vw - 46px)"
          else if (page === "fichestation")
            textHeader.style.width = "calc(100vw - 92px)"
          else if (page === "visites")
            textHeader.style.width = "calc(100vw - 92px)"
        }
      } else {
        if (textHeader) {
          if (page === "cheptel") textHeader.style.width = "calc(100vw - 138px)"
          else if (page === "inventaire" || page === "animal")
            textHeader.style.width = "calc(100vw - 230px)"
          else if (page === "station")
            textHeader.style.width = "calc(100vw - 46px)"
          else if (page === "visites")
            textHeader.style.width = "calc(100vw - 92px)"
          else if (page === "fichestation")
            textHeader.style.width = "calc(100vw - 92px)"
        }
      }
    }
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      {confirm && (
        <Confirm
          message={messageConfirm}
          giveConfirm={reloadButton}
          okText="Oui"
          cancelText="Non"
        />
      )}

      <SlidingMenu
        classMenu={classMenu}
        hideMenu={hideMenu}
        nbMessagesUnread={nbMessagesUnread}
        name={name}
        page={page}
      />
      {showBtnMenu && (
        <button className={`overflow-lat`} onClick={hideMenu}></button>
      )}

      <header id="header" className={`header_bar ${hide}`}>
        {page === "reglesconf" && (
          <>
            {param1 === "inApp" ? (
              <>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={() => goBackMultiSearch(2)}
                >
                  arrow_back
                </span>

                <div className="header_inv">
                  <div className="text_header_inv">
                    Règles de confidentialité
                  </div>
                </div>
              </>
            ) : (
              <div className="header_inv_title_only">
                Règles de confidentialité
              </div>
            )}
          </>
        )}
        {page === "admin" && (
          <>
            <div onClick={showMenu}>
              <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2">
                menu
              </span>
              {nbMessagesUnread > 0 && (
                <div className="icon_msg_unread">{nbMessagesUnread}</div>
              )}
            </div>
            <div className="div_search">
              <input
                type="search"
                id="search_home"
                ref={elSearchAdmn}
                className="input_search set_width2"
                placeholder="Num/nom cheptel ou num animal"
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.stopPropagation()
                    elSearchAdmn.current.blur()
                    goSearchAdmn()
                  }
                }}
                value={searchAdmin}
                onChange={e => setSearchAdmin(e.target.value.toUpperCase())}
              />
              {searchAdmin && (
                <span
                  className="ripple2 clear_search material-symbols-outlined material-symbols-outlined_even_more_weight"
                  onClick={() => setSearchAdmin("")}
                >
                  close
                </span>
              )}
            </div>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() =>
                searchAdmin === ""
                  ? elSearchAdmn.current.focus()
                  : goSearchAdmn()
              }
            >
              search
            </span>
          </>
        )}
        {page === "op" && (
          <>
            <div onClick={showMenu}>
              <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2">
                menu
              </span>
              {nbMessagesUnread > 0 && (
                <div className="icon_msg_unread">{nbMessagesUnread}</div>
              )}
            </div>
            <div className="header_inv">
              <div className="text_header_inv">Mes outils OP</div>
            </div>
          </>
        )}
        {page === "adherentsop" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBackSearch}
            >
              arrow_back
            </span>
            <div className="div_search">
              <input
                type="search"
                id="search_home"
                ref={elSearchOp}
                className="input_search set_width3"
                placeholder="Num/nom cheptel"
                value={searchOp}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.stopPropagation()
                    elSearchOp.current.blur()
                  }
                }}
                onChange={e => setSearchOp(e.target.value.toUpperCase())}
              />
              {searchOp && (
                <span
                  className="ripple2 clear_search material-symbols-outlined material-symbols-outlined_even_more_weight"
                  onClick={() => setSearchOp("")}
                >
                  close
                </span>
              )}
            </div>
            <span
              className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2"
              onClick={() => elSearchOp.current.focus()}
            >
              search
            </span>
            {droitsOP.adminadh_op !== false && (
              <span
                className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2"
                onClick={editCheptelOp}
              >
                edit
              </span>
            )}
          </>
        )}
        {page === "faildl" && (
          <>
            {valA || valO ? (
              <span
                className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                onClick={changeCheptel}
              >
                arrow_back
              </span>
            ) : (
              <div onClick={showMenu}>
                <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2">
                  menu
                </span>
                {nbMessagesUnread > 0 && (
                  <div className="icon_msg_unread">{nbMessagesUnread}</div>
                )}
              </div>
            )}

            <div className="header_inv">
              <div className="text_header_inv">
                {valA && window.localStorage.numCheptel + " - "}
                Erreur téléchargement
              </div>
            </div>
          </>
        )}
        {page === "game" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBackSearch}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv"></div>
            </div>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() => goToHash("game_rankings")}
            >
              trophy
            </span>
          </>
        )}
        {page === "messagerie" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBackSearch}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">Ma messagerie</div>
            </div>
          </>
        )}
        {page === "cheptel" && (
          <>
            <div onClick={showMenu}>
              <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight ripple2">
                menu
              </span>
              {nbMessagesUnread > 0 && (
                <div className="icon_msg_unread">{nbMessagesUnread}</div>
              )}
            </div>
            <div className="header_inv">
              <div
                id="text_header_inv"
                className="text_header_inv_cheptel full_width"
                title={(valO || valA) && name ? name : null}
              >
                {valO || valA ? <>{name ? name : numChep}</> : "Mon EspacePro"}
              </div>
              <InputSearchDesktop
                searchInv={searchInv}
                setSearchInv={setSearchInv}
                origin="cheptel"
              />
            </div>
            <span
              className="header_icon pointer icone_mobile material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() => goToHash("inventaire_search")}
            >
              search
            </span>
            <div>
              {!downloading ? (
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={e => {
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()

                    let numCheptel = window.localStorage.numCheptel ?? ""
                    let lastUpdate = window.localStorage.getItem(
                      "dateOfLatestData" + numCheptel
                    )
                    let objDate = new Date(+lastUpdate)

                    let year = objDate.getFullYear()
                    let month = objDate.getMonth() + 1
                    let day = objDate.getDate()
                    let hour = objDate.getHours()
                    let mins = objDate.getMinutes()

                    if (month < 10) month = "0" + month
                    if (day < 10) day = "0" + day
                    if (hour < 10) hour = "0" + hour
                    if (mins < 10) mins = "0" + mins

                    let time = `${day}-${month}-${year} à ${hour}h${mins}`

                    doConfirm(
                      `Les dernières données datent <br><br> du ${time}. <br><br> Voulez vous recharger les données ?`
                    )
                  }}
                >
                  autorenew
                </span>
              ) : (
                <>
                  {clSatutDL && <ModalStatutDl />}
                  <div
                    className="wrap_lds_ring ripple2 pointer"
                    onClick={() => goToHash("cheptel_statutdl")}
                  >
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {page === "station" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div id="text_header_inv" className="text_header_inv_cheptel">
                {valO || valA ? (
                  <>{name ? name : numChep}</>
                ) : (
                  <>Mes animaux en {nbStation > 1 ? "stations" : "station"}</>
                )}
              </div>
            </div>
          </>
        )}
        {page === "visites" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div id="text_header_inv" className="text_header_inv_cheptel">
                {valO || valA ? (
                  <>{name ? name : numChep}</>
                ) : (
                  "Mes visites HBL"
                )}
              </div>
            </div>
            <div
              className="div_filters ripple2"
              onClick={() => goToHash(`visites_filter`)}
            >
              <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight">
                filter_alt
              </span>
              {nbFiltersVisite > 0 && (
                <div className="icon_nb_filters">{nbFiltersVisite}</div>
              )}
            </div>
          </>
        )}
        {page === "fichestation" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div id="text_header_inv" className="text_header_inv_cheptel">
                {valO || valA ? <>{name ? name : numChep}</> : "Infos animal"}
              </div>
            </div>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={shareFicheStation}
            >
              share
            </span>
          </>
        )}
        {page === "inventaire" && (
          <>
            {pageOption === "search" ? (
              <>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={goBackSearch}
                >
                  arrow_back
                </span>
                <div className="div_search div_search_mobile">
                  <input
                    autoFocus
                    id="input_search_inventaire"
                    type="search"
                    className="input_search set_width"
                    placeholder="Rechercher un animal"
                    value={searchInv}
                    ref={inputSearchMobile}
                    onChange={e => setSearchInv(e.target.value.toUpperCase())}
                    onKeyDown={checkGoTo}
                  />
                  {searchInv && (
                    <span
                      className="ripple2 clear_search material-symbols-outlined material-symbols-outlined_even_more_weight"
                      onClick={() =>
                        setSearchInv("") && (localStorage.searchInv = "")
                      }
                    >
                      close
                    </span>
                  )}
                </div>
              </>
            ) : (
              <>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={() => {
                    goBack()
                    window.localStorage.searchInv = "" // Needed to avoid emptying searchbar but having inv still filtred by it on leaving then goin back to inv
                  }}
                >
                  arrow_back
                </span>
                <div className="header_inv">
                  <div id="text_header_inv" className="text_header_inv_cheptel">
                    {valO || valA ? (
                      <>{name ? name : numChep}</>
                    ) : (
                      "Mes inventaires"
                    )}
                  </div>
                  <InputSearchDesktop
                    searchInv={searchInv}
                    setSearchInv={setSearchInv}
                    origin="inventaire"
                  />
                </div>

                <div
                  className="div_filters ripple2"
                  onClick={() => {
                    if (window.innerWidth >= 1100)
                      document
                        .getElementById("div_inventaire")
                        .dispatchEvent(new CustomEvent("sideFilters"))
                    else
                      goToHash(
                        `inventaire_filter|${window.localStorage.choixInv}`
                      )
                  }}
                >
                  <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight">
                    filter_alt
                  </span>
                  {nbFilters > 0 && (
                    <div className="icon_nb_filters">{nbFilters}</div>
                  )}
                </div>

                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={() => goToHash("inventaire_pickdate")}
                >
                  calendar_month
                </span>
                <span
                  className="header_icon pointer icone_mobile material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={() => goToHash("inventaire_search")}
                >
                  search
                </span>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight2"
                  onClick={() => goToHash("inventaire_dots")}
                >
                  more_vert
                </span>
              </>
            )}
          </>
        )}
        {page === "animal" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div id="text_header_inv" className="text_header_inv_cheptel">
                {valO || valA ? <>{name ? name : numChep}</> : "Fiche animal"}
              </div>
              <InputSearchDesktop
                searchInv={searchInv}
                setSearchInv={setSearchInv}
                origin="animal"
              />
            </div>
            {window.localStorage.numCheptel !== "" && (
              <span
                className="header_icon pointer icone_mobile material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                onClick={goSearchFromAnimal}
              >
                search
              </span>
            )}
            {isDetenteurAni === "oui" && (
              <>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  onClick={ficheAniCamera}
                >
                  photo_camera
                </span>
                <span
                  className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
                  // onClick={isMobile ? shareFiche : shareFichePC}
                  onClick={shareFiche}
                >
                  share
                </span>
              </>
            )}
            {tooltipShare !== "donotdisplay" && (
              <div className="tooltip">
                <div className="encoche"></div>
                <h3>Partager la fiche !</h3>
                <p>
                  Vous pouvez partager vos fiches animal sur les autres
                  applications de votre téléphone tel que Facebook, WhatsApp,
                  etc...
                </p>
                <p>
                  Les onglets de la fiche que vous aurez fermer ne seront pas
                  partager.
                </p>
                <div className="div_btn_tooltip">
                  <button
                    className="btn_tooltip ripple2"
                    onClick={hideTooltipShare}
                  >
                    J'ai compris
                  </button>
                </div>
              </div>
            )}
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight2"
              onClick={() => goToHashWithParams("animal_dots")}
            >
              more_vert
            </span>
          </>
        )}
        {page === "gestioninv" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() => goBackXAmount(2)}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">Gestion des inventaires</div>
            </div>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() => goToHash("gestioninv_addinv")}
            >
              add
            </span>
          </>
        )}
        {page === "gestioncolumns" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">Gestion des colonnes</div>
            </div>
            <div
              className="div_filters ripple2"
              onClick={() => goToHashWithParams("customfilters")}
            >
              <span className="header_icon pointer material-symbols-outlined material-symbols-outlined_even_more_weight">
                filter_alt
              </span>
              {nbFiltersCust > 0 && (
                <div className="icon_nb_filters">{nbFiltersCust}</div>
              )}
            </div>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={() => goToHashWithParams("gestioncolumns_settings")}
            >
              settings
            </span>
          </>
        )}
        {page === "customfilters" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">
                Filtres de l'inventaire: {customInvName}
              </div>
            </div>
          </>
        )}
        {page === "comparaison" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">Comparaison</div>
            </div>
          </>
        )}
        {page === "account" && (
          <>
            <span
              className="header_icon pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
              onClick={goBack}
            >
              arrow_back
            </span>
            <div className="header_inv">
              <div className="text_header_inv">
                {valA ? "Infos éleveur" : "Mes informations"}
              </div>
            </div>
          </>
        )}
      </header>
    </>
  )
}

export default Header

const InputSearchDesktop = ({ searchInv, setSearchInv, origin }) => {
  const divSearchInputDestop = useRef()
  const searchInputDesktop = useRef()

  const focusSearchInput = () => {
    searchInputDesktop.current.focus()
  }
  const changeBackground = focused => {
    if (focused) divSearchInputDestop.current.classList.add("input_focused")
    else divSearchInputDestop.current.classList.remove("input_focused")
  }
  const checkGoTo = e => {
    if (e.key === "Enter") {
      const event = new CustomEvent("searchValidated")

      let divInv = document.getElementById("div_inventaire")

      if (divInv) divInv.dispatchEvent(event)
    }
  }
  const onChangeInput = value => {
    window.localStorage.searchInv = value

    if (origin === "inventaire") setSearchInv(value)
    if (origin === "cheptel") {
      if (value) window.location.hash = "inventaire"
      setSearchInv(value)
    }
    if (origin === "animal") {
      let listeHistoAni = JSON.parse(window.localStorage.listeHistoAni ?? "[]")

      if (value) {
        goBackXAmount(listeHistoAni.length)
      }
      setSearchInv(value)
    }
  }

  useEffect(() => {
    if (origin !== "inventaire" && origin !== "animal") {
      setSearchInv("")
      localStorage.searchInv = ""
    }
    if (origin === "inventaire" && searchInv) searchInputDesktop.current.focus()
    divSearchInputDestop.current.classList.add("transition")
  }, [])

  return (
    <div className="div_search_desktop" id="div_search_desktop">
      <div
        className="div_input_search_desk"
        id="div_input_search_desk"
        onClick={focusSearchInput}
        ref={divSearchInputDestop}
      >
        <span className="search_inp_icon material-symbols-outlined">
          search
        </span>
        <input
          ref={searchInputDesktop}
          id="input_search_inventaire"
          type="search"
          className="input_search_desktop"
          placeholder="Rechercher un animal"
          value={searchInv}
          onChange={e => onChangeInput(e.target.value.toUpperCase())}
          onKeyDown={checkGoTo}
          onFocus={() => {
            changeBackground(true)
            if (origin === "animal")
              if (searchInv && window.localStorage.searchInv) goBack()
          }}
          onBlur={() => changeBackground(false)}
        />

        <span
          className={
            searchInv
              ? "ripple2 search_inp_icon clear_search pointer material-symbols-outlined material-symbols-outlined_even_more_weight"
              : "search_inp_icon clear_search material-symbols-outlined material-symbols-outlined_even_more_weight"
          }
          onClick={() => {
            setSearchInv("")
            window.localStorage.searchInv = "" // Needed to avoid emptying searchbar but having inv still filtred by it
          }}
        >
          {searchInv && "close"}
        </span>
      </div>
    </div>
  )
}
