import "./ModalAskConfirm.scss"
import { useEffect, useRef, useState } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack, goBackXAmount } from "../../../functions/handleNavigation"
import BigGreenButton from "../../BigGreenButton"

const ModalAskConfirm = ({ casesCochees, infosAni, callback, origin }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  let cachedImpressions = JSON.parse(localStorage.caseImpressions ?? "{}")

  let tmpData = casesCochees.map(ani => {
    return {
      num: ani,
      NOBOVI: infosAni[ani].NOBOVI,
      COFGMU: infosAni[ani].COFGMU,
      SEXBOV: infosAni[ani].SEXBOV,
    }
  })
  const [data, setData] = useState(tmpData)
  const [impressions, setImpressions] = useState(cachedImpressions)

  const [sortNum, setSortNum] = useState("sortDown")
  const [sortNom, setSortNom] = useState("")
  const [sortSexe, setSortSexe] = useState("")
  const [sortImpr, setSortImpr] = useState("")

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    localStorage.caseImpressions = JSON.stringify(impressions)
  }, [impressions])
  ///////////////
  // Functions //
  ///////////////
  const altGoBack = () => {
    if (origin === "animal") goBackXAmount(2)
    else goBack()
  }
  const selectAll = () => {
    casesCochees.forEach(ani => {
      impressions[ani] = true

      setImpressions({ ...impressions })
    })
  }
  const unSelectAll = () => {
    casesCochees.forEach(ani => {
      impressions[ani] = false

      setImpressions({ ...impressions })
    })
  }
  const sortCol = col => {
    if (col === "num") {
      const frCollator = new Intl.Collator("fr")

      if (sortNum !== "sortDown") {
        data.sort((a, b) => frCollator.compare(a.num, b.num))

        setSortNum("sortDown")
      } else {
        data.sort((b, a) => frCollator.compare(a.num, b.num))

        setSortNum("sortUp")
      }

      setSortNom("")
      setSortSexe("")
      setSortImpr("")
    } else if (col === "nom") {
      const frCollator = new Intl.Collator("fr")

      if (sortNom !== "sortDown") {
        data.sort((a, b) => frCollator.compare(a.NOBOVI, b.NOBOVI))

        setSortNom("sortDown")
      } else {
        data.sort((b, a) => frCollator.compare(a.NOBOVI, b.NOBOVI))

        setSortNom("sortUp")
      }

      setSortNum("")
      setSortSexe("")
      setSortImpr("")
    } else if (col === "sexe") {
      if (sortSexe !== "sortDown") {
        console.log("ici")

        data.sort((a, b) =>
          +a.SEXBOV > +b.SEXBOV ? 1 : +a.SEXBOV < +b.SEXBOV ? -1 : 0
        )

        setSortSexe("sortDown")
      } else {
        data.sort((b, a) =>
          +a.SEXBOV > +b.SEXBOV ? 1 : +a.SEXBOV < +b.SEXBOV ? -1 : 0
        )

        setSortSexe("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortImpr("")
    } else if (col === "impr") {
      if (sortImpr !== "sortDown") {
        data.sort((a, b) =>
          impressions?.[a.num] && !impressions?.[b.num]
            ? 1
            : !impressions?.[a.num] && impressions?.[b.num]
            ? -1
            : 0
        )

        setSortImpr("sortDown")
      } else {
        data.sort((b, a) =>
          impressions?.[a.num] && !impressions?.[b.num]
            ? 1
            : !impressions?.[a.num] && impressions?.[b.num]
            ? -1
            : 0
        )

        setSortImpr("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortSexe("")
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let allSelected = casesCochees.length !== 0 ? true : false
  casesCochees.forEach(ani => !impressions?.[ani] && (allSelected = false))

  return (
    <>
      <div ref={divModal} className={"modal_ask_confirm"}>
        <div className="div_modal_header">
          <span
            onClick={altGoBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <h3>Confirmation(s)</h3>
        </div>
        {/* <div className="top_message">
          Veuillez sélectionner si vous le souhaiter une impression sur carton
          HBL s.v.p
        </div> */}
        {/* <div className="select_all_div">
          <BigGreenButton
            onClick={allSelected ? unSelectAll : selectAll}
            text={allSelected ? "Tout désélectionner" : "Tout sélectionner"}
          />
        </div> */}
        <div className="modal_content">
          <div className="div_table">
            <table>
              <thead>
                <tr>
                  <th
                    onClick={() => sortCol("num")}
                    className={sortNum + " pointer ripple2"}
                  >
                    Numéro
                  </th>
                  <th
                    onClick={() => sortCol("sexe")}
                    className={sortSexe + " pointer ripple2"}
                  >
                    Sexe
                  </th>
                  <th
                    onClick={() => sortCol("nom")}
                    className={sortNom + " pointer ripple2"}
                  >
                    Nom
                  </th>
                  {/* <th
                    onClick={() => sortCol("impr")}
                    className={sortImpr + " pointer ripple2"}
                  >
                    Impression
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data?.map(ani => (
                  <tr key={ani.num}>
                    <td>{ani.num}</td>
                    <td>
                      {(() => {
                        let class_sexbov

                        if (ani.SEXBOV === "2")
                          if (ani.COFGMU > 1) class_sexbov = "femelle_jumelle"
                          else class_sexbov = "femelle"
                        if (ani.SEXBOV === "1")
                          if (ani.COFGMU > 1) class_sexbov = "male_jumeau"
                          else class_sexbov = "male"

                        return <div className={`sexe ${class_sexbov}`}></div>
                      })()}
                    </td>
                    <td>{ani.NOBOVI}</td>
                    {/* <td>
                      <div
                        className={
                          impressions?.[ani.num] ? "coche on" : "coche"
                        }
                        onClick={() =>
                          impressions?.[ani.num]
                            ? setImpressions({
                                ...impressions,
                                [ani.num]: !impressions[ani.num],
                              })
                            : setImpressions({
                                ...impressions,
                                [ani.num]: true,
                              })
                        }
                      ></div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="div_bottom" id="div-bottom">
          {/* <div className="info_facturation">
            <p>
              Cette action entrainera la facturation en rapport. L'impression
              sur carton HBL entrainera des frais supplémentaires de 1€46 H.T.
            </p>
          </div> */}
          <div className="div_button">
            <button className="button_validate pointer" onClick={callback}>
              valider
            </button>
          </div>
        </div>
      </div>
      <button className={"background-modal"} onClick={altGoBack}></button>
    </>
  )
}

export default ModalAskConfirm
