import "./NotificationUpdate.scss"
import logo512 from "../assets/images/nlogo512.png"

const NotificationUpdate = ({
  id,
  classNotif,
  messageNotif,
  messageButton,
  onClick,
}) => {
  const onClickUpdateData = () => {
    onClick()
  }
  const getCl = () => {
    let cl = `wrapper_notification ${classNotif}`

    if (window.localStorage.sidePanelInv === "true") {
      cl += " notif_with_side_filters"
    }

    return cl
  }

  return (
    <div id={"wrapper_notification_" + id} className={getCl()}>
      <div className={`notification`}>
        <div className="div_text_update">
          <img className="img_update" src={logo512} />
          <p>{messageNotif}</p>
        </div>
        <button
          className="button-update ripple2 pointer"
          onClick={onClickUpdateData}
        >
          {messageButton}
        </button>
      </div>
    </div>
  )
}

export default NotificationUpdate
