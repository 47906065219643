export const todayDate = (format = "display", dateTs = 0) => {
  let todayDate

  if (dateTs === 0) todayDate = new Date()
  else todayDate = new Date(+dateTs)

  let year = todayDate.getFullYear()
  let month = todayDate.getMonth() + 1
  let day = todayDate.getDate()
  let hours = todayDate.getHours()
  let mins = todayDate.getMinutes()

  if (month < 10) month = "0" + month
  if (day < 10) day = "0" + day
  if (hours < 10) hours = "0" + hours
  if (mins < 10) mins = "0" + mins

  switch (format) {
    case "display":
      return `${day}-${month}-${year}`
    case "displaytime":
      return `${day}-${month}-${year} à ${hours}h${mins}`
    case "input":
      return `${year}-${month}-${day}`
    case "numeric":
      return +`${year}${month}${day}`

    default:
      break
  }
}
