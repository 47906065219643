import CheptelSectionTitle from "../CheptelSectionTitle"
import ModalLoader from "../ModalLoader"
import "./MyDocs.scss"
import { useState } from "react"
import Alert from "../Alert"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import { checkIfConnected } from "../../functions/checkIfConnecetd"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"

const MyDocs = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  let numCheptel = window.localStorage.numCheptel ?? ""
  let ts = Math.round(Date.now() / 1000)

  let savedShow = window.localStorage.showevolution === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showmydocs = !show
  }
  const getPdf = async (link, name) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let scrolledElementId = "wrap_page_cheptel"
                let div = document.getElementById(scrolledElementId)

                if (div) {
                  localStorage.scrollXPageNative = div.scrollLeft
                  localStorage.scrollYPageNative = div.scrollTop
                  localStorage.scrolledElementIdNative = scrolledElementId
                }

                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        window.open(link)
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_dlmydocs" message="Téléchargement en cours..." />

      <CheptelSectionTitle
        title="Mes documents"
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="mydocs">
          <div
            className="colorblock fit ripple11"
            onClick={() =>
              data.docFIT
                ? getPdf(
                    `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/dl/fit`,
                    `FIT_${data.docFIT}.pdf_${ts}`
                  )
                : doAlert("Pas de FIT trouvé.")
            }
          >
            <h3>FIT</h3>
            <div className="div_icon_pdf">
              <span className="icon_pdf material-symbols-outlined material-symbols-outlined_even_more_weight">
                picture_as_pdf
              </span>
            </div>
            <p className="text_icon_pdf">
              {data.docFIT && `édition du ${data.docFIT}`}
            </p>
          </div>
          <div
            className="colorblock fiva ripple7"
            onClick={() =>
              data.docFIVA
                ? getPdf(
                    `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/dl/fiva`,
                    `FIVA${data.docFIVA}_${ts}.pdf`
                  )
                : doAlert("Pas de FIVA trouvé.")
            }
          >
            <h3>FIVA</h3>
            <div className="div_icon_pdf">
              <span className="icon_pdf material-symbols-outlined material-symbols-outlined_even_more_weight">
                picture_as_pdf
              </span>
            </div>
            <p className="text_icon_pdf">
              {data.docFIVA && `édition du ${data.docFIVA}`}
            </p>
          </div>
          <div className="bgta doc-border">
            <h3>BGTA</h3>
            {data.docBGTA?.map((doc, i) => {
              return (
                <a
                  className="pdf-link ripple2"
                  target="_blank"
                  key={"bgta" + i}
                  onClick={() =>
                    getPdf(
                      `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/dl/bgta/${doc.num}.pdf`,
                      `BGTA_${doc.year}_${numCheptel}_${ts}.pdf`
                    )
                  }
                >
                  {`édition ${doc.year}`}
                </a>
              )
            })}
          </div>
          <div className="birth-data doc-border">
            <h3>Données de naissance</h3>
            {data.docBirth.TP && (
              <p
                className="pdf-link ripple2"
                target="_blank"
                onClick={() =>
                  getPdf(
                    `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/dl/TP`,
                    `TP_juin_${data?.anneeDocBirth ?? ""}_${ts}.pdf`
                  )
                }
              >
                {`édition août ${data?.anneeDocBirth ?? ""} TP`}
              </p>
            )}
            {data.docBirth.PN && (
              <p
                className="pdf-link"
                target="_blank"
                onClick={() =>
                  getPdf(
                    `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/dl/PN`,
                    `PN_juin_${data?.anneeDocBirth ?? ""}_${ts}.pdf`
                  )
                }
              >
                {`édition août ${data?.anneeDocBirth ?? ""} PN`}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default MyDocs
