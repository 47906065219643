import "./FicheAniShare.scss"
import background from "../../assets/images/background_fiche_export.png"
import { formatDate } from "../../functions/formatDate"
import CheptelSectionTitle from "../CheptelSectionTitle"

const FicheAniShare = ({ aniData, b64Blur, b64Graph }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let aneeBaseRefPere
  if (aniData?.Pannein) {
    aneeBaseRefPere = Math.round(aniData.Pannein - 1)?.toString()
    aneeBaseRefPere = aneeBaseRefPere.substring(2, 4)
  }
  let rangBaseRefPere
  if (aniData?.Prangin) {
    rangBaseRefPere = aniData.Prangin?.toString()
    rangBaseRefPere = rangBaseRefPere.substring(1, 2)
  }
  let aneeBaseRefMere
  if (aniData?.Mannein) {
    aneeBaseRefMere = Math.round(aniData.Mannein - 1)?.toString()
    aneeBaseRefMere = aneeBaseRefMere.substring(2, 4)
  }
  let rangBaseRefMere
  if (aniData?.Mrangin) {
    rangBaseRefMere = aniData.Mrangin?.toString()
    rangBaseRefMere = rangBaseRefMere.substring(1, 2)
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const genalogieBulle = (nom, copaip, numati, qualif, inscription, sexe) => {
    return (
      <div
        className={
          sexe === 2 ? "genalogie-bulle femelle" : "genalogie-bulle male"
        }
      >
        {nom}
        <i> {qualif}</i>
        {inscription && inscription !== qualif && (
          <i className="qualifSecond">{" " + inscription}</i>
        )}
        <div className="numTravail">
          {copaip && numati ? copaip + numati : ""}
        </div>
      </div>
    )
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  let isAnalyse =
    aniData.retyupsc ||
    aniData.retyupmh ||
    aniData.RETYUP_HPF ||
    aniData.retyuppaf ||
    aniData.retyuppro

  return (
    <div
      className={
        "ReactNativeWebView" in window
          ? "fiche_ani_share native_web_view"
          : "fiche_ani_share"
      }
    >
      {/* Div background */}
      <div className="div_background">
        <img src={background} />
      </div>
      {/* Div photo animal */}
      <div className="div_img_ani">
        <div className="photos">
          <img src={b64Blur} className="photo_flou_ani" />
          <div className="real_photo">
            <img
              className={
                aniData.photoHeight > aniData.photoWidth
                  ? "photo_ani portrait"
                  : "photo_ani landscape"
              }
              src={`${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL}${
                aniData.COPAIP + aniData.NUNATI
              }_${aniData.ts_photos}.jpg`}
            />
          </div>
        </div>
      </div>
      {/* Div info cheptel */}
      <div className="info_cheptel">
        <p>
          {aniData.detenteurNom} ({aniData.detenteurNum.substring(2, 4)})
        </p>
      </div>
      {/* Div infos animal */}
      <div className="infos_animal">
        <div className="titre">
          <h2
            className={
              aniData.SEXBOV === "2" ? "nom sexe-femelle" : "nom sexe-male"
            }
          >
            {aniData.NOBOVI}
            <i
              className="aboqual"
              title={
                aniData.qualifAnimalLIDETA
                  ? aniData.qualifAnimalLIDETA
                  : aniData.inscrAnimalLIDETA && aniData.inscrAnimalLIDETA
              }
            >
              {aniData.qualifAnimal
                ? aniData.qualifAnimal
                : aniData.inscrAnimal && aniData.inscrAnimal}
            </i>
          </h2>
          <h3 className="numtravail">{aniData.COPAIP + aniData.NUNATI}</h3>
        </div>
        <div className="infos-naissance">
          <div className="line_info">
            <p>Date de naissance :</p>
            <p>{`${aniData.DANAIS}`}</p>
          </div>
        </div>
        <div className="table_header">
          {(aniData.PONAIS !== "" ||
            aniData.bopoat120 !== "" ||
            aniData.bopoat210 !== "") && (
            <table>
              <thead>
                <tr>
                  <th>Poids de naissance</th>
                  <th>{aniData.PONAIS}</th>
                </tr>
              </thead>
              <tbody>
                {aniData.bopoat120 !== "" && (
                  <tr>
                    <td>à 120 jours</td>
                    <td className="right">{`${aniData.bopoat120} kg`}</td>
                  </tr>
                )}
                {aniData.bopoat210 !== "" && (
                  <tr>
                    <td>à 210 jours</td>
                    <td className="right">{`${aniData.bopoat210} kg`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {(aniData.bopoalDevmus !== "" ||
            aniData.bopoalDevsqe !== "" ||
            aniData.bopoalAptfon !== "" ||
            aniData.bopoalNoetpo !== "") && (
            <table>
              <thead>
                <tr>
                  <th>DM</th>
                  <th>DS</th>
                  <th>AF</th>
                  <th>Etat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="center">{aniData.bopoalDevmus}</td>
                  <td className="center">{aniData.bopoalDevsqe}</td>
                  <td className="center">{aniData.bopoalAptfon}</td>
                  <td className="center">{aniData.bopoalNoetpo}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/* Div genealogie */}
      <div className="bloc_genealogie">
        <CheptelSectionTitle title="Généalogie" />
        <div className="genalogie_share_full_fiche" id="genealogie">
          <table>
            <tbody>
              <tr>
                {/* pere */}
                <td rowSpan="2" className="td_pere">
                  {genalogieBulle(
                    aniData?.Pnobovi,
                    aniData?.Pcopaip,
                    aniData?.Pnunati,
                    aniData?.qualifPere,
                    aniData?.inscrPere,
                    1
                  )}
                  {(aniData?.Pifnais ||
                    aniData?.Pcrsevs ||
                    aniData?.Pdmsevs ||
                    aniData?.Pdssevs ||
                    aniData?.Pfosevs ||
                    aniData?.Pisevre ||
                    aniData?.Pavelag ||
                    aniData?.Palaits ||
                    aniData?.Pivmate) && (
                    <>
                      <div className="table_index_parents">
                        <table className="table-index-parents">
                          <thead>
                            <tr>
                              {aniData?.Pifnais && <th>IFNAIS</th>}
                              {aniData?.Pcrsevs && <th>CRsev</th>}
                              {aniData?.Pdmsevs && <th>DMsev</th>}
                              {aniData?.Pdssevs && <th>DSsev</th>}
                              {aniData?.Pfosevs && <th>FOSsev</th>}
                              {aniData?.Pisevre && <th>ISEVR</th>}
                              {aniData?.Pavelag && <th>AVEL</th>}
                              {aniData?.Palaits && <th>ALait</th>}
                              {aniData?.Pivmate && <th>IVMAT</th>}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {aniData?.Pifnais && <td>{aniData.Pifnais}</td>}
                              {aniData?.Pcrsevs && <td>{aniData.Pcrsevs}</td>}
                              {aniData?.Pdmsevs && <td>{aniData.Pdmsevs}</td>}
                              {aniData?.Pdssevs && <td>{aniData.Pdssevs}</td>}
                              {aniData?.Pfosevs && <td>{aniData.Pfosevs}</td>}
                              {aniData?.Pisevre && (
                                <td className="pp">{aniData.Pisevre}</td>
                              )}
                              {aniData?.Pavelag && <td>{aniData.Pavelag}</td>}
                              {aniData?.Palaits && <td>{aniData.Palaits}</td>}
                              {aniData?.Pivmate && (
                                <td className="pp">{aniData.Pivmate}</td>
                              )}
                            </tr>
                            <tr className="d">
                              {aniData?.Pifnais && <td>{aniData.Pcdifna}</td>}
                              {aniData?.Pcrsevs && <td>{aniData.Pcdcrse}</td>}
                              {aniData?.Pdmsevs && <td>{aniData.Pcddmse}</td>}
                              {aniData?.Pdssevs && <td>{aniData.Pcddsse}</td>}
                              {aniData?.Pfosevs && <td>{aniData.Pcdfose}</td>}
                              {aniData?.Pisevre && (
                                <td className="pp">{aniData.Pcdisev}</td>
                              )}
                              {aniData?.Pavelag && <td>{aniData.Pcdavel}</td>}
                              {aniData?.Palaits && <td>{aniData.Pcdalai}</td>}
                              {aniData?.Pivmate && (
                                <td className="pp">{aniData.Pcdivma}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="base_ref_parents">
                        <span>
                          {`Base de référence: LI.PF.${aneeBaseRefPere}.${rangBaseRefPere} `}
                        </span>
                        {aniData.Ptypein === "G" && (
                          <span className="ibovalG" title="IBOVAL Génomique">
                            G
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </td>
                {/* grand pere paternel */}
                <td className="lineUnder">
                  {genalogieBulle(
                    aniData?.GPPnobovi,
                    aniData?.GPPcopaip,
                    aniData?.GPPnunati,
                    aniData?.qualifGPP,
                    aniData?.inscrGPP,
                    1
                  )}
                </td>
              </tr>
              <tr>
                <td className="lineUnder">
                  {/* grand mere paternel */}
                  {genalogieBulle(
                    aniData?.GMPnobovi,
                    aniData?.GMPcopaip,
                    aniData?.GMPnunati,
                    aniData?.qualifGMP,
                    aniData?.inscrGMP,
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td rowSpan="2">
                  {/* mere */}
                  {genalogieBulle(
                    aniData?.Mnobovi,
                    aniData?.Mcopaip,
                    aniData?.Mnunati,
                    aniData?.qualifMere,
                    aniData?.inscrMere,
                    2
                  )}
                  {(aniData?.Mifnais ||
                    aniData?.Mcrsevs ||
                    aniData?.Mdmsevs ||
                    aniData?.Mdssevs ||
                    aniData?.Mfosevs ||
                    aniData?.Misevre ||
                    aniData?.Mavelag ||
                    aniData?.Malaits ||
                    aniData?.Mivmate) && (
                    <>
                      <div className="table_index_parents">
                        <table className="table-index-parents">
                          <thead>
                            <tr>
                              {aniData?.Mifnais && <th>IFNAIS</th>}
                              {aniData?.Mcrsevs && <th>CRsev</th>}
                              {aniData?.Mdmsevs && <th>DMsev</th>}
                              {aniData?.Mdssevs && <th>DSsev</th>}
                              {aniData?.Mfosevs && <th>FOSsev</th>}
                              {aniData?.Misevre && <th>ISEVR</th>}
                              {aniData?.Mavelag && <th>AVEL</th>}
                              {aniData?.Malaits && <th>ALait</th>}
                              {aniData?.Mivmate && <th>IVMAT</th>}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {aniData?.Mifnais && <td>{aniData.Mifnais}</td>}
                              {aniData?.Mcrsevs && <td>{aniData.Mcrsevs}</td>}
                              {aniData?.Mdmsevs && <td>{aniData.Mdmsevs}</td>}
                              {aniData?.Mdssevs && <td>{aniData.Mdssevs}</td>}
                              {aniData?.Mfosevs && <td>{aniData.Mfosevs}</td>}
                              {aniData?.Misevre && (
                                <td className="pm">{aniData.Misevre}</td>
                              )}
                              {aniData?.Mavelag && <td>{aniData.Mavelag}</td>}
                              {aniData?.Malaits && <td>{aniData.Malaits}</td>}
                              {aniData?.Mivmate && (
                                <td className="pm">{aniData.Mivmate}</td>
                              )}
                            </tr>
                            <tr className="d">
                              {aniData?.Mifnais && <td>{aniData.Mcdifna}</td>}
                              {aniData?.Mcrsevs && <td>{aniData.Mcdcrse}</td>}
                              {aniData?.Mdmsevs && <td>{aniData.Mcddmse}</td>}
                              {aniData?.Mdssevs && <td>{aniData.Mcddsse}</td>}
                              {aniData?.Mfosevs && <td>{aniData.Mcdfose}</td>}
                              {aniData?.Misevre && (
                                <td className="pm">{aniData.Mcdisev}</td>
                              )}
                              {aniData?.Mavelag && <td>{aniData.Mcdavel}</td>}
                              {aniData?.Malaits && <td>{aniData.Mcdalai}</td>}
                              {aniData?.Mivmate && (
                                <td className="pm">{aniData.Mcdivma}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="base_ref_parents">
                        <span>
                          {`Base de référence: LI.PF.${aneeBaseRefMere}.${rangBaseRefMere} `}
                        </span>
                        {aniData.Mtypein === "G" && (
                          <span className="ibovalG" title="IBOVAL Génomique">
                            G
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </td>
                <td className="lineUnder">
                  {/* grand pere maternel */}
                  {genalogieBulle(
                    aniData?.GPMnobovi,
                    aniData?.GPMcopaip,
                    aniData?.GPMnunati,
                    aniData?.qualifGPM,
                    aniData?.inscrGPM,
                    1
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {/* grand mere maternel */}
                  {genalogieBulle(
                    aniData?.GMMnobovi,
                    aniData?.GMMcopaip,
                    aniData?.GMMnunati,
                    aniData?.qualifGMM,
                    aniData?.inscrGMM,
                    2
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Div performance */}
      <div className="bloc_performance">
        <CheptelSectionTitle title="Performance" />
        <div className="indexiboval_share_full">
          {b64Graph ? (
            <img src={b64Graph} className="graph_isu" />
          ) : (
            <div className="alert">Pas d'index connus</div>
          )}
        </div>
      </div>
      {/* Div analyses genetiques */}
      <div className="blocs_analyses">
        <CheptelSectionTitle title="Analyses génétiques" />
        {isAnalyse ? (
          <div className="blocs_adn">
            <div className="bloc_adn">
              <p className="title_adn">Sans cornes</p>
              <div
                className={
                  aniData.retyupsc === "+/+"
                    ? "resultat green"
                    : aniData.retyupsc === "P/+" || aniData.retyupsc === "P/P"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyupsc === "+/+" && "Non porteur"}
                {aniData.retyupsc === "P/+" && "Porteur hétérozygote"}
                {aniData.retyupsc === "P/P" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Culard</p>
              <div
                className={
                  aniData.retyupmh === "+/+"
                    ? "resultat green"
                    : aniData.retyupmh === "MH/+" ||
                      aniData.retyupmh === "MH/MH"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyupmh === "+/+" && "Non porteur"}
                {aniData.retyupmh === "MH/+" && "Porteur hétérozygote"}
                {aniData.retyupmh === "MH/MH" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Palais fendu</p>
              {aniData.retyuppaf !== "" ? (
                <div
                  className={
                    aniData.retyuppaf === "+/+"
                      ? "resultat green"
                      : aniData.retyuppaf === "PAL/+" ||
                        aniData.retyuppaf === "PAL/PAL"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {aniData.retyuppaf === "+/+" && "Non porteur"}
                  {aniData.retyuppaf === "PAL/+" && "Porteur hétérozygote"}
                  {aniData.retyuppaf === "PAL/PAL" && "Porteur homozygote"}
                </div>
              ) : (
                <div
                  className={
                    aniData.RETYUP_HPF == "0"
                      ? "resultat green"
                      : aniData.RETYUP_HPF == "1" || aniData.RETYUP_HPF == "2"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {aniData.RETYUP_HPF == "0" && "Non porteur (hpf)"}
                  {aniData.RETYUP_HPF == "1" && "Porteur hétérozygote (hpf)"}
                  {aniData.RETYUP_HPF == "2" && "Porteur homozygote (hpf)"}
                </div>
              )}
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Protoporphyrie</p>
              <div
                className={
                  aniData.retyuppro === "+/+"
                    ? "resultat green"
                    : aniData.retyuppro === "PRO/+" ||
                      aniData.retyuppro === "PRO/PRO"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {aniData.retyuppro === "+/+" && "Non porteur"}
                {aniData.retyuppro === "PRO/+" && "Porteur hétérozygote"}
                {aniData.retyuppro === "PRO/PRO" && "Porteur homozygote"}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert">Pas d'analyse connue</div>
        )}
      </div>
      {/* Div donnees repro */}
      {aniData.SEXBOV === "2" && (
        <div className="blocs_reproduction">
          <CheptelSectionTitle title="Données de reproduction" />
          <div className="infos_repro">
            <div className="line_info_repro">
              <p className="lib_info_repro">Rang de vêlage:</p>
              <p className="value_info_repro">{aniData.RADEVE}</p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Age au premier vêlage:</p>
              <p className="value_info_repro">
                {aniData.AGE1VE && `${Math.floor(aniData.AGE1VE / 30)} mois`}
              </p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Conditions 1er vêlage:</p>
              <p className="value_info_repro">{aniData.COV1VE}</p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">Dernier vêlage:</p>
              <p className="value_info_repro">
                {aniData.DADEVE && aniData.DADEVE !== "--" && aniData.DADEVE}
              </p>
            </div>
            <div className="line_info_repro">
              <p className="lib_info_repro">IVV moyen:</p>
              <p className="value_info_repro">
                {aniData.IVVMOY && `${aniData.IVVMOY} jrs `}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Bottom message */}
      {/* <div className="bottom_message">
        Informations et données issues de l'application EspacePro © France
        Limousin Sélection
      </div> */}
    </div>
  )
}

export default FicheAniShare
