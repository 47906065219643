import "./SlidingMenu.scss"
import localforage from "localforage"
import ModalLoader from "./ModalLoader"
import Alert from "./Alert"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import { useCustomAlert } from "../hooks/useCustomAlert"
import Confirm from "./Confirm"
import { useEffect, useRef } from "react"

const SlidingMenu = ({ classMenu, hideMenu, nbMessagesUnread, name, page }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  const divMenu = useRef()

  let valA = window.localStorage.valA === "true" ? true : false
  let valO = window.localStorage.valO === "true" ? true : false
  let numChep = window.localStorage.numCheptel ?? ""

  let appVers = window.localStorage.app_version
  let cache_changelogs = window.localStorage.changeLogs ?? ""
  let changeLogs = cache_changelogs !== "" ? JSON.parse(cache_changelogs) : []
  let lastVers = changeLogs.length > 0 ? changeLogs[0].version : ""
  let colorVersion = appVers === lastVers ? "green_text" : "red_text"
  let personne = window.localStorage.personne ?? ""

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divMenu.current

    if (curr) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual =
          +window
            .getComputedStyle(curr, null)
            .getPropertyValue("top")
            .split("px")[0] ?? 0

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        curr.style.top = newHeight + "px"
      }
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const logout = async () => {
    hideMenu()
    doConfirm("Voulez-vous vous déconnecter ?")
  }
  const doLogout = async confirmed => {
    hideConfirm()

    if (confirmed) {
      let divModal = document.getElementById("loader_logout")
      if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

      await localforage.keys().then(async keys => {
        for (let indexKey in keys) {
          await localforage.removeItem(keys[indexKey])
        }

        window.location.href = ""
      })

      let internalVersion = window.localStorage.internalVersion
      let uuid = window.localStorage.uuid
      window.localStorage.clear()
      window.localStorage.internalVersion = internalVersion
      window.localStorage.uuid = uuid

      window.localStorage.JWT_TOKEN = "" // Without token, you're disconnected from server

      if ("ReactNativeWebView" in window) {
        let message = {
          type: "logout",
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      }

      window.location.reload()

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
  }
  // Hide menu then ask for change cheptel logic
  const changeCheptel = async (action = "changeCheptel") => {
    hideMenu()

    document.getElementById("app")?.dispatchEvent(
      new CustomEvent("changeChep", {
        detail: {
          action: action,
        },
      })
    )
  }
  const goChangeLog = () => {
    hideMenu()
    if (page === "cheptel") window.location.hash = "cheptel_changelog"
    if (page === "admin") window.location.hash = "admin_changelog"
    if (page === "op") window.location.hash = "op_changelog"
  }
  const goGame = () => {
    hideMenu()
    window.location.hash = "game"
  }
  const goMessages = () => {
    hideMenu()
    window.location.hash = "messagerie"
  }
  const goAccount = () => {
    hideMenu()
    window.location.hash = "account"
  }

  return (
    <>
      {confirm && <Confirm message={messageConfirm} giveConfirm={doLogout} />}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      <div className={`menu ${classMenu}`} ref={divMenu}>
        <div className="header_menu">
          {valO && window.localStorage.nom_op ? (
            <p className="num_cheptel">{window.localStorage.nom_op}</p>
          ) : personne || numChep ? (
            <p className="num_cheptel">{personne ? personne : numChep}</p>
          ) : (
            (name || numChep) && (
              <p className="num_cheptel">{name ? name : numChep}</p>
            )
          )}
        </div>
        <ul>
          {page !== "faildl" && numChep && (
            <li className="ripple2" onClick={goAccount}>
              <span className="material-symbols-outlined">person</span>
              <p>{valA || valO ? "Infos éleveur" : "Mes informations"}</p>
            </li>
          )}
          {valO && (
            <li className="ripple2" onClick={() => changeCheptel("outilsOP")}>
              <span className="material-symbols-outlined">handyman</span>
              <p>Mes outils OP</p>
            </li>
          )}
          {(valA || valO) && numChep && (
            <li
              className="ripple2"
              onClick={() => changeCheptel("changeCheptel")}
            >
              <span className="material-symbols-outlined">swap_vert</span>
              <p>Changer de cheptel</p>
            </li>
          )}
          {page !== "faildl" && (
            <li className="ripple2" onClick={goMessages}>
              <span className="material-symbols-outlined">chat</span>
              <p>messagerie</p>
              {nbMessagesUnread > 0 && (
                <div className="icon_msg_unread_menu">{nbMessagesUnread}</div>
              )}
            </li>
          )}
          {page !== "faildl" && (
            <li className="ripple2" onClick={goGame}>
              <span className="material-symbols-outlined">sports_esports</span>
              <p>game</p>
            </li>
          )}
          <li className="ripple2" onClick={logout}>
            <span className="material-symbols-outlined">logout</span>
            <p>me déconnecter</p>
          </li>
        </ul>
        <div className="footer_menu ripple2" onClick={goChangeLog}>
          <div className="div_version">
            <p>À propos</p>
            <p className={`version_number ${colorVersion}`}>V {appVers}</p>
          </div>
        </div>
      </div>
      <ModalLoader id="loader_logout" message="Déconnexion en cours..." />
    </>
  )
}

export default SlidingMenu
