import "./Loader.scss"

const Loader = ({ loadingMessage, horizontal = false }) => {
  return (
    <div className={horizontal ? "loader horizontal" : "loader"}>
      <div className="container">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
        <div className="shape shape-4"></div>
      </div>

      <p>{loadingMessage}</p>
    </div>
  )
}

export default Loader
