import "./Confirm.scss"
import { useEffect, useRef } from "react"
import { useCenterModal } from "../hooks/useCenterModal"

const Confirm = ({
  message,
  giveConfirm,
  okText = "OK",
  cancelText = "Annuler",
}) => {
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const btnOk = useRef()
  const btnCancel = useRef()

  useEffect(() => {
    let widthOk = btnOk.current.offsetWidth
    let widthCancel = btnCancel.current.offsetWidth
    let biggestWidth = widthOk > widthCancel ? widthOk : widthCancel

    btnOk.current.style.width = `${biggestWidth}px`
    btnCancel.current.style.width = `${biggestWidth}px`
  })

  let objHtml = {
    __html: message,
  }

  return (
    <>
      <div ref={divModal} className="confirm_window">
        <div className="alert">
          <p dangerouslySetInnerHTML={objHtml}></p>
        </div>
        <div className="div_btn_alert">
          <button
            ref={btnOk}
            className="btn_ok_alert ripple2"
            onClick={() => giveConfirm(true)}
          >
            {okText}
          </button>
          <button
            ref={btnCancel}
            className="btn_cancel_alert ripple2"
            onClick={() => giveConfirm(false)}
          >
            {cancelText}
          </button>
        </div>
      </div>
      {/* Important de garder un background pour bien avoir l'effet "bloquant" d'une confirm */}
      <button className={"background-modal zindex_alert"}></button>
    </>
  )
}

export default Confirm
