import { useEffect, useRef, useState } from "react"
import FilterList from "../components/FilterList"
import "./FilterCustom.scss"
import { fetchSaveCustInv } from "../functions/fetches/fetchSaveCustInv"

const FilterCustom = ({ allData }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Hash parameters
  let param1 = window.location.hash.split("|")[1] // Inventory index

  let listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")
  let thisInv = listCustInv[+param1.replace("custom", "")]
  if (!thisInv.filters) thisInv.filters = {}

  const [filters, setFilters] = useState(thisInv.filters)

  const divWrap = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divWrap.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = 60 + addHeight

      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }
  }, [])
  useEffect(() => {
    thisInv.filters = filters
    let strCustInv = JSON.stringify(listCustInv)
    localStorage.listCustInv = strCustInv
    fetchSaveCustInv(strCustInv)
  }, [filters])
  ///////////////
  // Functions //
  ///////////////

  return (
    <div className="page_customfilter" ref={divWrap}>
      <FilterList
        inventory={thisInv}
        filters={filters}
        setFilters={setFilters}
        allData={allData}
        origin="custom"
      />
    </div>
  )
}

export default FilterCustom
