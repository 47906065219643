import { fetchUrlEncoded } from "./fetch"

export const fetchHighScore = async (signal = null) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "getHighScore"
  let personne = localStorage.personne

  let params = {
    a: action,
    u: personne,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    checkConnect: false,
    signal: signal,
  })
}
