import Alert from "../components/Alert"
import { goBack } from "../functions/handleNavigation"
import { useCustomAlert } from "../hooks/useCustomAlert"
import "./Account.scss"

const Account = ({ data }) => {
  let isAdmin = window.localStorage.valA === "true" ? true : false

  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const spaceForPhone = number => {
    let spacedNumber = ""
    let space = false
    for (let figure in number) {
      spacedNumber += number[figure]
      if (space) {
        space = false
        spacedNumber += " "
      } else space = true
    }

    return spacedNumber
  }
  const goLinkAdmin = link => {
    if (!isAdmin) return

    var a = document.createElement("a")
    a.href = link
    a.target = "_blank"
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove() //afterwards we remove the element again
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  if (!data && !alert) {
    doAlert(
      "Aucune données de compte chargées. Veuillez recharger les données et réessayer s.v.p."
    )
    setCallback(() => goBack())
  }

  return (
    <div className="account_infos">
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {data && (
        <>
          {(data.societe_nom || data.cheptel_num) && (
            <div className="line_info">
              <span className="material-symbols-outlined">agriculture</span>
              <div className="right_side_account_info">
                <p>{data.societe_nom}</p>
                <p>{data.cheptel_pays + data.cheptel_num}</p>
              </div>
            </div>
          )}

          {(data.nom || data.prenom) && (
            <div className="line_info">
              <span className="material-symbols-outlined">person</span>
              <p className="right_side_account_info">
                {data.nom + " " + data.prenom}
              </p>
            </div>
          )}

          {(data.societe_code_postal ||
            data.commune ||
            data.societe_adresse) && (
            <div
              className={isAdmin ? "line_info pointer ripple2" : "line_info"}
              onClick={() =>
                goLinkAdmin(
                  `http://maps.google.com/?q=` +
                    data.societe_code_postal +
                    " " +
                    data.societe_commune +
                    data.societe_adresse
                )
              }
            >
              <span className="material-symbols-outlined">location_on</span>
              <div className="right_side_account_info">
                <p>{data?.societe_code_postal + " " + data?.societe_commune}</p>
                <p>{data?.societe_adresse}</p>
              </div>
            </div>
          )}

          {data.email && (
            <div
              className={isAdmin ? "line_info pointer ripple2" : "line_info"}
              onClick={() => goLinkAdmin("mailto:" + data.email)}
            >
              <span className="material-symbols-outlined">mail</span>
              <p className="right_side_account_info">{data.email}</p>
            </div>
          )}

          {data.telephone && (
            <div
              className={isAdmin ? "line_info pointer ripple2" : "line_info"}
              onClick={() => goLinkAdmin("tel:" + data.telephone)}
            >
              <span className="material-symbols-outlined">call</span>
              <p className="right_side_account_info">
                {spaceForPhone(data.telephone)}
              </p>
            </div>
          )}

          {data.mobile && (
            <div
              className={isAdmin ? "line_info pointer ripple2" : "line_info"}
              onClick={() => goLinkAdmin("tel:" + data.mobile)}
            >
              <span className="material-symbols-outlined">smartphone</span>
              <p className="right_side_account_info">
                {spaceForPhone(data.mobile)}
              </p>
            </div>
          )}

          {data.fax && (
            <div className="line_info">
              <span className="material-symbols-outlined">fax</span>
              <p className="right_side_account_info">
                {spaceForPhone(data.fax)}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Account
