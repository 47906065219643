import { useEffect, useRef } from "react"
import "./Alert.scss"
import { useCenterModal } from "../hooks/useCenterModal"

const Alert = ({ message, hideAlert }) => {
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)
  const btn = useRef()

  useEffect(() => {
    const clickWithEnter = e => {
      if (e.key === "Enter") {
        btn.current.click()
      }
    }

    document.addEventListener("keydown", clickWithEnter)

    return () => {
      document.removeEventListener("keydown", clickWithEnter)
    }
  }, [])

  let objHtml = {
    __html: message,
  }

  return (
    <>
      <div ref={divModal} className="alert_window" id="alert_window">
        <div className="alert">
          <p dangerouslySetInnerHTML={objHtml}></p>
        </div>
        <div className="div_btn_alert">
          <button
            ref={btn}
            className="btn_ok_alert ripple2"
            onClick={hideAlert}
          >
            OK
          </button>
        </div>
      </div>
      <button className={"background-modal zindex_alert"}></button>
    </>
  )
}

export default Alert
