import { formatDate2 } from "./formatDate"
import { monthAmount } from "./monthAmount"

export const extractDataAnimal = animal => {
  let numAnimal =
    animal?.copaip + animal?.nunati.substring(0, animal?.nunati.length - 4)

  let numTravailAnimal = animal?.nunati.substring(
    animal?.nunati?.length - 4,
    animal?.nunati?.length
  )

  let numTravailMere = animal?.numere.substring(
    animal?.numere?.length - 4,
    animal?.numere?.length
  )

  let numTravailPere = animal?.nupere.substring(
    animal?.nupere?.length - 4,
    animal?.nupere?.length
  )

  let year = animal?.danais?.substring(0, 4)
  let month = animal?.danais?.substring(4, 6)
  let day = animal?.danais?.substring(6, 8)

  let dateNaissobj = new Date(year, month - 1, day)

  let nbProduitFemelle = 0
  let nbProduitMale = 0

  animal?.produits &&
    animal?.produits.forEach(produit => {
      if (produit?.enfant_sexbov === "2") {
        nbProduitFemelle++
      } else if (produit?.enfant_sexbov === "1") {
        nbProduitMale++
      }
    })

  // Variables table Index
  let nbIndexIfnais = ""
  let nbIndexCrsevs = ""
  let nbIndexDmsevs = ""
  let nbIndexDssevs = ""
  let nbIndexFosevs = ""
  let nbIndexIsevre = ""
  let nbIndexAvelag = ""
  let nbIndexAlaits = ""
  let nbIndexIvmate = ""
  let isu = ""
  //Variables tablex Index autres
  let nbIndexANNEIN = ""
  let nbIndexCDALAI = ""
  let nbIndexCDAVEL = ""
  let nbIndexCDCRSE = ""
  let nbIndexCDDMSE = ""
  let nbIndexCDDSSE = ""
  let nbIndexCDFOSE = ""
  let nbIndexCDIFNA = ""
  let nbIndexCDISEV = ""
  let nbIndexCDIVMA = ""

  if (animal.sexbov === "1") {
    // Valeurs for table Index
    nbIndexIfnais = animal?.A_ivmale_IFNAIS
    nbIndexCrsevs = animal?.A_ivmale_CRSEVS
    nbIndexDmsevs = animal?.A_ivmale_DMSEVS
    nbIndexDssevs = animal?.A_ivmale_DSSEVS
    nbIndexFosevs = animal?.A_ivmale_FOSEVS
    nbIndexIsevre = animal?.A_ivmale_ISEVRE
    nbIndexAvelag = animal?.A_ivmale_AVELAG
    nbIndexAlaits = animal?.A_ivmale_ALAITS
    nbIndexIvmate = animal?.A_ivmale_IVMATE

    nbIndexANNEIN = animal?.A_ivmale_ANNEIN
    nbIndexCDALAI = animal?.A_ivmale_CDALAI
    nbIndexCDAVEL = animal?.A_ivmale_CDAVEL
    nbIndexCDCRSE = animal?.A_ivmale_CDCRSE
    nbIndexCDDMSE = animal?.A_ivmale_CDDMSE
    nbIndexCDDSSE = animal?.A_ivmale_CDDSSE
    nbIndexCDFOSE = animal?.A_ivmale_CDFOSE
    nbIndexCDIFNA = animal?.A_ivmale_CDIFNA
    nbIndexCDISEV = animal?.A_ivmale_CDISEV
    nbIndexCDIVMA = animal?.A_ivmale_CDIVMA
  } else {
    // Valeurs for table Index
    nbIndexIfnais = animal?.A_ivfemr_IFNAIS
    nbIndexCrsevs = animal?.A_ivfemr_CRSEVS
    nbIndexDmsevs = animal?.A_ivfemr_DMSEVS
    nbIndexDssevs = animal?.A_ivfemr_DSSEVS
    nbIndexFosevs = animal?.A_ivfemr_FOSEVS
    nbIndexIsevre = animal?.A_ivfemr_ISEVRE
    nbIndexAvelag = animal?.A_ivfemr_AVELAG
    nbIndexAlaits = animal?.A_ivfemr_ALAITS
    nbIndexIvmate = animal?.A_ivfemr_IVMATE
    isu = animal?.A_boisuf_INDISU

    nbIndexANNEIN = animal?.A_ivfemr_ANNEIN
    nbIndexCDALAI = animal?.A_ivfemr_CDALAI
    nbIndexCDAVEL = animal?.A_ivfemr_CDAVEL
    nbIndexCDCRSE = animal?.A_ivfemr_CDDMSE
    nbIndexCDDMSE = animal?.A_ivfemr_CDCRSE
    nbIndexCDDSSE = animal?.A_ivfemr_CDDSSE
    nbIndexCDFOSE = animal?.A_ivfemr_CDFOSE
    nbIndexCDIFNA = animal?.A_ivfemr_CDIFNA
    nbIndexCDISEV = animal?.A_ivfemr_CDISEV
    nbIndexCDIVMA = animal?.A_ivfemr_CDIVMA
  }

  let retyuptrtxt = ""

  if (animal?.retyup_tr === "NR") retyuptrtxt = "Non transloqué"
  if (animal?.retyup_tr === "RO") retyuptrtxt = "Transloqué"

  let inscrExamMere
  if (animal.M_boqual_LIBECO) inscrExamMere = animal.M_boqual_LIBECO
  else {
    let cor = animal?.M_borexa_1_coreex

    if (cor === "R") {
      inscrExamMere = "Examen Refusé"
    } else if (cor === "C") {
      inscrExamMere = `Examen Conforme (${animal?.M_borexa_1_libeco})`
    } else if (cor === "A") {
      inscrExamMere = "Examen Ajourné"
    }
  }

  let ADN_filter = ""
  if (
    animal.A_boange_codeut === "1" ||
    animal.A_boange_codeut === "4" ||
    animal.A_boange_codeut === "5" ||
    animal.A_boange_codeut === "8"
  ) {
    ADN_filter = animal.A_boange_typana + " utilisable"
  } else if (animal.A_boange_codeut === "2") {
    ADN_filter = animal.A_boange_typana + " inutilisable"
  } else if (animal.A_boange_codeut === "9") {
    ADN_filter = animal.A_boange_typana + " inconnu en bdnsat"
  }

  return {
    // INFO ANIMAL
    isInInventory: true,
    numAnimal: numAnimal,
    numTravailAnimal: numTravailAnimal,
    ts_photos: animal?.ts_photos,
    COPAIP: animal?.copaip,
    NUNATI: animal?.nunati,
    SEXBOV: animal?.sexbov,
    DANAIS: formatDate2(animal?.danais),
    NOBOVI: animal?.nobovi,
    CORABO: animal?.corabo,
    COPCNA: animal?.copcna,
    NUCHNA: animal?.nuchna,
    cheptelNaisseur: animal?.copcna + animal?.nuchna,
    naisseurTxt: animal?.NAISSEUR_TXT,
    detenteurNom: animal?.DETENTEUR_nom,
    detenteurNum: animal?.DETENTEUR_num,
    isDetenteur: animal?.isDetenteur,
    ipgmou_daench: animal?.ipgmou_daench,
    ipgmou_casort: animal?.ipgmou_casort,
    ipgmou_dasort: animal?.ipgmou_dasort,
    age_format: animal?.age_format,
    age_month: monthAmount(dateNaissobj),
    COFGMU: animal?.cofgmu,
    produits: animal?.produits,
    nbProduits: animal?.produits.length,
    nbProduitFemelle: nbProduitFemelle,
    nbProduitMale: nbProduitMale,
    passage: animal?.PASSAGE,
    // INFOS POIDS NAISSANCE
    TOUPOI: animal?.toupoi,
    PONAIS: animal?.ponais,
    pntp: animal?.ponais ? animal?.ponais : animal?.toupoi,
    bopoat120: animal?.A_bopoat_120_POAGTY,
    bopoat210: animal?.A_bopoat_210_POAGTY,
    // INFOS CONFIRMATIONS
    erreurs: animal?.erreurs_confirm,
    reponse: animal?.reponse_confirm,
    // INFOS EXAMENS
    borexa_a_CORADI: animal?.borexa_a_CORADI,
    borexa_a_COREEX: animal?.borexa_a_COREEX,
    borexa_a_ETQUBO: animal?.borexa_a_ETQUBO,
    borexa_c_CORADI: animal?.borexa_c_CORADI,
    borexa_c_COREEX: animal?.borexa_c_COREEX,
    borexa_c_ETQUBO: animal?.borexa_c_ETQUBO,
    examAni: animal?.borexa_1_libeco,
    examAniLIBELO: animal?.borexa_1_libelo,
    examAniLIDETA: animal?.borexa_1_lideta,
    borexa_coreex: animal?.borexa_1_COREEX,
    borexa_daexbo: animal?.borexa_1_DAEXBO,
    borexa_etqubo: animal?.borexa_1_ETQUBO,
    borexa_coqubo: animal?.borexa_1_COQUBO,
    borexa_ccqubo: animal?.borexa_1_CCQUBO,
    borexa_copace: animal?.borexa_1_COPACE,
    borexa_nuchex: animal?.borexa_1_NUCHEX,
    borexa_copoin: animal?.borexa_1_COPOIN,
    borexa_corefu: animal?.borexa_1_COREFU,
    borexaCorefuLib: animal?.borexa_corefu_libelle,
    // INFOS QUALIF
    qualifAnimal: animal?.bestqual_LIBECO,
    qualifAnimalLIDETA: animal?.bestqual_LIDETA,
    inscrAnimal: animal?.boqual_LIBECO,
    inscrAnimalLIDETA: animal?.boqual_LIDETA,
    qualifAnimalLIBELO: animal?.bestqual_LIBELO,
    inscrAnimalLIBELO: animal?.boqual_LIBELO,
    A_boqual_ETQUBO: animal?.boqual_ETQUBO,
    A_boqual_COQUBO: animal?.boqual_COQUBO,
    A_boqual_CCQUBO: animal?.boqual_CCQUBO,
    A_boqual_DAQUBO: animal?.boqual_DAQUBO,
    A_bestqual_ETQUBO: animal?.bestqual_ETQUBO,
    A_bestqual_COQUBO: animal?.bestqual_COQUBO,
    A_bestqual_CCQUBO: animal?.bestqual_CCQUBO,
    A_bestqual_COCOUT: animal?.bestqual_COCOUT,
    A_bestqual_NIQUBO: animal?.bestqual_NIQUBO,
    A_bestqual_SNQUBO: animal?.bestqual_SNQUBO,
    A_bestqual_COPACQ: animal?.bestqual_COPACQ,
    A_bestqual_NUCHQU: animal?.bestqual_NUCHQU,
    A_bestqual_DAQUBO: animal?.bestqual_DAQUBO,
    A_bestqual_DAEXBO: animal?.bestqual_DAEXBO,
    A_bestqual_COPOIN: animal?.bestqual_COPOIN,
    // INDEXS
    nbIndexIfnais: nbIndexIfnais,
    nbIndexCrsevs: nbIndexCrsevs,
    nbIndexDmsevs: nbIndexDmsevs,
    nbIndexDssevs: nbIndexDssevs,
    nbIndexFosevs: nbIndexFosevs,
    nbIndexIsevre: nbIndexIsevre,
    nbIndexAvelag: nbIndexAvelag,
    nbIndexAlaits: nbIndexAlaits,
    nbIndexIvmate: nbIndexIvmate,
    nbIndexANNEIN: nbIndexANNEIN,
    nbIndexCDALAI: nbIndexCDALAI,
    nbIndexCDAVEL: nbIndexCDAVEL,
    nbIndexCDCRSE: nbIndexCDCRSE,
    nbIndexCDDMSE: nbIndexCDDMSE,
    nbIndexCDDSSE: nbIndexCDDSSE,
    nbIndexCDFOSE: nbIndexCDFOSE,
    nbIndexCDIFNA: nbIndexCDIFNA,
    nbIndexCDISEV: nbIndexCDISEV,
    nbIndexCDIVMA: nbIndexCDIVMA,
    // ISU FEMELLE
    isu: isu,
    boisuf_BAREIS: animal?.A_boisuf_BAREIS,
    boisuf_DACAIS: animal?.A_boisuf_DACAIS,
    // DONNES REPRO FEMELLE
    RADEVE: animal?.A_RADEVE,
    AGE1VE: animal?.A_AGE1VE,
    COV1VE: animal?.A_COV1VE,
    DADEVE: formatDate2(animal?.A_DADEVE),
    NIVVCA: animal?.A_NIVVCA,
    IVVMOY: animal?.A_IVVMOY,
    IVV1A2: animal?.A_IVV1A2,
    IVV2PL: animal?.A_IVV2PL,
    Adacalc: animal?.A_DACALC,
    // DONNEES ANALYSES
    CODEUT: animal?.A_boange_codeut,
    TYPANA: animal?.A_boange_typana,
    DAOBAN: formatDate2(animal?.A_boange_daoban),
    CORDCG: animal?.A_bocog2_cordcg,
    cordcg_txt: animal?.A_bocog2_cordcg_txt,
    dartcg: formatDate2(animal?.A_bocog2_dartcg),
    retyupsc: animal?.retyup_sc,
    daretysc: formatDate2(animal?.darety_sc),
    NUTYAN_SC: animal.nutyan_sc,
    retyupmh: animal?.retyup_mh,
    daretymh: formatDate2(animal?.darety_mh),
    NUTYAN_MH: animal.nutyan_mh,
    retyuppaf: animal?.retyup_paf,
    daretypaf: formatDate2(animal?.darety_paf),
    NUTYAN_PAF: animal.nutyan_paf,
    retyuppro: animal?.retyup_pro,
    daretypro: formatDate2(animal?.darety_pro),
    retyupbli: animal?.retyup_bli,
    daretybli: formatDate2(animal?.darety_bli),
    RETYUP_TR: animal?.retyup_tr,
    retyuptrtxt: retyuptrtxt,
    daretytr: formatDate2(animal?.darety_tr),
    NUTYAN_TR: animal.darety_hpf,
    DARETY_HPF: animal.darety_hpf,
    RETYUP_HPF: animal.retyup_hpf,
    NUTYAN_HPF: animal.nutyan_hpf,
    IDLABO: animal?.Evalim_IDLABO,
    CIFNAI: animal?.Evalim_CIFNAI,
    CCRSEV: animal?.Evalim_CCRSEV,
    CDMSEV: animal?.Evalim_CDMSEV,
    CDSSEV: animal?.Evalim_CDSSEV,
    CFOSSE: animal?.Evalim_CFOSSE,
    CAVELS: animal?.Evalim_CAVELS,
    CALAIT: animal?.Evalim_CALAIT,
    COPSEV: animal?.Evalim_COPSEV,
    BASREF: animal?.Evalim_BASREF,
    CMADPJ: animal.Evalim_CMADPJ,
    CMAGRT: animal.Evalim_CMAGRT,
    CMALGT: animal.Evalim_CMALGT,
    CMAMEQ: animal.Evalim_CMAMEQ,
    DATINS: formatDate2(animal.Evalim_DATINS),
    COPAMT: animal.A_bocog2_copamt,
    NUNAMT: animal.A_bocog2_nunamt,
    COPAPT: animal.A_bocog2_copapt,
    NUNAPT: animal.A_bocog2_nunapt,
    NUECHA: animal.A_bocog2_nuecha,
    ADN_filter: ADN_filter,
    Evalim_filter: animal.Evalim_IDLABO ? "Avec Evalim" : "Sans Evalim",
    filiation: animal?.filiation,
    filiation_filter: animal?.cordcg ? animal?.A_bocog2_cordcg_txt : "",
    // PERFORMANCES
    Aifnais:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_IFNAIS
        : animal?.A_ivmale_IFNAIS,
    Acrsevs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CRSEVS
        : animal?.A_ivmale_CRSEVS,
    Admsevs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_DMSEVS
        : animal?.A_ivmale_DMSEVS,
    Adssevs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_DSSEVS
        : animal?.A_ivmale_DSSEVS,
    Aalaits:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_ALAITS
        : animal?.A_ivmale_ALAITS,
    Aavelag:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_AVELAG
        : animal?.A_ivmale_AVELAG,
    Afosevs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_FOSEVS
        : animal?.A_ivmale_FOSEVS,
    Aisevre:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_ISEVRE
        : animal?.A_ivmale_ISEVRE,
    Aivmate:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_IVMATE
        : animal?.A_ivmale_IVMATE,
    Atypein:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_TYPEIN
        : animal?.A_ivmale_TYPEIN,
    Acdifna:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDIFNA
        : animal?.A_ivmale_CDIFNA,
    Acdcrse:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDCRSE
        : animal?.A_ivmale_CDCRSE,
    Acddmse:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDDMSE
        : animal?.A_ivmale_CDDMSE,
    Acddsse:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDDSSE
        : animal?.A_ivmale_CDDSSE,
    Acdalai:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDALAI
        : animal?.A_ivmale_CDALAI,
    Acdavel:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDAVEL
        : animal?.A_ivmale_CDAVEL,
    Acdfose:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDFOSE
        : animal?.A_ivmale_CDFOSE,
    Acdisev:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDISEV
        : animal?.A_ivmale_CDISEV,
    Acdivma:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDIVMA
        : animal?.A_ivmale_CDIVMA,
    Aannein:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_ANNEIN
        : animal?.A_ivmale_ANNEIN,
    Arangin:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_RANGIN
        : animal?.A_ivmale_RANGIN,
    Aboisuf:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_BOISUF
        : animal?.A_ivmale_BOISUF,
    Acompse:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_COMPSE
        : animal?.A_ivmale_COMPSE,
    Acdcomp:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDCOMP
        : animal?.A_ivmale_CDCOMP,
    Areacse:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_REACSE
        : animal?.A_ivmale_REACSE,
    Acdreac:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDREAC
        : animal?.A_ivmale_CDREAC,
    Acrpsfs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CRPSFS
        : animal?.A_ivmale_CRPSFS,
    Admpsfs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_DMPSFS
        : animal?.A_ivmale_DMPSFS,
    Adspsfs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_DSPSFS
        : animal?.A_ivmale_DSPSFS,
    Abaspss:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_BASPSS
        : animal?.A_ivmale_BASPSS,
    Aafpsfs:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_AFPSFS
        : animal?.A_ivmale_AFPSFS,
    Afospss:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_FOSPSS
        : animal?.A_ivmale_FOSPSS,
    Acdcpsf:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDCPSF
        : animal?.A_ivmale_CDCPSF,
    Acddmps:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDDMPS
        : animal?.A_ivmale_CDDMPS,
    Acddsps:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDDSPS
        : animal?.A_ivmale_CDDSPS,
    Acdbasp:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDBASP
        : animal?.A_ivmale_CDBASP,
    Acdafps:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDAFPS
        : animal?.A_ivmale_CDAFPS,
    Acdfosp:
      animal?.sexbov === "2"
        ? animal?.A_ivfemr_CDFOSP
        : animal?.A_ivmale_CDFOSP,
    // POINTAGES
    bopoalNoetpo: animal?.bopoal_NOETPO,
    bopoalDeepau: animal?.bopoal_DEEPAU,
    bopoalLados: animal?.bopoal_LADOS,
    bopoalArculo: animal?.bopoal_ARCULO,
    bopoalLaculo: animal?.bopoal_LACULO,
    bopoalLoculo: animal?.bopoal_LOCULO,
    bopoalEpdess: animal?.bopoal_EPDESS,
    bopoalDevmus: animal?.bopoal_DEVMUS,
    bopoalLodess: animal?.bopoal_LODESS,
    bopoalLobass: animal?.bopoal_LOBASS,
    bopoalLahanc: animal?.bopoal_LAHANC,
    bopoalDevelo: animal?.bopoal_DEVELO,
    bopoalDevsqe: animal?.bopoal_DEVSQE,
    bopoalLamufl: animal?.bopoal_LAMUFL,
    bopoalApavan: animal?.bopoal_APAVAN,
    bopoalAparri: animal?.bopoal_APARRI,
    bopoalRedess: animal?.bopoal_REDESS,
    bopoalAptfon: animal?.bopoal_APTFON,
    bopoalPrpoit: animal?.bopoal_PRPOIT,
    bopoalLapoit: animal?.bopoal_LAPOIT,
    bopoalLatroc: animal?.bopoal_LATROC,
    bopoalGrcano: animal?.bopoal_GRCANO,
    A_bopaps_posp01: animal?.A_BOPAPS_POSP01,
    A_bopaps_posp02: animal?.A_BOPAPS_POSP02,
    A_bopaps_posp03: animal?.A_BOPAPS_POSP03,
    A_bopaps_posp04: animal?.A_BOPAPS_POSP04,
    A_bopaps_posp05: animal?.A_BOPAPS_POSP05,
    A_bopaps_posp06: animal?.A_BOPAPS_POSP06,
    A_bopaps_posp11: animal?.A_BOPAPS_POSP11,
    A_bopaps_posp12: animal?.A_BOPAPS_POSP12,
    A_bopaps_posp13: animal?.A_BOPAPS_POSP13,
    A_bopaps_posp14: animal?.A_BOPAPS_POSP14,
    A_bopaps_posp16: animal?.A_BOPAPS_POSP16,
    A_bopaps_posp15: animal?.A_BOPAPS_POSP15,
    A_bopaps_posp17: animal?.A_BOPAPS_POSP17,
    A_bopaps_posp18: animal?.A_BOPAPS_POSP18,
    A_bopaps_posp20: animal?.A_BOPAPS_POSP20,
    A_bopaps_posp31: animal?.A_BOPAPS_POSP31,
    A_bopaps_posp19: animal?.A_BOPAPS_POSP19,
    A_bopaps_posp34: animal?.A_BOPAPS_POSP34,
    A_bopaps_posp35: animal?.A_BOPAPS_POSP35,
    A_bopaps_posp36: animal?.A_BOPAPS_POSP36,
    A_bopaps_posp21: animal?.A_BOPAPS_POSP21,
    A_bopaps_posp26: animal?.A_BOPAPS_POSP26,
    A_bopaps_posp27: animal?.A_BOPAPS_POSP27,
    A_bopaps_posp33: animal?.A_BOPAPS_POSP33,
    A_bopaps_posp22: animal?.A_BOPAPS_POSP22,
    A_bopaps_posp23: animal?.A_BOPAPS_POSP23,
    A_bopaps_posp24: animal?.A_BOPAPS_POSP24,
    A_bopaps_posp25: animal?.A_BOPAPS_POSP25,
    A_bopaps_posp28: animal?.A_BOPAPS_POSP28,
    A_bopaps_posp29: animal?.A_BOPAPS_POSP29,
    A_bopaps_posp30: animal?.A_BOPAPS_POSP30,
    A_bopaps_posp32: animal?.A_BOPAPS_POSP32,
    // INFO GENEALOGIE
    AanalyseVcgGenreCompat2p: animal?.A_analyse_VCG_gene_compat_2p,
    // MERE
    Mnobovi: animal?.M_bovide_NOBOVI,
    Mcopaip: animal?.M_bovide_COPAIP,
    Mnunati: animal?.M_bovide_NUNATI,
    numMere: animal?.copame + animal?.numere,
    numTravailMere: numTravailMere,
    qualifMere: animal?.M_bestqual_LIBECO,
    qualifMereLIDETA: animal?.M_bestqual_LIDETA,
    qualifMereLIBELO: animal?.M_bestqual_LIBELO,
    inscrMere: animal?.M_boqual_LIBECO,
    inscrMereLIDETA: animal?.M_boqual_LIDETA,
    inscrMereLIBELO: animal?.M_boqual_LIBELO,
    M_bestqual_ETQUBO: animal?.M_bestqual_ETQUBO,
    M_bestqual_COQUBO: animal?.M_bestqual_COQUBO,
    M_bestqual_CCQUBO: animal?.M_bestqual_CCQUBO,
    M_bestqual_COCOUT: animal?.M_bestqual_COCOUT,
    M_bestqual_NIQUBO: animal?.M_bestqual_NIQUBO,
    M_bestqual_SNQUBO: animal?.M_bestqual_SNQUBO,
    M_bestqual_COPACQ: animal?.M_bestqual_COPACQ,
    M_bestqual_NUCHQU: animal?.M_bestqual_NUCHQU,
    M_bestqual_DAQUBO: animal?.M_bestqual_DAQUBO,
    M_bestqual_DAEXBO: animal?.M_bestqual_DAEXBO,
    M_bestqual_COPOIN: animal?.M_bestqual_COPOIN,
    examMere: animal?.M_borexa_1_libeco,
    ccqubo_exa_mere: animal?.M_borexa_1_ccqubo,
    coqubo_exa_mere: animal?.M_borexa_1_coqubo,
    inscrExamMere: inscrExamMere,
    examMereLIBELO: animal?.M_borexa_1_libelo,
    examMereLIDETA: animal?.M_borexa_1_lideta,
    coreex_exa_mere: animal?.M_borexa_1_coreex,
    //Data index mere genealogie
    Mifnais: animal?.M_ifnais,
    Mcrsevs: animal?.M_crsevs,
    Mdmsevs: animal?.M_dmsevs,
    Mdssevs: animal?.M_dssevs,
    Mfosevs: animal?.M_fosevs,
    Misevre: animal?.M_isevre,
    Mavelag: animal?.M_avelag,
    Malaits: animal?.M_alaits,
    Mivmate: animal?.M_ivmate,
    Mcdifna: animal?.M_cdifna,
    Mcdcrse: animal?.M_cdcrse,
    Mcddmse: animal?.M_cddmse,
    Mcddsse: animal?.M_cddsse,
    Mcdfose: animal?.M_cdfose,
    Mcdisev: animal?.M_cdisev,
    Mcdavel: animal?.M_cdavel,
    Mcdalai: animal?.M_cdalai,
    Mcdivma: animal?.M_cdivma,
    Mannein: animal?.M_annein,
    Mrangin: animal?.M_rangin,
    Mtypein: animal?.M_typein,
    //PERE
    numPere: animal?.copape + animal?.nupere,
    numTravailPere: numTravailPere,
    Pnobovi: animal?.P_bovide_NOBOVI,
    Pcopaip: animal?.P_bovide_COPAIP,
    Pnunati: animal?.P_bovide_NUNATI,
    qualifPere: animal?.P_bestqual_LIBECO,
    qualifPereLIDETA: animal?.P_bestqual_LIDETA,
    qualifPereLIBELO: animal?.P_bestqual_LIBELO,
    inscrPere: animal?.P_boqual_LIBECO,
    inscrPereLIDETA: animal?.P_boqual_LIDETA,
    inscrPereLIBELO: animal?.P_boqual_LIBELO,
    P_bestqual_ETQUBO: animal?.P_bestqual_ETQUBO,
    P_bestqual_COQUBO: animal?.P_bestqual_COQUBO,
    P_bestqual_CCQUBO: animal?.P_bestqual_CCQUBO,
    P_bestqual_COCOUT: animal?.P_bestqual_COCOUT,
    P_bestqual_NIQUBO: animal?.P_bestqual_NIQUBO,
    P_bestqual_SNQUBO: animal?.P_bestqual_SNQUBO,
    P_bestqual_COPACQ: animal?.P_bestqual_COPACQ,
    P_bestqual_NUCHQU: animal?.P_bestqual_NUCHQU,
    P_bestqual_DAQUBO: animal?.P_bestqual_DAQUBO,
    P_bestqual_DAEXBO: animal?.P_bestqual_DAEXBO,
    P_bestqual_COPOIN: animal?.P_bestqual_COPOIN,
    examPere: animal?.P_borexa_1_libeco,
    ccqubo_exa_pere: animal?.P_borexa_1_ccqubo,
    coqubo_exa_pere: animal?.P_borexa_1_coqubo,
    examPereLIBELO: animal?.P_borexa_1_libelo,
    examPereLIDETA: animal?.P_borexa_1_lideta,
    coreex_exa_pere: animal?.P_borexa_1_coreex,
    P_bocog2_cordcg: animal?.P_cordcg,
    P_bocog2_cordcg_txt: animal?.P_cordcg_txt,
    retyupPere: animal?.P_mh_retyup,
    nuechaPere: animal?.P_boange_nuecha,
    //Data index pere genealogie
    Pifnais: animal?.P_ifnais,
    Pcrsevs: animal?.P_crsevs,
    Pdmsevs: animal?.P_dmsevs,
    Pdssevs: animal?.P_dssevs,
    Pfosevs: animal?.P_fosevs,
    Pisevre: animal?.P_isevre,
    Pavelag: animal?.P_avelag,
    Palaits: animal?.P_alaits,
    Pivmate: animal?.P_ivmate,
    Pcdifna: animal?.P_cdifna,
    Pcdcrse: animal?.P_cdcrse,
    Pcddmse: animal?.P_cddmse,
    Pcddsse: animal?.P_cddsse,
    Pcdfose: animal?.P_cdfose,
    Pcdisev: animal?.P_cdisev,
    Pcdavel: animal?.P_cdavel,
    Pcdalai: animal?.P_cdalai,
    Pcdivma: animal?.P_cdivma,
    Pannein: animal?.P_annein,
    Prangin: animal?.P_rangin,
    Ptypein: animal?.P_typein,
    //GPP
    GPPnobovi: animal?.GPP_bovide_NOBOVI,
    GPPcopaip: animal?.GPP_bovide_COPAIP,
    GPPnunati: animal?.GPP_bovide_NUNATI,
    numGPP: animal?.GPP_bovide_COPAIP + animal?.GPP_bovide_NUNATI,
    nomGPP: animal?.GPP_bovide_NOBOVI,
    qualifGPP: animal?.GPP_bestqual_LIBECO,
    qualifGPPLIDETA: animal?.GPP_bestqual_LIDETA,
    qualifGPPLIBELO: animal?.GPP_bestqual_LIBELO,
    inscrGPP: animal?.GPP_boqual_LIBECO,
    inscrGPPLIDETA: animal?.GPP_boqual_LIDETA,
    inscrGPPLIBELO: animal?.GPP_boqual_LIBELO,
    GPP_bestqual_ETQUBO: animal?.GPP_bestqual_ETQUBO,
    GPP_bestqual_COQUBO: animal?.GPP_bestqual_COQUBO,
    GPP_bestqual_CCQUBO: animal?.GPP_bestqual_CCQUBO,
    GPP_bestqual_COCOUT: animal?.GPP_bestqual_COCOUT,
    GPP_bestqual_NIQUBO: animal?.GPP_bestqual_NIQUBO,
    GPP_bestqual_SNQUBO: animal?.GPP_bestqual_SNQUBO,
    GPP_bestqual_COPACQ: animal?.GPP_bestqual_COPACQ,
    GPP_bestqual_NUCHQU: animal?.GPP_bestqual_NUCHQU,
    GPP_bestqual_DAQUBO: animal?.GPP_bestqual_DAQUBO,
    GPP_bestqual_DAEXBO: animal?.GPP_bestqual_DAEXBO,
    GPP_bestqual_COPOIN: animal?.GPP_bestqual_COPOIN,
    // PGPP
    PGPPnobovi: animal?.PGPP_bovide_NOBOVI,
    PGPPcopaip: animal?.PGPP_bovide_COPAIP,
    PGPPnunati: animal?.PGPP_bovide_NUNATI,
    numPGPP: animal?.PGPP_bovide_COPAIP + animal?.PGPP_bovide_NUNATI,
    nomPGPP: animal?.PGPP_bovide_NOBOVI,
    qualifPGPP: animal?.PGPP_bestqual_LIBECO,
    qualifPGPPLIDETA: animal?.PGPP_bestqual_LIDETA,
    qualifPGPPLIBELO: animal?.PGPP_bestqual_LIBELO,
    inscrPGPP: animal?.PGPP_boqual_LIBECO,
    inscrPGPPLIDETA: animal?.PGPP_boqual_LIDETA,
    inscrPGPPLIBELO: animal?.PGPP_boqual_LIBELO,
    PGPP_bestqual_ETQUBO: animal?.PGPP_bestqual_ETQUBO,
    PGPP_bestqual_COQUBO: animal?.PGPP_bestqual_COQUBO,
    PGPP_bestqual_CCQUBO: animal?.PGPP_bestqual_CCQUBO,
    PGPP_bestqual_COCOUT: animal?.PGPP_bestqual_COCOUT,
    PGPP_bestqual_NIQUBO: animal?.PGPP_bestqual_NIQUBO,
    PGPP_bestqual_SNQUBO: animal?.PGPP_bestqual_SNQUBO,
    PGPP_bestqual_COPACQ: animal?.PGPP_bestqual_COPACQ,
    PGPP_bestqual_NUCHQU: animal?.PGPP_bestqual_NUCHQU,
    PGPP_bestqual_DAQUBO: animal?.PGPP_bestqual_DAQUBO,
    PGPP_bestqual_DAEXBO: animal?.PGPP_bestqual_DAEXBO,
    PGPP_bestqual_COPOIN: animal?.PGPP_bestqual_COPOIN,
    // MGPP
    MGPPnobovi: animal?.MGPP_bovide_NOBOVI,
    MGPPcopaip: animal?.MGPP_bovide_COPAIP,
    MGPPnunati: animal?.MGPP_bovide_NUNATI,
    numMGPP: animal?.MGPP_bovide_NOBOVI + animal?.MGPP_bovide_NUNATI,
    nomMGPP: animal?.MGPP_bovide_NOBOVI,
    qualifMGPP: animal?.MGPP_bestqual_LIBECO,
    qualiMGPPLIDETA: animal?.MGPP_bestqual_LIDETA,
    qualifMGPPLIBELO: animal?.MGPP_bestqual_LIBELO,
    inscrMGPP: animal?.MGPP_boqual_LIBECO,
    inscrMGPPLIDETA: animal?.MGPP_boqual_LIDETA,
    inscrMGPPLIBELO: animal?.MGPP_boqual_LIBELO,
    MGPP_bestqual_ETQUBO: animal?.MGPP_bestqual_ETQUBO,
    MGPP_bestqual_COQUBO: animal?.MGPP_bestqual_COQUBO,
    MGPP_bestqual_CCQUBO: animal?.MGPP_bestqual_CCQUBO,
    MGPP_bestqual_COCOUT: animal?.MGPP_bestqual_COCOUT,
    MGPP_bestqual_NIQUBO: animal?.MGPP_bestqual_NIQUBO,
    MGPP_bestqual_SNQUBO: animal?.MGPP_bestqual_SNQUBO,
    MGPP_bestqual_COPACQ: animal?.MGPP_bestqual_COPACQ,
    MGPP_bestqual_NUCHQU: animal?.MGPP_bestqual_NUCHQU,
    MGPP_bestqual_DAQUBO: animal?.MGPP_bestqual_DAQUBO,
    MGPP_bestqual_DAEXBO: animal?.MGPP_bestqual_DAEXBO,
    MGPP_bestqual_COPOIN: animal?.MGPP_bestqual_COPOIN,
    // GMP
    GMPnobovi: animal?.GMP_bovide_NOBOVI,
    GMPcopaip: animal?.GMP_bovide_COPAIP,
    GMPnunati: animal?.GMP_bovide_NUNATI,
    numGMP: animal?.GMP_bovide_COPAIP + animal?.GMP_bovide_NUNATI,
    nomGMP: animal?.GMP_bovide_NOBOVI,
    qualifGMP: animal?.GMP_bestqual_LIBECO,
    qualifGMPLIDETA: animal?.GMP_bestqual_LIDETA,
    qualifGMPLIBELO: animal?.GMP_bestqual_LIBELO,
    inscrGMP: animal?.GMP_boqual_LIBECO,
    inscrGMPLIDETA: animal?.GMP_boqual_LIDETA,
    inscrGMPLIBELO: animal?.GMP_boqual_LIBELO,
    GMP_bestqual_ETQUBO: animal?.GMP_bestqual_ETQUBO,
    GMP_bestqual_COQUBO: animal?.GMP_bestqual_COQUBO,
    GMP_bestqual_CCQUBO: animal?.GMP_bestqual_CCQUBO,
    GMP_bestqual_COCOUT: animal?.GMP_bestqual_COCOUT,
    GMP_bestqual_NIQUBO: animal?.GMP_bestqual_NIQUBO,
    GMP_bestqual_SNQUBO: animal?.GMP_bestqual_SNQUBO,
    GMP_bestqual_COPACQ: animal?.GMP_bestqual_COPACQ,
    GMP_bestqual_NUCHQU: animal?.GMP_bestqual_NUCHQU,
    GMP_bestqual_DAQUBO: animal?.GMP_bestqual_DAQUBO,
    GMP_bestqual_DAEXBO: animal?.GMP_bestqual_DAEXBO,
    GMP_bestqual_COPOIN: animal?.GMP_bestqual_COPOIN,
    // PGMP
    PGMPnobovi: animal?.PGMP_bovide_NOBOVI,
    PGMPcopaip: animal?.PGMP_bovide_COPAIP,
    PGMPnunati: animal?.PGMP_bovide_NUNATI,
    numPGMP: animal?.PGMP_bovide_COPAIP + animal?.PGMP_bovide_NUNATI,
    nomPGMP: animal?.PGMP_bovide_NOBOVI,
    qualifPGMP: animal?.PGMP_bestqual_LIBECO,
    qualifPGMPLIDETA: animal?.PGMP_bestqual_LIDETA,
    qualifPGMPLIBELO: animal?.PGMP_bestqual_LIBELO,
    inscrPGMP: animal?.PGMP_boqual_LIBECO,
    inscrPGMPLIDETA: animal?.PGMP_boqual_LIDETA,
    inscrPGMPLIBELO: animal?.PGMP_boqual_LIBELO,
    PGMP_bestqual_ETQUBO: animal?.PGMP_bestqual_ETQUBO,
    PGMP_bestqual_COQUBO: animal?.PGMP_bestqual_COQUBO,
    PGMP_bestqual_CCQUBO: animal?.PGMP_bestqual_CCQUBO,
    PGMP_bestqual_COCOUT: animal?.PGMP_bestqual_COCOUT,
    PGMP_bestqual_NIQUBO: animal?.PGMP_bestqual_NIQUBO,
    PGMP_bestqual_SNQUBO: animal?.PGMP_bestqual_SNQUBO,
    PGMP_bestqual_COPACQ: animal?.PGMP_bestqual_COPACQ,
    PGMP_bestqual_NUCHQU: animal?.PGMP_bestqual_NUCHQU,
    PGMP_bestqual_DAQUBO: animal?.PGMP_bestqual_DAQUBO,
    PGMP_bestqual_DAEXBO: animal?.PGMP_bestqual_DAEXBO,
    PGMP_bestqual_COPOIN: animal?.PGMP_bestqual_COPOIN,
    // MGMP
    MGMPnobovi: animal?.MGMP_bovide_NOBOVI,
    MGMPcopaip: animal?.MGMP_bovide_COPAIP,
    MGMPnunati: animal?.MGMP_bovide_NUNATI,
    numMGMP: animal?.MGMP_bovide_COPAIP + animal?.MGMP_bovide_NUNATI,
    nomMGMP: animal?.MGMP_bovide_NOBOVI,
    qualifMGMP: animal?.MGMP_bestqual_LIBECO,
    qualifMGMPLIDETA: animal?.MGMP_bestqual_LIDETA,
    qualifMGMPLIBELO: animal?.MGMP_bestqual_LIBELO,
    inscrMGMP: animal?.MGMP_boqual_LIBECO,
    inscrMGMPLIDETA: animal?.MGMP_boqual_LIDETA,
    inscrMGMPLIBELO: animal?.MGMP_boqual_LIBELO,
    MGMP_bestqual_ETQUBO: animal?.MGMP_bestqual_ETQUBO,
    MGMP_bestqual_COQUBO: animal?.MGMP_bestqual_COQUBO,
    MGMP_bestqual_CCQUBO: animal?.MGMP_bestqual_CCQUBO,
    MGMP_bestqual_COCOUT: animal?.MGMP_bestqual_COCOUT,
    MGMP_bestqual_NIQUBO: animal?.MGMP_bestqual_NIQUBO,
    MGMP_bestqual_SNQUBO: animal?.MGMP_bestqual_SNQUBO,
    MGMP_bestqual_COPACQ: animal?.MGMP_bestqual_COPACQ,
    MGMP_bestqual_NUCHQU: animal?.MGMP_bestqual_NUCHQU,
    MGMP_bestqual_DAQUBO: animal?.MGMP_bestqual_DAQUBO,
    MGMP_bestqual_DAEXBO: animal?.MGMP_bestqual_DAEXBO,
    MGMP_bestqual_COPOIN: animal?.MGMP_bestqual_COPOIN,
    // GPM
    GPMnobovi: animal?.GPM_bovide_NOBOVI,
    GPMcopaip: animal?.GPM_bovide_COPAIP,
    GPMnunati: animal?.GPM_bovide_NUNATI,
    numGPM: animal?.GPM_bovide_COPAIP + animal?.GPM_bovide_NUNATI,
    nomGPM: animal?.GPM_bovide_NOBOVI,
    qualifGPM: animal?.GPM_bestqual_LIBECO,
    qualifGPMLIDETA: animal?.GPM_bestqual_LIDETA,
    qualifGPMLIBELO: animal?.GPM_bestqual_LIBELO,
    inscrGPM: animal?.GPM_boqual_LIBECO,
    inscrGPMLIDETA: animal?.GPM_boqual_LIDETA,
    inscrGPMLIBELO: animal?.GPM_boqual_LIBELO,
    GPM_bestqual_ETQUBO: animal?.GPM_bestqual_ETQUBO,
    GPM_bestqual_COQUBO: animal?.GPM_bestqual_COQUBO,
    GPM_bestqual_CCQUBO: animal?.GPM_bestqual_CCQUBO,
    GPM_bestqual_COCOUT: animal?.GPM_bestqual_COCOUT,
    GPM_bestqual_NIQUBO: animal?.GPM_bestqual_NIQUBO,
    GPM_bestqual_SNQUBO: animal?.GPM_bestqual_SNQUBO,
    GPM_bestqual_COPACQ: animal?.GPM_bestqual_COPACQ,
    GPM_bestqual_NUCHQU: animal?.GPM_bestqual_NUCHQU,
    GPM_bestqual_DAQUBO: animal?.GPM_bestqual_DAQUBO,
    GPM_bestqual_DAEXBO: animal?.GPM_bestqual_DAEXBO,
    GPM_bestqual_COPOIN: animal?.GPM_bestqual_COPOIN,
    // PGPM
    PGPMnobovi: animal?.PGPM_bovide_NOBOVI,
    PGPMcopaip: animal?.PGPM_bovide_COPAIP,
    PGPMnunati: animal?.PGPM_bovide_NUNATI,
    numPGPM: animal?.PGPM_bovide_COPAIP + animal?.PGPM_bovide_NUNATI,
    nomPGPM: animal?.PGPM_bovide_NOBOVI,
    qualifPGPM: animal?.PGPM_bestqual_LIBECO,
    qualifPGPMLIDETA: animal?.PGPM_bestqual_LIDETA,
    qualifPGPMLIBELO: animal?.PGPM_bestqual_LIBELO,
    inscrPGPM: animal?.PGPM_boqual_LIBECO,
    inscrPGPMLIDETA: animal?.PGPM_boqual_LIDETA,
    inscrPGPMLIBELO: animal?.PGPM_boqual_LIBELO,
    PGPM_bestqual_ETQUBO: animal?.PGPM_bestqual_ETQUBO,
    PGPM_bestqual_COQUBO: animal?.PGPM_bestqual_COQUBO,
    PGPM_bestqual_CCQUBO: animal?.PGPM_bestqual_CCQUBO,
    PGPM_bestqual_COCOUT: animal?.PGPM_bestqual_COCOUT,
    PGPM_bestqual_NIQUBO: animal?.PGPM_bestqual_NIQUBO,
    PGPM_bestqual_SNQUBO: animal?.PGPM_bestqual_SNQUBO,
    PGPM_bestqual_COPACQ: animal?.PGPM_bestqual_COPACQ,
    PGPM_bestqual_NUCHQU: animal?.PGPM_bestqual_NUCHQU,
    PGPM_bestqual_DAQUBO: animal?.PGPM_bestqual_DAQUBO,
    PGPM_bestqual_DAEXBO: animal?.PGPM_bestqual_DAEXBO,
    PGPM_bestqual_COPOIN: animal?.PGPM_bestqual_COPOIN,
    // MGPM
    MGPMnobovi: animal?.MGPM_bovide_NOBOVI,
    MGPMcopaip: animal?.MGPM_bovide_COPAIP,
    MGPMnunati: animal?.MGPM_bovide_NUNATI,
    numMGPM: animal?.MGPM_bovide_COPAIP + animal?.MGPM_bovide_NUNATI,
    nomMGPM: animal?.MGPM_bovide_NOBOVI,
    qualifMGPM: animal?.MGPM_bestqual_LIBECO,
    qualifMGPMLIDETA: animal?.MGPM_bestqual_LIDETA,
    qualifMGPMLIBELO: animal?.MGPM_bestqual_LIBELO,
    inscrMGPM: animal?.MGPM_boqual_LIBECO,
    inscrMGPMLIDETA: animal?.MGPM_boqual_LIDETA,
    inscrMGPMLIBELO: animal?.MGPM_boqual_LIBELO,
    MGPM_bestqual_ETQUBO: animal?.MGPM_bestqual_ETQUBO,
    MGPM_bestqual_COQUBO: animal?.MGPM_bestqual_COQUBO,
    MGPM_bestqual_CCQUBO: animal?.MGPM_bestqual_CCQUBO,
    MGPM_bestqual_COCOUT: animal?.MGPM_bestqual_COCOUT,
    MGPM_bestqual_NIQUBO: animal?.MGPM_bestqual_NIQUBO,
    MGPM_bestqual_SNQUBO: animal?.MGPM_bestqual_SNQUBO,
    MGPM_bestqual_COPACQ: animal?.MGPM_bestqual_COPACQ,
    MGPM_bestqual_NUCHQU: animal?.MGPM_bestqual_NUCHQU,
    MGPM_bestqual_DAQUBO: animal?.MGPM_bestqual_DAQUBO,
    MGPM_bestqual_DAEXBO: animal?.MGPM_bestqual_DAEXBO,
    MGPM_bestqual_COPOIN: animal?.MGPM_bestqual_COPOIN,
    // GMM
    GMMnobovi: animal?.GMM_bovide_NOBOVI,
    GMMcopaip: animal?.GMM_bovide_COPAIP,
    GMMnunati: animal?.GMM_bovide_NUNATI,
    numGMM: animal?.GMM_bovide_COPAIP + animal?.GMM_bovide_NUNATI,
    nomGMM: animal?.GMM_bovide_NOBOVI,
    qualifGMM: animal?.GMM_bestqual_LIBECO,
    qualifGMMLIDETA: animal?.GMM_bestqual_LIDETA,
    qualifGMMLIBELO: animal?.GMM_bestqual_LIBELO,
    inscrGMM: animal?.GMM_boqual_LIBECO,
    inscrGMMLIDETA: animal?.GMM_boqual_LIDETA,
    inscrGMMLIBELO: animal?.GMM_boqual_LIBELO,
    GMM_bestqual_ETQUBO: animal?.GMM_bestqual_ETQUBO,
    GMM_bestqual_COQUBO: animal?.GMM_bestqual_COQUBO,
    GMM_bestqual_CCQUBO: animal?.GMM_bestqual_CCQUBO,
    GMM_bestqual_COCOUT: animal?.GMM_bestqual_COCOUT,
    GMM_bestqual_NIQUBO: animal?.GMM_bestqual_NIQUBO,
    GMM_bestqual_SNQUBO: animal?.GMM_bestqual_SNQUBO,
    GMM_bestqual_COPACQ: animal?.GMM_bestqual_COPACQ,
    GMM_bestqual_NUCHQU: animal?.GMM_bestqual_NUCHQU,
    GMM_bestqual_DAQUBO: animal?.GMM_bestqual_DAQUBO,
    GMM_bestqual_DAEXBO: animal?.GMM_bestqual_DAEXBO,
    GMM_bestqual_COPOIN: animal?.GMM_bestqual_COPOIN,
    // PGMM
    PGMMnobovi: animal?.PGMM_bovide_NOBOVI,
    PGMMcopaip: animal?.PGMM_bovide_COPAIP,
    PGMMnunati: animal?.PGMM_bovide_NUNATI,
    numPGMM: animal?.PGMM_bovide_COPAIP + animal?.PGMM_bovide_NUNATI,
    nomPGMM: animal?.PGMM_bovide_NOBOVI,
    qualifPGMM: animal?.PGMM_bestqual_LIBECO,
    qualifPGMMLIDETA: animal?.PGMM_bestqual_LIDETA,
    qualifPGMMLIBELO: animal?.PGMM_bestqual_LIBELO,
    inscrPGMM: animal?.PGMM_boqual_LIBECO,
    inscrPGMMLIDETA: animal?.PGMM_boqual_LIDETA,
    inscrPGMMLIBELO: animal?.PGMM_boqual_LIBELO,
    PGMM_bestqual_ETQUBO: animal?.PGMM_bestqual_ETQUBO,
    PGMM_bestqual_COQUBO: animal?.PGMM_bestqual_COQUBO,
    PGMM_bestqual_CCQUBO: animal?.PGMM_bestqual_CCQUBO,
    PGMM_bestqual_COCOUT: animal?.PGMM_bestqual_COCOUT,
    PGMM_bestqual_NIQUBO: animal?.PGMM_bestqual_NIQUBO,
    PGMM_bestqual_SNQUBO: animal?.PGMM_bestqual_SNQUBO,
    PGMM_bestqual_COPACQ: animal?.PGMM_bestqual_COPACQ,
    PGMM_bestqual_NUCHQU: animal?.PGMM_bestqual_NUCHQU,
    PGMM_bestqual_DAQUBO: animal?.PGMM_bestqual_DAQUBO,
    PGMM_bestqual_DAEXBO: animal?.PGMM_bestqual_DAEXBO,
    PGMM_bestqual_COPOIN: animal?.PGMM_bestqual_COPOIN,
    // MGMM
    MGMMnobovi: animal?.MGMM_bovide_NOBOVI,
    MGMMcopaip: animal?.MGMM_bovide_COPAIP,
    MGMMnunati: animal?.MGMM_bovide_NUNATI,
    numMGMM: animal?.MGMM_bovide_COPAIP + animal?.MGMM_bovide_NUNATI,
    nomMGMM: animal?.MGMM_bovide_NOBOVI,
    qualifMGMM: animal?.MGMM_bestqual_LIBECO,
    qualifMGMMLIDETA: animal?.MGMM_bestqual_LIDETA,
    qualifMGMMLIBELO: animal?.MGMM_bestqual_LIBELO,
    inscrMGMM: animal?.MGMM_boqual_LIBECO,
    inscrMGMMLIDETA: animal?.MGMM_boqual_LIDETA,
    inscrMGMMLIBELO: animal?.MGMM_boqual_LIBELO,
    MGMM_bestqual_ETQUBO: animal?.MGMM_bestqual_ETQUBO,
    MGMM_bestqual_COQUBO: animal?.MGMM_bestqual_COQUBO,
    MGMM_bestqual_CCQUBO: animal?.MGMM_bestqual_CCQUBO,
    MGMM_bestqual_COCOUT: animal?.MGMM_bestqual_COCOUT,
    MGMM_bestqual_NIQUBO: animal?.MGMM_bestqual_NIQUBO,
    MGMM_bestqual_SNQUBO: animal?.MGMM_bestqual_SNQUBO,
    MGMM_bestqual_COPACQ: animal?.MGMM_bestqual_COPACQ,
    MGMM_bestqual_NUCHQU: animal?.MGMM_bestqual_NUCHQU,
    MGMM_bestqual_DAQUBO: animal?.MGMM_bestqual_DAQUBO,
    MGMM_bestqual_DAEXBO: animal?.MGMM_bestqual_DAEXBO,
    MGMM_bestqual_COPOIN: animal?.MGMM_bestqual_COPOIN,
  }
}
