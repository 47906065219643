import "./PointagePostSevrage.scss"
import FillingBar from "../../FillingBar"
import { useState } from "react"

let width = {
  posp11: 2,
  posp12: 2,
  posp13: 2,
  posp14: 2,
  posp16: 2,
  posp15: 2,
  posp17: 2,
  posp18: 2,
  posp20: 2,
  posp31: 2,
  posp19: 2,
  posp34: 2,
  posp35: 2,
  posp36: 2,
  posp21: 2,
  posp26: 2,
  posp27: 2,
  posp33: 2,
  posp22: 2,
  posp23: 2,
  posp24: 2,
  posp25: 2,
  posp28: 2,
  posp29: 2,
  posp30: 2,
  posp32: 2,
}

let margin = {
  posp11: 49,
  posp12: 49,
  posp13: 49,
  posp14: 49,
  posp16: 49,
  posp15: 49,
  posp17: 49,
  posp18: 49,
  posp20: 49,
  posp31: 49,
  posp19: 49,
  posp34: 49,
  posp35: 49,
  posp36: 49,
  posp21: 49,
  posp26: 49,
  posp27: 49,
  posp33: 49,
  posp22: 49,
  posp23: 49,
  posp24: 49,
  posp25: 49,
  posp28: 49,
  posp29: 49,
  posp30: 49,
  posp32: 49,
}

let color = {
  posp11: "",
  posp12: "",
  posp13: "",
  posp14: "",
  posp16: "",
  posp15: "",
  posp17: "",
  posp18: "",
  posp20: "",
  posp31: "",
  posp19: "",
  posp34: "",
  posp35: "",
  posp36: "",
  posp21: "",
  posp26: "",
  posp27: "",
  posp33: "",
  posp22: "",
  posp23: "",
  posp24: "",
  posp25: "",
  posp28: "",
  posp29: "",
  posp30: "",
  posp32: "",
}
const PointagePostSevrage = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow =
    window.localStorage.showpointagepostsevrage === "false" ? false : true
  const [show, setShow] = useState(savedShow)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showpointagepostsevrage = !show
  }
  const calculateWidthMargin = (key, value) => {
    if (value === "") {
      width[key] = 0
      margin[key] = 0
    } else if (value > 5) {
      margin[key] = 50
      let tmpValue = value - 5
      tmpValue = (tmpValue * 100) / 5
      tmpValue = (tmpValue * 50) / 100
      width[key] = tmpValue
    } else if (value < 5) {
      let tmpValue = 5 - value
      tmpValue = (tmpValue * 100) / 5
      tmpValue = (tmpValue * 50) / 100
      width[key] = tmpValue
      margin[key] = 50 - tmpValue
    }

    // Color
    if (value >= 7) color[key] = "green"
    else if (value < 7 && value > 3) color[key] = "blue"
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  calculateWidthMargin("posp11", animal.A_bopaps_posp11)
  calculateWidthMargin("posp12", animal.A_bopaps_posp12)
  calculateWidthMargin("posp13", animal.A_bopaps_posp13)
  calculateWidthMargin("posp14", animal.A_bopaps_posp14)
  calculateWidthMargin("posp16", animal.A_bopaps_posp16)
  calculateWidthMargin("posp15", animal.A_bopaps_posp15)
  calculateWidthMargin("posp17", animal.A_bopaps_posp17)
  calculateWidthMargin("posp18", animal.A_bopaps_posp18)
  calculateWidthMargin("posp20", animal.A_bopaps_posp20)
  calculateWidthMargin("posp31", animal.A_bopaps_posp31)
  calculateWidthMargin("posp19", animal.A_bopaps_posp19)
  calculateWidthMargin("posp34", animal.A_bopaps_posp34)
  calculateWidthMargin("posp35", animal.A_bopaps_posp35)
  calculateWidthMargin("posp36", animal.A_bopaps_posp36)
  calculateWidthMargin("posp21", animal.A_bopaps_posp21)
  calculateWidthMargin("posp26", animal.A_bopaps_posp26)
  calculateWidthMargin("posp27", animal.A_bopaps_posp27)
  calculateWidthMargin("posp33", animal.A_bopaps_posp33)
  calculateWidthMargin("posp22", animal.A_bopaps_posp22)
  calculateWidthMargin("posp23", animal.A_bopaps_posp23)
  calculateWidthMargin("posp24", animal.A_bopaps_posp24)
  calculateWidthMargin("posp25", animal.A_bopaps_posp25)
  calculateWidthMargin("posp28", animal.A_bopaps_posp28)
  calculateWidthMargin("posp29", animal.A_bopaps_posp29)
  calculateWidthMargin("posp30", animal.A_bopaps_posp30)
  calculateWidthMargin("posp32", animal.A_bopaps_posp32)

  return (
    <div className="pointagepostsevrage" id="div_pointagepostsevrage">
      <h3 onClick={hideOrShow} className="ripple2">
        Pointage post sevrage{" "}
        <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>
      {show &&
        (animal.A_bopaps_posp01 !== "" ||
        animal.A_bopaps_posp02 !== "" ||
        animal.A_bopaps_posp03 !== "" ||
        animal.A_bopaps_posp04 !== "" ||
        animal.A_bopaps_posp05 !== "" ||
        animal.A_bopaps_posp06 !== "" ? (
          <div id="pointagepostsevrage">
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Note d'état attribuée lors du pointage</th>
                  <th className="index-header">{animal.A_bopaps_posp06}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Dessus d'épaule</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp11"]}%`,
                        margin: `${margin["posp11"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp11"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp11 && parseInt(animal.A_bopaps_posp11)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur du dos</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp12"]}%`,
                        margin: `${margin["posp12"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp12"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp12 && parseInt(animal.A_bopaps_posp12)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Arrondi de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp13"]}%`,
                        margin: `${margin["posp13"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp13"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp13 && parseInt(animal.A_bopaps_posp13)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp14"]}%`,
                        margin: `${margin["posp14"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp14"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp14 && parseInt(animal.A_bopaps_posp14)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp16"]}%`,
                        margin: `${margin["posp16"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp16"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp16 && parseInt(animal.A_bopaps_posp16)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Epaisseur du dessus</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp15"]}%`,
                        margin: `${margin["posp15"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp15"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp15 && parseInt(animal.A_bopaps_posp15)}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colSpan="2">Développement musculaire</th>
                  <th className="index-header">{animal.A_bopaps_posp01}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur du dessus</p>
                  </td>
                  <td className="full_width">
                    <FillingBar
                      styleValues={{
                        width: `${width["posp17"]}%`,
                        margin: `${margin["posp17"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp17"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp17 && parseInt(animal.A_bopaps_posp17)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur du bassin</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp18"]}%`,
                        margin: `${margin["posp18"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp18"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp18 && parseInt(animal.A_bopaps_posp18)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Développement</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp20"]}%`,
                        margin: `${margin["posp20"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp20"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp20 && parseInt(animal.A_bopaps_posp20)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Profondeur de poitrine</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp31"]}%`,
                        margin: `${margin["posp31"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp31"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp31 && parseInt(animal.A_bopaps_posp31)}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colSpan="2">Développement squelettique</th>
                  <th className="index-header">{animal.A_bopaps_posp02}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur aux hanches</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp19"]}%`,
                        margin: `${margin["posp19"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp19"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp19 && parseInt(animal.A_bopaps_posp19)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur aux trochanters</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp34"]}%`,
                        margin: `${margin["posp34"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp34"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp34 && parseInt(animal.A_bopaps_posp34)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur Ishions</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp35"]}%`,
                        margin: `${margin["posp35"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp35"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp35 && parseInt(animal.A_bopaps_posp35)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Inclinaison du bassin</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp36"]}%`,
                        margin: `${margin["posp36"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp36"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp36 && parseInt(animal.A_bopaps_posp36)}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colSpan="2">Bassin</th>
                  <th className="index-header">{animal.A_bopaps_posp03}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur du mufle</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp21"]}%`,
                        margin: `${margin["posp21"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp21"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp21 && parseInt(animal.A_bopaps_posp21)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Rectitude du dessus</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp26"]}%`,
                        margin: `${margin["posp26"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp26"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp26 && parseInt(animal.A_bopaps_posp26)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Passage de sangle</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp27"]}%`,
                        margin: `${margin["posp27"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp27"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp27 && parseInt(animal.A_bopaps_posp27)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Profondeur Grasset</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp33"]}%`,
                        margin: `${margin["posp33"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp33"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp33 && parseInt(animal.A_bopaps_posp33)}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colSpan="2">Aptitude fonctionnelle</th>
                  <th className="index-header">{animal.A_bopaps_posp04}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs avant face</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp22"]}%`,
                        margin: `${margin["posp22"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp22"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp22 && parseInt(animal.A_bopaps_posp22)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs avant profil</p>
                  </td>
                  <td>
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp23"]}%`,
                        margin: `${margin["posp23"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp23"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp23 && parseInt(animal.A_bopaps_posp23)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs arrière face</p>
                  </td>
                  <td>
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp24"]}%`,
                        margin: `${margin["posp24"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp24"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp24 && parseInt(animal.A_bopaps_posp24)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs arrière profil</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp25"]}%`,
                        margin: `${margin["posp25"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp25"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp25 && parseInt(animal.A_bopaps_posp25)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Eclairage de la tête</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp28"]}%`,
                        margin: `${margin["posp28"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp28"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp28 && parseInt(animal.A_bopaps_posp28)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Robe</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp29"]}%`,
                        margin: `${margin["posp29"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp29"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp29 && parseInt(animal.A_bopaps_posp29)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Harmonie</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp30"]}%`,
                        margin: `${margin["posp30"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp30"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp30 && parseInt(animal.A_bopaps_posp30)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur de poitrine</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["posp32"]}%`,
                        margin: `${margin["posp32"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["posp32"],
                      }}
                    />
                  </td>
                  <td>
                    {animal.A_bopaps_posp32 && parseInt(animal.A_bopaps_posp32)}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colSpan="2">Grosseur des canons</th>
                  <th className="index-header">{animal.A_bopaps_posp05}</th>
                </tr>
              </thead>
            </table>
          </div>
        ) : (
          <div className="alert">Pas de pointages connus</div>
        ))}
    </div>
  )
}

export default PointagePostSevrage
