import "./DinoGame.scss"
import Player from "./Player.js"
import Ground from "./Ground.js"
import CactiController from "./CactiController.js"
import Score from "./Score.js"
import { useEffect } from "react"
import cactus_1Img from "./images/cactus_1.png"
import cactus_2Img from "./images/cactus_2.png"
import cactus_3Img from "./images/cactus_3.png"

const DinoGame = () => {
  useEffect(() => {
    const canvas = document.getElementById("game")
    const ctx = canvas.getContext("2d")

    const GAME_SPEED_START = 1 // 1.0
    const GAME_SPEED_INCREMENT = 0.00001

    const GAME_WIDTH = window.innerWidth >= 780 ? window.innerWidth : 800
    const GAME_HEIGHT = 200
    const PLAYER_WIDTH = 128 / 1.5 //58
    const PLAYER_HEIGHT = 80 / 1.5 //62
    const MAX_JUMP_HEIGHT = GAME_HEIGHT
    const MIN_JUMP_HEIGHT = 150
    const GROUND_WIDTH = 2400
    const GROUND_HEIGHT = 24
    const GROUND_AND_CACTUS_SPEED = 0.5

    const CACTI_CONFIG = [
      { width: 48 / 1.5, height: 100 / 1.5, image: cactus_1Img },
      { width: 98 / 1.5, height: 100 / 1.5, image: cactus_2Img },
      { width: 68 / 1.5, height: 70 / 1.5, image: cactus_3Img },
    ]

    //Game Objects
    let player = null
    let ground = null
    let cactiController = null
    let score = null

    let scaleRatio = null
    let previousTime = null
    let gameSpeed = GAME_SPEED_START
    let gameOver = false
    let hasAddedEventListenersForRestart = false
    let waitingToStart = true

    let countMethodGameOver = 0

    function createSprites() {
      const playerWidthInGame = PLAYER_WIDTH * scaleRatio
      const playerHeightInGame = PLAYER_HEIGHT * scaleRatio
      const minJumpHeightInGame = MIN_JUMP_HEIGHT * scaleRatio
      const maxJumpHeightInGame = MAX_JUMP_HEIGHT * scaleRatio

      const groundWidthInGame = GROUND_WIDTH * scaleRatio
      const groundHeightInGame = GROUND_HEIGHT * scaleRatio

      player = new Player(
        ctx,
        playerWidthInGame,
        playerHeightInGame,
        minJumpHeightInGame,
        maxJumpHeightInGame,
        scaleRatio
      )

      ground = new Ground(
        ctx,
        groundWidthInGame,
        groundHeightInGame,
        GROUND_AND_CACTUS_SPEED,
        scaleRatio
      )

      const cactiImages = CACTI_CONFIG.map(cactus => {
        const image = new Image()
        image.src = cactus.image
        return {
          image: image,
          width: cactus.width * scaleRatio,
          height: cactus.height * scaleRatio,
        }
      })

      cactiController = new CactiController(
        ctx,
        cactiImages,
        scaleRatio,
        GROUND_AND_CACTUS_SPEED
      )

      score = new Score(ctx, scaleRatio)
    }

    function setScreen() {
      scaleRatio = getScaleRatio()
      canvas.width = GAME_WIDTH * scaleRatio
      canvas.height = GAME_HEIGHT * scaleRatio
      createSprites()
    }

    setScreen()
    //Use setTimeout on Safari mobile rotation otherwise works fine on desktop
    window.addEventListener("resize", () => setTimeout(setScreen, 500))

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", setScreen)
    }

    function getScaleRatio() {
      const screenHeight = Math.min(
        window.innerHeight,
        document.documentElement.clientHeight
      )

      const screenWidth = Math.min(
        window.innerWidth,
        document.documentElement.clientWidth
      )

      //window is wider than the game width
      if (screenWidth / screenHeight < GAME_WIDTH / GAME_HEIGHT) {
        return screenWidth / GAME_WIDTH
      } else {
        return screenHeight / GAME_HEIGHT
      }
    }

    function showGameOver() {
      if (countMethodGameOver === 0) {
        window.localStorage.gameRunning = "false"

        let game = document.getElementById("game")

        const event = new CustomEvent("gameIsOver")

        if (game) game.dispatchEvent(event)
      }

      countMethodGameOver++

      const fontSize = 70 * scaleRatio
      ctx.font = `${fontSize}px Verdana`
      ctx.fillStyle = "grey"
      const x = canvas.width / 4.5
      const y = canvas.height / 2
      ctx.fillText("GAME OVER", x, y)
    }

    function setupGameReset() {
      if (!hasAddedEventListenersForRestart) {
        hasAddedEventListenersForRestart = true

        setTimeout(() => {
          window.addEventListener("keyup", reset, { once: true })
          window.addEventListener("touchstart", reset, { once: true })
          window.addEventListener("mousedown", reset, { once: true })

          if (gameOver) {
            window.localStorage.gameRunning = "true"
            gameOver = false
            waitingToStart = true
            ground.reset()
            cactiController.reset()
            showStartGameText()
          }
        }, 1000)
      }
    }

    function reset() {
      window.localStorage.gameRunning = "true"
      hasAddedEventListenersForRestart = false
      gameOver = false
      waitingToStart = false
      ground.reset()
      cactiController.reset()
      score.reset()
      gameSpeed = GAME_SPEED_START
    }

    function showStartGameText() {
      let isMobile = !!navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      )

      let fontSize = 40 * scaleRatio
      if (!isMobile) fontSize = 28 * scaleRatio
      ctx.font = `${fontSize}px Verdana`
      ctx.fillStyle = "grey"
      let x = canvas.width / 14
      if (!isMobile) x = canvas.width / 42
      const y = canvas.height / 2

      if (isMobile) ctx.fillText("Appuyer sur l'écran pour démarrer", x, y)
      else
        ctx.fillText(
          "Cliquer sur l'écran/Appuyer sur espace pour démarrer",
          x,
          y
        )
    }

    function updateGameSpeed(frameTimeDelta) {
      gameSpeed += frameTimeDelta * GAME_SPEED_INCREMENT
    }

    function clearScreen() {
      ctx.fillStyle = "#fff"
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    }

    let gameLoop = currentTime => {
      if (previousTime === null) {
        previousTime = currentTime
        requestAnimationFrame(gameLoop)
        return
      }
      const frameTimeDelta = currentTime - previousTime
      previousTime = currentTime

      clearScreen()

      if (!gameOver && !waitingToStart) {
        //Update game objects
        ground.update(gameSpeed, frameTimeDelta)
        cactiController.update(gameSpeed, frameTimeDelta)
        player.update(gameSpeed, frameTimeDelta)
        score.update(frameTimeDelta)
        updateGameSpeed(frameTimeDelta)
      }

      if (!gameOver && cactiController.collideWith(player)) {
        gameOver = true
        setupGameReset()
        score.setHighScore()
      }

      //Draw game objects
      ground.draw()
      cactiController.draw()
      player.draw()
      score.draw()

      if (gameOver) {
        showGameOver()
      }
      if (!gameOver) countMethodGameOver = 0

      if (waitingToStart) {
        window.localStorage.gameRunning = "false"
        showStartGameText()
      }

      requestAnimationFrame(gameLoop)
    }

    requestAnimationFrame(gameLoop)

    window.addEventListener("keyup", reset, { once: true })
    window.addEventListener("touchstart", reset, { once: true })
    window.addEventListener("mousedown", reset, { once: true })

    let dinoDiv = document.getElementById("div_dino_game")
    let loaderDl = document.getElementById("loaderDl")

    if (dinoDiv && loaderDl) {
      var rect2 = loaderDl.getBoundingClientRect()
      let space = window.innerHeight - rect2.bottom
      let remaining = Math.floor((space - dinoDiv.offsetHeight) / 2)
      dinoDiv.style.bottom = remaining + "px"
    }

    return () => {
      gameLoop = () => (window.localStorage.gameRunning = "false")
    }
  }, [])

  return <canvas id="game" className="canvas_game"></canvas>
}

export default DinoGame
