import "./FicheShareStation.scss"
import Lanaud from "../../assets/images/fiche_station_bg_lanaud.png"
import ABL from "../../assets/images/fiche_station_bg_abl.png"
import Gelioc from "../../assets/images/fiche_station_bg_gelioc.png"
import Gevial from "../../assets/images/fiche_station_bg_gevial.png"
import { formatDate, formatDate2 } from "../../functions/formatDate"
import CheptelSectionTitle from "../CheptelSectionTitle"
import { getRFQUAL } from "../../functions/objectColums"
import { todayDate } from "../../functions/todayDate"

const FicheShareStation = ({
  data,
  b64Blur,
  b64Graph,
  moyennes,
  cheptelName,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let urlPhoto = `${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_STATION_URL}${
    data.id_vente
  }/photos/${+data.nuinst}.JPG`

  let numCheptel = window.localStorage.numCheptel ?? ""

  const RFQUAL = getRFQUAL()

  let analyseHpf = data.A_analyse_HPF
  if (data.AanalysePAF !== "") analyseHpf = data.A_analyse_PAF

  let isAnalyse =
    data.retyup_sc ||
    data.retyup_mh ||
    data.retyup_hpf ||
    data.retyup_paf ||
    data.retyup_pro

  let labels = []
  let dataGraph = []
  let codeStation = data.costat
  let campagne = data.caenst
  let serie = data.nuseri
  let lab
  let dataMoy = []
  let moyenneSerie = moyennes?.[codeStation]?.[campagne]?.[serie]
  let haveP2 = false

  // Poid arrivé
  if (data.povipe_PA || moyenneSerie?.PA?.moyenne) {
    if (data.povipe_PA) labels.push(formatDate2(data.dapevi_PA))
    else if (moyenneSerie?.PA?.moyenne_PA) {
      labels.push(formatDate2(moyenneSerie?.PA?.date))
    }

    if (data.povipe_PA) dataGraph.push(data.povipe_PA)
    else dataGraph.push(null)

    if (moyenneSerie?.PA?.moyenne) dataMoy.push(moyenneSerie?.PA?.moyenne)
    else dataMoy.push(null)
  }
  // Poid DD
  if (data.povipe_DD || moyenneSerie?.DD?.moyenne) {
    if (data.povipe_DD) labels.push(formatDate2(data.dapevi_DD))
    else if (moyenneSerie?.DD?.moyenne)
      labels.push(formatDate2(moyenneSerie?.DD?.date))

    if (data.povipe_DD) dataGraph.push(data.povipe_DD)
    else dataGraph.push(null)

    if (moyenneSerie?.DD?.moyenne) dataMoy.push(moyenneSerie?.DD?.moyenne)
    else dataMoy.push(null)
  }
  // Poid P1
  if (data.povipe_P1 || moyenneSerie?.P1?.moyenne) {
    if (data.povipe_P1) labels.push(formatDate2(data.dapevi_P1))
    else if (moyenneSerie?.P1?.moyenne)
      labels.push(formatDate2(moyenneSerie?.P1?.date))

    if (data.povipe_P1) dataGraph.push(data.povipe_P1)
    else dataGraph.push(null)

    if (moyenneSerie?.P1?.moyenne) dataMoy.push(moyenneSerie?.P1?.moyenne)
    else dataMoy.push(null)
  }
  // Poid P2
  if (data.povipe_P2 || moyenneSerie?.P2?.moyenne) {
    haveP2 = true

    if (data.povipe_P2) labels.push(formatDate2(data.dapevi_P2))
    else if (moyenneSerie?.P2?.moyenne)
      labels.push(formatDate2(moyenneSerie?.P2?.date))

    if (data.povipe_P2) dataGraph.push(data.povipe_P2)
    else dataGraph.push(null)

    if (moyenneSerie?.P2?.moyenne) dataMoy.push(moyenneSerie?.P2?.moyenne)
    else dataMoy.push(null)
  }
  // Poid DF
  if (data.povipe_DF || moyenneSerie?.DF?.moyenne) {
    if (data.povipe_DF) labels.push(formatDate2(data.dapevi_DF))
    else if (moyenneSerie?.DF?.moyenne)
      labels.push(formatDate2(moyenneSerie?.DF?.date))

    if (data.povipe_DF) dataGraph.push(data.povipe_DF)
    else dataGraph.push(null)

    if (moyenneSerie?.DF?.moyenne) dataMoy.push(moyenneSerie?.DF?.moyenne)
    else dataMoy.push(null)
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const getStationName = () => {
    return data.costat === "871"
      ? "Lanaud"
      : data.costat === "191"
      ? "ABL"
      : data.costat === "122"
      ? "Gelioc"
      : data.costat === "232" && "Gevial"
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  let showBandereau = false
  if (
    data?.A_boqual_2_etqubo !== "" ||
    (data.etat_animal === "3" && data.prix_vente) ||
    data.coexev ||
    data.coentr === ""
  )
    showBandereau = true
  let classRectQualif = "rectangel_qualif"

  if (data?.A_boqual_2_liqure === "RJ") classRectQualif += " rj"
  else if (data?.A_boqual_2_liqure === "Espoir") classRectQualif += " espoir"
  else if (data?.A_boqual_2_liqure === "RCV") classRectQualif += " rcv"
  else if (
    todayDate("numeric") > +data.date_vente &&
    !data.coexev &&
    data?.A_boqual_2_etqubo === ""
  )
    classRectQualif += " nq"
  else if (data.coexev) classRectQualif += " elimine"

  return (
    <div
      className={
        "ReactNativeWebView" in window
          ? "fiche_station_share native_web_view"
          : "fiche_station_share"
      }
    >
      {/* Div background */}
      <div className="div_background">
        <img
          src={
            data.costat === "871"
              ? Lanaud
              : data.costat === "191"
              ? ABL
              : data.costat === "122"
              ? Gelioc
              : data.costat === "232" && Gevial
          }
        />
      </div>

      {/* Div photo animal */}
      <div className="div_img_ani">
        <div className="photos">
          <img src={b64Blur} className="photo_flou_ani" />
          <div className="real_photo">
            <img
              className={
                data.photoHeight > data.photoWidth
                  ? "photo_ani portrait"
                  : "photo_ani landscape"
              }
              src={urlPhoto}
            />
          </div>
        </div>
      </div>

      {/* Div info cheptel */}
      <div className="info_cheptel">
        {cheptelName && (
          <p>
            {cheptelName} {numCheptel && <>({numCheptel.substring(2, 4)})</>}
          </p>
        )}
        <p>{numCheptel}</p>
      </div>

      {/* Div infos animal */}
      <div className="infos_animal">
        <div className="titre">
          <h2 className="nom sexe-male">{data.nobovi}</h2>
          <h3 className="numtravail">{data.copaip + data.nunati}</h3>
        </div>
        <div className="infos-naissance">
          <div className="line_info">
            <p>Date de naissance :</p>
            <p>{`${formatDate2(data.danais)}`}</p>
          </div>
        </div>
      </div>

      {/* Div rectangle qualif prix */}
      {showBandereau && (
        <div className="bandereau_fiche">
          {data?.A_boqual_2_etqubo && (
            <div className={classRectQualif}>{data?.A_boqual_2_liqure}</div>
          )}
          {todayDate("numeric") > +data.date_vente &&
            !data.coexev &&
            !data?.A_boqual_2_etqubo && (
              <div className={classRectQualif}>NQ</div>
            )}
          {data.coexev && <div className={classRectQualif}>Eliminé</div>}
          {data.etat_animal === "3" && data.prix_vente && (
            <div className="rectangle_pdv">{data.prix_vente}€</div>
          )}
          {data.etat_animal === "2" && (
            <div className="rectangle_pdv">Invendu</div>
          )}
        </div>
      )}

      {/* Div tag */}
      {data.nuinst && (
        <div className="tag">
          <p className="text_tag">TAG</p>
          <p className="number_tag">
            {Number.isNaN(+data.nuinst) ? data.nuinst : +data.nuinst}
          </p>
          <p className="name_station">
            {data.costat === "871"
              ? "LANAUD"
              : data.costat === "191"
              ? "ABL"
              : data.costat === "122"
              ? "GELIOC"
              : data.costat === "232" && "GEVIAL"}
          </p>
        </div>
      )}

      {/* Div label série */}
      {<div className="label_serie">Série :</div>}

      {/* Div serie */}
      <div className={"serie " + getStationName()}>
        {data.caenst}/{data.nuseri}
      </div>

      {/* Div parents */}
      <div className="parents">
        <CheptelSectionTitle title={"Parents"} />
        <div className="div_parents_fiche_station">
          {data?.A_analyse_VCG_gene_compat_2p === "ok" && (
            <div className="infos_vcg">
              "Génétiquement compatible avec les deux parents"
            </div>
          )}
          {data?.A_analyse_VCG_gene_compat_2p === "p" && (
            <div className="infos_vcg">
              "Génétiquement compatible uniquement avec le père"
            </div>
          )}
          {data?.A_analyse_VCG_gene_compat_2p === "m" && (
            <div className="infos_vcg">
              "Génétiquement compatible uniquement avec la mère"
            </div>
          )}

          <div className="parents_fiche_station">
            <div className="genalogie_pere">
              {data.nobovi_p}{" "}
              <i>{data?.P_boqual_3_libeco ?? data?.P_boqual_2_libeco ?? ""}</i>
              <p className="numero">{data.copape + data.nupere}</p>
            </div>

            <div className="genalogie_mere">
              {data.nobovi_m}{" "}
              <i>{data?.M_boqual_3_libeco ?? data?.M_boqual_2_libeco ?? ""}</i>
              <p className="numero">{data.copame + data.numere}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Div analyses */}
      <div className="analyses">
        <CheptelSectionTitle title={"Analyses"} />
        {isAnalyse ? (
          <div className="blocs_adn">
            <div className="bloc_adn">
              <p className="title_adn">Sans cornes</p>
              <div
                className={
                  data.retyup_sc === "+/+"
                    ? "resultat green"
                    : data.retyup_sc === "P/+" || data.retyup_sc === "P/P"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {data.retyup_sc === "+/+" && "Non porteur"}
                {data.retyup_sc === "P/+" && "Porteur hétérozygote"}
                {data.retyup_sc === "P/P" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Culard</p>
              <div
                className={
                  data.retyup_mh === "+/+"
                    ? "resultat green"
                    : data.retyup_mh === "MH/+" || data.retyup_mh === "MH/MH"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {data.retyup_mh === "+/+" && "Non porteur"}
                {data.retyup_mh === "MH/+" && "Porteur hétérozygote"}
                {data.retyup_mh === "MH/MH" && "Porteur homozygote"}
              </div>
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Palais fendu</p>
              {data.retyup_paf !== "" ? (
                <div
                  className={
                    data.retyup_paf === "+/+"
                      ? "resultat green"
                      : data.retyup_paf === "PAL/+" ||
                        data.retyup_paf === "PAL/PAL"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {data.retyup_paf === "+/+" && "Non porteur"}
                  {data.retyup_paf === "PAL/+" && "Porteur hétérozygote"}
                  {data.retyup_paf === "PAL/PAL" && "Porteur homozygote"}
                </div>
              ) : (
                <div
                  className={
                    data.retyup_hpf == "0"
                      ? "resultat green"
                      : data.retyup_hpf == "1" || data.retyup_hpf == "2"
                      ? "resultat red"
                      : "resultat"
                  }
                >
                  {data.retyup_hpf == "0" && "Non porteur (hpf)"}
                  {data.retyup_hpf == "1" && "Porteur hétérozygote (hpf)"}
                  {data.retyup_hpf == "2" && "Porteur homozygote (hpf)"}
                </div>
              )}
            </div>
            <div className="bloc_adn">
              <p className="title_adn">Protoporphyrie</p>
              <div
                className={
                  data.retyup_pro === "+/+"
                    ? "resultat green"
                    : data.retyup_pro === "PRO/+" ||
                      data.retyup_pro === "PRO/PRO"
                    ? "resultat red"
                    : "resultat"
                }
              >
                {data.retyup_pro === "+/+" && "Non porteur"}
                {data.retyup_pro === "PRO/+" && "Porteur hétérozygote"}
                {data.retyup_pro === "PRO/PRO" && "Porteur homozygote"}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert">Pas d'analyse connue</div>
        )}
      </div>

      {/* Div index station */}
      <div className="index_station">
        <CheptelSectionTitle title={"Index Station"} />
        {data.idcrei ||
        data.iddmei ||
        data.iddsei ||
        data.imocei ||
        data.idopei ||
        data.idafei ||
        data.idqrei ? (
          <div className="div_table" id="index_station">
            <table>
              <thead>
                <tr>
                  <th>CR</th>
                  <th>DM</th>
                  <th>DS</th>
                  <th>IMOCR</th>
                  <th>OP</th>
                  <th>AF</th>
                  <th>QR</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.idcrei}</td>
                  <td>{data.iddmei}</td>
                  <td>{data.iddsei}</td>
                  <td>{data.imocei}</td>
                  <td>{data.idopei}</td>
                  <td>{data.idafei}</td>
                  <td>{data.idqrei}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert">Pas d'index connus</div>
        )}
      </div>

      {/* Div evalim */}
      {data.coentr === "E" && (
        <div className="evalim">
          <CheptelSectionTitle title={"Evalim"} />
          {data.Evalim_CIFNAI ||
          data.Evalim_CCRSEV ||
          data.Evalim_CDMSEV ||
          data.Evalim_CDSSEV ||
          data.Evalim_CFOSSE ||
          data.Evalim_CAVELS ||
          data.Evalim_CALAIT ||
          data.Evalim_COPSEV ? (
            <div className="div_table" id="evalim_station">
              <table>
                <thead>
                  <tr>
                    <th>FN</th>
                    <th>CR</th>
                    <th>DM</th>
                    <th>DS</th>
                    <th>FOS</th>
                    <th>AV</th>
                    <th>AL</th>
                    <th>OP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.Evalim_CIFNAI}</td>
                    <td>{data.Evalim_CCRSEV}</td>
                    <td>{data.Evalim_CDMSEV}</td>
                    <td>{data.Evalim_CDSSEV}</td>
                    <td>{data.Evalim_CFOSSE}</td>
                    <td>{data.Evalim_CAVELS}</td>
                    <td>{data.Evalim_CALAIT}</td>
                    <td>{data.Evalim_COPSEV}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert">Pas d'evalim connu</div>
          )}
        </div>
      )}

      {/* Div performances */}
      <div className="performances">
        <CheptelSectionTitle title={"Performance en Station"} />
        {data["povipe_PA"] ? (
          <div className="graph_gmq" id="perf_station">
            <img src={b64Graph} className="graph_perf" />
            <div className="line_x_axis">
              {labels.map((label, index) => (
                <div
                  key={index}
                  className={
                    haveP2
                      ? `xAxis_val haveP2 pos${index}`
                      : `xAxis_val pos${index}`
                  }
                >
                  {label}
                </div>
              ))}
            </div>
            <div className="line_billes">
              <div className="bille_gmq">
                {data.GMQ ? (
                  <>GMQ : {Math.floor(data.GMQ * 1000, 2)} g/j</>
                ) : (
                  "Animal"
                )}
              </div>
              <div className="bille_serie">
                {moyenneSerie?.GMQ ? (
                  <>Serie : {Math.floor(moyenneSerie?.GMQ * 1000, 2)} g/j</>
                ) : (
                  "Série"
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert">Pas de performances connues</div>
        )}
      </div>

      {/* Bottom message */}
      {/* <div className="bottom_message">
        Informations et données issues de l'application EspacePro © France
        Limousin Sélection
      </div> */}
    </div>
  )
}

export default FicheShareStation
