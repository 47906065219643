import { fetchLogError } from "./fetchLogError"
import { fetchLogin } from "./fetchLogin"
import { fetchAdmChep } from "./fetchAdmChep"
import localforage from "localforage"

export const fetchUrlEncoded = async ({
  url,
  formBody,
  fromLogin = false,
  fromLogError = false,
  falseOnError = true,
  noEmptyObjectOnError = false,
  signal = null,
}) => {
  const leFetch = async () => {
    let jsonResponse = {}

    try {
      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.JWT_TOKEN ?? ""}`,
        },
        body: formBody,
        signal: signal,
      })
        .then(async response => {
          if (response.status !== 200 && response.status !== 401) {
            if (!fromLogError)
              fetchLogError("erreur " + response.status, url + " | " + formBody)
            if (falseOnError) return false
            else return response.status
          }
          // If unauthorized, tries to log again if possible
          if (response.status === 401) {
            jsonResponse = "needReconnect"
          }
          if (response.ok) {
            return response.json()
          }
        })
        .then(json => {
          if (jsonResponse !== "needReconnect") jsonResponse = json
        })
        .catch(e => {
          if (e.name === "AbortError") jsonResponse = "downloadAborted"

          if (e.name !== "AbortError" && !fromLogError) {
            fetchLogError(e, url + " | " + formBody)
            if (noEmptyObjectOnError) jsonResponse = false
          }
        })

      if (jsonResponse && jsonResponse.error) {
        if (!fromLogError)
          fetchLogError(jsonResponse.error, url + " | " + formBody)
      }

      return jsonResponse
    } catch (error) {
      if (!fromLogError) fetchLogError(error, url + " | " + formBody)
    }
  }
  const tryReconnect = async () => {
    let a = window.localStorage.dataReturnA
    let b = window.localStorage.dataReturnB

    let dLog = await fetchLogin(a, b, "N", origin)

    if (dLog && dLog.reponse && dLog.reponse !== "ok") {
      localforage.setItem("login", false)

      const event = new CustomEvent("showAlert", {
        detail: {
          msg: "Vous avez été déconnecté. Veuillez vous reconnecter s.v.p.",
          callback: () => window.location.reload(),
        },
      })

      let root = document.getElementById("root")

      if (root) root.dispatchEvent(event)
    } else if (dLog) {
      window.localStorage.JWT_TOKEN = dLog.token

      let valA = window.localStorage.valA === "true" ? true : false
      let numCheptel = window.localStorage.numCheptel ?? ""

      if (valA && numCheptel) {
        let retAdmnChep = await fetchAdmChep(numCheptel, null, origin)
        if (retAdmnChep.token) {
          window.localStorage.JWT_TOKEN = retAdmnChep.token
          return true
        } else return false
      }

      return true
    }

    return false
  }

  let leFetchRes = await leFetch()

  if (leFetchRes === "needReconnect" && !fromLogin) {
    let tryReco = await tryReconnect()

    if (tryReco) leFetchRes = await leFetch()
  }

  return leFetchRes ?? false
}

export const fetchBlob = async url => {
  let blobReturned

  await fetch(url)
    .then(response => {
      if (response.status !== 200) {
        fetchLogError("erreur " + response.status, url)
        return false
      }
      if (response.ok) {
        return response.blob()
      }
    })
    .then(blob => (blobReturned = blob))
    .catch(e => fetchLogError(e, url))

  return blobReturned
}

export const fetchUploadBlob = async ({
  url,
  formData,
  fromLogin = false,
  fromLogError = false,
  falseOnError = true,
  signal = null,
}) => {
  const leFetch = async () => {
    let jsonResponse = {}

    try {
      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.JWT_TOKEN ?? ""}`,
        },
        body: formData,
        signal: signal,
      })
        .then(async response => {
          if (response.status !== 200 && response.status !== 401) {
            if (!fromLogError)
              fetchLogError("erreur " + response.status, url + " | ")
            if (falseOnError) return false
            else return response.status
          }
          // If unauthorized, tries to log again if possible
          if (response.status === 401) {
            jsonResponse = "needReconnect"
          }
          if (response.ok) {
            return response.json()
          }
        })
        .then(json => {
          if (jsonResponse !== "needReconnect") jsonResponse = json
        })
        .catch(e => {
          if (e.name === "AbortError") jsonResponse = "downloadAborted"

          if (e.name !== "AbortError" && !fromLogError)
            fetchLogError(e, url + " | ")
        })

      if (jsonResponse && jsonResponse.error) {
        if (!fromLogError) fetchLogError(jsonResponse.error, url + " | ")
      }

      return jsonResponse
    } catch (error) {
      if (!fromLogError) fetchLogError(error, url + " | ")
    }
  }
  const tryReconnect = async () => {
    let a = window.localStorage.dataReturnA
    let b = window.localStorage.dataReturnB

    let dLog = await fetchLogin(a, b, "N", origin)

    if (dLog && dLog.reponse && dLog.reponse !== "ok") {
      localforage.setItem("login", false)

      const event = new CustomEvent("showAlert", {
        detail: {
          msg: "Vous avez été déconnecté. Veuillez vous reconnecter s.v.p.",
          callback: () => window.location.reload(),
        },
      })

      let root = document.getElementById("root")

      if (root) root.dispatchEvent(event)
    } else if (dLog) {
      window.localStorage.JWT_TOKEN = dLog.token

      let valA = window.localStorage.valA === "true" ? true : false
      let numCheptel = window.localStorage.numCheptel ?? ""

      if (valA && numCheptel) {
        let retAdmnChep = await fetchAdmChep(numCheptel, null, origin)
        if (retAdmnChep.token) {
          window.localStorage.JWT_TOKEN = retAdmnChep.token
          return true
        } else return false
      }

      return true
    }

    return false
  }

  let leFetchRes = await leFetch()

  if (leFetchRes === "needReconnect" && !fromLogin) {
    let tryReco = await tryReconnect()

    if (tryReco) leFetchRes = await leFetch()
  }

  return leFetchRes ?? false
}

export const fetchBlobWithParams = async ({ url, formBody }) => {
  let blobReturned

  await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
  })
    .then(response => {
      if (response.status !== 200) {
        fetchLogError("erreur " + response.status, url)
        return false
      }
      if (response.ok) {
        return response.blob()
        // return response.text()
      }
    })
    // .then(text => console.log(text))
    .then(blob => (blobReturned = blob))
    .catch(e => fetchLogError(e, url))

  return blobReturned
}

export const blobToBase64 = blob => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export function b64toBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1])
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: "image/png" })
}
