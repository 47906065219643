import { useState } from "react"
import "./ScoresEvalim.scss"

const ScoresEvalim = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow =
    window.localStorage.showevalimficheani === "false" ? false : true
  const [show, setShow] = useState(savedShow)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showevalimficheani = !show
  }
  return (
    <div className="scoresevalim" id="div_scoresevalim">
      <h3 onClick={hideOrShow} className="ripple2">
        Scores génomiques EVALIM{" "}
        <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>

      {show && (
        <div id="scoresevalim">
          <div className="table-evalim">
            <table>
              <thead>
                <tr>
                  <th>FN</th>
                  <th>CR</th>
                  <th>DM</th>
                  <th>DS</th>
                  <th>FOS</th>
                  <th>AV</th>
                  <th>AL</th>
                  <th>OP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{animal.CIFNAI}</td>
                  <td>{animal.CCRSEV}</td>
                  <td>{animal.CDMSEV}</td>
                  <td>{animal.CDSSEV}</td>
                  <td>{animal.CFOSSE}</td>
                  <td>{animal.CAVELS}</td>
                  <td>{animal.CALAIT}</td>
                  <td>{animal.COPSEV}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="baseref">Base de référence: INGX-{animal.BASREF}</div>
        </div>
      )}
    </div>
  )
}

export default ScoresEvalim
