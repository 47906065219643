import { fetchUrlEncoded } from "./fetch"

// Function to login
export const fetchLoginSession = async (login, password, f) => {
  let url = `${process.env.REACT_APP_OLD_API_URL}ajax.php`
  let action = "login"

  let params = {
    a: action,
    l: login,
    p: password,
    f: f,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
