export const getDateWritten = date => {
  let formatedDate = ""

  let day = date.substring(6, 8)
  let month = date.substring(4, 6)
  let year = date.substring(0, 4)

  let dateObject = new Date(year, month - 1, day)
  let dayWeek = dateObject.getDay()

  switch (dayWeek) {
    case 1:
      formatedDate += "Lundi, " + day + " "
      break
    case 2:
      formatedDate += "Mardi, " + day + " "
      break
    case 3:
      formatedDate += "Mercredi, " + day + " "
      break
    case 4:
      formatedDate += "Jeudi, " + day + " "
      break
    case 5:
      formatedDate += "Vendredi, " + day + " "
      break
    case 6:
      formatedDate += "Samedi, " + day + " "
      break
    case 7:
      formatedDate += "Dimanche, " + day + " "
      break
    default:
      break
  }

  switch (month) {
    case "01":
      formatedDate += "janvier "
      break
    case "02":
      formatedDate += "février "
      break
    case "03":
      formatedDate += "mars "
      break
    case "04":
      formatedDate += "avril "
      break
    case "05":
      formatedDate += "mai "
      break
    case "06":
      formatedDate += "juin "
      break
    case "07":
      formatedDate += "juillet "
      break
    case "08":
      formatedDate += "aout "
      break
    case "09":
      formatedDate += "septembre "
      break
    case "10":
      formatedDate += "octobre "
      break
    case "11":
      formatedDate += "novembre "
      break
    case "12":
      formatedDate += "décembre "
      break

    default:
      break
  }

  formatedDate += year

  return formatedDate
}
