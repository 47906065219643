import { useRef } from "react"
import { goBack } from "../../functions/handleNavigation"
import { useCenterModal } from "../../hooks/useCenterModal"
import "./ModalCheptels.scss"
import ModalLoader from "../ModalLoader"
import { fetchUpdateCheptelsOp } from "../../functions/fetches/fetchUpdateCheptelsOp"
import { useCustomConfirm } from "../../hooks/useCustomConfrm"
import Confirm from "../Confirm"

const ModalCheptels = ({ setListeCheptels }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let listeCheptels = JSON.parse(localStorage.adherents ?? "[]")

  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const areaListe = useRef()

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const useResponse = response => {
    hideConfirm()

    if (response) validateList()
  }
  const validateList = async () => {
    let divModal = document.getElementById("loader_update_list")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let area = areaListe.current.value.trim()
    let liste = area.split("\n")

    let ret = await fetchUpdateCheptelsOp(liste)

    if (ret && !ret.error) {
      setListeCheptels(ret)
      window.localStorage.adherents = JSON.stringify(ret)

      document
        .getElementById("header")
        ?.dispatchEvent(new CustomEvent("emptySearchOp"))
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

    goBack()
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let baseListe = ""
  listeCheptels.forEach(cheptel => (baseListe += cheptel.fullnumchep + "\n"))

  return (
    <>
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={useResponse} />
      )}
      <ModalLoader id="loader_update_list" message="Validation en cours..." />

      <div className="modal_liste_cheptels_op" ref={divModal}>
        <div className="div_modal_header">
          <h3>Gestion de mes adhérents</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
        </div>

        <div className="modal_content">
          <textarea ref={areaListe} defaultValue={baseListe}></textarea>
        </div>

        <div className="div_bottom" id="div-bottom">
          <div className="div_button">
            <button
              className="button_validate pointer"
              onClick={() =>
                doConfirm(
                  "Vous vous engagez à accéder uniquement aux données des cheptels pour lesquels vous disposé du consentement à le faire. Toutes les modifications de votre liste sont archivées.<br><br>Voulez-vous enregistrer cette liste de cheptels ?"
                )
              }
            >
              Valider
            </button>
          </div>
        </div>
      </div>

      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalCheptels
