import "./Information.scss"
import logo_fls from "../assets/images/logo.png"
import logo_hbl from "../assets/images/logo_hbl.png"
import logo_interlim from "../assets/images/logo_interlim.png"
import logo_station_lanaud from "../assets/images/logo_station_lanaud.png"
import logo_ingeniomix from "../assets/images/logo_ingeniomix.png"
import { useState } from "react"

const Information = ({ message, structure }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let needSuite = message.length > 180 ? true : false

  const [showSuite, setShowSuite] = useState(false)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const getLogo = structure => {
    switch (structure) {
      case 1:
        return logo_fls
      case 2:
        return logo_hbl
      case 3:
        return logo_interlim
      case 4:
        return logo_station_lanaud
      case 5:
        return logo_ingeniomix

      default:
        break
    }
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div
      className="div_cadre_info"
      onClick={() => needSuite && setShowSuite(prev => !prev)}
    >
      <div className="div_header_info">
        <div className="bulle_avatar">
          <img src={getLogo(structure)} className="img_avatar_info" />
        </div>
        <p className="p_header_info">{message.nom_structure}</p>
        <span className="bulle_date_info">{message.dateCreationFr}</span>
      </div>
      <div className="bulle_content_info">
        {(needSuite && showSuite) || !needSuite
          ? message.message
          : message.message.substring(0, 180) + "..."}
      </div>
      {needSuite && !showSuite && (
        <div className="bulle_read_next">Lire la suite...</div>
      )}
    </div>
  )
}

export default Information
