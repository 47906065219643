import { useState } from "react"
import {
  statutBarColor,
  statutBarN1Color,
  statutBarN2Color,
} from "../functions/statutBarColor"

let tmpHeaderColor = null

export const useCustomConfirm = () => {
  const [confirm, setConfirm] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState("")

  const doConfirm = message => {
    tmpHeaderColor = document.querySelector('meta[name="theme-color"]').content

    if (tmpHeaderColor !== statutBarN1Color) {
      statutBarColor(statutBarN1Color)
    } else {
      statutBarColor(statutBarN2Color)
    }

    setConfirm(true)
    setMessageConfirm(message)
  }

  const hideConfirm = () => {
    if (tmpHeaderColor) statutBarColor(tmpHeaderColor)

    setConfirm(false)
  }

  return [confirm, hideConfirm, messageConfirm, doConfirm]
}
