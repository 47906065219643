import { easepick } from "@easepick/bundle"
import { AmpPlugin } from "@easepick/amp-plugin"
import { LockPlugin } from "@easepick/lock-plugin"
import { todayDate } from "../../functions/todayDate"
import "./InventaireFooter.scss"
import { useEffect, useRef, useState } from "react"
import { formatDate3 } from "../../functions/formatDate"
import Confirm from "../Confirm"
import { dateWritten } from "../../functions/dateWritten"
import Alert from "../Alert"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import { useCustomConfirm } from "../../hooks/useCustomConfrm"
import { css } from "../../assets/css_externe/css"
import {
  statutBarColor,
  statutBarDefaultColor,
} from "../../functions/statutBarColor"
import { goBack } from "../../functions/handleNavigation"

let actualDate

const InventaireFooter = ({ data, nbAnimals, sidePanel }) => {
  let ajd = todayDate("input")
  let date = window.localStorage.dataSuffix ?? ""
  date === "" && (date = ajd)

  let clDate = date === ajd ? "hideDate" : ""
  let affDate = date

  ///////////
  // HOOKS //
  ///////////
  const divFooter = useRef()

  const [datePicker, setDatePicker] = useState(null)
  const [saveDateInput, setSavedDateInput] = useState("")

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let numCheptel = window.localStorage.numCheptel ?? ""
    let dateOfLatestData =
      localStorage.getItem("dateOfLatestData" + numCheptel) ?? null

    let dataSuffix = window.localStorage.dataSuffix ?? null
    let defDate = new Date().getTime()
    if (dataSuffix) defDate = new Date(formatDate3(dataSuffix)).getTime()
    else if (dateOfLatestData) defDate = +dateOfLatestData

    let maxDate = new Date().getTime()

    actualDate = defDate

    const picker = new easepick.create({
      element: "#dateInventaire",
      css: css,
      zIndex: 10,
      lang: "fr-FR",
      format: "DD-MM-YYYY",
      date: defDate,
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
        },
        darkMode: false,
      },
      LockPlugin: {
        maxDate: maxDate,
      },
      plugins: [LockPlugin, AmpPlugin],
    })

    picker.on("select", e => {
      onChangeDate(picker)
    })

    divFooter.current.addEventListener("closePicker", e => {
      picker.hide()
    })
  }, [])
  ///////////////
  // FUNCTIONS //
  ///////////////
  const onChangeDate = picker => {
    let dateFormatInp = document.getElementById("dateInventaire").value
    let dateInput = dateFormatInp.replaceAll("/", "-")

    let displayInput = document.getElementById("displayDateInventaire")
    displayInput.value = dateFormatInp

    let affDate = dateInput

    var decoupe = dateInput.split("-")
    dateInput = decoupe[2] + "-" + decoupe[1] + "-" + decoupe[0]
    let dateEcrite = dateWritten(affDate)

    var dateJour = new Date()

    let mydate = new Date(dateInput)

    if (mydate > dateJour) {
      doAlert("La date doit être antérieur ou égal à la date du jour")
    } else {
      setDatePicker(picker)
      doConfirm(
        `Etes-vous sur de vouloir aller à l'inventaire <br>du ${dateEcrite}?`
      )
      setSavedDateInput(affDate)
    }
  }
  const giveConfirmGoDate = confirmed => {
    hideConfirm()

    if (confirmed) {
      statutBarColor(statutBarDefaultColor)

      actualDate = document.getElementById("dateInventaire").value

      document.getElementById("app")?.dispatchEvent(
        new CustomEvent("changeDate", {
          detail: {
            date: saveDateInput,
          },
        })
      )
    } else {
      statutBarColor(statutBarDefaultColor)

      const event = new CustomEvent("hideDateBg")

      let divInv = document.getElementById("div_inventaire")

      if (divInv) divInv.dispatchEvent(event)

      datePicker?.setDate(actualDate)
    }

    goBack()
  }
  const clickDateInp = () => {
    window.location.hash = "inventaire_pickdate"
  }
  const spanClassName = () => {
    let clSpan = ""

    if (clDate === "") {
      clSpan += " a_date"

      if (nbAnimals !== data.nbAnimal) clSpan += " diff_nb_animals"
    }

    return clSpan
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={giveConfirmGoDate} />
      )}

      <div
        ref={divFooter}
        id="inventaire_footer"
        className={
          sidePanel
            ? "inventaire_footer with_side_filters"
            : `inventaire_footer`
        }
      >
        <span className={spanClassName()}>
          {nbAnimals === data.nbAnimal ? (
            <>
              {(() => {
                let ret = ""

                if (data.nbAnimal === 0) ret = `Aucun animal à votre inventaire`
                else if (data.nbAnimal === 1)
                  ret = `${data.nbAnimal} animal à votre inventaire`
                else ret = `${data.nbAnimal} animaux à votre inventaire`

                if (date !== ajd) ret += " au"

                return ret
              })()}
            </>
          ) : (
            <div className="div_nbani_footer">
              <p>
                {(() => {
                  let ret = ""

                  if (nbAnimals === 0) ret = `Aucun animal dans la liste`
                  else if (nbAnimals === 1)
                    ret = `${nbAnimals} animal dans la liste`
                  else ret = `${nbAnimals} animaux dans la liste`

                  return ret
                })()}
              </p>
              <p className="ani_inventaire">
                {(() => {
                  let ret = ""

                  if (data.nbAnimal === 0)
                    ret = `(Aucun animal à votre inventaire)`
                  else if (data.nbAnimal === 1)
                    ret = `(${data.nbAnimal} animal à votre inventaire)`
                  else ret = `(${data.nbAnimal} animaux à votre inventaire)`

                  if (date !== ajd) ret += " au"

                  return ret
                })()}
              </p>
            </div>
          )}

          <input
            id="displayDateInventaire"
            name="dateInventaire"
            value={affDate}
            onClick={clickDateInp}
            className={clDate}
            readOnly={true}
          />
        </span>
      </div>
      <div className={`div_datepicker`}>
        <input id="dateInventaire" name="dateInventaire" />
      </div>
    </>
  )
}

export default InventaireFooter
