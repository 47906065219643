export const statutBarDefaultColor = "#eaedf9"
export const statutBarN1Color = "#8C8E95"
export const statutBarN2Color = "#545559"
export const statutBarN3Color = "#353637"

export const statutBarColor = color => {
  document.querySelector('meta[name="theme-color"]').content = color

  if ("ReactNativeWebView" in window) {
    let message = {
      type: "statutBarColor",
      color: color,
    }

    let stringified = JSON.stringify(message)

    window.ReactNativeWebView.postMessage(stringified)
  }
}
