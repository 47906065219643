import { Fragment, useEffect, useRef, useState } from "react"
import "./ModalRankings.scss"
import { updateGameRankings } from "../functions/updateGameRankings"
import { useCenterModal } from "../hooks/useCenterModal"
import { dateWritten2 } from "../functions/dateWritten"
import Loader from "./Loader"
import goldTrophy from "../assets/images/gold_trophy.png"
import silverTrohpy from "../assets/images/silver_trophy.png"
import bronzeTrophy from "../assets/images/bronze_trophy.png"
import { goBack } from "../functions/handleNavigation"

const ModalRankings = ({}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const divModal = useRef()
  const divRankings = useRef()
  useCenterModal(divModal)

  const [rankings, setRankings] = useState(null)
  const [dateRanking, setDateRanking] = useState("")
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    getRankings()

    let curr = divModal.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeightRankings = 72 + addHeight

      curr.style.height = `calc(calc(var(--vh) * 96) - ${addHeight}px)`

      if (divRankings?.current?.style)
        divRankings.current.style.height = `calc(calc(var(--vh) * 96) - ${newHeightRankings}px)`

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = curr.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      curr.style.top = `${topHeight}px`
    }
  }, [])
  useEffect(() => {
    let curr = divModal.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeightRankings = 72 + addHeight

      curr.style.height = `calc(calc(var(--vh) * 96) - ${addHeight}px)`

      if (divRankings?.current?.style)
        divRankings.current.style.height = `calc(calc(var(--vh) * 96) - ${newHeightRankings}px)`

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = curr.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      curr.style.top = `${topHeight}px`
    }
  }, [rankings])
  ///////////////
  // Functions //
  ///////////////
  const getRankings = async () => {
    let ranks = await updateGameRankings()
    setRankings(ranks)

    let date = localStorage.rankingMonth ?? null

    if (!date) {
      let today = new Date()
      let month = today.getMonth() + 1
      if (month < 10) month = "0" + month
      let year = today.getFullYear()
      date = month + "-" + year
    }

    setDateRanking(dateWritten2(date))
  }
  return (
    <>
      <div className="modal_game_rankings" ref={divModal}>
        {!rankings ? (
          <>
            <span
              className="close_modal ripple2 pointer material-symbols-outlined"
              onClick={goBack}
            >
              close
            </span>
            <div className="loading_rankings">
              <Loader loadingMessage="Chargement en cours..;" />
            </div>
          </>
        ) : (
          <>
            <div className="div_modal_header">
              <span
                className="close_modal ripple2  material-symbols-outlined"
                onClick={goBack}
              >
                close
              </span>
              <h3>Classement de {dateRanking}</h3>
            </div>
            <div className="div_rankings_wrapper" ref={divRankings}>
              {rankings.map((rank, index) => (
                <div
                  className="line_ranking"
                  key={rank.utilisateur + rank.highScore}
                >
                  <div className="ranking_figure">
                    {index === 0 ? (
                      <img className="ranking_trophy" src={goldTrophy} />
                    ) : index === 1 ? (
                      <img className="ranking_trophy" src={silverTrohpy} />
                    ) : index === 2 ? (
                      <img className="ranking_trophy" src={bronzeTrophy} />
                    ) : (
                      index + 1
                    )}
                  </div>
                  <div className="ranking_user">{rank.utilisateur}</div>
                  <div className="ranking_score">{rank.highScore}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalRankings
