import { useEffect, useRef } from "react"
import "./ModalConcours.scss"
import { useCenterModal } from "../../hooks/useCenterModal"
import { goBack } from "../../functions/handleNavigation"

const ModalConcours = ({ concours, callback }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let divModal = useRef()
  let divList = useRef()
  useCenterModal(divModal)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divList.current
    let div = divModal.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = 136 + addHeight

      curr.style.maxHeight = `calc(calc(var(--vh) * 96) - ${newHeight}px)`

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = div.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      div.style.top = topHeight + "px"
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div ref={divModal} className="modal_concours">
        <div className="div_choices_input" ref={divList}>
          {concours.map((line, i) => (
            <p
              key={i}
              className="ripple2 pointer"
              onClick={() => callback(line.id, line.titre)}
            >
              {line.titre}
            </p>
          ))}
        </div>
        <div className="div_button_input_choices">
          <button className="ripple2 pointer" onClick={goBack}>
            Annuler
          </button>
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalConcours
