import "./ModalResultDeclar.scss"
import { useRef } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack, goBackXAmount } from "../../../functions/handleNavigation"

const ModalResultDeclar = ({ libelleSerie, messageDeclaration, origin }) => {
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const altGoBack = () => {
    if (origin === "animal") goBackXAmount(2)
    else goBack()
  }

  return (
    <>
      <div ref={divModal} className="modal2_evalim_lanaud">
        <div className="div_modal_header">
          <span
            onClick={altGoBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <h3>
            Récapitulatif de votre déclaration pour la série {libelleSerie}
          </h3>
        </div>
        <div className="modal-content">
          <div
            className="modal-message"
            dangerouslySetInnerHTML={messageDeclaration}
          ></div>
        </div>
      </div>
      <button className={"background-modal"} onClick={altGoBack}></button>
    </>
  )
}

export default ModalResultDeclar
