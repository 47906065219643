import React, { useEffect, useState } from "react"
import "./reset.scss"
import "./App.scss"
import "./functions/global"
import Template from "./templates/DefaultTemplate"
import localforage from "localforage"
import Loader from "./components/Loader"
import { fetchChangeLogs } from "./functions/fetches/fetchChangeLogs"
import { fetchLogConnec } from "./functions/fetches/fetchLogConnec"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { fetchSaveFb } from "./functions/fetches/fetchSaveFb"
import icon from "./assets/images/nlogo48.png"
import Alert from "./components/Alert"
import { useCustomAlert } from "./hooks/useCustomAlert"
import { fetchAppParameters } from "./functions/fetches/fetchAppParameters"
import Confirm from "./components/Confirm"
import { useCustomConfirm } from "./hooks/useCustomConfrm"
import { fetchLogAction } from "./functions/fetches/fetchLogAction"
import { v4 as uuidv4 } from "uuid"
import { getDeviceData } from "./functions/getDeviceData"
import {
  statutBarColor,
  statutBarDefaultColor,
  statutBarN1Color,
  statutBarN2Color,
} from "./functions/statutBarColor"
import { indepedentRoutes, listRoutes } from "./routes"
import { checkAppParamsIntegrity } from "./functions/checkAppParamsIntegrity"
import { fetchMessagesAlert } from "./functions/fetches/fetchMessagesAlert"

let page

const App = () => {
  //////////////////////////////////////
  // APP INITIALIZATION SPECIFIC CODE //
  //////////////////////////////////////
  let valA = window.localStorage.valA === "true" ? true : false
  let valO = window.localStorage.valO === "true" ? true : false
  // Set app version in cache (method is probably improvable)
  window.localStorage.app_version = "0.052"
  // Use this to force reset of cache data if breaking changes are done
  let internalVersion = "0.004"
  // Use this to force reset of cache data if breaking changes are done
  let opInternalVersion = "0.001"
  let adminInternalVersion = "0.001"
  window.localStorage.adminInternalVersion = adminInternalVersion // TO DELETE WHEN ACTIVATING THE ADMIN VERSIONING
  // Set native app version
  let cacheNativeAppVersion = +(window.localStorage.nativeAppVersion ?? "0")
  // Use this to force reset of cache data if breaking changes are done
  let nativeAppVersion = 3

  // If not UUID yet, generate and set one
  if (!window.localStorage.uuid) {
    window.localStorage.uuid = uuidv4()
  }

  // If internal versions isnt the correct one, reset the app and set it correctly
  if (window.localStorage.internalVersion !== internalVersion) {
    localforage.keys().then(async keys => {
      for (let indexKey in keys) {
        await localforage.removeItem(keys[indexKey])
      }

      let uuid = window.localStorage.uuid

      window.location.href = ""
      window.localStorage.clear()
      window.localStorage.internalVersion = internalVersion
      window.localStorage.opInternalVersion = opInternalVersion
      window.localStorage.adminInternalVersion = adminInternalVersion
      window.localStorage.uuid = uuid
      window.location.reload()
    })
  }
  // If op internal versions isnt the correct one, reset the app and set it correctly
  if (valO && window.localStorage.opInternalVersion !== opInternalVersion) {
    localforage.keys().then(async keys => {
      for (let indexKey in keys) {
        await localforage.removeItem(keys[indexKey])
      }

      let uuid = window.localStorage.uuid

      window.location.href = ""
      window.localStorage.clear()
      window.localStorage.internalVersion = internalVersion
      window.localStorage.opInternalVersion = opInternalVersion
      window.localStorage.adminInternalVersion = adminInternalVersion
      window.localStorage.uuid = uuid
      window.location.reload()
    })
  }
  // If admin internal versions isnt the correct one, reset the app and set it correctly
  // if (
  //   valA &&
  //   window.localStorage.adminInternalVersion !== adminInternalVersion
  // ) {
  //   localforage.keys().then(async keys => {
  //     for (let indexKey in keys) {
  //       await localforage.removeItem(keys[indexKey])
  //     }

  //     let uuid = window.localStorage.uuid

  //     window.location.href = ""
  //     window.localStorage.clear()
  //     window.localStorage.internalVersion = internalVersion
  //     window.localStorage.opInternalVersion = opInternalVersion
  //     window.localStorage.adminInternalVersion = adminInternalVersion
  //     window.localStorage.uuid = uuid
  //     window.location.reload()
  //   })
  // }

  // If on data of a specific date for more than 24hours, just go back to latest data in app
  // It's here to avoid having a user not realizing he is on former data and using it every day
  let tsDataSuffix = +window.localStorage.tsDataSuffix ?? 0
  let dataSuffix = window.localStorage.dataSuffix ?? ""

  if (Date.now() - tsDataSuffix > 24 * 60 * 60 * 1000 && dataSuffix !== "") {
    window.localStorage.dataSuffix = ""
  }

  // We dont support android version lower than 7.1.1 due to certificate issue
  // On older version, our website certificate is invalid because the files
  // for it to be trusted arent up to date anymore
  // This causes our website to not be HTTPS wich make things not work
  let isIncompatibleAndroid = false
  let device = getDeviceData()
  let versionNbs = device.osVersion.split(".")

  if (device.os === "Android") {
    if (+versionNbs[0] < 7) isIncompatibleAndroid = true
    else if (+versionNbs[0] === 7) {
      if (+versionNbs[1] < 1) isIncompatibleAndroid = true
      else if (+versionNbs[1] === 1) {
        if (+versionNbs[2] < 1) isIncompatibleAndroid = true
      }
    }
  }

  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let numChep = window.localStorage.numCheptel ?? ""

  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()
  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()
  const [isLogged, setIsLogged] = useState(null)
  const [hashOnGoing, setHashOnGoing] = useState(null) // to have a render even if not used

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    // Send data to Native APP
    if ("ReactNativeWebView" in window) {
      let message = {
        type: "onLoadPWA",
        device: JSON.stringify(device),
        jwt: window.localStorage.JWT_TOKEN ?? "",
      }

      let stringified = JSON.stringify(message)

      window.ReactNativeWebView.postMessage(stringified)
    }

    const getMessagesAlert = async () => {
      let messagesAlert = await fetchMessagesAlert()

      if (messagesAlert)
        window.localStorage.messagesAlert = JSON.stringify(messagesAlert)
      else messagesAlert = JSON.parse(window.localStorage.messagesAlert ?? "[]")

      if (messagesAlert && messagesAlert?.length > 0) {
        messagesAlert.forEach(message => {
          let now = Date.now()
          let dateDebut = new Date(message.dateDebut)
          let dateFin = null
          if (message.dateFin) dateFin = new Date(message.dateFin)

          if (+dateDebut < now && (!dateFin || +dateFin > now))
            doAlert(message.message)
          // console.log("ici")
        })
      }
    }
    const getChangeLogs = async () => {
      let changeLogs = await fetchChangeLogs()

      window.localStorage.changeLogs = JSON.stringify(changeLogs)
    }
    const getParams = async () => {
      let appParams = await fetchAppParameters()

      if (checkAppParamsIntegrity(appParams))
        window.localStorage.appParams = JSON.stringify(appParams ?? "{}")
    }
    const logConnec = async () => {
      let lastLog = localStorage.lastLog ?? "0"
      let personne = window.localStorage.dataReturnA
      lastLog = +lastLog
      let now = Date.now()

      if (now - lastLog > 60 * 60 * 1000 && personne) {
        let ret2 = await fetchLogConnec("R")

        if (ret2 === "true") localStorage.lastLog = now
      }
    }

    getMessagesAlert()
    getChangeLogs()
    getParams()
    logConnec()

    let root = document.getElementById("root")

    root.addEventListener("showAlert", e => {
      setCallback(e.detail.callback)
      doAlert(e.detail.msg)
    })

    // Cheptel page is first page we see if connected and app reloaded
    // We reset the variables on app reload so we dont end up with an hybrid state
    // of app reloaded but we still scroll to previous position
    window.localStorage.scrollXPageCheptel = 0
    window.localStorage.scrollYPageCheptel = 0
  }, [])
  useEffect(() => {
    let hash = window.location.hash
    let hVals = hash.split("|")
    let pageName = hVals[0].substring(1)

    window.addEventListener("hashchange", locationHashChanged)

    if (indepedentRoutes.includes(pageName)) {
      page = pageName
      setHashOnGoing(hash)
    } else if (isLogged) {
      valA = window.localStorage.valA === "true" ? true : false
      valO = window.localStorage.valO === "true" ? true : false
      numChep = window.localStorage.numCheptel ?? ""

      if (window.location.hostname !== "localhost") {
        if (
          "Notification" in window &&
          Notification.permission !== "granted" &&
          Notification.permission !== "denied"
        ) {
          doConfirm(
            "Les notifications sont importantes pour le bon fonctionnement de votre EspacePro. Veuillez les accepter s.v.p!"
          )
        } else if (
          "Notification" in window &&
          Notification.permission === "granted"
        ) {
          handleNotifications()
        }
      }

      if (isPageNameValid(pageName)) {
        page = pageName
        setHashOnGoing(hash)
      } else {
        if (valA && !numChep) window.location.hash = "admin"
        else if (valO && !numChep) window.location.hash = "op"
        else window.location.hash = "cheptel"
      }
    } else if (isLogged === false) {
      if (pageName === "login_reinit") {
        page = "login_reinit"
        setHashOnGoing("#login_reinit")
      } else if (pageName === "login_register") {
        page = "login_register"
        setHashOnGoing("#login_register")
      } else {
        page = "login"
        setHashOnGoing("#login")
      }
    }
  }, [isLogged])
  ///////////////
  // Functions //
  ///////////////
  // Check if page name is a valid one
  const isPageNameValid = pageName => listRoutes.includes(pageName)
  // Method on hash change
  const locationHashChanged = () => {
    ////////////////////////////////////////////////////////////
    // SPECIFIC CODE TO MAINTAIN NAVIGATION LIKE A NATIVE APP //
    ////////////////////////////////////////////////////////////
    // NEEDED to keep app like navigation if you search from fiche animal
    if (localStorage.needGoSearch === "goSearch") {
      localStorage.needGoSearch = ""
      window.location.hash = "inventaire_search"
      return
    }
    // NEEDED to keep app like navigation if you change cheptel from op
    if (localStorage.needGoListAdhrents === "goListeAdhrents") {
      localStorage.needGoListAdhrents = ""
      window.location.hash = "adherentsop"
      return
    }

    // NEEDED to keep app like navigation when you ask for data refresh on inventaire page
    // We go back to remove the 3dots menu from the history and have this logic to automatically
    // go to the correct hash that will show the dl process modal
    if (localStorage.needGoStatuDl === "goStatutDl") {
      localStorage.needGoStatuDl = ""
      window.location.hash = "inventaire_statutdl"
    }

    ////////////////////////////////////////
    // code to handle locationHashChanged //
    ////////////////////////////////////////
    let hash = window.location.hash
    let hVals = hash.split("|")
    let pageName = hVals[0].substring(1)

    if (pageName === "inventaire") {
      localStorage.firstSearchAniMobile = ""
      window.localStorage.scrollObjectFicheAni = "{}"
    }

    if (
      pageName !== "admin_changelog" &&
      pageName !== "op_changelog" &&
      pageName !== "op_exports" &&
      pageName !== "op_declarationvente" &&
      pageName !== "adherentsop_cheptels" &&
      pageName !== "cheptel_changelog" &&
      pageName !== "cheptel_statutdl" &&
      pageName !== "cheptel_recap" &&
      pageName !== "cheptel_details" &&
      pageName !== "cheptel_pdfs" &&
      pageName !== "supprcompte_reinit" &&
      pageName !== "login_reinit" &&
      pageName !== "login_register" &&
      pageName !== "game_rankings" &&
      pageName !== "station_statutdl" &&
      pageName !== "fichestation_sharing" &&
      pageName !== "fichestation_video" &&
      pageName !== "fichestation_photo" &&
      pageName !== "visites_filter" &&
      pageName !== "visites_recap" &&
      pageName !== "visites_details" &&
      pageName !== "visites_statutdl" &&
      pageName !== "inventaire_statutdl" &&
      pageName !== "inventaire_pickdate" &&
      pageName !== "inventaire_listeCertifs" &&
      pageName !== "inventaire_filter" &&
      pageName !== "inventaire_series" &&
      pageName !== "inventaire_evalim" &&
      pageName !== "inventaire_filiation" &&
      pageName !== "inventaire_erreurs" &&
      pageName !== "inventaire_goconfirm" &&
      pageName !== "inventaire_declarations" &&
      pageName !== "inventaire_erreursconf" &&
      pageName !== "gestioninv_addinv" &&
      pageName !== "gestioncolumns_addcol" &&
      pageName !== "gestioncolumns_settings" &&
      pageName !== "customfilters" &&
      pageName !== "animal_photo" &&
      pageName !== "animal_sharing" &&
      pageName !== "animal_parcours" &&
      pageName !== "animal_camera" &&
      pageName !== "animal_confirm" &&
      pageName !== "animal_declar" &&
      pageName !== "animal_pere" &&
      pageName !== "animal_mere"
    ) {
      statutBarColor(statutBarDefaultColor)
    } else {
      statutBarColor(statutBarN1Color)

      switch (pageName) {
        case "cheptel_recap":
        case "cheptel_details":
        case "visites_recap":
        case "visites_details":
          if (window.innerWidth < 770) statutBarColor(statutBarDefaultColor)

          if (pageName === "visites_recap" || pageName === "cheptel_recap") {
            let paramsListe = window.location.hash.split("|")[2] ?? ""

            if (
              paramsListe === "askPere" ||
              paramsListe === "liste" ||
              paramsListe === "peres"
            ) {
              if (window.innerWidth < 770) statutBarColor(statutBarN1Color)
              else {
                statutBarColor(statutBarN2Color)
              }
            }
          }
          if (
            pageName === "visites_details" ||
            pageName === "cheptel_details"
          ) {
            let paramsListe = window.location.hash.split("|")[3] ?? ""

            if (paramsListe === "liste" || paramsListe === "peres") {
              if (window.innerWidth < 770) statutBarColor(statutBarN1Color)
              else statutBarColor(statutBarN2Color)
            }
          }
          break
        case "customfilters":
          if (!hVals[2]) statutBarColor(statutBarDefaultColor)
          break
        case "inventaire_filter":
          if (hVals[2]) statutBarColor(statutBarN2Color)
          break
        default:
          break
      }
    }

    if (indepedentRoutes.includes(pageName)) {
      page = pageName
      setHashOnGoing(hash)
    } else if (isLogged) {
      if (isPageNameValid(pageName)) {
        page = pageName
        setHashOnGoing(hash)
      } else {
        if (valA && !numChep) window.location.hash = "admin"
        else if (valO && !numChep) window.location.hash = "op"
        else window.location.hash = "cheptel"
      }
    } else if (isLogged === false) {
      if (pageName === "login_reinit") {
        page = "login_reinit"
        setHashOnGoing("#login_reinit")
      } else if (pageName === "login_register") {
        page = "login_register"
        setHashOnGoing("#login_register")
      } else {
        page = "login"
        setHashOnGoing("#login")
      }
    }
  }
  // Set notified
  const setNotified = async (idMessage, user) => {
    let params = {
      a: "setNotified",
      m: idMessage,
      u: user,
    }

    const formBody = Object.keys(params)
      .map(
        key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&")

    await fetch(`${process.env.REACT_APP_API_URL}ajax_pwa.php`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Accept: "application/json",
      },
      body: formBody,
    })
      .then(response => {
        if (response.status !== 200) {
          console.log("Notif recu pb, statut: ", response.status)
          return false
        }

        if (response.ok) {
          return true
        }
      })
      .catch(error => {
        console.log("Notif recu pb: ", error)
      })
  }
  // Handle the logic to make notifications work
  // BackgroundMessage listener is in serviceWorker
  const handleNotifications = () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

    navigator.serviceWorker.register(swUrl).then(reg => {
      const firebaseConfig = {
        apiKey: "AIzaSyBI2dm72Pz0M2ec5g0mVaWl0Qr_XjaiEH0",
        authDomain: "appespacepro.firebaseapp.com",
        projectId: "appespacepro",
        storageBucket: "appespacepro.appspot.com",
        messagingSenderId: "277169883149",
        appId: "1:277169883149:web:c9eed637f153d75cd8ed44",
      }

      // Initialize Firebase

      const app = initializeApp(firebaseConfig)
      const messaging = getMessaging(app)

      getToken(messaging, { serviceWorkerRegistration: reg }).then(
        currToken => {
          fetchSaveFb(currToken)
        }
      )

      onMessage(messaging, payload => {
        if (payload.data.personne === window.localStorage.personne) {
          let message = payload.data.msg
          let user = payload.data.user
          let idMsg = payload.data.idMsg

          setNotified(idMsg, user)

          // Customize notification here
          const notificationTitle = "Nouveau message"
          const notificationOptions = {
            body: message,
            icon: icon,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            requireInteraction: true,
          }

          return reg.showNotification(notificationTitle, notificationOptions)
        }
      })
    })

    navigator.serviceWorker.onmessage = async message => {
      if (message.data === "goMessage") {
        window.location.hash = "messagerie"
      }
    }
  }
  // Method to handle user accepting our confirm
  // to ask for notifications authorization
  const notifConfirm = reponse => {
    hideConfirm()

    if (reponse) {
      "Notification" in window &&
        Notification.requestPermission().then(permission => {
          let action =
            permission === "granted"
              ? "notif_accepte"
              : permission === "denied"
              ? "notif_refuse"
              : "notif_nodata"
          fetchLogAction(action)

          handleNotifications()
        })
    }
  }
  const getCorrectStoreName = () => {
    let storeName = "le store"

    if (window.localStorage.platformNative === "android")
      storeName = "le Play Store"
    if (window.localStorage.platformNative === "ios") storeName = "l'App Store"

    return storeName
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  if (valA && !numChep && page === "cheptel") window.location.hash = "admin"
  if (valA && !numChep && page === "loadata") window.location.hash = "admin"
  if (valA && numChep && page === "admin") window.location.hash = "cheptel"
  if (!valA && valO && page === "admin") window.location.hash = "op"
  if (valO && !numChep && page === "cheptel") window.location.hash = "op"
  if (valO && !numChep && page === "loadata") window.location.hash = "op"
  if (valO && numChep && page === "op") window.location.hash = "cheptel"

  if (page !== "animal") {
    localStorage.failedAniDl = ""
    localStorage.listeHistoAni = "[]"
  }

  localforage.getItem("login", (err, value) => {
    if (value && !isLogged) {
      setIsLogged(value)
    } else if (isLogged === null) {
      setIsLogged(false)
    }
  })

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={notifConfirm} />
      )}
      {(() => {
        // IF APP IS ON AN UNSUPPORTED ANDROID VERSION < 7.1.1
        // ITS UNSUPORTED AS CERTIFICATE WONT ALLOW THIS WEBSITE TO BE HTTPS
        // THEY ARENT UPDATED SO THE PWA WONT WORK
        if (isIncompatibleAndroid) {
          return (
            <div className="no_install_div_app">
              <p className="pls_install">
                La version d'Android sur votre appareil est trop ancienne et
                n'est pas compatible avec l'application EspacePro.
              </p>
            </div>
          )
        }

        // Check if app is ran on the correct native app version
        // It's a safety in place if we ever have to make breaking changes
        // So we at least have a fallback page telling to go update in the store
        // Instead of having crashes, etc...
        if ("ReactNativeWebView" in window) {
          if (+cacheNativeAppVersion < nativeAppVersion)
            return (
              <div className="no_install_div_app">
                <p className="pls_install">
                  Veuillez mettre à jour l'application sur{" "}
                  {getCorrectStoreName()} pour pouvoir continuer à utiliser
                  votre EspacePro.
                </p>
              </div>
            )
          if (cacheNativeAppVersion > nativeAppVersion)
            return (
              <div className="no_install_div_app">
                <p className="pls_install">
                  Veuillez attendre et accepter la mise à jour de votre
                  application qui devrait arriver sur cet écran d'ici quelques
                  instants.
                </p>
              </div>
            )
        }

        // BASE CODE THAT JUST GOES TO THE APP, BASIC RETURN
        return (
          <>
            {isLogged !== null && page ? (
              <Template page={page} />
            ) : (
              <div className="app_loading">
                <Loader />
                <p>Chargement</p>
              </div>
            )}
          </>
        )
      })()}
    </>
  )
}

export default App
