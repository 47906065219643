import { fetchUrlEncoded } from "./fetch"

// Function to login
export const fetchSaveCustInv = async custinv => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "saveCustomInvs"

  let params = {
    a: action,
    u: localStorage.personne,
    c: custinv,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  let returned = await fetchUrlEncoded({
    url: url,
    formBody: formBody,
    fromLogError: false,
  })

  if (returned !== "true") {
    window.localStorage.resendCustomInv = "true"
  }

  return returned
}
