import "./ModalAskDeclaration.scss"
import { useRef } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import Alert from "../../Alert"
import { useCustomAlert } from "../../../hooks/useCustomAlert"
import { goBack, goBackXAmount } from "../../../functions/handleNavigation"

const ModalAskDeclaration = ({
  aniToDeclare,
  libelleSerie,
  optionsDeclCochees,
  cocheOptionsDeclar,
  validateDeclarations,
  origin,
}) => {
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()

  const altGoBack = () => {
    if (origin === "animal") goBackXAmount(2)
    else goBack()
  }

  if (libelleSerie === "" && !alert) {
    doAlert("Aucune série de déclaration disponible pour cet animal.")
    setCallback(() => goBackXAmount(2))
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {libelleSerie !== "" && (
        <>
          <div ref={divModal} className="modal_evalim_lanaud">
            <div className="div_modal_header">
              <span
                onClick={altGoBack}
                className="close_modal ripple2  material-symbols-outlined"
              >
                close
              </span>
              <h3>
                Récapitulatif de votre déclaration pour la série {libelleSerie}
              </h3>
            </div>
            <div className="coche_souhaitee">Cochez les options souhaitées</div>
            <div className="modal-content">
              <div className="div-table">
                <table>
                  <thead>
                    <tr className="header-evalim">
                      <th>Numéro</th>
                      <th>Nom</th>
                      <th>Date de naissance</th>
                      <th>Test Evalim - 60€ HT*</th>
                      <th>Prélevé par technicien d'IA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aniToDeclare?.map(animal => (
                      <tr key={animal.numAnimal}>
                        <td>{animal.numAnimal}</td>
                        <td>{animal.nom}</td>
                        <td>{animal.datenaissance}</td>
                        <td>
                          {" "}
                          <div
                            className={
                              optionsDeclCochees[animal.numAnimal]?.evalim === 1
                                ? "coche on"
                                : "coche"
                            }
                            onClick={e =>
                              cocheOptionsDeclar(e, animal.numAnimal, "evalim")
                            }
                          ></div>
                        </td>
                        <td>
                          {" "}
                          <div
                            className={
                              optionsDeclCochees[animal.numAnimal]?.techIa === 1
                                ? "coche on"
                                : "coche"
                            }
                            onClick={e =>
                              cocheOptionsDeclar(e, animal.numAnimal, "techIa")
                            }
                          ></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="div-bottom" id="div-bottom">
              <p>
                *Tout test EVALIM demandé par l'éleveur lui sera facturé 60 € HT
                / veau. <br />
                Tests MH, Sans Cornes, Protoporphyrie, Blind et Palais fendu
                inclus, index Iboval génomique uniquement sur les veaux retenus
              </p>
              <div className="div-button">
                <button
                  className="button-validate"
                  onClick={validateDeclarations}
                >
                  valider ma déclaration
                </button>
              </div>
            </div>
          </div>
          <button className={"background-modal"} onClick={altGoBack}></button>
        </>
      )}
    </>
  )
}

export default ModalAskDeclaration
