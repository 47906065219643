import { useState } from "react"
import DinoGame from "../dinogame/DinoGame"
import "./PageGame.scss"
import ModalRankings from "../components/ModalRankings"

const PageGame = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [clRankings, setClRankings] = useState(false)
  pageOption === "rankings" && !clRankings && setClRankings(true)
  pageOption !== "rankings" && clRankings && setClRankings(false)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////

  return (
    <>
      <div className="page_dino_game">
        <DinoGame />
      </div>
      {clRankings && <ModalRankings />}
    </>
  )
}

export default PageGame
