export const goBack = () => {
  // if ("ReactNativeWebView" in window) {
  //   let message = {
  //     type: "goBack",
  //   }

  //   let stringified = JSON.stringify(message)

  //   window.ReactNativeWebView.postMessage(stringified)

  //   return
  // }

  window.history.back()
}

export const goBackXAmount = nb => {
  // if ("ReactNativeWebView" in window) {
  //   let message = {
  //     type: "goBackXAmount",
  //     amount: nb,
  //   }

  //   let stringified = JSON.stringify(message)

  //   window.ReactNativeWebView.postMessage(stringified)

  //   return
  // }

  window.history.go(-nb)
}
