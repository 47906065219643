import { useEffect, useRef, useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./FicheStationHeader.scss"
import { formatDate2 } from "../../functions/formatDate"
import { getRFQUAL } from "../../functions/objectColums"
import ModalPlayer from "../ModalPlayer"
import Loader from "../Loader"
import { todayDate } from "../../functions/todayDate"
import ModalPhoto from "../ModalPhoto"
import { goToHashWithParams } from "../../functions/hash"

const FicheStationHeader = ({ data, pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  const divImgStation = useRef()
  const divLeftPart = useRef()
  const cardAnimal = useRef()
  const firstHalf = useRef()
  const imgPhoto = useRef()

  let urlPhoto = `${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_STATION_URL}${
    data.id_vente
  }/photos/${+data.nuinst}.JPG`

  const [showParents, setShowParents] = useState(
    window.localStorage.showParentsFS ?? "true" === "true"
  )
  const [showSituation, setShowSituation] = useState(
    window.localStorage.showSituation ?? "true" === "true"
  )
  const [showModalPlayer, setShowModalPlayer] = useState(false)
  pageOption === "video" && !showModalPlayer && setShowModalPlayer(true)
  pageOption !== "video" && showModalPlayer && setShowModalPlayer(false)
  const [showModalPhoto, setShowModalPhoto] = useState(false)
  pageOption === "photo" && !showModalPhoto && setShowModalPhoto(true)
  pageOption !== "photo" && showModalPhoto && setShowModalPhoto(false)

  const [imgClass, setImgClass] = useState("noDisplay")

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    window.localStorage.showParentsFS = showParents
  }, [showParents])
  ///////////////
  // Functions //
  ///////////////
  const getStationName = () => {
    return data.costat === "871"
      ? "Lanaud"
      : data.costat === "191"
      ? "ABL"
      : data.costat === "122"
      ? "Gelioc"
      : data.costat === "232" && "Gevial"
  }
  const goToVideo = () => {
    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "ios"
    ) {
      let message = {
        type: "PageYoutubeAniStation",
        url: "https://www.youtube.com/embed/" + data.url_video,
        title: `${data.copaip + data.nunati} - ${data.nobovi}`,
      }

      let stringified = JSON.stringify(message)

      window.ReactNativeWebView.postMessage(stringified)
    } else if (isMobile) {
      window.open("https://www.youtube.com/embed/" + data.url_video).focus()
    } else goToHashWithParams("fichestation_video")
  }
  const noImgFound = () => {
    divImgStation?.current?.classList?.add("no_display")
    divLeftPart?.current?.classList?.add("side_by_side")
    firstHalf?.current?.classList?.add("side_by_side")
    cardAnimal?.current?.classList?.add("one_side")
  }
  const imgLoaded = () => {
    setImgClass("pointer")

    let height = imgPhoto.current.height
    let width = imgPhoto.current.width

    data.photoWidth = width
    data.photoHeight = height
  }
  const imgOnClick = () => {
    if ("ReactNativeWebView" in window) {
      var newImg = new Image()

      newImg.onload = function () {
        // Create a canvas element
        const canvas = document.createElement("canvas")
        // Set the canvas dimensions to the image dimensions
        canvas.width = newImg.width
        canvas.height = newImg.height
        // Get the canvas context
        const ctx = canvas.getContext("2d")
        // Draw the image on the canvas
        ctx.drawImage(newImg, 0, 0)
        // Get the Base64-encoded data
        const dataURL = canvas.toDataURL()

        let message = {
          type: "ImageB64",
          content: {
            title: `${data.copaip + data.nunati} - ${data.nobovi}`,
            b64: dataURL,
          },
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      }

      newImg.crossOrigin = "*"
      newImg.src = imgPhoto.current.src
    } else {
      goToHashWithParams("fichestation_photo")
    }
  }
  const goAniFiche = ani => {
    window.location.hash = `animal|${ani}`
  }
  const getNameStation = () => {
    let cl = "Station"

    if (data.costat === "871") cl = " Lanaud"
    if (data.costat === "191") cl = " ABL"
    if (data.costat === "122") cl = " Gelioc"
    if (data.costat === "232") cl = " Gevial"

    return cl
  }
  const classTag = () => {
    let cl = "tag"

    cl += " " + getStationName()

    return cl
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  let showBandereau = false
  let showBandereauVente = false
  if (
    data?.A_boqual_2_etqubo !== "" ||
    (data.etat_animal === "3" && data.prix_vente && !data.acheteur_nom) ||
    data.coexev ||
    data.coentr === ""
  )
    showBandereau = true

  if (data.etat_animal === "3" && data.prix_vente && data.acheteur_nom)
    showBandereauVente = true

  let classRectQualif = "rectangel_qualif"

  if (data?.A_boqual_2_liqure === "RJ") classRectQualif += " rj"
  else if (data?.A_boqual_2_liqure === "Espoir") classRectQualif += " espoir"
  else if (data?.A_boqual_2_liqure === "RCV") classRectQualif += " rcv"
  else if (
    todayDate("numeric") > +data.date_vente &&
    !data.coexev &&
    data?.A_boqual_2_etqubo === ""
  )
    classRectQualif += " nq"
  else if (data.coexev) classRectQualif += " elimine"

  return (
    <div id="fiche_station_header" className="fiche_station_header">
      {showModalPlayer && (
        <ModalPlayer
          url={data.url_video}
          title={data.nobovi + " - " + data.copaip + data.nunati}
        />
      )}
      {showModalPhoto && (
        <ModalPhoto
          url={urlPhoto}
          title={data.nobovi + " - " + data.copaip + data.nunati}
        />
      )}
      {showBandereau && (
        <div className="bandereau_fiche">
          {data?.A_boqual_2_etqubo && (
            <div className={classRectQualif}>{data?.A_boqual_2_liqure}</div>
          )}
          {todayDate("numeric") > +data.date_vente &&
            !data.coexev &&
            !data?.A_boqual_2_etqubo && (
              <div className={classRectQualif}>NQ</div>
            )}
          {data.coexev && <div className={classRectQualif}>Eliminé</div>}
          {data.etat_animal === "3" &&
            data.prix_vente &&
            !data.acheteur_nom && (
              <div className="rectangle_pdv">{data.prix_vente}€</div>
            )}
          {data.etat_animal === "2" && (
            <div className="rectangle_pdv">Invendu</div>
          )}
          {data.coentr === "" && (
            <div className="rectangel_qualif nq">
              Déclaré a {getNameStation()} pour la série {data.caenst}/
              {data.nuseri}
            </div>
          )}
        </div>
      )}
      {showBandereauVente && (
        <div className="bandereau_fiche_vente">
          <div className="rectangle_pdv">{data.prix_vente}€</div>
          <div className="text_in_between">par</div>
          <div className="text_acheteur">
            <p>{data.acheteur_nom}</p>
          </div>
        </div>
      )}

      <div className="card_animal" ref={cardAnimal}>
        <div className="area_picture" ref={divImgStation}>
          <div className="img_ani_station">
            <img
              ref={imgPhoto}
              crossOrigin="*"
              className={imgClass}
              src={urlPhoto}
              onLoad={imgLoaded}
              onError={noImgFound}
              onClick={imgOnClick}
            />
            {imgClass === "noDisplay" && (
              <div className="photo_loading">
                <Loader loadingMessage={"Chargement de la photo..."} />
              </div>
            )}
            {imgClass !== "noDisplay" && data.url_video && (
              <div className="play_button ripple2 pointer" onClick={goToVideo}>
                <span className="ripple2  material-symbols-outlined">
                  play_circle
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="area_infos" ref={divLeftPart}>
          <div>
            <div className="top_part_infos">
              <div className="first_half" ref={firstHalf}>
                <div className="name">
                  <h2>{data.nobovi}</h2>
                </div>
                <h3 className="numero_ani">
                  {data.copaip + data.nunati}
                  <span
                    className="icon_go_fiche material-symbols-outlined ripple2 pointer material-symbols-outlined_even_more_weight"
                    onClick={() => goAniFiche(data.copaip + data.nunati)}
                  >
                    article
                  </span>
                </h3>
              </div>
              <div className="second_half">
                {data.nuinst && (
                  <div className={classTag()}>
                    <p className="text_tag">TAG</p>
                    <p className="number_tag">
                      {Number.isNaN(+data.nuinst) ? data.nuinst : +data.nuinst}
                    </p>
                    <p className="name_station">
                      {data.costat === "871"
                        ? "Lanaud"
                        : data.costat === "191"
                        ? "ABL"
                        : data.costat === "122"
                        ? "Gelioc"
                        : data.costat === "232" && "Gevial"}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom_part_infos">
              {data.copcna + data.nuchna !==
                (localStorage.numCheptel ?? "") && (
                <p>Cheptel de naissance: {data.copcna + data.nuchna}</p>
              )}

              <p>Date de naissance: {formatDate2(data.danais)}</p>
            </div>
          </div>

          <div className="parents_station">
            <CheptelSectionTitle
              title={"Parents"}
              show={showParents}
              onClick={() => setShowParents(!showParents)}
            />
            {showParents && (
              <div className="div_parents_fiche_station">
                {data?.A_analyse_VCG_gene_compat_2p === "ok" && (
                  <div className="infos_vcg">
                    "Génétiquement compatible avec les deux parents"
                  </div>
                )}
                {data?.A_analyse_VCG_gene_compat_2p === "p" && (
                  <div className="infos_vcg">
                    "Génétiquement compatible uniquement avec le père"
                  </div>
                )}
                {data?.A_analyse_VCG_gene_compat_2p === "m" && (
                  <div className="infos_vcg">
                    "Génétiquement compatible uniquement avec la mère"
                  </div>
                )}

                <div className="parents_fiche_station">
                  <div className="genalogie_pere">
                    {data.nobovi_p}{" "}
                    <i>
                      {data?.P_boqual_3_libeco ?? data?.P_boqual_2_libeco ?? ""}
                    </i>
                    <p className="numero">{data.copape + data.nupere}</p>
                  </div>

                  <div className="genalogie_mere">
                    {data.nobovi_m}{" "}
                    <i>
                      {data?.M_boqual_3_libeco ?? data?.M_boqual_2_libeco ?? ""}
                    </i>
                    <p className="numero">{data.copame + data.numere}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FicheStationHeader
