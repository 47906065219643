import { useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./InfosStation.scss"

const InfosStation = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showestation === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showestation = !show
  }

  const moveToAnimals = () => {
    localStorage.setItem("scrollXPageStation", 0)
    localStorage.setItem("scrollYPageStation", 0)

    localStorage.showStation = "true"
    localStorage.customsortAniStation = "serie"
    localStorage.customsensAniStation = "down"

    localStorage.showHisto = "true"
    localStorage.customsortAniHisto = "serie"
    localStorage.customsensAniHisto = "down"

    localStorage.stShowLanaud = "true"
    localStorage.stShowABL = "true"
    localStorage.stShowGelioc = "true"
    localStorage.stShowGevial = "true"

    window.location.hash = "station"
  }

  return (
    <>
      <div className="div_title_infos_stations">
        <CheptelSectionTitle
          title="Mes animaux en stations"
          onClick={screenWide ? undefined : hideOrShow}
          show={screenWide ? undefined : show}
          cheptelPage={true}
        />
      </div>
      {(screenWide || show) && (
        <div className="infos_station">
          <div className="left_column">
            <div className="left_bottom">
              <div className="div_aff_station">
                <span className="icon_aff_station material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                  roofing
                </span>
                <p className="aff_station_p">
                  {data?.nbAniMisEnStation ? (
                    <>
                      {data?.nbAniMisEnStation}{" "}
                      {data.nbAniMisEnStation > 1 ? "animaux" : "animal"} passés
                      en {data.nbStation > 1 ? "stations" : "station"}
                    </>
                  ) : (
                    "Aucun animal passé en station"
                  )}
                </p>
              </div>
              <div className="div_aff_station">
                <span className="icon_aff_station material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                  stars
                </span>
                <p className="aff_station_p">
                  <>
                    {(() => {
                      if (data?.nbRj && data?.nbEspoir && data?.nbRCV) {
                        return `${data?.nbRj} RJ, ${data?.nbEspoir} Espoir et 
                    ${data?.nbRCV ?? 0} RCV`
                      } else if (data?.nbRj && data?.nbEspoir && !data?.nbRCV) {
                        return `${data?.nbRj} RJ et ${data?.nbEspoir} Espoir`
                      } else if (data?.nbRj && !data?.nbEspoir && data?.nbRCV) {
                        return `${data?.nbRj} RJ et ${data?.nbRCV} RCV`
                      } else if (!data?.nbRj && data?.nbEspoir && data?.nbRCV) {
                        return `${data?.nbEspoir} Espoir et ${data?.nbRCV} RCV`
                      } else if (
                        data?.nbRj &&
                        !data?.nbEspoir &&
                        !data?.nbRCV
                      ) {
                        return `${data?.nbRj} RJ`
                      } else if (
                        !data?.nbRj &&
                        data?.nbEspoir &&
                        !data?.nbRCV
                      ) {
                        return `${data?.nbEspoir} Espoir`
                      } else if (
                        !data?.nbRj &&
                        !data?.nbEspoir &&
                        data?.nbRCV
                      ) {
                        return `${data?.nbRCV} RCV`
                      } else if (
                        !data?.nbRj &&
                        !data?.nbEspoir &&
                        !data?.nbRCV
                      ) {
                        return `Aucun animal qualifié en station`
                      }
                    })()}
                  </>
                </p>
              </div>
              <div className="div_aff_station">
                <span className="icon_aff_station material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                  scale
                </span>
                <p className="aff_station_p">
                  {data?.gmqMoy ? (
                    <>
                      {Math.floor(data?.gmqMoy * 1000, 2)
                        .toString()
                        .replace(".", ",")}{" "}
                      g/j de GMQ moyen
                    </>
                  ) : (
                    "Aucun GMQ moyen récupéré"
                  )}
                </p>
              </div>
              <div className="div_aff_station">
                <span className="icon_aff_station material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                  euro
                </span>
                <p className="aff_station_p">
                  {data?.prixVenteMoy ? (
                    <>{data?.prixVenteMoy}€ en moy. aux ventes</>
                  ) : (
                    "Aucune vente recensée"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="right_column">
            <p className="nbanimal">{data?.nbAniEnStation ?? 0}</p>
            <p className="nbanimal-text">
              actuellement en {data.nbStation > 1 ? "stations" : "station"}
            </p>

            <button onClick={moveToAnimals} className="btn_inventaire ripple6">
              Voir les animaux
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default InfosStation
