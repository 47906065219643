export const dateWritten = date => {
  let formatedDate = ""

  let day = date.substring(0, 2)
  let month = date.substring(3, 5)
  let year = date.substring(6, 10)

  formatedDate += day + " "

  switch (month) {
    case "01":
      formatedDate += "janvier "
      break
    case "02":
      formatedDate += "février "
      break
    case "03":
      formatedDate += "mars "
      break
    case "04":
      formatedDate += "avril "
      break
    case "05":
      formatedDate += "mai "
      break
    case "06":
      formatedDate += "juin "
      break
    case "07":
      formatedDate += "juillet "
      break
    case "08":
      formatedDate += "aout "
      break
    case "09":
      formatedDate += "septembre "
      break
    case "10":
      formatedDate += "octobre "
      break
    case "11":
      formatedDate += "novembre "
      break
    case "12":
      formatedDate += "décembre "
      break

    default:
      break
  }

  formatedDate += year

  return formatedDate
}

export const dateWritten2 = date => {
  let formatedDate = ""

  let month = date.substring(0, 2)
  let year = date.substring(3)

  switch (month) {
    case "01":
      formatedDate += "janvier "
      break
    case "02":
      formatedDate += "février "
      break
    case "03":
      formatedDate += "mars "
      break
    case "04":
      formatedDate += "avril "
      break
    case "05":
      formatedDate += "mai "
      break
    case "06":
      formatedDate += "juin "
      break
    case "07":
      formatedDate += "juillet "
      break
    case "08":
      formatedDate += "aout "
      break
    case "09":
      formatedDate += "septembre "
      break
    case "10":
      formatedDate += "octobre "
      break
    case "11":
      formatedDate += "novembre "
      break
    case "12":
      formatedDate += "décembre "
      break

    default:
      break
  }

  formatedDate += year

  return formatedDate
}
