import { useEffect, useRef } from "react"
import "./ModalFilter.scss"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { getColumns } from "../../../functions/objectColums"
import ModalSexe from "./ModalChoices"
import FilterList from "../../FilterList"
import { goBack } from "../../../functions/handleNavigation"

const ModalFilter = ({
  inventory,
  filters,
  setFilters,
  allData,
  nbAnimals,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const columnsAvailable = getColumns()

  // Center the modal
  const divFilter = useRef()
  const divModal = useRef()
  useCenterModal(divModal)

  let fullFilters = {}
  for (let filter in inventory?.filters) {
    fullFilters[filter] = structuredClone(inventory?.filters[filter])
    fullFilters[filter].locked = true
  }
  for (let filter in filters[inventory.num]) {
    fullFilters[filter] = filters[inventory.num][filter]
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divFilter.current
    let div = divModal.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = 136 + addHeight

      curr.style.maxHeight = `calc(calc(var(--vh) * 96) - ${newHeight}px)`

      let halfHeightScreen = (window.innerHeight - addHeight) / 2
      let halfHeightModal = div.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal
      topHeight += addHeight

      div.style.top = topHeight + "px"
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const setFiltersIntermediate = fltrs => {
    let newFilters = {}
    for (let filter in fltrs) {
      if (!fltrs[filter].locked) {
        if (!newFilters[inventory.num]) newFilters[inventory.num] = {}

        newFilters[inventory.num][filter] = fltrs[filter]
      }
    }

    setFilters(newFilters)
  }

  return (
    <>
      <div ref={divModal} className="modal_filters">
        <div className="div_modal_header">
          <span
            className="close_modal ripple2  material-symbols-outlined"
            onClick={goBack}
          >
            close
          </span>
          <h3>Filtres</h3>
        </div>
        <div className="div_filters_wrapper" ref={divFilter}>
          <FilterList
            inventory={inventory}
            filters={fullFilters}
            setFilters={setFiltersIntermediate}
            allData={allData}
          />
        </div>
        <div className="div_close_modal_sort">
          <button className="close_modal_sort ripple2 pointer" onClick={goBack}>
            afficher {nbAnimals} {nbAnimals > 1 ? "résultats" : "résultat"}
          </button>
        </div>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalFilter
