import React from "react"
import ReactDOM from "react-dom/client"
import "./material-symbols-outlined.css"
import "./index.css"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { ErrorBoundary } from "react-error-boundary"
import { fetchLogError } from "./functions/fetches/fetchLogError"

let waitingWorker

const onSWUpdate = registratration => {
  waitingWorker = registratration.waiting

  let divTopNotif = document.getElementById("div_topnotif_sw")
  divTopNotif.classList.remove("hidden_notif")
  divTopNotif.classList.add("show_notif")

  let btnTopNotif = document.getElementById("btn_topnotif_sw")
  btnTopNotif.addEventListener("click", e => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
  })

  let divEvo = document.querySelector(".evolutions")
  if (divEvo) divEvo.classList.add("notif_on")
  let divInvFooter = document.querySelector(".inventaire_footer")
  if (divInvFooter) {
    divInvFooter.classList.add("diff_bottom")
    divTopNotif.classList.remove("bg_topnotif_blue_sw")
  }
  let divInventaire = document.getElementById("inventaire_table")
  if (divInventaire) {
    divInventaire.classList.add("diff_bottom")
    divTopNotif.classList.add("bg_topnotif_blue_sw")
  }
  let divRepro = document.getElementById("reproduction")
  if (divRepro) divRepro.classList.add("needMargin")

  if (window.localStorage.sidePanelInv === "true") {
    divTopNotif.classList.add("notif_with_side_filters")
  }
}

// setTimeout(() => {
//   let divTopNotif = document.getElementById("div_topnotif_sw")
//   divTopNotif.classList.remove("hidden_notif")
//   divTopNotif.classList.add("show_notif")

//   let btnTopNotif = document.getElementById("btn_topnotif_sw")
//   btnTopNotif.addEventListener("click", e => {
//     waitingWorker?.postMessage({ type: "SKIP_WAITING" })
//   })

//   let divEvo = document.querySelector(".evolutions")
//   if (divEvo) divEvo.classList.add("notif_on")
//   let divInvFooter = document.querySelector(".inventaire_footer")
//   if (divInvFooter) {
//     divInvFooter.classList.add("diff_bottom")
//     divTopNotif.classList.remove("bg_topnotif_blue_sw")
//   }
//   let divInventaire = document.getElementById("inventaire_table")
//   if (divInventaire) {
//     divInventaire.classList.add("diff_bottom")
//     divTopNotif.classList.add("bg_topnotif_blue_sw")
//   }
//   let divRepro = document.getElementById("reproduction")
//   if (divRepro) divRepro.classList.add("needMargin")

//   if (window.localStorage.sidePanelInv === "true") {
//     divTopNotif.classList.add("notif_with_side_filters")
//   }
// }, 4000)

const logError = (error, { componentStack }) => {
  fetchLogError(error.message, componentStack)
}
function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  setTimeout(() => {
    if (window.location.hostname !== "localhost") {
      window.location.hash = ""
      window.location.reload()
    }
  }, 5000)

  return (
    <div className="wrapper_error">
      <div className="div_error_handled">
        <p>
          Un problème est survenu avec l'application. <br />
          Vous allez être redirigé vers l'accueil dans 5 secondes.
        </p>
      </div>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ErrorBoundary onError={logError} FallbackComponent={Fallback}>
    <React.StrictMode>
      <App waitingWorker={waitingWorker} />
    </React.StrictMode>
  </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onSWUpdate,
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
