import "./Cheptel.scss"
import MyCheptel from "../components/cheptel/MyCheptel"
import MyInfosHBL from "../components/cheptel/MyInfosHBL"
import MyDocs from "../components/cheptel/MyDocs"
import Repartition from "../components/cheptel/Repartition"
import Iboval from "../components/cheptel/Iboval"
import { useEffect, useRef, useState } from "react"
import InfosStation from "../components/cheptel/InfosStation"
import ModalChangeLogs from "../components/ModalChangeLogs"
import IVV from "../components/cheptel/IVV"
import AscMat from "../components/cheptel/AscMat"
import AscPat from "../components/cheptel/AscPat"
import { fetchListeConcours } from "../functions/fetches/fetchListeConcours"
import BigGreenButton from "../components/BigGreenButton"
import ModalConcours from "../components/cheptel/ModalConcours"
import { checkInternet } from "../functions/checkInternet"
import { checkIfConnected } from "../functions/checkIfConnecetd"
import ModalLoader from "../components/ModalLoader"
import { useCustomAlert } from "../hooks/useCustomAlert"
import Alert from "../components/Alert"

let isScrolling // Setup isScrolling variable

const Cheptel = ({
  dataCalculated,
  pageOption,
  pocketData,
  isAdherent,
  cheptelName,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  const valScreenWide = 1000

  window.localStorage.justAni = "N"

  let divWrap = useRef()

  let numCheptel = window.localStorage.numCheptel ?? ""

  const [clChangeLog, setChangeLog] = useState(false)
  pageOption === "changelog" && !clChangeLog && setChangeLog(true)
  pageOption !== "changelog" && clChangeLog && setChangeLog(false)
  const [clConcours, setClConcours] = useState(false)
  pageOption === "concours" && !clConcours && setClConcours(true)
  pageOption !== "concours" && clConcours && setClConcours(false)

  const [screenWide, setScreenWide] = useState(
    window.innerWidth >= valScreenWide ? true : false
  )

  let listeConcours = JSON.parse(window.localStorage.listeConcours ?? "[]")

  const [concours, setConcours] = useState(listeConcours)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = divWrap.current

    const setWidth = () =>
      setScreenWide(window.innerWidth >= valScreenWide ? true : false)

    window.addEventListener("resize", setWidth)

    if (div) {
      if (
        localStorage.scrolledElementIdNative &&
        localStorage.scrolledElementIdNative === "wrap_page_cheptel"
      ) {
        div.scrollLeft = localStorage.scrollXPageNative ?? 0
        div.scrollTop = localStorage.scrollYPageNative ?? 0

        delete localStorage.scrolledElementIdNative
        delete localStorage.scrollXPageNative
        delete localStorage.scrollYPageNative
      } else {
        div.scrollLeft = localStorage.scrollXPageCheptel ?? 0
        div.scrollTop = localStorage.scrollYPageCheptel ?? 0
      }
    }

    const getListeConcours = async () => {
      let concours = await fetchListeConcours()

      if (concours) {
        window.localStorage.listeConcours = JSON.stringify(concours)
        setConcours(concours)
      }
    }

    getListeConcours()

    return () => {
      window.removeEventListener("resize", setWidth)
    }
  }, [])

  ///////////////
  // Functions //
  ///////////////
  // Method to save scroll pos in inventaire
  const checkScroll = () => {
    let div = divWrap.current
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling)

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(() => {
      if (div) {
        localStorage.setItem("scrollXPageCheptel", div.scrollLeft)
        localStorage.setItem("scrollYPageCheptel", div.scrollTop)
      }
    }, 250)
  }
  const goDeclareConcours = () => {
    if (concours.length > 1) window.location.hash = "cheptel_concours"
    else if (concours.length === 1)
      goToConcours(concours[0].id, concours[0].titre)
  }
  const goToConcours = async (id, title) => {
    let divModal = document.getElementById("loader_concours")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      let link = `https://concours.limousine.org/declaration/${id}.html`

      if ("ReactNativeWebView" in window) {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

        let message = {
          type: "PageYoutubeAniStation",
          url: link,
          title: title,
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

        setTimeout(() => {
          window.open(link, "_blank")
        })
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }

  return (
    <div
      className="wrap_page_cheptel"
      id="wrap_page_cheptel"
      ref={divWrap}
      onScroll={checkScroll}
    >
      <ModalLoader id="loader_concours" message="Redirection en cours..." />
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      <div className="page_cheptel" id="page_cheptel">
        {listeConcours.length > 0 && (
          <div className="banderole_concours">
            {listeConcours.length === 1
              ? `${listeConcours[0].titre}`
              : "Déclaration concours disponibles"}
            <button
              className="declare_button ripple3"
              onClick={goDeclareConcours}
            >
              Déclarer
            </button>
          </div>
        )}
        <div className="top_part_cheptel_page">
          <MyCheptel data={dataCalculated.MyCheptel} />
          <InfosStation data={dataCalculated.Station} screenWide={screenWide} />
        </div>
        {isAdherent && (
          <>
            <MyInfosHBL
              data={dataCalculated.MyInfosHBL}
              pocketData={pocketData}
              pageOption={pageOption}
              screenWide={screenWide}
              cheptelName={cheptelName}
            />
            <MyDocs data={dataCalculated.MyDocs} screenWide={screenWide} />
          </>
        )}

        <div className="graph_line1">
          <Repartition
            data={dataCalculated.Repartition}
            screenWide={screenWide}
          />
          <Iboval data={dataCalculated.Iboval} screenWide={screenWide} />
          <IVV data={dataCalculated.Evolutions} screenWide={screenWide} />
        </div>
        <div className="graph_line2">
          <AscMat data={dataCalculated.Evolutions} screenWide={screenWide} />
          <AscPat data={dataCalculated.Evolutions} screenWide={screenWide} />
        </div>

        {clChangeLog && <ModalChangeLogs />}
        {clConcours && (
          <ModalConcours concours={concours} callback={goToConcours} />
        )}
      </div>
    </div>
  )
}

export default Cheptel
