import { useRef } from "react"
import "./ModalEvalim.scss"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack } from "../../../functions/handleNavigation"

const ModalEvalim = ({ aniData }) => {
  !aniData && goBack()
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div ref={divModal} className={"modal_evalim"}>
        <div className="div_modal_header">
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <h3>Résultats EVALIM</h3>
        </div>

        <div className="modal-content">
          <table>
            <thead>
              <tr>
                <th>FN</th>
                <th>CR</th>
                <th>DM</th>
                <th>DS</th>
                <th>FOS</th>
                <th>AV</th>
                <th>AL</th>
                <th>OP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{aniData?.CIFNAI}</td>
                <td>{aniData?.CCRSEV}</td>
                <td>{aniData?.CDMSEV}</td>
                <td>{aniData?.CDSSEV}</td>
                <td>{aniData?.CFOSSE}</td>
                <td>{aniData?.CAVELS}</td>
                <td>{aniData?.CALAIT}</td>
                <td>{aniData?.COPSEV}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className="result_evalim_baseref">
            Base de référence: {aniData?.BASREF}
          </div>
        </div>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalEvalim
