import pck from "../../package.json"

export const checkInternet = async () => {
  let success = true

  let adress = pck.homepage
  if (window.location.hostname === "localhost") return true

  try {
    await timeout(
      30 * 1000,
      fetch(`${adress}ping.txt`)
        .then(res => {
          if (res.status !== 200) {
            success = false
          }
          if (res.ok) {
            return res.text()
          }
        })
        .then(text => {
          if (text !== "ping") success = false
        })
        .catch(() => (success = false))
    )
  } catch (error) {
    success = false
  }

  return success
}

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."))
    }, time)
    promise.then(resolve, reject)
  })
}
