import localforage from "localforage"
import { fetchLoginSession } from "./fetches/fetchLoginSession"
import { fetchAdmChepSession } from "./fetches/fetchAdmChepSession"
import { fetchIsConnectedSession } from "./fetches/fetchIsConnectedSession"

export const checkIfConnected = async cheptel => {
  let isConnected = await fetchIsConnectedSession(cheptel)

  if (!isConnected) {
    let a = window.localStorage.dataReturnA
    let b = window.localStorage.dataReturnB

    let dLog = await fetchLoginSession(a, b, "N")

    if (dLog && dLog.reponse && dLog.reponse !== "ok") {
      localforage.setItem("login", false)

      const event = new CustomEvent("showAlert", {
        detail: {
          msg: "Vous avez été déconnecté. Veuillez vous reconnecter s.v.p.",
          callback: () => window.location.reload(),
        },
      })

      let root = document.getElementById("root")

      if (root) root.dispatchEvent(event)
    } else {
      let valA = window.localStorage.valA === "true" ? true : false
      let numCheptel = window.localStorage.numCheptel ?? ""

      if (valA && numCheptel) await fetchAdmChepSession(numCheptel)
    }
  }
}
