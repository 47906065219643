import { getDeviceData } from "../getDeviceData"
import { fetchUrlEncoded } from "./fetch"

// Function to login
export const fetchLogAction = async act => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "logAction"
  let device = getDeviceData()

  let params = {
    a: action,
    act: act, // Action to log
    v: localStorage.app_version,
    d: JSON.stringify(device),
    uuid: window.localStorage.uuid,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
