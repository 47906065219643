export const listRoutes = [
  "loadata",
  "faildl",
  "reglesconf",
  "op",
  "op_exports",
  "op_declarationvente",
  "op_changelog",
  "adherentsop",
  "adherentsop_cheptels",
  "admin",
  "admin_changelog",
  "game",
  "game_rankings",
  "messagerie",
  "account",
  "station",
  "station_statutdl",
  "fichestation",
  "fichestation_sharing",
  "fichestation_video",
  "fichestation_photo",
  "visites",
  "visites_filter",
  "visites_recap",
  "visites_details",
  "visites_statutdl",
  "cheptel",
  "cheptel_statutdl",
  "cheptel_changelog",
  "cheptel_recap",
  "cheptel_details",
  "cheptel_pdfs",
  "cheptel_concours",
  "inventaire",
  "supprcompte",
  "supprcompte_reinit",
  "login_reinit",
  "login_register",
  "inventaire_statutdl",
  "inventaire_pickdate",
  "inventaire_listeCertifs",
  "inventaire_filter",
  "inventaire_series",
  "inventaire_search",
  "inventaire_dots",
  "inventaire_evalim",
  "inventaire_filiation",
  "inventaire_erreurs",
  "inventaire_goconfirm",
  "inventaire_declarations",
  "inventaire_erreursconf",
  "comparaison",
  "gestioninv",
  "gestioninv_addinv",
  "gestioncolumns",
  "gestioncolumns_addcol",
  "gestioncolumns_settings",
  "customfilters",
  "animal_pere",
  "animal_mere",
  "animal_dots",
  "animal_confirm",
  "animal_declar",
  "animal_photo",
  "animal_sharing",
  "animal_parcours",
  "animal_camera",
  "animal",
]

export const indepedentRoutes = [
  "supprcompte",
  "supprcompte_reinit",
  "reglesconf",
]
