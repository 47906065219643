import platform from "platform"

export const getDeviceData = () => {
  let deviceData = {}

  deviceData.os = platform.os.family
  deviceData.osVersion = platform.os.version
  deviceData.browser = platform.name
  deviceData.browserVersion = platform.version
  deviceData.product = platform.product

  return deviceData
}
