import { useState } from "react"
import {
  statutBarColor,
  statutBarN1Color,
  statutBarN2Color,
} from "../functions/statutBarColor"

let tmpHeaderColor = null
let callback = null

export const useCustomAlert = () => {
  const [alert, setAlert] = useState(false)
  const [messageAlert, setMessageAlert] = useState("")

  const doAlert = message => {
    tmpHeaderColor = document.querySelector('meta[name="theme-color"]').content

    if (tmpHeaderColor !== statutBarN1Color) {
      statutBarColor(statutBarN1Color)
    } else {
      statutBarColor(statutBarN2Color)
    }

    setAlert(true)
    setMessageAlert(message)
  }

  const hideAlert = () => {
    if (tmpHeaderColor) statutBarColor(tmpHeaderColor)

    setAlert(false)

    if (callback && typeof callback === "function") callback()
  }

  const setCallback = cb => {
    callback = cb
  }

  return [alert, hideAlert, messageAlert, doAlert, setCallback]
}
