// Format a YYYYMMDD date to DD-MM-YYYY
export const formatDate = date => {
  if (!date) return ""

  let year = date?.substring(0, 4)
  let month = date?.substring(4, 6)
  let day = date?.substring(6, 8)

  return `${day}-${month}-${year}`
}

// Format a YYYY-MM-DD date to DD-MM-YYYY
export const formatDate2 = date => {
  if (!date) return ""

  let year = date?.substring(0, 4)
  let month = date?.substring(5, 7)
  let day = date?.substring(8, 10)

  return `${day}-${month}-${year}`
}

// Format a DD-MM-YYYY date to YYYY-MM-DD
export const formatDate3 = date => {
  if (!date) return ""

  let day = date?.substring(0, 2)
  let month = date?.substring(3, 5)
  let year = date?.substring(6, 10)

  return `${year}-${month}-${day}`
}
// Format a DD-MM-YYYY date to YYYYMMDD
export const formatDate4 = date => {
  if (!date) return ""

  let day = date?.substring(0, 2)
  let month = date?.substring(3, 5)
  let year = date?.substring(6, 10)

  return `${year}${month}${day}`
}

// Format a YYYY-MM-DD date to YYYYMMDD
export const formatDate5 = date => {
  if (!date) return ""

  let year = date?.substring(0, 4)
  let month = date?.substring(5, 7)
  let day = date?.substring(8, 10)

  return `${year}${month}${day}`
}

// Format date the way PHP does
export const formatDateLikePHP = (date, format = "Y-m-d") => {
  if (!date) return ""
  let objDate = new Date(date)

  let year = objDate.getFullYear()
  let month = objDate.getMonth() + 1
  let day = objDate.getDate()
  if (month < 10) month = "0" + month
  if (day < 10) day = "0" + day

  let ret = format
  ret = ret.replaceAll("Y", year).replaceAll("m", month).replaceAll("d", day)

  return ret
}

export const isValidDate = dateString => {
  var regex_date = /^\d{1,2}-\d{1,2}-\d{4}$/
  if (!regex_date.test(dateString)) {
    return false
  }
  var parts = dateString.split("-")
  var day = parseInt(parts[0], 10)
  var month = parseInt(parts[1], 10)
  var year = parseInt(parts[2], 10)
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false
  }
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29
  }
  return day > 0 && day <= monthLength[month - 1]
}
