import { monthAmount } from "./monthAmount"
import { todayDate } from "./todayDate"
import { formatDate2, formatDate4, formatDate5 } from "./formatDate"

export const calculateData = (data, dataSuffix) => {
  // Variables male a
  let nbMale = 0

  let nbMaleIndex = 0
  let nbMaleToExamine = 0
  let nbMaleToExamineArray = []
  let nbMaleToConfirm = 0
  let nbMaleToConfirmArray = []
  let nbMalP6MNnInscrit = 0
  let nbMalP6MNnInscritArray = []

  // Variables female
  let nbFemale = 0

  // Variables vache
  let nbVaches = 0
  let nbVachesIndex = 0
  let nbVachesToConfirm = 0
  let nbVachesToConfirmArray = []
  let nbFemP6MNnInscrite = 0
  let nbFemP6MNnInscriteArray = []

  // Variables genisse
  let nbGenisses = 0
  let nbGenissesIndex = 0
  let nbGenissesToExamine = 0
  let nbGenissesToExamineArray = []
  let nbGenissesToConfirm = 0
  let nbGenissesToConfirmArray = []

  // Variables moyennes
  let moyenneIfnaisM = 0
  let moyenneIfnaisV = 0
  let moyenneIfnaisG = 0
  let moyenneIfnaisI = 0
  let moyenneIfnaisT = 0
  let moyenneIfnaisTM = 0
  let moyenneIfnaisIM = 0
  let moyenneIfnaisTV = 0
  let moyenneIfnaisIV = 0
  let moyenneIfnaisTG = 0
  let moyenneIfnaisIG = 0
  let moyenneCrsevsM = 0
  let moyenneCrsevsV = 0
  let moyenneCrsevsG = 0
  let moyenneCrsevsI = 0
  let moyenneCrsevsT = 0
  let moyenneCrsevsTM = 0
  let moyenneCrsevsIM = 0
  let moyenneCrsevsTV = 0
  let moyenneCrsevsIV = 0
  let moyenneCrsevsTG = 0
  let moyenneCrsevsIG = 0
  let moyenneDmsevsM = 0
  let moyenneDmsevsV = 0
  let moyenneDmsevsG = 0
  let moyenneDmsevsI = 0
  let moyenneDmsevsT = 0
  let moyenneDmsevsTM = 0
  let moyenneDmsevsIM = 0
  let moyenneDmsevsTV = 0
  let moyenneDmsevsIV = 0
  let moyenneDmsevsTG = 0
  let moyenneDmsevsIG = 0
  let moyenneDssevsM = 0
  let moyenneDssevsV = 0
  let moyenneDssevsG = 0
  let moyenneDssevsI = 0
  let moyenneDssevsT = 0
  let moyenneDssevsTM = 0
  let moyenneDssevsIM = 0
  let moyenneDssevsTV = 0
  let moyenneDssevsIV = 0
  let moyenneDssevsTG = 0
  let moyenneDssevsIG = 0
  let moyenneFosevsM = 0
  let moyenneFosevsV = 0
  let moyenneFosevsG = 0
  let moyenneFosevsI = 0
  let moyenneFosevsT = 0
  let moyenneFosevsTM = 0
  let moyenneFosevsIM = 0
  let moyenneFosevsTV = 0
  let moyenneFosevsIV = 0
  let moyenneFosevsTG = 0
  let moyenneFosevsIG = 0
  let moyenneIsevreM = 0
  let moyenneIsevreV = 0
  let moyenneIsevreG = 0
  let moyenneIsevreI = 0
  let moyenneIsevreT = 0
  let moyenneIsevreTM = 0
  let moyenneIsevreIM = 0
  let moyenneIsevreTV = 0
  let moyenneIsevreIV = 0
  let moyenneIsevreTG = 0
  let moyenneIsevreIG = 0
  let moyenneAvelagM = 0
  let moyenneAvelagV = 0
  let moyenneAvelagG = 0
  let moyenneAvelagI = 0
  let moyenneAvelagT = 0
  let moyenneAvelagTM = 0
  let moyenneAvelagIM = 0
  let moyenneAvelagTV = 0
  let moyenneAvelagIV = 0
  let moyenneAvelagTG = 0
  let moyenneAvelagIG = 0
  let moyenneAlaitsM = 0
  let moyenneAlaitsV = 0
  let moyenneAlaitsG = 0
  let moyenneAlaitsI = 0
  let moyenneAlaitsT = 0
  let moyenneAlaitsTM = 0
  let moyenneAlaitsIM = 0
  let moyenneAlaitsTV = 0
  let moyenneAlaitsIV = 0
  let moyenneAlaitsTG = 0
  let moyenneAlaitsIG = 0
  let moyenneIvmateM = 0
  let moyenneIvmateV = 0
  let moyenneIvmateG = 0
  let moyenneIvmateI = 0
  let moyenneIvmateT = 0
  let moyenneIvmateTM = 0
  let moyenneIvmateIM = 0
  let moyenneIvmateTV = 0
  let moyenneIvmateIV = 0
  let moyenneIvmateTG = 0
  let moyenneIvmateIG = 0

  // Variable IVV
  let ii = 0
  let datesIVV = []
  let isuArray = []
  let ivvArray = []
  let ivv12Array = []
  let ivv2pArray = []
  let ivvMoyenI = 0
  let ivvMoyenT = 0
  let ivvMoyen12I = 0
  let ivvMoyen12T = 0
  let ivvMoyen2pI = 0
  let ivvMoyen2pT = 0

  // Variables table Index
  let nbIndexIfnais = ""
  let nbIndexCrsevs = ""
  let nbIndexDmsevs = ""
  let nbIndexDssevs = ""
  let nbIndexFosevs = ""
  let nbIndexIsevre = ""
  let nbIndexAvelag = ""
  let nbIndexAlaits = ""
  let nbIndexIvmate = ""
  let isu = ""
  //Variables tablex Index autres
  let nbIndexANNEIN = ""
  let nbIndexCDALAI = ""
  let nbIndexCDAVEL = ""
  let nbIndexCDCRSE = ""
  let nbIndexCDDMSE = ""
  let nbIndexCDDSSE = ""
  let nbIndexCDFOSE = ""
  let nbIndexCDIFNA = ""
  let nbIndexCDISEV = ""
  let nbIndexCDIVMA = ""

  // Variables filter analyses
  let needAnalyse = 0
  let needAnalyseArray = []
  let hasIncompatibility = 0
  let hasIncompatibilityArray = []
  let maleSansCompatPere = 0
  let maleSansMh = 0
  let maleSansProfilAdn = 0
  let maleSansCompatPereArray = []
  let maleSansMhArray = []
  let maleSansProfilAdnArray = []

  //Variables docs
  let docBGTA = []
  let docBirth = { TP: false, PN: false }
  let anneeDocBirth = ""

  // Variables BGTA index
  let campArray = []
  let nivgenmatIfnais = []
  let nivgenmatCrsev = []
  let nivgenmatDmsev = []
  let nivgenmatDssev = []
  let nivgenmatFossev = []
  let nivgenmatIsevr = []
  let nivgenmatAvel = []
  let nivgenmatAlait = []
  let nivgenmatIvmat = []
  let nivgenpatIfnais = []
  let nivgenpatCrsev = []
  let nivgenpatDmsev = []
  let nivgenpatDssev = []
  let nivgenpatFossev = []
  let nivgenpatIsevr = []
  let nivgenpatAvel = []
  let nivgenpatAlait = []
  let nivgenpatIvmat = []

  let dataAnimaux = []
  let tmpDataAni = {}

  Object.keys(data.animaux).forEach(fullNum => {
    let animal = data.animaux[fullNum]

    ////////////////////////////////////////
    // Calcul pour data pour page accueil //
    ////////////////////////////////////////
    let toExamine = false
    let toConfirm = false
    let refuse = false

    if (
      animal?.age > 182.4 &&
      animal?.boqual_ETQUBO === "" &&
      animal?.borexa_c_ETQUBO === "" &&
      (animal?.borexa_a_ETQUBO === "" ||
        (animal?.borexa_a_ETQUBO === "1" && animal?.borexa_a_COREEX === "A"))
    )
      toExamine = true

    if (
      animal?.boqual_ETQUBO === "" &&
      animal?.borexa_c_ETQUBO === "1" &&
      animal?.borexa_c_COREEX === "C" &&
      animal?.borexa_c_CORADI === ""
    )
      toConfirm = true

    if (
      animal?.age > 182.4 &&
      animal?.boqual_ETQUBO === "" &&
      animal?.borexa_a_ETQUBO === "1" &&
      animal?.borexa_a_COREEX === "R"
    )
      refuse = true

    if (animal?.sexbov === "2") {
      nbFemale++

      // Femelles +6mois non inscrit HBL
      if (toExamine || toConfirm || refuse) {
        nbFemP6MNnInscriteArray.push(animal?.copaip + animal?.nunati)
        nbFemP6MNnInscrite++
      }

      if (animal?.A_RADEVE > 0) {
        nbVaches++
        if (
          animal?.A_ivfemr_IFNAIS !== "" ||
          animal?.A_ivfemr_CRSEVS !== "" ||
          animal?.A_ivfemr_DMSEVS !== "" ||
          animal?.A_ivfemr_DSSEVS !== "" ||
          animal?.A_ivfemr_FOSEVS !== "" ||
          animal?.A_ivfemr_ISEVRE !== "" ||
          animal?.A_ivfemr_AVELAG !== "" ||
          animal?.A_ivfemr_ALAITS !== "" ||
          animal?.A_ivfemr_IVMATE !== ""
        ) {
          nbVachesIndex++
        }

        if (animal?.A_ivfemr_IFNAIS !== "") {
          moyenneIfnaisI++
          moyenneIfnaisT += parseInt(animal?.A_ivfemr_IFNAIS)
          moyenneIfnaisIV++
          moyenneIfnaisTV += parseInt(animal?.A_ivfemr_IFNAIS)
        }
        if (animal?.A_ivfemr_CRSEVS !== "") {
          moyenneCrsevsI++
          moyenneCrsevsT += parseInt(animal?.A_ivfemr_CRSEVS)
          moyenneCrsevsIV++
          moyenneCrsevsTV += parseInt(animal?.A_ivfemr_CRSEVS)
        }
        if (animal?.A_ivfemr_DMSEVS !== "") {
          moyenneDmsevsI++
          moyenneDmsevsT += parseInt(animal?.A_ivfemr_DMSEVS)
          moyenneDmsevsIV++
          moyenneDmsevsTV += parseInt(animal?.A_ivfemr_DMSEVS)
        }
        if (animal?.A_ivfemr_DSSEVS !== "") {
          moyenneDssevsI++
          moyenneDssevsT += parseInt(animal?.A_ivfemr_DSSEVS)
          moyenneDssevsIV++
          moyenneDssevsTV += parseInt(animal?.A_ivfemr_DSSEVS)
        }
        if (animal?.A_ivfemr_FOSEVS !== "") {
          moyenneFosevsI++
          moyenneFosevsT += parseInt(animal?.A_ivfemr_FOSEVS)
          moyenneFosevsIV++
          moyenneFosevsTV += parseInt(animal?.A_ivfemr_FOSEVS)
        }
        if (animal?.A_ivfemr_ISEVRE !== "") {
          moyenneIsevreI++
          moyenneIsevreT += parseInt(animal?.A_ivfemr_ISEVRE)
          moyenneIsevreIV++
          moyenneIsevreTV += parseInt(animal?.A_ivfemr_ISEVRE)
        }
        if (animal?.A_ivfemr_AVELAG !== "") {
          moyenneAvelagI++
          moyenneAvelagT += parseInt(animal?.A_ivfemr_AVELAG)
          moyenneAvelagIV++
          moyenneAvelagTV += parseInt(animal?.A_ivfemr_AVELAG)
        }
        if (animal?.A_ivfemr_ALAITS !== "") {
          moyenneAlaitsI++
          moyenneAlaitsT += parseInt(animal?.A_ivfemr_ALAITS)
          moyenneAlaitsIV++
          moyenneAlaitsTV += parseInt(animal?.A_ivfemr_ALAITS)
        }
        if (animal?.A_ivfemr_IVMATE !== "") {
          moyenneIvmateI++
          moyenneIvmateT += parseInt(animal?.A_ivfemr_IVMATE)
          moyenneIvmateIV++
          moyenneIvmateTV += parseInt(animal?.A_ivfemr_IVMATE)
        }

        if (animal?.A_IVVMOY) {
          ivvMoyenI++
          ivvMoyenT += parseInt(animal?.A_IVVMOY)
        }
        if (animal?.A_IVV1A2) {
          ivvMoyen12I++
          ivvMoyen12T += parseInt(animal?.A_IVV1A2)
        }
        if (animal?.A_IVV2PL) {
          ivvMoyen2pI++
          ivvMoyen2pT += parseInt(animal?.A_IVV2PL)
        }

        //VACHES A CONFIRMER
        if (toConfirm) {
          nbVachesToConfirmArray.push(fullNum)
          nbVachesToConfirm++
        }
      } else if (animal?.A_RADEVE === "") {
        nbGenisses++

        if (
          animal?.A_ivfemr_IFNAIS !== "" ||
          animal?.A_ivfemr_CRSEVS !== "" ||
          animal?.A_ivfemr_DMSEVS !== "" ||
          animal?.A_ivfemr_DSSEVS !== "" ||
          animal?.A_ivfemr_FOSEVS !== "" ||
          animal?.A_ivfemr_ISEVRE !== "" ||
          animal?.A_ivfemr_AVELAG !== "" ||
          animal?.A_ivfemr_ALAITS !== "" ||
          animal?.A_ivfemr_IVMATE !== ""
        ) {
          nbGenissesIndex++
        }

        if (animal?.A_ivfemr_IFNAIS !== "") {
          moyenneIfnaisI++
          moyenneIfnaisT += parseInt(animal?.A_ivfemr_IFNAIS)
          moyenneIfnaisIG++
          moyenneIfnaisTG += parseInt(animal?.A_ivfemr_IFNAIS)
        }
        if (animal?.A_ivfemr_CRSEVS !== "") {
          moyenneCrsevsI++
          moyenneCrsevsT += parseInt(animal?.A_ivfemr_CRSEVS)
          moyenneCrsevsIG++
          moyenneCrsevsTG += parseInt(animal?.A_ivfemr_CRSEVS)
        }
        if (animal?.A_ivfemr_DMSEVS !== "") {
          moyenneDmsevsI++
          moyenneDmsevsT += parseInt(animal?.A_ivfemr_DMSEVS)
          moyenneDmsevsIG++
          moyenneDmsevsTG += parseInt(animal?.A_ivfemr_DMSEVS)
        }
        if (animal?.A_ivfemr_DSSEVS !== "") {
          moyenneDssevsI++
          moyenneDssevsT += parseInt(animal?.A_ivfemr_DSSEVS)
          moyenneDssevsIG++
          moyenneDssevsTG += parseInt(animal?.A_ivfemr_DSSEVS)
        }
        if (animal?.A_ivfemr_FOSEVS !== "") {
          moyenneFosevsI++
          moyenneFosevsT += parseInt(animal?.A_ivfemr_FOSEVS)
          moyenneFosevsIG++
          moyenneFosevsTG += parseInt(animal?.A_ivfemr_FOSEVS)
        }
        if (animal?.A_ivfemr_ISEVRE !== "") {
          moyenneIsevreI++
          moyenneIsevreT += parseInt(animal?.A_ivfemr_ISEVRE)
          moyenneIsevreIG++
          moyenneIsevreTG += parseInt(animal?.A_ivfemr_ISEVRE)
        }
        if (animal?.A_ivfemr_AVELAG !== "") {
          moyenneAvelagI++
          moyenneAvelagT += parseInt(animal?.A_ivfemr_AVELAG)
          moyenneAvelagIG++
          moyenneAvelagTG += parseInt(animal?.A_ivfemr_AVELAG)
        }
        if (animal?.A_ivfemr_ALAITS !== "") {
          moyenneAlaitsI++
          moyenneAlaitsT += parseInt(animal?.A_ivfemr_ALAITS)
          moyenneAlaitsIG++
          moyenneAlaitsTG += parseInt(animal?.A_ivfemr_ALAITS)
        }
        if (animal?.A_ivfemr_IVMATE !== "") {
          moyenneIvmateI++
          moyenneIvmateT += parseInt(animal?.A_ivfemr_IVMATE)
          moyenneIvmateIG++
          moyenneIvmateTG += parseInt(animal?.A_ivfemr_IVMATE)
        }

        // GENISSES +6mois à examiner
        if (toExamine) {
          nbGenissesToExamineArray.push(animal?.copaip + animal?.nunati)
          nbGenissesToExamine++
        }
        //GENISSES A CONFIRMER
        if (toConfirm) {
          nbGenissesToConfirmArray.push(animal?.copaip + animal?.nunati)
          nbGenissesToConfirm++
        }
      }

      // Valeurs for table Index
      nbIndexIfnais = animal?.A_ivfemr_IFNAIS
      nbIndexCrsevs = animal?.A_ivfemr_CRSEVS
      nbIndexDmsevs = animal?.A_ivfemr_DMSEVS
      nbIndexDssevs = animal?.A_ivfemr_DSSEVS
      nbIndexFosevs = animal?.A_ivfemr_FOSEVS
      nbIndexIsevre = animal?.A_ivfemr_ISEVRE
      nbIndexAvelag = animal?.A_ivfemr_AVELAG
      nbIndexAlaits = animal?.A_ivfemr_ALAITS
      nbIndexIvmate = animal?.A_ivfemr_IVMATE
      isu = animal?.A_boisuf_INDISU

      nbIndexANNEIN = animal?.A_ivfemr_ANNEIN
      nbIndexCDALAI = animal?.A_ivfemr_CDALAI
      nbIndexCDAVEL = animal?.A_ivfemr_CDAVEL
      nbIndexCDCRSE = animal?.A_ivfemr_CDDMSE
      nbIndexCDDMSE = animal?.A_ivfemr_CDCRSE
      nbIndexCDDSSE = animal?.A_ivfemr_CDDSSE
      nbIndexCDFOSE = animal?.A_ivfemr_CDFOSE
      nbIndexCDIFNA = animal?.A_ivfemr_CDIFNA
      nbIndexCDISEV = animal?.A_ivfemr_CDISEV
      nbIndexCDIVMA = animal?.A_ivfemr_CDIVMA
    } else if (animal?.sexbov === "1") {
      nbMale++

      if (
        animal?.A_ivmale_IFNAIS !== "" ||
        animal?.A_ivmale_CRSEVS !== "" ||
        animal?.A_ivmale_DMSEVS !== "" ||
        animal?.A_ivmale_DSSEVS !== "" ||
        animal?.A_ivmale_FOSEVS !== "" ||
        animal?.A_ivmale_ISEVRE !== "" ||
        animal?.A_ivmale_AVELAG !== "" ||
        animal?.A_ivmale_ALAITS !== "" ||
        animal?.A_ivmale_IVMATE !== ""
      ) {
        nbMaleIndex++
      }

      if (animal?.A_ivmale_IFNAIS !== "") {
        moyenneIfnaisI++
        moyenneIfnaisT += parseInt(animal?.A_ivmale_IFNAIS)
        moyenneIfnaisIM++
        moyenneIfnaisTM += parseInt(animal?.A_ivmale_IFNAIS)
      }
      if (animal?.A_ivmale_CRSEVS !== "") {
        moyenneCrsevsI++
        moyenneCrsevsT += parseInt(animal?.A_ivmale_CRSEVS)
        moyenneCrsevsIM++
        moyenneCrsevsTM += parseInt(animal?.A_ivmale_CRSEVS)
      }
      if (animal?.A_ivmale_DMSEVS !== "") {
        moyenneDmsevsI++
        moyenneDmsevsT += parseInt(animal?.A_ivmale_DMSEVS)
        moyenneDmsevsIM++
        moyenneDmsevsTM += parseInt(animal?.A_ivmale_DMSEVS)
      }
      if (animal?.A_ivmale_DSSEVS !== "") {
        moyenneDssevsI++
        moyenneDssevsT += parseInt(animal?.A_ivmale_DSSEVS)
        moyenneDssevsIM++
        moyenneDssevsTM += parseInt(animal?.A_ivmale_DSSEVS)
      }
      if (animal?.A_ivmale_FOSEVS !== "") {
        moyenneFosevsI++
        moyenneFosevsT += parseInt(animal?.A_ivmale_FOSEVS)
        moyenneFosevsIM++
        moyenneFosevsTM += parseInt(animal?.A_ivmale_FOSEVS)
      }
      if (animal?.A_ivmale_ISEVRE !== "") {
        moyenneIsevreI++
        moyenneIsevreT += parseInt(animal?.A_ivmale_ISEVRE)
        moyenneIsevreIM++
        moyenneIsevreTM += parseInt(animal?.A_ivmale_ISEVRE)
      }
      if (animal?.A_ivmale_AVELAG !== "") {
        moyenneAvelagI++
        moyenneAvelagT += parseInt(animal?.A_ivmale_AVELAG)
        moyenneAvelagIM++
        moyenneAvelagTM += parseInt(animal?.A_ivmale_AVELAG)
      }
      if (animal?.A_ivmale_ALAITS !== "") {
        moyenneAlaitsI++
        moyenneAlaitsT += parseInt(animal?.A_ivmale_ALAITS)
        moyenneAlaitsIM++
        moyenneAlaitsTM += parseInt(animal?.A_ivmale_ALAITS)
      }
      if (animal?.A_ivmale_IVMATE !== "") {
        moyenneIvmateI++
        moyenneIvmateT += parseInt(animal?.A_ivmale_IVMATE)
        moyenneIvmateIM++
        moyenneIvmateTM += parseInt(animal?.A_ivmale_IVMATE)
      }
      // CONFIRMATION : 6-18 à examiner
      if (toExamine && animal?.age < 547.2) {
        nbMaleToExamineArray.push(animal?.copaip + animal?.nunati)
        nbMaleToExamine++
      }

      // CONFIRMATION : Mâles à confirmer
      if (toConfirm) {
        nbMaleToConfirmArray.push(animal?.copaip + animal?.nunati)
        nbMaleToConfirm++
      }

      // Mâles +6mois non inscrit HBL
      if (toExamine || toConfirm || refuse) {
        nbMalP6MNnInscritArray.push(animal?.copaip + animal?.nunati)
        nbMalP6MNnInscrit++
      }

      // Valeurs for table Index
      nbIndexIfnais = animal?.A_ivmale_IFNAIS
      nbIndexCrsevs = animal?.A_ivmale_CRSEVS
      nbIndexDmsevs = animal?.A_ivmale_DMSEVS
      nbIndexDssevs = animal?.A_ivmale_DSSEVS
      nbIndexFosevs = animal?.A_ivmale_FOSEVS
      nbIndexIsevre = animal?.A_ivmale_ISEVRE
      nbIndexAvelag = animal?.A_ivmale_AVELAG
      nbIndexAlaits = animal?.A_ivmale_ALAITS
      nbIndexIvmate = animal?.A_ivmale_IVMATE
      isu = ""

      nbIndexANNEIN = animal?.A_ivmale_ANNEIN
      nbIndexCDALAI = animal?.A_ivmale_CDALAI
      nbIndexCDAVEL = animal?.A_ivmale_CDAVEL
      nbIndexCDCRSE = animal?.A_ivmale_CDCRSE
      nbIndexCDDMSE = animal?.A_ivmale_CDDMSE
      nbIndexCDDSSE = animal?.A_ivmale_CDDSSE
      nbIndexCDFOSE = animal?.A_ivmale_CDFOSE
      nbIndexCDIFNA = animal?.A_ivmale_CDIFNA
      nbIndexCDISEV = animal?.A_ivmale_CDISEV
      nbIndexCDIVMA = animal?.A_ivmale_CDIVMA
    }

    /////////////////////////////////
    // Calcul pour filter Analyses //
    /////////////////////////////////
    let boolNeedAnalyse = false

    if (
      animal.A_bocog2_cordcg &&
      animal.A_bocog2_cordcg !== "C" &&
      animal.A_bocog2_cordcg !== "D" &&
      animal.A_bocog2_cordcg !== "E" &&
      animal.A_bocog2_cordcg !== "1"
    ) {
      hasIncompatibilityArray.push(fullNum)
      hasIncompatibility++

      boolNeedAnalyse = true
    }

    if (
      animal?.age > 547.2 &&
      animal?.boqual_ETQUBO === "1" &&
      animal?.sexbov === "1" &&
      (animal?.boqual_COQUBO === "05" || animal?.boqual_COQUBO === "06") &&
      animal.A_bocog2_cordcg === ""
    ) {
      maleSansCompatPereArray.push(fullNum)
      maleSansCompatPere++

      boolNeedAnalyse = true
    }

    if (
      animal?.age > 547.2 &&
      animal?.sexbov === "1" &&
      animal.retyup_mh === ""
    ) {
      maleSansMhArray.push(fullNum)
      maleSansMh++

      boolNeedAnalyse = true
    }

    if (
      animal?.age > 547.2 &&
      animal?.sexbov === "1" &&
      animal.A_boange_typana === ""
    ) {
      maleSansProfilAdnArray.push(fullNum)
      maleSansProfilAdn++

      boolNeedAnalyse = true
    }

    if (boolNeedAnalyse) {
      needAnalyseArray.push(fullNum)
      needAnalyse++
    }

    let numAnimal =
      animal?.copaip + animal?.nunati.substring(0, animal?.nunati.length - 4)

    let numTravailAnimal = animal?.nunati.substring(
      animal?.nunati?.length - 4,
      animal?.nunati?.length
    )

    let numTravailMere = animal?.numere.substring(
      animal?.numere?.length - 4,
      animal?.numere?.length
    )

    let numTravailPere = animal?.nupere.substring(
      animal?.nupere?.length - 4,
      animal?.nupere?.length
    )

    let year = animal?.danais?.substring(0, 4)
    let month = animal?.danais?.substring(5, 7)
    let day = animal?.danais?.substring(8, 10)

    let dateNaissobj = new Date(year, month - 1, day)

    let ADN_filter = ""
    if (
      animal.A_boange_codeut === "1" ||
      animal.A_boange_codeut === "4" ||
      animal.A_boange_codeut === "5" ||
      animal.A_boange_codeut === "8"
    ) {
      ADN_filter = animal.A_boange_typana_txt + " utilisable"
    } else if (animal.A_boange_codeut === "2") {
      ADN_filter = animal.A_boange_typana_txt + " inutilisable"
    } else if (animal.A_boange_codeut === "9") {
      ADN_filter = animal.A_boange_typana_txt + " inconnu en bdnsat"
    }

    let paf_filter = ""

    if (animal?.retyup_paf === "+/+") paf_filter = "Non porteur"
    else if (animal?.retyup_paf === "PAL/+") paf_filter = "Porteur hétérozygote"
    else if (animal?.retyup_paf === "PAL/PAL") paf_filter = "Porteur homozygote"

    let exam_mere_txt = ""
    let cor = animal?.M_borexa_1_coreex

    if (cor === "R") {
      exam_mere_txt = "Examen Refusé"
    } else if (cor === "C") {
      exam_mere_txt = `Examen Conforme (${animal?.M_borexa_1_libeco})`
    } else if (cor === "A") {
      exam_mere_txt = "Examen Ajourné"
    }

    let exam_pere_txt = ""
    cor = animal?.P_borexa_1_coreex

    if (cor === "R") {
      exam_pere_txt = "Examen Refusé"
    } else if (cor === "C") {
      exam_pere_txt = `Examen Conforme (${animal?.P_borexa_1_libeco})`
    } else if (cor === "A") {
      exam_pere_txt = "Examen Ajourné"
    }

    let inscrExamMere
    if (animal.M_boqual_LIBECO) inscrExamMere = animal.M_boqual_LIBECO
    else inscrExamMere = exam_mere_txt

    let retyuptrtxt = ""

    if (animal?.retyup_tr === "NR") retyuptrtxt = "Non transloqué"
    if (animal?.retyup_tr === "RO") retyuptrtxt = "Transloqué"

    let nbProduitFemelle = 0
    let nbProduitMale = 0

    animal?.produits &&
      animal?.produits.forEach(produit => {
        if (produit?.enfant_sexbov === "2") {
          nbProduitFemelle++
        } else if (produit?.enfant_sexbov === "1") {
          nbProduitMale++
        }
      })

    tmpDataAni[fullNum] = {
      // INFO ANIMAL
      isInInventory: true,
      numAnimal: numAnimal,
      numTravailAnimal: numTravailAnimal,
      ts_photos: animal?.ts_photos,
      COPAIP: animal?.copaip,
      NUNATI: animal?.nunati,
      SEXBOV: animal?.sexbov,
      DANAIS: formatDate2(animal?.danais),
      NOBOVI: animal?.nobovi,
      CORABO: animal?.corabo,
      COPCNA: animal?.copcna,
      NUCHNA: animal?.nuchna,
      cheptelNaisseur: animal?.copcna + animal?.nuchna,
      naisseurTxt: animal?.NAISSEUR_TXT,
      detenteurNom: animal?.DETENTEUR_nom,
      detenteurNum: animal?.DETENTEUR_num,
      isDetenteur: animal?.isDetenteur,
      ipgmou_daench: formatDate2(animal?.ipgmou_daench),
      age: animal?.age,
      age_format: animal?.age_format,
      age_month: monthAmount(dateNaissobj),
      COFGMU: animal?.cofgmu,
      produits: animal?.produits,
      nbProduits: animal?.produits.length,
      nbProduitFemelle: nbProduitFemelle,
      nbProduitMale: nbProduitMale,
      passage: animal?.PASSAGE,
      // INFOS DECLARATIONS
      canBeDeclared: animal?.declarable_lanaud,
      dejaDeclare: animal?.deja_declare_lanaud,
      catasc: animal?.catasc,
      // INFOS POIDS NAISSANCE
      TOUPOI: animal?.toupoi,
      PONAIS: animal?.ponais,
      pntp: animal?.ponais ? animal?.ponais : animal?.toupoi,
      bopoat120: animal?.A_bopoat_120_POAGTY,
      bopoat210: animal?.A_bopoat_210_POAGTY,
      // INFOS CONFIRMATIONS
      erreurs: animal?.erreurs_confirm,
      reponse: animal?.reponse_confirm,
      // INFOS EXAMENS
      borexa_a_CORADI: animal?.borexa_a_CORADI,
      borexa_a_COREEX: animal?.borexa_a_COREEX,
      borexa_a_ETQUBO: animal?.borexa_a_ETQUBO,
      borexa_c_CORADI: animal?.borexa_c_CORADI,
      borexa_c_COREEX: animal?.borexa_c_COREEX,
      borexa_c_ETQUBO: animal?.borexa_c_ETQUBO,
      examAni: animal?.borexa_1_libeco,
      examAniLIBELO: animal?.borexa_1_libelo,
      examAniLIDETA: animal?.borexa_1_lideta,
      borexa_coreex: animal?.borexa_1_COREEX,
      borexa_daexbo: formatDate2(animal?.borexa_1_DAEXBO),
      borexa_etqubo: animal?.borexa_1_ETQUBO,
      borexa_coqubo: animal?.borexa_1_COQUBO,
      borexa_ccqubo: animal?.borexa_1_CCQUBO,
      borexa_copace: animal?.borexa_1_COPACE,
      borexa_nuchex: animal?.borexa_1_NUCHEX,
      borexa_copoin: animal?.borexa_1_COPOIN,
      borexa_corefu: animal?.borexa_1_COREFU,
      borexaCorefuLib: animal?.borexa_corefu_libelle,
      // INFOS CERTIF
      inscrAnimal: animal?.boqual_LIBECO,
      inscrAnimalLIDETA: animal?.boqual_LIDETA,
      inscrAnimalLIBELO: animal?.boqual_LIBELO,
      A_boqual_ETQUBO: animal?.boqual_ETQUBO,
      A_boqual_COQUBO: animal?.boqual_COQUBO,
      A_boqual_CCQUBO: animal?.boqual_CCQUBO,
      A_boqual_DAQUBO: formatDate2(animal?.boqual_DAQUBO),
      // INFOS QUALIF
      qualifAnimal: animal?.bestqual_LIBECO,
      qualifAnimalLIDETA: animal?.bestqual_LIDETA,
      qualifAnimalLIBELO: animal?.bestqual_LIBELO,
      A_bestqual_ETQUBO: animal?.bestqual_ETQUBO,
      A_bestqual_COQUBO: animal?.bestqual_COQUBO,
      A_bestqual_CCQUBO: animal?.bestqual_CCQUBO,
      A_bestqual_COCOUT: animal?.bestqual_COCOUT,
      A_bestqual_NIQUBO: animal?.bestqual_NIQUBO,
      A_bestqual_SNQUBO: animal?.bestqual_SNQUBO,
      A_bestqual_COPACQ: animal?.bestqual_COPACQ,
      A_bestqual_NUCHQU: animal?.bestqual_NUCHQU,
      A_bestqual_DAQUBO: formatDate2(animal?.bestqual_DAQUBO),
      A_bestqual_DAEXBO: formatDate2(animal?.bestqual_DAEXBO),
      A_bestqual_COPOIN: animal?.bestqual_COPOIN,
      // INDEXS
      nbIndexIfnais: nbIndexIfnais,
      nbIndexCrsevs: nbIndexCrsevs,
      nbIndexDmsevs: nbIndexDmsevs,
      nbIndexDssevs: nbIndexDssevs,
      nbIndexFosevs: nbIndexFosevs,
      nbIndexIsevre: nbIndexIsevre,
      nbIndexAvelag: nbIndexAvelag,
      nbIndexAlaits: nbIndexAlaits,
      nbIndexIvmate: nbIndexIvmate,
      nbIndexANNEIN: nbIndexANNEIN,
      nbIndexCDALAI: nbIndexCDALAI,
      nbIndexCDAVEL: nbIndexCDAVEL,
      nbIndexCDCRSE: nbIndexCDCRSE,
      nbIndexCDDMSE: nbIndexCDDMSE,
      nbIndexCDDSSE: nbIndexCDDSSE,
      nbIndexCDFOSE: nbIndexCDFOSE,
      nbIndexCDIFNA: nbIndexCDIFNA,
      nbIndexCDISEV: nbIndexCDISEV,
      nbIndexCDIVMA: nbIndexCDIVMA,
      // ISU FEMELLE
      isu: isu,
      boisuf_BAREIS: animal?.A_boisuf_BAREIS,
      boisuf_DACAIS: animal?.A_boisuf_DACAIS,
      // DONNES REPRO FEMELLE
      RADEVE: animal?.A_RADEVE,
      AGE1VE: animal?.A_AGE1VE,
      COV1VE: animal?.A_COV1VE,
      DADEVE: formatDate2(animal?.A_DADEVE),
      NIVVCA: animal?.A_NIVVCA,
      IVVMOY: animal?.A_IVVMOY,
      IVV1A2: animal?.A_IVV1A2,
      IVV2PL: animal?.A_IVV2PL,
      Adacalc: animal?.A_DACALC,
      // DONNEES ANALYSES
      CODEUT: animal?.A_boange_codeut,
      TYPANA: animal?.A_boange_typana,
      TYPANA_txt: animal?.A_boange_typana_txt,
      DAOBAN: formatDate2(animal?.A_boange_daoban),
      CORDCG: animal?.A_bocog2_cordcg,
      cordcg_txt: animal?.A_bocog2_cordcg_txt,
      filiation_filter: animal?.A_bocog2_cordcg
        ? animal?.A_bocog2_cordcg_txt
        : "",
      dartcg: formatDate2(animal?.A_bocog2_dartcg),
      retyupsc: animal?.retyup_sc,
      daretysc: formatDate2(animal?.darety_sc),
      NUTYAN_SC: animal.nutyan_sc,
      retyupmh: animal?.retyup_mh,
      daretymh: formatDate2(animal?.darety_mh),
      NUTYAN_MH: animal.nutyan_mh,
      retyuppaf: animal?.retyup_paf,
      paf_filter: paf_filter,
      daretypaf: formatDate2(animal?.darety_paf),
      NUTYAN_PAF: animal.nutyan_paf,
      retyuppro: animal?.retyup_pro,
      daretypro: formatDate2(animal?.darety_pro),
      retyupbli: animal?.retyup_bli,
      daretybli: formatDate2(animal?.darety_bli),
      RETYUP_TR: animal?.retyup_tr,
      retyuptrtxt: retyuptrtxt,
      daretytr: formatDate2(animal?.darety_tr),
      NUTYAN_TR: animal.darety_hpf,
      DARETY_HPF: animal.darety_hpf,
      RETYUP_HPF: animal.retyup_hpf,
      NUTYAN_HPF: animal.nutyan_hpf,
      IDLABO: animal?.Evalim_IDLABO,
      CIFNAI: animal?.Evalim_CIFNAI,
      CCRSEV: animal?.Evalim_CCRSEV,
      CDMSEV: animal?.Evalim_CDMSEV,
      CDSSEV: animal?.Evalim_CDSSEV,
      CFOSSE: animal?.Evalim_CFOSSE,
      CAVELS: animal?.Evalim_CAVELS,
      CALAIT: animal?.Evalim_CALAIT,
      COPSEV: animal?.Evalim_COPSEV,
      BASREF: animal?.Evalim_BASREF,
      CMADPJ: animal.Evalim_CMADPJ,
      CMAGRT: animal.Evalim_CMAGRT,
      CMALGT: animal.Evalim_CMALGT,
      CMAMEQ: animal.Evalim_CMAMEQ,
      DATINS: formatDate2(animal.Evalim_DATINS),
      COPAMT: animal.A_bocog2_copamt,
      NUNAMT: animal.A_bocog2_nunamt,
      COPAPT: animal.A_bocog2_copapt,
      NUNAPT: animal.A_bocog2_nunapt,
      NUECHA: animal.A_bocog2_nuecha,
      ADN_filter: ADN_filter,
      Evalim_filter: animal.Evalim_IDLABO ? "Avec Evalim" : "Sans Evalim",
      filiation: animal?.filiation,
      // PERFORMANCES
      Aifnais:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_IFNAIS
          : animal?.A_ivmale_IFNAIS,
      Acrsevs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CRSEVS
          : animal?.A_ivmale_CRSEVS,
      Admsevs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_DMSEVS
          : animal?.A_ivmale_DMSEVS,
      Adssevs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_DSSEVS
          : animal?.A_ivmale_DSSEVS,
      Aalaits:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_ALAITS
          : animal?.A_ivmale_ALAITS,
      Aavelag:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_AVELAG
          : animal?.A_ivmale_AVELAG,
      Afosevs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_FOSEVS
          : animal?.A_ivmale_FOSEVS,
      Aisevre:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_ISEVRE
          : animal?.A_ivmale_ISEVRE,
      Aivmate:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_IVMATE
          : animal?.A_ivmale_IVMATE,
      Atypein:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_TYPEIN
          : animal?.A_ivmale_TYPEIN,
      Acdifna:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDIFNA
          : animal?.A_ivmale_CDIFNA,
      Acdcrse:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDCRSE
          : animal?.A_ivmale_CDCRSE,
      Acddmse:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDDMSE
          : animal?.A_ivmale_CDDMSE,
      Acddsse:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDDSSE
          : animal?.A_ivmale_CDDSSE,
      Acdalai:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDALAI
          : animal?.A_ivmale_CDALAI,
      Acdavel:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDAVEL
          : animal?.A_ivmale_CDAVEL,
      Acdfose:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDFOSE
          : animal?.A_ivmale_CDFOSE,
      Acdisev:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDISEV
          : animal?.A_ivmale_CDISEV,
      Acdivma:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDIVMA
          : animal?.A_ivmale_CDIVMA,
      Aannein:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_ANNEIN
          : animal?.A_ivmale_ANNEIN,
      Arangin:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_RANGIN
          : animal?.A_ivmale_RANGIN,
      Aboisuf: animal?.A_boisuf_INDISU,
      Acompse:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_COMPSE
          : animal?.A_ivmale_COMPSE,
      Acdcomp:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDCOMP
          : animal?.A_ivmale_CDCOMP,
      Areacse:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_REACSE
          : animal?.A_ivmale_REACSE,
      Acdreac:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDREAC
          : animal?.A_ivmale_CDREAC,
      Acrpsfs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CRPSFS
          : animal?.A_ivmale_CRPSFS,
      Admpsfs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_DMPSFS
          : animal?.A_ivmale_DMPSFS,
      Adspsfs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_DSPSFS
          : animal?.A_ivmale_DSPSFS,
      Abaspss:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_BASPSS
          : animal?.A_ivmale_BASPSS,
      Aafpsfs:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_AFPSFS
          : animal?.A_ivmale_AFPSFS,
      Afospss:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_FOSPSS
          : animal?.A_ivmale_FOSPSS,
      Acdcpsf:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDCPSF
          : animal?.A_ivmale_CDCPSF,
      Acddmps:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDDMPS
          : animal?.A_ivmale_CDDMPS,
      Acddsps:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDDSPS
          : animal?.A_ivmale_CDDSPS,
      Acdbasp:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDBASP
          : animal?.A_ivmale_CDBASP,
      Acdafps:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDAFPS
          : animal?.A_ivmale_CDAFPS,
      Acdfosp:
        animal?.sexbov === "2"
          ? animal?.A_ivfemr_CDFOSP
          : animal?.A_ivmale_CDFOSP,
      // POINTAGES
      bopoalNoetpo: animal?.bopoal_NOETPO,
      bopoalDeepau: animal?.bopoal_DEEPAU,
      bopoalLados: animal?.bopoal_LADOS,
      bopoalArculo: animal?.bopoal_ARCULO,
      bopoalLaculo: animal?.bopoal_LACULO,
      bopoalLoculo: animal?.bopoal_LOCULO,
      bopoalEpdess: animal?.bopoal_EPDESS,
      bopoalDevmus: animal?.bopoal_DEVMUS,
      bopoalLodess: animal?.bopoal_LODESS,
      bopoalLobass: animal?.bopoal_LOBASS,
      bopoalLahanc: animal?.bopoal_LAHANC,
      bopoalDevelo: animal?.bopoal_DEVELO,
      bopoalDevsqe: animal?.bopoal_DEVSQE,
      bopoalLamufl: animal?.bopoal_LAMUFL,
      bopoalApavan: animal?.bopoal_APAVAN,
      bopoalAparri: animal?.bopoal_APARRI,
      bopoalRedess: animal?.bopoal_REDESS,
      bopoalAptfon: animal?.bopoal_APTFON,
      bopoalPrpoit: animal?.bopoal_PRPOIT,
      bopoalLapoit: animal?.bopoal_LAPOIT,
      bopoalLatroc: animal?.bopoal_LATROC,
      bopoalGrcano: animal?.bopoal_GRCANO,
      A_bopaps_posp01: animal?.A_BOPAPS_POSP01,
      A_bopaps_posp02: animal?.A_BOPAPS_POSP02,
      A_bopaps_posp03: animal?.A_BOPAPS_POSP03,
      A_bopaps_posp04: animal?.A_BOPAPS_POSP04,
      A_bopaps_posp05: animal?.A_BOPAPS_POSP05,
      A_bopaps_posp06: animal?.A_BOPAPS_POSP06,
      A_bopaps_posp11: animal?.A_BOPAPS_POSP11,
      A_bopaps_posp12: animal?.A_BOPAPS_POSP12,
      A_bopaps_posp13: animal?.A_BOPAPS_POSP13,
      A_bopaps_posp14: animal?.A_BOPAPS_POSP14,
      A_bopaps_posp16: animal?.A_BOPAPS_POSP16,
      A_bopaps_posp15: animal?.A_BOPAPS_POSP15,
      A_bopaps_posp17: animal?.A_BOPAPS_POSP17,
      A_bopaps_posp18: animal?.A_BOPAPS_POSP18,
      A_bopaps_posp20: animal?.A_BOPAPS_POSP20,
      A_bopaps_posp31: animal?.A_BOPAPS_POSP31,
      A_bopaps_posp19: animal?.A_BOPAPS_POSP19,
      A_bopaps_posp34: animal?.A_BOPAPS_POSP34,
      A_bopaps_posp35: animal?.A_BOPAPS_POSP35,
      A_bopaps_posp36: animal?.A_BOPAPS_POSP36,
      A_bopaps_posp21: animal?.A_BOPAPS_POSP21,
      A_bopaps_posp26: animal?.A_BOPAPS_POSP26,
      A_bopaps_posp27: animal?.A_BOPAPS_POSP27,
      A_bopaps_posp33: animal?.A_BOPAPS_POSP33,
      A_bopaps_posp22: animal?.A_BOPAPS_POSP22,
      A_bopaps_posp23: animal?.A_BOPAPS_POSP23,
      A_bopaps_posp24: animal?.A_BOPAPS_POSP24,
      A_bopaps_posp25: animal?.A_BOPAPS_POSP25,
      A_bopaps_posp28: animal?.A_BOPAPS_POSP28,
      A_bopaps_posp29: animal?.A_BOPAPS_POSP29,
      A_bopaps_posp30: animal?.A_BOPAPS_POSP30,
      A_bopaps_posp32: animal?.A_BOPAPS_POSP32,
      // INFO GENEALOGIE
      AanalyseVcgGenreCompat2p: animal?.A_analyse_VCG_gene_compat_2p,
      // MERE
      Mnobovi: animal?.M_bovide_NOBOVI,
      Mcopaip: animal?.M_bovide_COPAIP,
      Mnunati: animal?.M_bovide_NUNATI,
      numMere: animal?.copame + animal?.numere,
      numTravailMere: numTravailMere,
      qualifMere: animal?.M_bestqual_LIBECO,
      qualifMereLIDETA: animal?.M_bestqual_LIDETA,
      qualifMereLIBELO: animal?.M_bestqual_LIBELO,
      inscrMere: animal?.M_boqual_LIBECO,
      inscrMereLIDETA: animal?.M_boqual_LIDETA,
      inscrMereLIBELO: animal?.M_boqual_LIBELO,
      M_bestqual_ETQUBO: animal?.M_bestqual_ETQUBO,
      M_bestqual_COQUBO: animal?.M_bestqual_COQUBO,
      M_bestqual_CCQUBO: animal?.M_bestqual_CCQUBO,
      M_bestqual_COCOUT: animal?.M_bestqual_COCOUT,
      M_bestqual_NIQUBO: animal?.M_bestqual_NIQUBO,
      M_bestqual_SNQUBO: animal?.M_bestqual_SNQUBO,
      M_bestqual_COPACQ: animal?.M_bestqual_COPACQ,
      M_bestqual_NUCHQU: animal?.M_bestqual_NUCHQU,
      M_bestqual_DAQUBO: formatDate2(animal?.M_bestqual_DAQUBO),
      M_bestqual_DAEXBO: formatDate2(animal?.M_bestqual_DAEXBO),
      M_bestqual_COPOIN: animal?.M_bestqual_COPOIN,
      examMere: animal?.M_borexa_1_libeco,
      ccqubo_exa_mere: animal?.M_borexa_1_ccqubo,
      coqubo_exa_mere: animal?.M_borexa_1_coqubo,
      inscrExamMere: inscrExamMere,
      examMereLIBELO: animal?.M_borexa_1_libelo,
      examMereLIDETA: animal?.M_borexa_1_lideta,
      coreex_exa_mere: animal?.M_borexa_1_coreex,
      exam_mere_txt: exam_mere_txt,
      //Data index mere genealogie
      Mifnais: animal?.M_ifnais,
      Mcrsevs: animal?.M_crsevs,
      Mdmsevs: animal?.M_dmsevs,
      Mdssevs: animal?.M_dssevs,
      Mfosevs: animal?.M_fosevs,
      Misevre: animal?.M_isevre,
      Mavelag: animal?.M_avelag,
      Malaits: animal?.M_alaits,
      Mivmate: animal?.M_ivmate,
      Mcdifna: animal?.M_cdifna,
      Mcdcrse: animal?.M_cdcrse,
      Mcddmse: animal?.M_cddmse,
      Mcddsse: animal?.M_cddsse,
      Mcdfose: animal?.M_cdfose,
      Mcdisev: animal?.M_cdisev,
      Mcdavel: animal?.M_cdavel,
      Mcdalai: animal?.M_cdalai,
      Mcdivma: animal?.M_cdivma,
      Mannein: animal?.M_annein,
      Mrangin: animal?.M_rangin,
      Mtypein: animal?.M_typein,
      //PERE
      numPere: animal?.copape + animal?.nupere,
      numTravailPere: numTravailPere,
      Pnobovi: animal?.P_bovide_NOBOVI,
      Pcopaip: animal?.P_bovide_COPAIP,
      Pnunati: animal?.P_bovide_NUNATI,
      qualifPere: animal?.P_bestqual_LIBECO,
      qualifPereLIDETA: animal?.P_bestqual_LIDETA,
      qualifPereLIBELO: animal?.P_bestqual_LIBELO,
      inscrPere: animal?.P_boqual_LIBECO,
      inscrPereLIDETA: animal?.P_boqual_LIDETA,
      inscrPereLIBELO: animal?.P_boqual_LIBELO,
      P_bestqual_ETQUBO: animal?.P_bestqual_ETQUBO,
      P_bestqual_COQUBO: animal?.P_bestqual_COQUBO,
      P_bestqual_CCQUBO: animal?.P_bestqual_CCQUBO,
      P_bestqual_COCOUT: animal?.P_bestqual_COCOUT,
      P_bestqual_NIQUBO: animal?.P_bestqual_NIQUBO,
      P_bestqual_SNQUBO: animal?.P_bestqual_SNQUBO,
      P_bestqual_COPACQ: animal?.P_bestqual_COPACQ,
      P_bestqual_NUCHQU: animal?.P_bestqual_NUCHQU,
      P_bestqual_DAQUBO: formatDate2(animal?.P_bestqual_DAQUBO),
      P_bestqual_DAEXBO: formatDate2(animal?.P_bestqual_DAEXBO),
      P_bestqual_COPOIN: animal?.P_bestqual_COPOIN,
      examPere: animal?.P_borexa_1_libeco,
      ccqubo_exa_pere: animal?.P_borexa_1_ccqubo,
      coqubo_exa_pere: animal?.P_borexa_1_coqubo,
      examPereLIBELO: animal?.P_borexa_1_libelo,
      examPereLIDETA: animal?.P_borexa_1_lideta,
      coreex_exa_pere: animal?.P_borexa_1_coreex,
      exam_pere_txt: exam_pere_txt,
      P_bocog2_cordcg: animal?.P_cordcg,
      P_bocog2_cordcg_txt: animal?.P_cordcg_txt,
      retyupPere: animal?.P_mh_retyup,
      nuechaPere: animal?.P_boange_nuecha,
      //Data index pere genealogie
      Pifnais: animal?.P_ifnais,
      Pcrsevs: animal?.P_crsevs,
      Pdmsevs: animal?.P_dmsevs,
      Pdssevs: animal?.P_dssevs,
      Pfosevs: animal?.P_fosevs,
      Pisevre: animal?.P_isevre,
      Pavelag: animal?.P_avelag,
      Palaits: animal?.P_alaits,
      Pivmate: animal?.P_ivmate,
      Pcdifna: animal?.P_cdifna,
      Pcdcrse: animal?.P_cdcrse,
      Pcddmse: animal?.P_cddmse,
      Pcddsse: animal?.P_cddsse,
      Pcdfose: animal?.P_cdfose,
      Pcdisev: animal?.P_cdisev,
      Pcdavel: animal?.P_cdavel,
      Pcdalai: animal?.P_cdalai,
      Pcdivma: animal?.P_cdivma,
      Pannein: animal?.P_annein,
      Prangin: animal?.P_rangin,
      Ptypein: animal?.P_typein,
      //GPP
      GPPnobovi: animal?.GPP_bovide_NOBOVI,
      GPPcopaip: animal?.GPP_bovide_COPAIP,
      GPPnunati: animal?.GPP_bovide_NUNATI,
      numGPP: animal?.GPP_bovide_COPAIP + animal?.GPP_bovide_NUNATI,
      nomGPP: animal?.GPP_bovide_NOBOVI,
      qualifGPP: animal?.GPP_bestqual_LIBECO,
      qualifGPPLIDETA: animal?.GPP_bestqual_LIDETA,
      qualifGPPLIBELO: animal?.GPP_bestqual_LIBELO,
      inscrGPP: animal?.GPP_boqual_LIBECO,
      inscrGPPLIDETA: animal?.GPP_boqual_LIDETA,
      inscrGPPLIBELO: animal?.GPP_boqual_LIBELO,
      GPP_bestqual_ETQUBO: animal?.GPP_bestqual_ETQUBO,
      GPP_bestqual_COQUBO: animal?.GPP_bestqual_COQUBO,
      GPP_bestqual_CCQUBO: animal?.GPP_bestqual_CCQUBO,
      GPP_bestqual_COCOUT: animal?.GPP_bestqual_COCOUT,
      GPP_bestqual_NIQUBO: animal?.GPP_bestqual_NIQUBO,
      GPP_bestqual_SNQUBO: animal?.GPP_bestqual_SNQUBO,
      GPP_bestqual_COPACQ: animal?.GPP_bestqual_COPACQ,
      GPP_bestqual_NUCHQU: animal?.GPP_bestqual_NUCHQU,
      GPP_bestqual_DAQUBO: formatDate2(animal?.GPP_bestqual_DAQUBO),
      GPP_bestqual_DAEXBO: formatDate2(animal?.GPP_bestqual_DAEXBO),
      GPP_bestqual_COPOIN: animal?.GPP_bestqual_COPOIN,
      // PGPP
      PGPPnobovi: animal?.PGPP_bovide_NOBOVI,
      PGPPcopaip: animal?.PGPP_bovide_COPAIP,
      PGPPnunati: animal?.PGPP_bovide_NUNATI,
      numPGPP: animal?.PGPP_bovide_COPAIP + animal?.PGPP_bovide_NUNATI,
      nomPGPP: animal?.PGPP_bovide_NOBOVI,
      qualifPGPP: animal?.PGPP_bestqual_LIBECO,
      qualifPGPPLIDETA: animal?.PGPP_bestqual_LIDETA,
      qualifPGPPLIBELO: animal?.PGPP_bestqual_LIBELO,
      inscrPGPP: animal?.PGPP_boqual_LIBECO,
      inscrPGPPLIDETA: animal?.PGPP_boqual_LIDETA,
      inscrPGPPLIBELO: animal?.PGPP_boqual_LIBELO,
      PGPP_bestqual_ETQUBO: animal?.PGPP_bestqual_ETQUBO,
      PGPP_bestqual_COQUBO: animal?.PGPP_bestqual_COQUBO,
      PGPP_bestqual_CCQUBO: animal?.PGPP_bestqual_CCQUBO,
      PGPP_bestqual_COCOUT: animal?.PGPP_bestqual_COCOUT,
      PGPP_bestqual_NIQUBO: animal?.PGPP_bestqual_NIQUBO,
      PGPP_bestqual_SNQUBO: animal?.PGPP_bestqual_SNQUBO,
      PGPP_bestqual_COPACQ: animal?.PGPP_bestqual_COPACQ,
      PGPP_bestqual_NUCHQU: animal?.PGPP_bestqual_NUCHQU,
      PGPP_bestqual_DAQUBO: formatDate2(animal?.PGPP_bestqual_DAQUBO),
      PGPP_bestqual_DAEXBO: formatDate2(animal?.PGPP_bestqual_DAEXBO),
      PGPP_bestqual_COPOIN: animal?.PGPP_bestqual_COPOIN,
      // MGPP
      MGPPnobovi: animal?.MGPP_bovide_NOBOVI,
      MGPPcopaip: animal?.MGPP_bovide_COPAIP,
      MGPPnunati: animal?.MGPP_bovide_NUNATI,
      numMGPP: animal?.MGPP_bovide_COPAIP + animal?.MGPP_bovide_NUNATI,
      nomMGPP: animal?.MGPP_bovide_NOBOVI,
      qualifMGPP: animal?.MGPP_bestqual_LIBECO,
      qualifMGPPLIDETA: animal?.MGPP_bestqual_LIDETA,
      qualifMGPPLIBELO: animal?.MGPP_bestqual_LIBELO,
      inscrMGPP: animal?.MGPP_boqual_LIBECO,
      inscrMGPPLIDETA: animal?.MGPP_boqual_LIDETA,
      inscrMGPPLIBELO: animal?.MGPP_boqual_LIBELO,
      MGPP_bestqual_ETQUBO: animal?.MGPP_bestqual_ETQUBO,
      MGPP_bestqual_COQUBO: animal?.MGPP_bestqual_COQUBO,
      MGPP_bestqual_CCQUBO: animal?.MGPP_bestqual_CCQUBO,
      MGPP_bestqual_COCOUT: animal?.MGPP_bestqual_COCOUT,
      MGPP_bestqual_NIQUBO: animal?.MGPP_bestqual_NIQUBO,
      MGPP_bestqual_SNQUBO: animal?.MGPP_bestqual_SNQUBO,
      MGPP_bestqual_COPACQ: animal?.MGPP_bestqual_COPACQ,
      MGPP_bestqual_NUCHQU: animal?.MGPP_bestqual_NUCHQU,
      MGPP_bestqual_DAQUBO: formatDate2(animal?.MGPP_bestqual_DAQUBO),
      MGPP_bestqual_DAEXBO: formatDate2(animal?.MGPP_bestqual_DAEXBO),
      MGPP_bestqual_COPOIN: animal?.MGPP_bestqual_COPOIN,
      // GMP
      GMPnobovi: animal?.GMP_bovide_NOBOVI,
      GMPcopaip: animal?.GMP_bovide_COPAIP,
      GMPnunati: animal?.GMP_bovide_NUNATI,
      numGMP: animal?.GMP_bovide_COPAIP + animal?.GMP_bovide_NUNATI,
      nomGMP: animal?.GMP_bovide_NOBOVI,
      qualifGMP: animal?.GMP_bestqual_LIBECO,
      qualifGMPLIDETA: animal?.GMP_bestqual_LIDETA,
      qualifGMPLIBELO: animal?.GMP_bestqual_LIBELO,
      inscrGMP: animal?.GMP_boqual_LIBECO,
      inscrGMPLIDETA: animal?.GMP_boqual_LIDETA,
      inscrGMPLIBELO: animal?.GMP_boqual_LIBELO,
      GMP_bestqual_ETQUBO: animal?.GMP_bestqual_ETQUBO,
      GMP_bestqual_COQUBO: animal?.GMP_bestqual_COQUBO,
      GMP_bestqual_CCQUBO: animal?.GMP_bestqual_CCQUBO,
      GMP_bestqual_COCOUT: animal?.GMP_bestqual_COCOUT,
      GMP_bestqual_NIQUBO: animal?.GMP_bestqual_NIQUBO,
      GMP_bestqual_SNQUBO: animal?.GMP_bestqual_SNQUBO,
      GMP_bestqual_COPACQ: animal?.GMP_bestqual_COPACQ,
      GMP_bestqual_NUCHQU: animal?.GMP_bestqual_NUCHQU,
      GMP_bestqual_DAQUBO: formatDate2(animal?.GMP_bestqual_DAQUBO),
      GMP_bestqual_DAEXBO: formatDate2(animal?.GMP_bestqual_DAEXBO),
      GMP_bestqual_COPOIN: animal?.GMP_bestqual_COPOIN,
      // PGMP
      PGMPnobovi: animal?.PGMP_bovide_NOBOVI,
      PGMPcopaip: animal?.PGMP_bovide_COPAIP,
      PGMPnunati: animal?.PGMP_bovide_NUNATI,
      numPGMP: animal?.PGMP_bovide_COPAIP + animal?.PGMP_bovide_NUNATI,
      nomPGMP: animal?.PGMP_bovide_NOBOVI,
      qualifPGMP: animal?.PGMP_bestqual_LIBECO,
      qualifPGMPLIDETA: animal?.PGMP_bestqual_LIDETA,
      qualifPGMPLIBELO: animal?.PGMP_bestqual_LIBELO,
      inscrPGMP: animal?.PGMP_boqual_LIBECO,
      inscrPGMPLIDETA: animal?.PGMP_boqual_LIDETA,
      inscrPGMPLIBELO: animal?.PGMP_boqual_LIBELO,
      PGMP_bestqual_ETQUBO: animal?.PGMP_bestqual_ETQUBO,
      PGMP_bestqual_COQUBO: animal?.PGMP_bestqual_COQUBO,
      PGMP_bestqual_CCQUBO: animal?.PGMP_bestqual_CCQUBO,
      PGMP_bestqual_COCOUT: animal?.PGMP_bestqual_COCOUT,
      PGMP_bestqual_NIQUBO: animal?.PGMP_bestqual_NIQUBO,
      PGMP_bestqual_SNQUBO: animal?.PGMP_bestqual_SNQUBO,
      PGMP_bestqual_COPACQ: animal?.PGMP_bestqual_COPACQ,
      PGMP_bestqual_NUCHQU: animal?.PGMP_bestqual_NUCHQU,
      PGMP_bestqual_DAQUBO: formatDate2(animal?.PGMP_bestqual_DAQUBO),
      PGMP_bestqual_DAEXBO: formatDate2(animal?.PGMP_bestqual_DAEXBO),
      PGMP_bestqual_COPOIN: animal?.PGMP_bestqual_COPOIN,
      // MGMP
      MGMPnobovi: animal?.MGMP_bovide_NOBOVI,
      MGMPcopaip: animal?.MGMP_bovide_COPAIP,
      MGMPnunati: animal?.MGMP_bovide_NUNATI,
      numMGMP: animal?.MGMP_bovide_COPAIP + animal?.MGMP_bovide_NUNATI,
      nomMGMP: animal?.MGMP_bovide_NOBOVI,
      qualifMGMP: animal?.MGMP_bestqual_LIBECO,
      qualifMGMPLIDETA: animal?.MGMP_bestqual_LIDETA,
      qualifMGMPLIBELO: animal?.MGMP_bestqual_LIBELO,
      inscrMGMP: animal?.MGMP_boqual_LIBECO,
      inscrMGMPLIDETA: animal?.MGMP_boqual_LIDETA,
      inscrMGMPLIBELO: animal?.MGMP_boqual_LIBELO,
      MGMP_bestqual_ETQUBO: animal?.MGMP_bestqual_ETQUBO,
      MGMP_bestqual_COQUBO: animal?.MGMP_bestqual_COQUBO,
      MGMP_bestqual_CCQUBO: animal?.MGMP_bestqual_CCQUBO,
      MGMP_bestqual_COCOUT: animal?.MGMP_bestqual_COCOUT,
      MGMP_bestqual_NIQUBO: animal?.MGMP_bestqual_NIQUBO,
      MGMP_bestqual_SNQUBO: animal?.MGMP_bestqual_SNQUBO,
      MGMP_bestqual_COPACQ: animal?.MGMP_bestqual_COPACQ,
      MGMP_bestqual_NUCHQU: animal?.MGMP_bestqual_NUCHQU,
      MGMP_bestqual_DAQUBO: formatDate2(animal?.MGMP_bestqual_DAQUBO),
      MGMP_bestqual_DAEXBO: formatDate2(animal?.MGMP_bestqual_DAEXBO),
      MGMP_bestqual_COPOIN: animal?.MGMP_bestqual_COPOIN,
      // GPM
      GPMnobovi: animal?.GPM_bovide_NOBOVI,
      GPMcopaip: animal?.GPM_bovide_COPAIP,
      GPMnunati: animal?.GPM_bovide_NUNATI,
      numGPM: animal?.GPM_bovide_COPAIP + animal?.GPM_bovide_NUNATI,
      nomGPM: animal?.GPM_bovide_NOBOVI,
      qualifGPM: animal?.GPM_bestqual_LIBECO,
      qualifGPMLIDETA: animal?.GPM_bestqual_LIDETA,
      qualifGPMLIBELO: animal?.GPM_bestqual_LIBELO,
      inscrGPM: animal?.GPM_boqual_LIBECO,
      inscrGPMLIDETA: animal?.GPM_boqual_LIDETA,
      inscrGPMLIBELO: animal?.GPM_boqual_LIBELO,
      GPM_bestqual_ETQUBO: animal?.GPM_bestqual_ETQUBO,
      GPM_bestqual_COQUBO: animal?.GPM_bestqual_COQUBO,
      GPM_bestqual_CCQUBO: animal?.GPM_bestqual_CCQUBO,
      GPM_bestqual_COCOUT: animal?.GPM_bestqual_COCOUT,
      GPM_bestqual_NIQUBO: animal?.GPM_bestqual_NIQUBO,
      GPM_bestqual_SNQUBO: animal?.GPM_bestqual_SNQUBO,
      GPM_bestqual_COPACQ: animal?.GPM_bestqual_COPACQ,
      GPM_bestqual_NUCHQU: animal?.GPM_bestqual_NUCHQU,
      GPM_bestqual_DAQUBO: formatDate2(animal?.GPM_bestqual_DAQUBO),
      GPM_bestqual_DAEXBO: formatDate2(animal?.GPM_bestqual_DAEXBO),
      GPM_bestqual_COPOIN: animal?.GPM_bestqual_COPOIN,
      // PGPM
      PGPMnobovi: animal?.PGPM_bovide_NOBOVI,
      PGPMcopaip: animal?.PGPM_bovide_COPAIP,
      PGPMnunati: animal?.PGPM_bovide_NUNATI,
      numPGPM: animal?.PGPM_bovide_COPAIP + animal?.PGPM_bovide_NUNATI,
      nomPGPM: animal?.PGPM_bovide_NOBOVI,
      qualifPGPM: animal?.PGPM_bestqual_LIBECO,
      qualifPGPMLIDETA: animal?.PGPM_bestqual_LIDETA,
      qualifPGPMLIBELO: animal?.PGPM_bestqual_LIBELO,
      inscrPGPM: animal?.PGPM_boqual_LIBECO,
      inscrPGPMLIDETA: animal?.PGPM_boqual_LIDETA,
      inscrPGPMLIBELO: animal?.PGPM_boqual_LIBELO,
      PGPM_bestqual_ETQUBO: animal?.PGPM_bestqual_ETQUBO,
      PGPM_bestqual_COQUBO: animal?.PGPM_bestqual_COQUBO,
      PGPM_bestqual_CCQUBO: animal?.PGPM_bestqual_CCQUBO,
      PGPM_bestqual_COCOUT: animal?.PGPM_bestqual_COCOUT,
      PGPM_bestqual_NIQUBO: animal?.PGPM_bestqual_NIQUBO,
      PGPM_bestqual_SNQUBO: animal?.PGPM_bestqual_SNQUBO,
      PGPM_bestqual_COPACQ: animal?.PGPM_bestqual_COPACQ,
      PGPM_bestqual_NUCHQU: animal?.PGPM_bestqual_NUCHQU,
      PGPM_bestqual_DAQUBO: formatDate2(animal?.PGPM_bestqual_DAQUBO),
      PGPM_bestqual_DAEXBO: formatDate2(animal?.PGPM_bestqual_DAEXBO),
      PGPM_bestqual_COPOIN: animal?.PGPM_bestqual_COPOIN,
      // MGPM
      MGPMnobovi: animal?.MGPM_bovide_NOBOVI,
      MGPMcopaip: animal?.MGPM_bovide_COPAIP,
      MGPMnunati: animal?.MGPM_bovide_NUNATI,
      numMGPM: animal?.MGPM_bovide_COPAIP + animal?.MGPM_bovide_NUNATI,
      nomMGPM: animal?.MGPM_bovide_NOBOVI,
      qualifMGPM: animal?.MGPM_bestqual_LIBECO,
      qualifMGPMLIDETA: animal?.MGPM_bestqual_LIDETA,
      qualifMGPMLIBELO: animal?.MGPM_bestqual_LIBELO,
      inscrMGPM: animal?.MGPM_boqual_LIBECO,
      inscrMGPMLIDETA: animal?.MGPM_boqual_LIDETA,
      inscrMGPMLIBELO: animal?.MGPM_boqual_LIBELO,
      MGPM_bestqual_ETQUBO: animal?.MGPM_bestqual_ETQUBO,
      MGPM_bestqual_COQUBO: animal?.MGPM_bestqual_COQUBO,
      MGPM_bestqual_CCQUBO: animal?.MGPM_bestqual_CCQUBO,
      MGPM_bestqual_COCOUT: animal?.MGPM_bestqual_COCOUT,
      MGPM_bestqual_NIQUBO: animal?.MGPM_bestqual_NIQUBO,
      MGPM_bestqual_SNQUBO: animal?.MGPM_bestqual_SNQUBO,
      MGPM_bestqual_COPACQ: animal?.MGPM_bestqual_COPACQ,
      MGPM_bestqual_NUCHQU: animal?.MGPM_bestqual_NUCHQU,
      MGPM_bestqual_DAQUBO: formatDate2(animal?.MGPM_bestqual_DAQUBO),
      MGPM_bestqual_DAEXBO: formatDate2(animal?.MGPM_bestqual_DAEXBO),
      MGPM_bestqual_COPOIN: animal?.MGPM_bestqual_COPOIN,
      // GMM
      GMMnobovi: animal?.GMM_bovide_NOBOVI,
      GMMcopaip: animal?.GMM_bovide_COPAIP,
      GMMnunati: animal?.GMM_bovide_NUNATI,
      numGMM: animal?.GMM_bovide_COPAIP + animal?.GMM_bovide_NUNATI,
      nomGMM: animal?.GMM_bovide_NOBOVI,
      qualifGMM: animal?.GMM_bestqual_LIBECO,
      qualifGMMLIDETA: animal?.GMM_bestqual_LIDETA,
      qualifGMMLIBELO: animal?.GMM_bestqual_LIBELO,
      inscrGMM: animal?.GMM_boqual_LIBECO,
      inscrGMMLIDETA: animal?.GMM_boqual_LIDETA,
      inscrGMMLIBELO: animal?.GMM_boqual_LIBELO,
      GMM_bestqual_ETQUBO: animal?.GMM_bestqual_ETQUBO,
      GMM_bestqual_COQUBO: animal?.GMM_bestqual_COQUBO,
      GMM_bestqual_CCQUBO: animal?.GMM_bestqual_CCQUBO,
      GMM_bestqual_COCOUT: animal?.GMM_bestqual_COCOUT,
      GMM_bestqual_NIQUBO: animal?.GMM_bestqual_NIQUBO,
      GMM_bestqual_SNQUBO: animal?.GMM_bestqual_SNQUBO,
      GMM_bestqual_COPACQ: animal?.GMM_bestqual_COPACQ,
      GMM_bestqual_NUCHQU: animal?.GMM_bestqual_NUCHQU,
      GMM_bestqual_DAQUBO: formatDate2(animal?.GMM_bestqual_DAQUBO),
      GMM_bestqual_DAEXBO: formatDate2(animal?.GMM_bestqual_DAEXBO),
      GMM_bestqual_COPOIN: animal?.GMM_bestqual_COPOIN,
      // PGMM
      PGMMnobovi: animal?.PGMM_bovide_NOBOVI,
      PGMMcopaip: animal?.PGMM_bovide_COPAIP,
      PGMMnunati: animal?.PGMM_bovide_NUNATI,
      numPGMM: animal?.PGMM_bovide_COPAIP + animal?.PGMM_bovide_NUNATI,
      nomPGMM: animal?.PGMM_bovide_NOBOVI,
      qualifPGMM: animal?.PGMM_bestqual_LIBECO,
      qualifPGMMLIDETA: animal?.PGMM_bestqual_LIDETA,
      qualifPGMMLIBELO: animal?.PGMM_bestqual_LIBELO,
      inscrPGMM: animal?.PGMM_boqual_LIBECO,
      inscrPGMMLIDETA: animal?.PGMM_boqual_LIDETA,
      inscrPGMMLIBELO: animal?.PGMM_boqual_LIBELO,
      PGMM_bestqual_ETQUBO: animal?.PGMM_bestqual_ETQUBO,
      PGMM_bestqual_COQUBO: animal?.PGMM_bestqual_COQUBO,
      PGMM_bestqual_CCQUBO: animal?.PGMM_bestqual_CCQUBO,
      PGMM_bestqual_COCOUT: animal?.PGMM_bestqual_COCOUT,
      PGMM_bestqual_NIQUBO: animal?.PGMM_bestqual_NIQUBO,
      PGMM_bestqual_SNQUBO: animal?.PGMM_bestqual_SNQUBO,
      PGMM_bestqual_COPACQ: animal?.PGMM_bestqual_COPACQ,
      PGMM_bestqual_NUCHQU: animal?.PGMM_bestqual_NUCHQU,
      PGMM_bestqual_DAQUBO: formatDate2(animal?.PGMM_bestqual_DAQUBO),
      PGMM_bestqual_DAEXBO: formatDate2(animal?.PGMM_bestqual_DAEXBO),
      PGMM_bestqual_COPOIN: animal?.PGMM_bestqual_COPOIN,
      // MGMM
      MGMMnobovi: animal?.MGMM_bovide_NOBOVI,
      MGMMcopaip: animal?.MGMM_bovide_COPAIP,
      MGMMnunati: animal?.MGMM_bovide_NUNATI,
      numMGMM: animal?.MGMM_bovide_COPAIP + animal?.MGMM_bovide_NUNATI,
      nomMGMM: animal?.MGMM_bovide_NOBOVI,
      qualifMGMM: animal?.MGMM_bestqual_LIBECO,
      qualifMGMMLIDETA: animal?.MGMM_bestqual_LIDETA,
      qualifMGMMLIBELO: animal?.MGMM_bestqual_LIBELO,
      inscrMGMM: animal?.MGMM_boqual_LIBECO,
      inscrMGMMLIDETA: animal?.MGMM_boqual_LIDETA,
      inscrMGMMLIBELO: animal?.MGMM_boqual_LIBELO,
      MGMM_bestqual_ETQUBO: animal?.MGMM_bestqual_ETQUBO,
      MGMM_bestqual_COQUBO: animal?.MGMM_bestqual_COQUBO,
      MGMM_bestqual_CCQUBO: animal?.MGMM_bestqual_CCQUBO,
      MGMM_bestqual_COCOUT: animal?.MGMM_bestqual_COCOUT,
      MGMM_bestqual_NIQUBO: animal?.MGMM_bestqual_NIQUBO,
      MGMM_bestqual_SNQUBO: animal?.MGMM_bestqual_SNQUBO,
      MGMM_bestqual_COPACQ: animal?.MGMM_bestqual_COPACQ,
      MGMM_bestqual_NUCHQU: animal?.MGMM_bestqual_NUCHQU,
      MGMM_bestqual_DAQUBO: formatDate2(animal?.MGMM_bestqual_DAQUBO),
      MGMM_bestqual_DAEXBO: formatDate2(animal?.MGMM_bestqual_DAEXBO),
      MGMM_bestqual_COPOIN: animal?.MGMM_bestqual_COPOIN,
    }
  })

  //Calcul des moyennes
  moyenneIfnaisM = esproMoyenne(moyenneIfnaisTM / moyenneIfnaisIM)
  moyenneIfnaisV = esproMoyenne(moyenneIfnaisTV / moyenneIfnaisIV)
  moyenneIfnaisG = esproMoyenne(moyenneIfnaisTG / moyenneIfnaisIG)

  moyenneCrsevsM = esproMoyenne(moyenneCrsevsTM / moyenneCrsevsIM)
  moyenneCrsevsV = esproMoyenne(moyenneCrsevsTV / moyenneCrsevsIV)
  moyenneCrsevsG = esproMoyenne(moyenneCrsevsTG / moyenneCrsevsIG)

  moyenneDmsevsM = esproMoyenne(moyenneDmsevsTM / moyenneDmsevsIM)
  moyenneDmsevsV = esproMoyenne(moyenneDmsevsTV / moyenneDmsevsIV)
  moyenneDmsevsG = esproMoyenne(moyenneDmsevsTG / moyenneDmsevsIG)

  moyenneDssevsM = esproMoyenne(moyenneDssevsTM / moyenneDssevsIM)
  moyenneDssevsV = esproMoyenne(moyenneDssevsTV / moyenneDssevsIV)
  moyenneDssevsG = esproMoyenne(moyenneDssevsTG / moyenneDssevsIG)

  moyenneFosevsM = esproMoyenne(moyenneFosevsTM / moyenneFosevsIM)
  moyenneFosevsV = esproMoyenne(moyenneFosevsTV / moyenneFosevsIV)
  moyenneFosevsG = esproMoyenne(moyenneFosevsTG / moyenneFosevsIG)

  moyenneIsevreM = esproMoyenne(moyenneIsevreTM / moyenneIsevreIM)
  moyenneIsevreV = esproMoyenne(moyenneIsevreTV / moyenneIsevreIV)
  moyenneIsevreG = esproMoyenne(moyenneIsevreTG / moyenneIsevreIG)

  moyenneAvelagM = esproMoyenne(moyenneAvelagTM / moyenneAvelagIM)
  moyenneAvelagV = esproMoyenne(moyenneAvelagTV / moyenneAvelagIV)
  moyenneAvelagG = esproMoyenne(moyenneAvelagTG / moyenneAvelagIG)

  moyenneAlaitsM = esproMoyenne(moyenneAlaitsTM / moyenneAlaitsIM)
  moyenneAlaitsV = esproMoyenne(moyenneAlaitsTV / moyenneAlaitsIV)
  moyenneAlaitsG = esproMoyenne(moyenneAlaitsTG / moyenneAlaitsIG)

  moyenneIvmateM = esproMoyenne(moyenneIvmateTM / moyenneIvmateIM)
  moyenneIvmateV = esproMoyenne(moyenneIvmateTV / moyenneIvmateIV)
  moyenneIvmateG = esproMoyenne(moyenneIvmateTG / moyenneIvmateIG)

  //////////////////
  // PRELEVEMENTS //
  /////////////////
  data?.prelevements?.forEach(prelev => {
    let nAni = prelev?.copaip + prelev?.nunati

    let paf_filter = ""

    if (prelev?.paf_retyup === "+/+") paf_filter = "Non porteur"
    else if (prelev?.paf_retyup === "PAL/+") paf_filter = "Porteur hétérozygote"
    else if (prelev?.paf_retyup === "PAL/PAL") paf_filter = "Porteur homozygote"

    if (tmpDataAni[nAni] === undefined) {
      tmpDataAni[nAni] = {
        numAnimal: prelev?.copaip + prelev?.nunati?.substring(0, 6),
        numTravailAnimal: prelev?.nunati?.substring(6),
        COPAIP: prelev?.copaip,
        NUNATI: prelev?.nunati,
        SEXBOV: prelev?.sexbov,
        NOBOVI: prelev?.nobovi,
        isInInventory: false,
        retyupsc: prelev?.sc_retyup,
        daretysc: formatDate2(prelev?.sc_darety),
        NUTYAN_SC: prelev.sc_nutyan,
        retyupmh: prelev?.mh_retyup,
        daretymh: formatDate2(prelev?.mh_darety),
        NUTYAN_MH: prelev.mh_nutyan,
        retyuppaf: prelev?.paf_retyup,
        paf_filter: paf_filter,
        daretypaf: formatDate2(prelev?.paf_darety),
        NUTYAN_PAF: prelev.paf_nutyan,
        retyuppro: prelev?.pro_retyup,
        daretypro: formatDate2(prelev?.pro_darety),
        retyupbli: prelev?.bli_retyup,
        daretybli: formatDate2(prelev?.bli_darety),
        DARETY_HPF: prelev.hpf_darety,
        RETYUP_HPF: prelev.hpf_retyup,
        NUTYAN_HPF: prelev.hpf_nutyan,
        CORDCG:
          prelev?.bocog2_nuecha_cordcg ?? prelev?.bocog2_nuprel_cordcg ?? "",
        cordcg_txt:
          prelev?.bocog2_nuecha_cordcg_txt ??
          prelev?.bocog2_nuprel_cordcg_txt ??
          "",
        dartcg: formatDate2(
          prelev?.bocog2_nuecha_dartcg ?? prelev?.bocog2_nuprel_dartcg ?? ""
        ),
      }
    }

    tmpDataAni[nAni].isPrelev = true
    tmpDataAni[nAni].NUPREL = prelev?.nuprel
    tmpDataAni[nAni].DAPREL = formatDate2(prelev?.daprel)
    tmpDataAni[nAni].NOORGA = prelev?.noorga
    tmpDataAni[nAni].DARECH = formatDate2(prelev?.darech)
    tmpDataAni[nAni].QUAPRA = prelev?.quapra
    tmpDataAni[nAni].COIDEN = prelev?.coiden
    tmpDataAni[nAni].sortCoiden = prelev?.boange_nuecha_codeut
      ? prelev?.boange_nuecha_codeut
      : prelev?.coiden
      ? prelev?.coiden
      : -100
    tmpDataAni[nAni].NUECHA_CODEUT = prelev?.boange_nuecha_codeut
    tmpDataAni[nAni].NUECHA_DAOBAN = formatDate2(prelev?.boange_nuecha_daoban)
    tmpDataAni[nAni].COFILI = prelev?.cofili
    tmpDataAni[nAni].COCULA = prelev?.cocula
    tmpDataAni[nAni].COPOLL = prelev?.copoll
    tmpDataAni[nAni].COPAFE = prelev?.copafe
    tmpDataAni[nAni].IDLABO = prelev?.Evalim_IDLABO
    tmpDataAni[nAni].CIFNAI = prelev?.Evalim_CIFNAI
    tmpDataAni[nAni].CCRSEV = prelev?.Evalim_CCRSEV
    tmpDataAni[nAni].CDMSEV = prelev?.Evalim_CDMSEV
    tmpDataAni[nAni].CDSSEV = prelev?.Evalim_CDSSEV
    tmpDataAni[nAni].CFOSSE = prelev?.Evalim_CFOSSE
    tmpDataAni[nAni].CAVELS = prelev?.Evalim_CAVELS
    tmpDataAni[nAni].CALAIT = prelev?.Evalim_CALAIT
    tmpDataAni[nAni].COPSEV = prelev?.Evalim_COPSEV
    tmpDataAni[nAni].BASREF = prelev?.Evalim_BASREF
    tmpDataAni[nAni].Evalim_filter = prelev?.Evalim_IDLABO
      ? "Avec Evalim"
      : "Sans Evalim"

    if (!prelev?.bocog2_nuprel_cordcg && prelev?.cofili === "O")
      tmpDataAni[nAni].filiation_filter = "En attente"

    let ident_adn_filter = ""

    if (
      prelev?.boange_nuecha_codeut === "1" ||
      prelev?.boange_nuecha_codeut === "4" ||
      prelev?.boange_nuecha_codeut === "5" ||
      prelev?.boange_nuecha_codeut === "8"
    )
      ident_adn_filter = "Utilisable"
    else if (prelev?.boange_nuecha_codeut === "2")
      ident_adn_filter = "Inutilisable"
    else if (prelev?.boange_nuecha_codeut === "9")
      ident_adn_filter = "Inconnu en bdnsat"
    else if (prelev?.coiden === "O") ident_adn_filter = "En attente"

    tmpDataAni[nAni].ident_adn_filter = ident_adn_filter
  })

  // Sort pointages HBL pdf list
  let pointagesHBl = data?.documents?.hbl_f_point_elev?.sort((b, a) =>
    +formatDate4(a["d"]) > +formatDate4(b["d"])
      ? 1
      : +formatDate4(a["d"]) < +formatDate4(b["d"])
      ? -1
      : 0
  )
  let pointagesHBlPere = data?.documents?.hbl_f_point_peres?.sort((b, a) =>
    +formatDate4(a["d"]) > +formatDate4(b["d"])
      ? 1
      : +formatDate4(a["d"]) < +formatDate4(b["d"])
      ? -1
      : 0
  )
  let pointagesHBlDetail = data?.documents?.hbl_f_point_col?.sort((b, a) =>
    +formatDate4(a["d"]) > +formatDate4(b["d"])
      ? 1
      : +formatDate4(a["d"]) < +formatDate4(b["d"])
      ? -1
      : 0
  )

  // Generation of documents BGTA infos
  if (
    data?.documents?.doc_BGTA !== undefined &&
    data?.documents?.doc_BGTA[1]?.length > 0
  ) {
    let version = parseInt(
      data?.documents?.doc_BGTA[1][0]?.num.toString().substr(2, 1)
    )

    docBGTA.push({
      num: data?.documents?.doc_BGTA[1][0]?.num,
      year: dateFromNumBGTA(
        data?.documents?.doc_BGTA[1][0]?.num.toString().substr(2, 1),
        data?.documents?.doc_BGTA[1][0]?.num
      ),
    })

    if (version === 1) {
      if (data?.documents?.doc_BGTA[1][1] !== undefined) {
        docBGTA.push({
          num: data?.documents?.doc_BGTA[1][1]?.num,
          year: dateFromNumBGTA(
            data?.documents?.doc_BGTA[1][1]?.num.toString().substr(2, 1),
            data?.documents?.doc_BGTA[1][1]?.num
          ),
        })
      }
      if (data?.documents?.doc_BGTA[1][3] !== undefined) {
        docBGTA.push({
          num: data?.documents?.doc_BGTA[1][3]?.num,
          year: dateFromNumBGTA(
            data?.documents?.doc_BGTA[1][3]?.num.toString().substr(2, 1),
            data?.documents?.doc_BGTA[1][3]?.num
          ),
        })
      }
    }
    if (version === 2) {
      if (data?.documents?.doc_BGTA[1][2] !== undefined) {
        docBGTA.push({
          num: data?.documents?.doc_BGTA[1][2]?.num,
          year: dateFromNumBGTA(
            data?.documents?.doc_BGTA[1][2]?.num.toString().substr(2, 1),
            data?.documents?.doc_BGTA[1][2]?.num
          ),
        })
      }
    }
  }

  //Checking if we need to generate birth datas
  anneeDocBirth = data?.documents?.anne_doc_naissance

  if (data?.documents?.doc_naissance !== undefined) {
    if (data?.documents?.doc_naissance["TP"]) docBirth.TP = true
    if (data?.documents?.doc_naissance["PN"]) docBirth.PN = true
  }

  // Calculating IVV graph datas
  ii = 0
  for (let i in data?.documents?.bgta) {
    datesIVV[ii] = data?.documents?.bgta[i]["annee"]
    isuArray[ii] = data?.documents?.bgta[i]["isu"]
    ivvArray[ii] = data?.documents?.bgta[i]["ivv"]
    ivv12Array[ii] = data?.documents?.bgta[i]["ivv12"]
    ivv2pArray[ii] = data?.documents?.bgta[i]["ivv2p"]
    ii++
  }

  datesIVV[ii] = "Aujourd'hui"
  isuArray[ii] = ""
  ivvArray[ii] = Math.round(ivvMoyenT / ivvMoyenI)
  ivv12Array[ii] = Math.round(ivvMoyen12T / ivvMoyen12I)
  ivv2pArray[ii] = Math.round(ivvMoyen2pT / ivvMoyen2pI)

  //Calculating BGTA index for graph data
  ii = 0
  for (let i in data?.documents?.bgta_index) {
    campArray[ii] = data?.documents?.bgta_index[i]["camp"]
    nivgenmatIfnais[ii] = data?.documents?.bgta_index[i]["nivgenmat_ifnais"]
    nivgenmatCrsev[ii] = data?.documents?.bgta_index[i]["nivgenmat_crsev"]
    nivgenmatDmsev[ii] = data?.documents?.bgta_index[i]["nivgenmat_dmsev"]
    nivgenmatDssev[ii] = data?.documents?.bgta_index[i]["nivgenmat_dssev"]
    nivgenmatFossev[ii] = data?.documents?.bgta_index[i]["nivgenmat_fossev"]
    nivgenmatIsevr[ii] = data?.documents?.bgta_index[i]["nivgenmat_isevr"]
    nivgenmatAvel[ii] = data?.documents?.bgta_index[i]["nivgenmat_avel"]
    nivgenmatAlait[ii] = data?.documents?.bgta_index[i]["nivgenmat_alait"]
    nivgenmatIvmat[ii] = data?.documents?.bgta_index[i]["nivgenmat_ivmat"]
    nivgenpatIfnais[ii] = data?.documents?.bgta_index[i]["nivgenpat_ifnais"]
    nivgenpatCrsev[ii] = data?.documents?.bgta_index[i]["nivgenpat_crsev"]
    nivgenpatDmsev[ii] = data?.documents?.bgta_index[i]["nivgenpat_dmsev"]
    nivgenpatDssev[ii] = data?.documents?.bgta_index[i]["nivgenpat_dssev"]
    nivgenpatFossev[ii] = data?.documents?.bgta_index[i]["nivgenpat_fossev"]
    nivgenpatIsevr[ii] = data?.documents?.bgta_index[i]["nivgenpat_isevr"]
    nivgenpatAvel[ii] = data?.documents?.bgta_index[i]["nivgenpat_avel"]
    nivgenpatAlait[ii] = data?.documents?.bgta_index[i]["nivgenpat_alait"]
    nivgenpatIvmat[ii] = data?.documents?.bgta_index[i]["nivgenpat_ivmat"]
    ii++
  }

  // Make dataAnimaux array
  for (let i in tmpDataAni) {
    dataAnimaux.push(tmpDataAni[i])
  }

  // Save series in cache
  if (data?.series_lanaud)
    window.localStorage.seriesLanaud = JSON.stringify(data.series_lanaud ?? [])

  // Filter object to then save in cache + send to object !
  let AnalyseFilter = {
    needAnalyse: needAnalyse,
    needAnalyseArray: needAnalyseArray,
    hasIncompatibility: hasIncompatibility,
    hasIncompatibilityArray: hasIncompatibilityArray,
    maleSansCompatPere: maleSansCompatPere,
    maleSansCompatPereArray: maleSansCompatPereArray,
    maleSansMh: maleSansMh,
    maleSansMhArray: maleSansMhArray,
    maleSansProfilAdn: maleSansProfilAdn,
    maleSansProfilAdnArray: maleSansProfilAdnArray,
  }
  window.localStorage.analyseFilter = JSON.stringify(AnalyseFilter)
  let ConfirmFilter = {
    nbGenissesToExamine: nbGenissesToExamine,
    nbGenissesToExamineArray: nbGenissesToExamineArray,
    nbGenissesToConfirm: nbGenissesToConfirm,
    nbGenissesToConfirmArray: nbGenissesToConfirmArray,
    nbVachesToConfirm: nbVachesToConfirm,
    nbVachesToConfirmArray: nbVachesToConfirmArray,
    nbFemP6MNnInscrite: nbFemP6MNnInscrite,
    nbFemP6MNnInscriteArray: nbFemP6MNnInscriteArray,
    nbMaleToExamine: nbMaleToExamine,
    nbMaleToExamineArray: nbMaleToExamineArray,
    nbMaleToConfirm: nbMaleToConfirm,
    nbMaleToConfirmArray: nbMaleToConfirmArray,
    nbMalP6MNnInscrit: nbMalP6MNnInscrit,
    nbMalP6MNnInscritArray: nbMalP6MNnInscritArray,
  }
  window.localStorage.confirmFilter = JSON.stringify(ConfirmFilter)

  // Donnes de station
  let nbAniMisEnStation = 0
  let nbAniEnStation = 0
  let nbEspoir = 0
  let nbRj = 0
  let nbRCV = 0
  let prixVenteTot = 0
  let nbVentes = 0
  let gmqTot = 0
  let nbGmq = 0
  let gmqMoy = ""
  let animauxStation = []
  for (let costat in data?.station?.animaux) {
    for (let num in data?.station?.animaux[costat]) {
      let obj = data?.station?.animaux[costat][num]

      if (obj.coentr === "E") {
        if (obj?.A_boqual_2_liqure === "Espoir") nbEspoir++
        if (obj?.A_boqual_2_liqure === "RJ") nbRj++
        if (obj?.A_boqual_2_liqure === "RCV") nbRCV++

        if (obj.prix_vente && +obj.prix_vente !== 0) {
          prixVenteTot += +obj.prix_vente
          nbVentes++
        }

        if (obj.GMQ) {
          gmqTot += +obj.GMQ
          nbGmq++
        }

        if (!obj.dasort) {
          if (todayDate("numeric") > +formatDate5(obj.date_vente))
            nbAniMisEnStation++
          else nbAniEnStation++
        } else nbAniMisEnStation++
      }

      animauxStation.push(obj)
    }
  }

  if (nbGmq !== 0) gmqMoy = (gmqTot / nbGmq).toFixed(2)

  let nbLanaud = 0
  let nbABL = 0
  let nbGelioc = 0
  let nbGevial = 0
  let nbLanaudAct = 0
  let nbABLAct = 0
  let nbGeliocAct = 0
  let nbGevialAct = 0

  let totPoidsEntreeLanaud = 0
  let totPoidsDDLanaud = 0
  let totPoidsP1Lanaud = 0
  let totPoidsDFLanaud = 0
  let nbPoidsEntreeLanaud = 0
  let nbPoidsDDLanaud = 0
  let nbPoidsP1Lanaud = 0
  let nbPoidsDFLanaud = 0

  let totPoidsEntreeABL = 0
  let totPoidsDDABL = 0
  let totPoidsP1ABL = 0
  let totPoidsP2ABL = 0
  let totPoidsDFABL = 0
  let nbPoidsEntreeABL = 0
  let nbPoidsDDABL = 0
  let nbPoidsP1ABL = 0
  let nbPoidsP2ABL = 0
  let nbPoidsDFABL = 0

  let totPoidsEntreeGelioc = 0
  let totPoidsDDGelioc = 0
  let totPoidsP1Gelioc = 0
  let totPoidsP2Gelioc = 0
  let totPoidsDFGelioc = 0
  let nbPoidsEntreeGelioc = 0
  let nbPoidsDDGelioc = 0
  let nbPoidsP1Gelioc = 0
  let nbPoidsP2Gelioc = 0
  let nbPoidsDFGelioc = 0

  let totPoidsEntreeGevial = 0
  let totPoidsDDGevial = 0
  let totPoidsP1Gevial = 0
  let totPoidsP2Gevial = 0
  let totPoidsDFGevial = 0
  let nbPoidsEntreeGevial = 0
  let nbPoidsDDGevial = 0
  let nbPoidsP1Gevial = 0
  let nbPoidsP2Gevial = 0
  let nbPoidsDFGevial = 0

  let todayNum = todayDate("numeric")

  animauxStation.forEach((ani, index) => {
    if (ani.coentr === "E") {
      switch (ani.costat) {
        case "871":
          if (!ani.dasort) {
            if (todayNum <= +ani.date_vente) nbLanaudAct++
          }

          nbLanaud++
          break
        case "191":
          if (!ani.dasort) {
            if (todayNum <= +ani.date_vente) nbABLAct++
          }

          nbABL++
          break
        case "122":
          if (!ani.dasort) {
            if (todayNum <= +ani.date_vente) nbGeliocAct++
          }

          nbGelioc++
          break
        case "232":
          if (!ani.dasort) {
            if (todayNum <= +ani.date_vente) nbGevialAct++
          }

          nbGevial++
          break
      }

      if (ani.povipe_DF) {
        if (ani.costat === "871") {
          totPoidsEntreeLanaud += +ani.povipe_PA
          totPoidsDDLanaud += +ani.povipe_DD
          totPoidsP1Lanaud += +ani.povipe_P1
          totPoidsDFLanaud += +ani.povipe_DF

          nbPoidsEntreeLanaud++
          nbPoidsDDLanaud++
          nbPoidsP1Lanaud++
          nbPoidsDFLanaud++
        }
        if (ani.costat === "191") {
          totPoidsEntreeABL += +ani.povipe_PA
          totPoidsDDABL += +ani.povipe_DD
          totPoidsP1ABL += +ani.povipe_P1
          totPoidsP2ABL += +ani.povipe_P2
          totPoidsDFABL += +ani.povipe_DF

          nbPoidsEntreeABL++
          nbPoidsDDABL++
          nbPoidsP1ABL++
          nbPoidsP2ABL++
          nbPoidsDFABL++
        }
        if (ani.costat === "122") {
          totPoidsEntreeGelioc += +ani.povipe_PA
          totPoidsDDGelioc += +ani.povipe_DD
          totPoidsP1Gelioc += +ani.povipe_P1
          totPoidsP2Gelioc += +ani.povipe_P2
          totPoidsDFGelioc += +ani.povipe_DF

          nbPoidsEntreeGelioc++
          nbPoidsDDGelioc++
          nbPoidsP1Gelioc++
          nbPoidsP2Gelioc++
          nbPoidsDFGelioc++
        }
        if (ani.costat === "232") {
          totPoidsEntreeGevial += +ani.povipe_PA
          totPoidsDDGevial += +ani.povipe_DD
          totPoidsP1Gevial += +ani.povipe_P1
          totPoidsP2Gevial += +ani.povipe_P2
          totPoidsDFGevial += +ani.povipe_DF

          nbPoidsEntreeGevial++
          nbPoidsDDGevial++
          nbPoidsP1Gevial++
          nbPoidsP2Gevial++
          nbPoidsDFGevial++
        }
      }
    }
  })

  let tmpNbStation = 0
  if (nbLanaud) tmpNbStation++
  if (nbABL) tmpNbStation++
  if (nbGelioc) tmpNbStation++
  if (nbGevial) tmpNbStation++

  let tmpNbStationAct = 0
  if (nbLanaudAct) tmpNbStationAct++
  if (nbABLAct) tmpNbStationAct++
  if (nbGeliocAct) tmpNbStationAct++
  if (nbGevialAct) tmpNbStationAct++

  let moyPoidsEntreeLanaud = totPoidsEntreeLanaud / nbPoidsEntreeLanaud
  let moyPoidsDDLanaud = totPoidsDDLanaud / nbPoidsDDLanaud
  let moyPoidsP1Lanaud = totPoidsP1Lanaud / nbPoidsP1Lanaud
  let moyPoidsDFLanaud = totPoidsDFLanaud / nbPoidsDFLanaud

  let moyPoidsEntreeABL = totPoidsEntreeABL / nbPoidsEntreeABL
  let moyPoidsDDABL = totPoidsDDABL / nbPoidsDDABL
  let moyPoidsP1ABL = totPoidsP1ABL / nbPoidsP1ABL
  let moyPoidsP2ABL = totPoidsP2ABL / nbPoidsP2ABL
  let moyPoidsDFABL = totPoidsDFABL / nbPoidsDFABL

  let moyPoidsEntreeGelioc = totPoidsEntreeGelioc / nbPoidsEntreeGelioc
  let moyPoidsDDGelioc = totPoidsDDGelioc / nbPoidsDDGelioc
  let moyPoidsP1Gelioc = totPoidsP1Gelioc / nbPoidsP1Gelioc
  let moyPoidsP2Gelioc = totPoidsP2Gelioc / nbPoidsP2Gelioc
  let moyPoidsDFGelioc = totPoidsDFGelioc / nbPoidsDFGelioc

  let moyPoidsEntreeGevial = totPoidsEntreeGevial / nbPoidsEntreeGevial
  let moyPoidsDDGevial = totPoidsDDGevial / nbPoidsDDGevial
  let moyPoidsP1Gevial = totPoidsP1Gevial / nbPoidsP1Gevial
  let moyPoidsP2Gevial = totPoidsP2Gevial / nbPoidsP2Gevial
  let moyPoidsDFGevial = totPoidsDFGevial / nbPoidsDFGevial

  // Needed in localStorage too for fetchLogConnec(called in app)
  window.localStorage.isAdherent = data.is_adherent

  return {
    isAdherent: data.is_adherent,
    costad: data.costad,
    stadup: data.stadup,
    pocketData: data.pocket,
    cheptel: {
      MyCheptel: {
        nbAnimal: nbMale + nbFemale,
        nbMale: nbMale,
        nbFemale: nbFemale,
        ivvMoyen: Math.round(ivvMoyenT / ivvMoyenI),
        nbAnimalIndexes: nbMaleIndex + nbGenissesIndex + nbVachesIndex,
      },
      Station: {
        nbAniMisEnStation: nbAniMisEnStation,
        nbAniEnStation: nbAniEnStation,
        nbEspoir: nbEspoir,
        nbRj: nbRj,
        nbRCV: nbRCV,
        prixVenteMoy: Math.round(prixVenteTot / nbVentes),
        gmqMoy: gmqMoy,
        nbStation: tmpNbStation,
        nbStationAct: tmpNbStationAct,
      },
      MyInfosHBL: {
        needAnalyse: needAnalyse,
        nbGenissesToExamine: nbGenissesToExamine,
        nbGenissesToConfirm: nbGenissesToConfirm,
        nbVachesToConfirm: nbVachesToConfirm,
        nbFemP6MNnInscrite: nbFemP6MNnInscrite,
        nbMaleToExamine: nbMaleToExamine,
        nbMaleToConfirm: nbMaleToConfirm,
        nbMalP6MNnInscrit: nbMalP6MNnInscrit,
        pointagesHBl: pointagesHBl,
        pointagesHBlPere: pointagesHBlPere,
        pointagesHBlDetail: pointagesHBlDetail,
        demandesConfirmation: data?.documents?.hbl_confirm,
      },
      MyDocs: {
        docFIT: data?.documents?.doc_FIT,
        docFIVA: data?.documents?.doc_FIVA,
        docBGTA: docBGTA,
        docBirth: docBirth,
        anneeDocBirth: anneeDocBirth,
      },
      Repartition: {
        nbMale: nbMale,
        nbFemale: nbFemale,
        nbVaches: nbVaches,
        nbGenisses: nbGenisses,
      },
      Iboval: {
        nbMaleIndex: nbMaleIndex,
        nbGenissesIndex: nbGenissesIndex,
        nbVachesIndex: nbVachesIndex,
        moyenneIfnaisM: moyenneIfnaisM,
        moyenneIfnaisV: moyenneIfnaisV,
        moyenneIfnaisG: moyenneIfnaisG,
        moyenneCrsevsM: moyenneCrsevsM,
        moyenneCrsevsV: moyenneCrsevsV,
        moyenneCrsevsG: moyenneCrsevsG,
        moyenneDmsevsM: moyenneDmsevsM,
        moyenneDmsevsV: moyenneDmsevsV,
        moyenneDmsevsG: moyenneDmsevsG,
        moyenneDssevsM: moyenneDssevsM,
        moyenneDssevsV: moyenneDssevsV,
        moyenneDssevsG: moyenneDssevsG,
        moyenneFosevsM: moyenneFosevsM,
        moyenneFosevsV: moyenneFosevsV,
        moyenneFosevsG: moyenneFosevsG,
        moyenneIsevreM: moyenneIsevreM,
        moyenneIsevreV: moyenneIsevreV,
        moyenneIsevreG: moyenneIsevreG,
        moyenneAvelagM: moyenneAvelagM,
        moyenneAvelagV: moyenneAvelagV,
        moyenneAvelagG: moyenneAvelagG,
        moyenneAlaitsM: moyenneAlaitsM,
        moyenneAlaitsV: moyenneAlaitsV,
        moyenneAlaitsG: moyenneAlaitsG,
        moyenneIvmateM: moyenneIvmateM,
        moyenneIvmateV: moyenneIvmateV,
        moyenneIvmateG: moyenneIvmateG,
      },
      Evolutions: {
        datesIVV: datesIVV,
        ivvArray: ivvArray,
        ivv12Array: ivv12Array,
        ivv2pArray: ivv2pArray,
        campArray: campArray,
        nivgenmatIfnais: nivgenmatIfnais,
        nivgenmatCrsev: nivgenmatCrsev,
        nivgenmatDmsev: nivgenmatDmsev,
        nivgenmatDssev: nivgenmatDssev,
        nivgenmatFossev: nivgenmatFossev,
        nivgenmatIsevr: nivgenmatIsevr,
        nivgenmatAvel: nivgenmatAvel,
        nivgenmatAlait: nivgenmatAlait,
        nivgenmatIvmat: nivgenmatIvmat,
        nivgenpatIfnais: nivgenpatIfnais,
        nivgenpatCrsev: nivgenpatCrsev,
        nivgenpatDmsev: nivgenpatDmsev,
        nivgenpatDssev: nivgenpatDssev,
        nivgenpatFossev: nivgenpatFossev,
        nivgenpatIsevr: nivgenpatIsevr,
        nivgenpatAvel: nivgenpatAvel,
        nivgenpatAlait: nivgenpatAlait,
        nivgenpatIvmat: nivgenpatIvmat,
      },
    },
    station: {
      moyennes: data?.station?.moyennes,
      animaux: animauxStation,
      nbLanaud: nbLanaud,
      nbABL: nbABL,
      nbGelioc: nbGelioc,
      nbGevial: nbGevial,
      nbLanaudAct: nbLanaudAct,
      nbABLAct: nbABLAct,
      nbGeliocAct: nbGeliocAct,
      nbGevialAct: nbGevialAct,
      moyPoidsEntreeLanaud: moyPoidsEntreeLanaud,
      moyPoidsDDLanaud: moyPoidsDDLanaud,
      moyPoidsP1Lanaud: moyPoidsP1Lanaud,
      moyPoidsDFLanaud: moyPoidsDFLanaud,
      moyPoidsEntreeABL: moyPoidsEntreeABL,
      moyPoidsDDABL: moyPoidsDDABL,
      moyPoidsP1ABL: moyPoidsP1ABL,
      moyPoidsP2ABL: moyPoidsP2ABL,
      moyPoidsDFABL: moyPoidsDFABL,
      moyPoidsEntreeGelioc: moyPoidsEntreeGelioc,
      moyPoidsDDGelioc: moyPoidsDDGelioc,
      moyPoidsP1Gelioc: moyPoidsP1Gelioc,
      moyPoidsP2Gelioc: moyPoidsP2Gelioc,
      moyPoidsDFGelioc: moyPoidsDFGelioc,
      moyPoidsEntreeGevial: moyPoidsEntreeGevial,
      moyPoidsDDGevial: moyPoidsDDGevial,
      moyPoidsP1Gevial: moyPoidsP1Gevial,
      moyPoidsP2Gevial: moyPoidsP2Gevial,
      moyPoidsDFGevial: moyPoidsDFGevial,
    },
    inventaire: {
      header: {
        nbAnimal: nbMale + nbFemale,
      },
      AnalyseFilter: AnalyseFilter,
      // AnalyseFiliation: filiation, to change, its in data of animals now
      ConfirmFilter: ConfirmFilter,
      Custom: dataAnimaux,
    },
  }
}

// Get the date for filename from num BGTA
const dateFromNumBGTA = (version, num) => {
  version = parseInt(version)

  if (version === 1) {
    return "septembre 20" + (num.toString().substr(0, 2) - 1)
  }
  if (version === 2) {
    return "mars 20" + num.toString().substr(0, 2)
  }

  return ""
}

const esproMoyenne = value => {
  if (!isNaN(value)) {
    value = Math.round(value * 100) / 100
    return value
  } else {
    return 0
  }
}
