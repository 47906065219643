import { useEffect, useRef, useState } from "react"
import "./OutilsOP.scss"
import CheptelSectionTitle from "../components/CheptelSectionTitle"
import BigGreenButton from "../components/BigGreenButton"
import ModalExports from "../components/op/ModalExports"
import ModalChangeLogs from "../components/ModalChangeLogs"
import ModalDeclarationAVendre from "../components/op/ModalDeclarationAVendre"

const OutilsOP = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let div = useRef()
  let typeExport = useRef(null)
  let typeDeclarationAVendre = useRef(null)

  const [clChangeLog, setChangeLog] = useState(false)
  pageOption === "changelog" && !clChangeLog && setChangeLog(true)
  pageOption !== "changelog" && clChangeLog && setChangeLog(false)
  const [clExports, setClExports] = useState(false)
  pageOption === "exports" && !clExports && setClExports(true)
  pageOption !== "exports" && clExports && setClExports(false)
  const [clDeclarationVente, setClDeclarationVente] = useState(false)
  pageOption === "declarationvente" &&
    !clDeclarationVente &&
    setClDeclarationVente(true)
  pageOption !== "declarationvente" &&
    clDeclarationVente &&
    setClDeclarationVente(false)

  let stringifiedDroitsOP = window.localStorage?.droitsOP ?? "{}"
  if (stringifiedDroitsOP === undefined) stringifiedDroitsOP = "{}"
  let droitsOP = JSON.parse(stringifiedDroitsOP)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = div.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("margin-top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.marginTop = newHeight + "px"
      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const goMesAdherents = () => {
    window.location.hash = "adherentsop"
  }
  const goExport = type => {
    typeExport.current = type
    window.location.hash = "op_exports"
  }
  const goDeclarationAVendre = type => {
    typeDeclarationAVendre.current = type
    window.location.hash = "op_declarationvente"
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  return (
    <div ref={div} className="outilsOP">
      {clChangeLog && <ModalChangeLogs />}
      {clExports && <ModalExports type={typeExport.current} />}
      {clDeclarationVente && (
        <ModalDeclarationAVendre type={typeDeclarationAVendre.current} />
      )}
      <CheptelSectionTitle title={"Mes adhérents"} />
      <div className="div_btns_outils_op">
        <BigGreenButton onClick={goMesAdherents} text={"Voir mes adhérents"} />
      </div>
      <CheptelSectionTitle title={"Exports en lot"} />
      <div className="div_btns_outils_op">
        <BigGreenButton onClick={() => goExport("PDF")} text={"PDF"} />
        <BigGreenButton onClick={() => goExport("CSV")} text={"CSV"} />
        <BigGreenButton onClick={() => goExport("Excel")} text={"Excel"} />
      </div>
      {droitsOP?.service_op && (
        <>
          <CheptelSectionTitle title={"Déclaration animaux à vendre"} />
          <div className="div_btns_outils_op">
            <BigGreenButton
              onClick={() => goDeclarationAVendre("taureaux")}
              text={"Taureaux"}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default OutilsOP
