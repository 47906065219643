import { fetchUrlEncoded } from "./fetch"
import platform from "platform"

// Function to login
export const fetchLogError = async (error, stack) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "logError"

  let params = {
    a: action,
    e: error,
    s: stack,
    v: localStorage.app_version,
    d: platform.description,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
    fromLogError: true,
  })
}
