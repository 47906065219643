import { useRef } from "react"
import "./ModalDeclarationAVendre.scss"
import { useCenterModal } from "../../hooks/useCenterModal"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import ModalLoader from "../ModalLoader"
import { goBack } from "../../functions/handleNavigation"
import { blobToBase64 } from "../../functions/fetches/fetch"
import { fetchDeclarationVenteOp } from "../../functions/fetches/fetchDeclarationVenteOp"

const ModalDeclarationAVendre = ({ type }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()

  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const areaListe = useRef()

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const getDeclartionAVendre = async () => {
    let divModal = document.getElementById("loader_declarations_a_vendre")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let area = areaListe.current.value.trim()
    if (area.length === 0) doAlert("Veuillez saisir au moins un animal s.v.p !")

    let animaux = area.split("\n")

    let isInError = false
    let ret = await fetchDeclarationVenteOp(type, animaux)

    if (!ret) isInError = true

    if (ret.csv && ret.name) {
      await fetch(process.env.REACT_APP_API_URL + ret.csv)
        .then(response => response.blob())
        .then(blob => {
          if ("ReactNativeWebView" in window) {
            blobToBase64(blob).then(res => {
              let message = {
                type: "saveFile",
                content: {
                  title: ret.name,
                  b64: res,
                  mime: "text/csv",
                },
              }

              let stringified = JSON.stringify(message)

              window.ReactNativeWebView.postMessage(stringified)
            })
          } else {
            var url = window.URL.createObjectURL(blob)
            var a = document.createElement("a")
            a.href = url
            // a.target = "_blank"
            a.download = ret.name
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove() //afterwards we remove the element again
          }
        })
        .catch(e => {
          isInError = true
        })
    }

    if (isInError) {
      setCallback(null)
      doAlert("Une erreur est survenue.")
    } else if (ret.rejetes && ret.rejetes.length !== 0) {
      let messageAlert = ""

      ret.rejetes.forEach(
        rejet =>
          (messageAlert += `<span class='message_alert_rejet'>${rejet} n'est pas à l'inventaire d'un de vos adhérents.</span>`)
      )
      setCallback(null)
      doAlert(messageAlert)
    } else {
      setCallback(goBack)
      doAlert("Votre fichier a été téléchargé !")
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_declarations_a_vendre"
        message="Chargement en cours..."
      />

      <div ref={divModal} className="modal_declarations_a_vendre_op">
        <div className="div_modal_header">
          <h3>Déclartion {type} à vendre</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
        </div>

        <div className="modal_content">
          <div className="explication_exports">
            <p>Un numéro animal complet par ligne</p>
          </div>
          <textarea
            ref={areaListe}
            onChange={e => (e.target.value = e.target.value.toUpperCase())}
          ></textarea>
        </div>

        <div className="div_bottom" id="div-bottom">
          <div className="div_button">
            <button
              className="button_validate pointer"
              onClick={getDeclartionAVendre}
            >
              Valider
            </button>
          </div>
        </div>
      </div>

      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalDeclarationAVendre
