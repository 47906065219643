import { Fragment, useEffect, useRef, useState } from "react"
import {
  getColumns,
  getColumnsCategories,
  getColumnsCompare,
} from "../functions/objectColums"
import "./Comparaison.scss"
import CheptelSectionTitle from "../components/CheptelSectionTitle"
import { formatDate, formatDate2 } from "../functions/formatDate"

let clickHeld = false
let mouseX = 0
let mouseY = 0

const Comparaison = ({ animaux, highestValues, diffValues }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const columns = getColumns()
  const columnsCompare = getColumnsCompare()
  const categories = getColumnsCategories()

  let colAvSorted = {}
  columnsCompare?.forEach(col => {
    let category = columns[col].category
    if (colAvSorted[category] === undefined) colAvSorted[category] = []

    colAvSorted[category].push(col)
  })

  const baseDisplayCats = {}
  let savedDisplayCats = JSON.parse(localStorage.displayCatsComp ?? "[]")

  for (let cat in categories) {
    baseDisplayCats[cat] = savedDisplayCats[cat] ?? true
  }

  const [displayCats, setDisplayCats] = useState(baseDisplayCats)
  let divWrap = useRef()
  let firstLine = useRef()
  const nbAni = Object.keys(animaux).length

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    // Needed to make sure CSS works fine. When scrolling horizontally,
    // we want the section title to stay on screen and not scroll
    // If the section is not showned(reduced), then the category div doesnt have
    // the right size and thus make the title scroll wich is unwanted
    document.querySelectorAll(".cat_comparaison").forEach(cat => {
      cat.style.width = `${firstLine.current.offsetWidth}px`
    })

    let div = divWrap.current

    if (div) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0

        div.style.paddingBottom = addHeight + "px"
      }
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const showHideCat = cat => {
    displayCats[cat] = !displayCats[cat]
    localStorage.displayCatsComp = JSON.stringify(displayCats)
    setDisplayCats(structuredClone(displayCats))
  }
  const giveClassIsNb = cl => {
    let isNbCl = "isNbCl" + nbAni

    return `${cl} ${isNbCl}`
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Methods to handle moving the table by holding the mouse (kinda like touch behaviour but with a mouse) //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const tbodyMouseDown = e => {
    if (e.button !== 0) return

    clickHeld = true
    if (!isMobile) divWrap.current.classList.add("cursor_grab")

    mouseX = e.nativeEvent.pageX
    mouseY = e.nativeEvent.pageY
  }
  const tbodyMouseMove = e => {
    if (clickHeld) {
      divWrap.current.scrollBy(
        mouseX - e.nativeEvent.pageX,
        mouseY - e.nativeEvent.pageY
      )
      mouseX = e.nativeEvent.pageX
      mouseY = e.nativeEvent.pageY
    }
  }
  const tbodyMouseUp = e => {
    if (clickHeld) {
      if (!isMobile) divWrap.current.classList.remove("cursor_grab")
      clickHeld = false
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div
      className="comapraison"
      onMouseDown={tbodyMouseDown}
      onMouseUp={tbodyMouseUp}
      onMouseMove={tbodyMouseMove}
      onMouseLeave={tbodyMouseUp}
      ref={divWrap}
    >
      <div className="first_line_comparaison" ref={firstLine}>
        <div className="header_column"></div>
        {animaux.map(ani => (
          <div
            className={giveClassIsNb("header_comparaison")}
            key={ani.COPAIP + ani.NUNATI}
          >
            <p>
              {ani.COPAIP + ani.NUNATI.substr(0, 6)}
              <span className="numtrav_comparaison">
                {ani.NUNATI.substr(6, 10)}
              </span>
            </p>
            <p className="el_name_ani">{ani.NOBOVI}</p>
          </div>
        ))}
      </div>
      {Object.keys(colAvSorted).map(cat => (
        <div key={cat} className="cat_comparaison">
          <div className="div_title_section">
            <CheptelSectionTitle
              title={categories[cat]?.name}
              noMargin={true}
              show={displayCats[cat]}
              onClick={() => showHideCat(cat)}
            />
          </div>
          <div className="lines_cat_comparaison">
            {displayCats[cat] &&
              colAvSorted[cat].map(key => (
                <div className="line_cat_comparaison" key={key}>
                  <p className="line_name">{columns[key]?.name}</p>
                  {animaux.map((ani, index) => (
                    <Fragment key={index + key}>
                      <div
                        className={giveClassIsNb("value_animal_comparaison")}
                        key={ani.COPAIP + ani.NUNATI}
                      >
                        <div
                          className={
                            isHighestValue(ani, key, highestValues)
                              ? "highest_value"
                              : ""
                          }
                        >
                          {getValueAnimal(ani, key)}
                        </div>
                      </div>
                      {nbAni === 2 && index === 0 && (
                        <div className="diff_value" key={"diff" + key}>
                          {diffValues[key] && (
                            <>
                              {diffValues[key]?.diff !== 0 && (
                                <>
                                  {diffValues[key]?.diff !==
                                    "DoNotDisplayAValue" &&
                                    `${diffValues[key]?.symbol ?? ""}${
                                      diffValues[key]?.diff
                                    }`}

                                  {diffValues[key].side === 1 && (
                                    <div className="side_diff">
                                      <div className="arrow_left"></div>
                                      <div className="line"></div>
                                    </div>
                                  )}
                                  {diffValues[key].side === 2 && (
                                    <div className="side_diff">
                                      <div className="line"></div>
                                      <div className="arrow_right"></div>
                                    </div>
                                  )}
                                </>
                              )}
                              {diffValues[key]?.diff === 0 && (
                                <div className="equal">=</div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Comparaison

const getValueAnimal = (animal, key) => {
  let returnValue = ""

  switch (key) {
    case "SEXBOV":
      if (animal.SEXBOV === "2")
        if (animal.COFGMU > 1)
          returnValue = <div className="sexe femelle_jumelle">2</div>
        else returnValue = <div className="sexe femelle">2</div>
      if (animal.SEXBOV === "1")
        if (animal.COFGMU > 1)
          returnValue = <div className="sexe male_jumeau">1</div>
        else returnValue = <div className="sexe male">1</div>
      break
    case "DANAIS":
    case "DADEVE":
      returnValue = animal[key] ? animal[key] : ""
      break
    case "ipgmou_daench":
      returnValue = animal[key] ? animal[key] : ""
      break
    case "pntp":
      returnValue = animal.PONAIS
      break
    case "bopoat120":
    case "bopoat210":
      if (animal[key]) returnValue = animal[key] + " kg"
      break
    default:
      returnValue = animal[key]
      break
  }

  return returnValue
}

const isHighestValue = (animal, key, highestValues) => {
  let isHighest

  switch (key) {
    case "qualifAnimal":
      let valAni =
        animal["A_bestqual_COQUBO"] + "|" + animal["A_bestqual_CCQUBO"]

      isHighest = highestValues[key] && valAni && highestValues[key] === valAni
      break
    case "pntp":
      let poids = getValueAnimal(animal, key)
        ?.replace(" kg", "")
        ?.replace(" calculé", "")

      isHighest = poids === highestValues[key]
      break
    case "bopoat120":
    case "bopoat210":
      isHighest =
        getValueAnimal(animal, key).replace(" kg", "") === highestValues[key]
      break
    default:
      isHighest =
        getValueAnimal(animal, key) &&
        highestValues[key] &&
        getValueAnimal(animal, key) === highestValues[key]
      break
  }

  return isHighest
}
