import { useRef, useState } from "react"
import { goBack, goBackXAmount } from "../../functions/handleNavigation"
import "./ModalParcours.scss"
import { useCenterModal } from "../../hooks/useCenterModal"
import { formatDateLikePHP } from "../../functions/formatDate"

const ModalParcours = ({ parcours, numAnimal, origin }) => {
  console.log(parcours)

  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let divModal = useRef()
  useCenterModal(divModal)

  const [data, setData] = useState(parcours)

  const [sortNum, setSortNum] = useState("")
  const [sortNom, setSortNom] = useState("")
  const [sortDaench, setSortDaench] = useState("sortDown")
  const [sortCaentr, setSortCaentr] = useState("")
  const [sortDasort, setSortDasort] = useState("")
  const [sortCasort, setSortCasort] = useState("")

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const customGoBack = () => (origin === "animal" ? goBackXAmount(2) : goBack())
  const sortCol = col => {
    let datatmp = structuredClone(parcours)

    if (col === "num") {
      const frCollator = new Intl.Collator("fr")

      if (sortNum !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(
            a.copach + a.cheipg ?? "",
            b.copach + b.cheipg ?? ""
          )
        )

        setSortNum("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(
            a.copach + a.cheipg ?? "",
            b.copach + b.cheipg ?? ""
          )
        )

        setSortNum("sortUp")
      }

      setSortNom("")
      setSortDaench("")
      setSortCaentr("")
      setSortDasort("")
      setSortCasort("")
    } else if (col === "nom") {
      const frCollator = new Intl.Collator("fr")

      if (sortNom !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.nom_cheptel ?? "", b.nom_cheptel ?? "")
        )

        setSortNom("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(a.nom_cheptel ?? "", b.nom_cheptel ?? "")
        )

        setSortNom("sortUp")
      }

      setSortNum("")
      setSortDaench("")
      setSortCaentr("")
      setSortDasort("")
      setSortCasort("")
    } else if (col === "daench") {
      const frCollator = new Intl.Collator("fr")

      if (sortDaench !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.daench ?? "", b.daench ?? "")
        )

        setSortDaench("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(a.daench ?? "", b.daench ?? "")
        )

        setSortDaench("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortCaentr("")
      setSortDasort("")
      setSortCasort("")
    } else if (col === "caentr") {
      const frCollator = new Intl.Collator("fr")

      if (sortCaentr !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.caentr ?? "", b.caentr ?? "")
        )

        setSortCaentr("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(a.caentr ?? "", b.caentr ?? "")
        )

        setSortCaentr("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortDaench("")
      setSortDasort("")
      setSortCasort("")
    } else if (col === "dasort") {
      const frCollator = new Intl.Collator("fr")

      if (sortDasort !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.dasort ?? "", b.dasort ?? "")
        )

        setSortDasort("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(a.dasort ?? "", b.dasort ?? "")
        )

        setSortDasort("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortDaench("")
      setSortCaentr("")
      setSortCasort("")
    } else if (col === "casort") {
      const frCollator = new Intl.Collator("fr")

      if (sortCasort !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.casort ?? "", b.casort ?? "")
        )

        setSortCasort("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(a.casort ?? "", b.casort ?? "")
        )

        setSortCasort("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortDaench("")
      setSortCaentr("")
      setSortDasort("")
    }

    setData(datatmp)
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  return (
    <>
      <div ref={divModal} className="modal_parcours">
        <div className="div_modal_header">
          <span
            className="close_modal ripple2  material-symbols-outlined"
            onClick={customGoBack}
          >
            close
          </span>
          <h3>Parcours de {numAnimal}</h3>
        </div>
        <div className="modal_content">
          <div className="div_table">
            <table>
              <thead>
                <tr>
                  <th
                    onClick={() => sortCol("num")}
                    className={sortNum + " pointer ripple2"}
                  >
                    Cheptel
                  </th>
                  <th
                    onClick={() => sortCol("nom")}
                    className={sortNom + " pointer ripple2"}
                  >
                    Nom Cheptel
                  </th>
                  <th
                    onClick={() => sortCol("daench")}
                    className={sortDaench + " pointer ripple2"}
                  >
                    Date d'entrée
                  </th>
                  <th
                    onClick={() => sortCol("caentr")}
                    className={sortCaentr + " pointer ripple2"}
                  >
                    Cause entrée
                  </th>
                  <th
                    onClick={() => sortCol("dasort")}
                    className={sortDasort + " pointer ripple2"}
                  >
                    Date de sortie
                  </th>
                  <th
                    onClick={() => sortCol("casort")}
                    className={sortCasort + " pointer ripple2"}
                  >
                    Cause sortie
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map &&
                  data?.map((ligne, i) => (
                    <tr key={i}>
                      <td>{ligne.copach + ligne.cheipg}</td>
                      <td>{ligne.nom_cheptel}</td>
                      <td className="nowrap">
                        {formatDateLikePHP(ligne.daench, "d-m-Y")}
                      </td>
                      <td className="nowrap">{ligne.caentr}</td>
                      <td className="nowrap">
                        {formatDateLikePHP(ligne.dasort, "d-m-Y")}
                      </td>
                      <td className="nowrap">{ligne.casort}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button className="background-modal" onClick={customGoBack}></button>
    </>
  )
}

export default ModalParcours
