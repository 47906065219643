import { useRef } from "react"
import "./ModalPlayer.scss"
import { useCenterModal } from "../hooks/useCenterModal"
import { goBack } from "../functions/handleNavigation"

const ModalPlayer = ({ url, title }) => {
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  let height = window.innerHeight * 0.85
  let width = window.innerWidth * 0.85

  return (
    <>
      <div ref={divModal} className="modal_youtube_player">
        <div className="div_modal_header">
          <span
            onClick={goBack}
            className="close_modal ripple2 pointer material-symbols-outlined"
          >
            close
          </span>
          <h3>{title}</h3>
        </div>
        <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${url}`}
          autoPlay="1"
          mute="1"
        ></iframe>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalPlayer
