import { useEffect, useRef, useState } from "react"
import "./SelectData.scss"
import { getDefaultInventories } from "../../functions/objectColums"

const SelectData = ({ onChange, isAdherent, inventaire, sidePanel }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const div = useRef()
  const defaultList = getDefaultInventories()
  const [dataType, setDataType] = useState(inventaire)
  const listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")

  let orderedList = JSON.parse(localStorage.orderedList ?? "[]")
  // If there is no list, just insert default inventories as orderedList
  if (orderedList.length === 0) {
    for (let id in defaultList) {
      orderedList.push(id)
    }
  } else {
    for (let id in defaultList) {
      if (!orderedList.includes(id)) orderedList.push(id)
    }
  }

  let newHeight = 60

  if (
    "ReactNativeWebView" in window &&
    window.localStorage.platformNative === "android"
  ) {
    let addHeight = window.localStorage.statutBarHeight
      ? +window.localStorage.statutBarHeight
      : 0
    newHeight += addHeight
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = div.current

    const changeTab = e => {
      setDataType(e.detail.dataType)
    }

    curr.addEventListener("changeTab", changeTab)

    return () => {
      curr.removeEventListener("changeTab", changeTab)
    }
  }, [])
  useEffect(() => {
    let widthBeforeElement = 0
    let widthBeforeElementM1 = 0
    let id = 0

    if (inventaire.includes("custom")) {
      id = inventaire
    } else {
      for (let i in defaultList) {
        if (inventaire === defaultList[i].dataInv) id = i
      }
    }

    let index = 0

    orderedList.forEach((element, i) => {
      if (id === element) index = i
    })

    for (let i = 0; i < index; i++) {
      const element = document.getElementById("li_" + i)
      if (element) {
        let width = element.offsetWidth

        if (i < index - 1) widthBeforeElementM1 += +width
        widthBeforeElement += +width
      }
    }

    let chosenWidth = document.getElementById("li_" + index).offsetWidth
    document.querySelector(".li_active")?.classList.remove("li_active")
    document.getElementById("li_" + index).classList.add("li_active")
    let activeBorder = document.getElementById("active_border")

    widthBeforeElement = widthBeforeElement + 15
    chosenWidth = chosenWidth - 30

    activeBorder.style.marginLeft = widthBeforeElement + "px"
    activeBorder.style.width = chosenWidth + "px"

    document.getElementById("selectdata").scroll({
      top: 0,
      left: widthBeforeElementM1,
      behavior: "smooth",
    })
  }, [dataType])

  ///////////////
  // Functions //
  ///////////////
  const sendValue = value => {
    onChange(value)
  }
  const getMainDivClass = () => {
    let cl = "selectdata"

    isMobile ? (cl += " noscroll") : (cl += " scroll_hover")
    sidePanel && (cl += " with_side_filters")

    return cl
  }

  return (
    <div
      ref={div}
      id="selectdata"
      className={getMainDivClass()}
      style={{
        top: `${newHeight}px`,
      }}
    >
      <ul className={isMobile ? "ul_select noscroll" : "ul_select"}>
        {orderedList.map((idInv, index) => {
          let dataInv
          let name
          let isCustom = idInv.includes("custom")

          if (isCustom) {
            dataInv = idInv

            let numInv = +idInv.replace("custom", "")

            name = listCustInv[numInv]?.name
          } else {
            dataInv = defaultList[idInv]?.dataInv
            name = defaultList[idInv]?.name
            let isHbl = defaultList[idInv]?.isHbl

            if (isHbl && !isAdherent) return
          }

          return (
            <li
              key={index}
              className="li_select ripple2"
              data-inv={dataInv}
              onClick={() => sendValue(dataInv)}
              id={"li_" + index}
            >
              {name}
            </li>
          )
        })}
      </ul>
      <div className="scroll_select">
        <div id="active_border" className="active_border"></div>
      </div>
    </div>
  )
}

export default SelectData
