import "./DeleteAccount.scss"
import logo from "../assets/images/logo.png"
import { useEffect, useRef, useState } from "react"
import ModalReinit from "../components/login/ModalReinit"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import Confirm from "../components/Confirm"
import { fetchDeleteAccount } from "../functions/fetches/fetchDeleteAccount"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalLoader from "../components/ModalLoader"
import { goToHashWithParams } from "../functions/hash"
import { goBack } from "../functions/handleNavigation"

const DeleteAccount = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  const [errorMessage, setErrorMessage] = useState("") // Login error message
  const [showPass, setShowPass] = useState(false)

  const centerModal = useRef()
  const input_login = useRef()
  const input_pass = useRef()
  const leftArrow = useRef()

  const [modalReinit, setModalReinit] = useState(false)
  pageOption === "reinit" && !modalReinit && setModalReinit(true)
  pageOption !== "reinit" && modalReinit && setModalReinit(false)

  let param1 = window.location.hash.split("|")[1]

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    const resizeLogo = () => {
      let imgLogo = document.getElementById("logo_login")
      let formLogin = document.getElementById("formlogin")

      if (imgLogo && formLogin) {
        var rect = formLogin.getBoundingClientRect()
        let topVal = Math.ceil(rect.top)
        let dixpourcent = topVal * 0.1

        imgLogo.style.top = dixpourcent + "px"
        imgLogo.style.height = topVal - 2 * dixpourcent + "px"
      }

      if (typeof centerModal.current === "function") centerModal.current()
    }

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android" &&
      leftArrow.current
    ) {
      let topActual =
        +window
          .getComputedStyle(leftArrow.current, null)
          .getPropertyValue("top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      leftArrow.current.style.top = newHeight + "px"
    }

    window.onresize = resizeLogo

    resizeLogo()

    return () => {
      window.onresize = null
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  // Show reinit password modal
  const showReinitModal = () => goToHashWithParams("supprcompte_reinit")

  const showHidePass = () => {
    setShowPass(prev => !prev)
  }
  const askDelete = () => {
    doConfirm(
      "Vous êtes sur le point de demander la suppression définitive de votre compte, êtes vous sur de vouloir continuer ?"
    )
  }
  const doDelete = async confirmed => {
    hideConfirm()

    if (confirmed) {
      let divModal = document.getElementById("loader_delete_acc")
      if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

      let login = input_login.current.value
      let pass = input_pass.current.value

      let del = await fetchDeleteAccount(login, pass)

      if (!del || del.error) {
        if (del && del.code_error === "no_result") {
          doAlert(
            "Aucun compte lié à votre identifiant et mot de passe. Supression impossible."
          )
        } else {
          doAlert(
            "Un problème est survenu lors de la suppression du compte. Veuillez réessayer s.v.p"
          )
        }
      } else if (del && del.success) {
        doAlert("Suppression effectué.")
      }

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="delete_account">
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && <Confirm message={messageConfirm} giveConfirm={doDelete} />}
      <ModalLoader id="loader_delete_acc" message="Suppression en cours..." />

      {param1 === "inApp" && (
        <div className="left_arrow_back" ref={leftArrow}>
          <span
            className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
            onClick={goBack}
          >
            arrow_back
          </span>
        </div>
      )}
      <img src={logo} className="logo_login" id="logo_login" alt="" />
      <div className="formlogin" id="formlogin">
        <h2 className="loginTitle">
          SUPPRESION DE VOTRE COMPTE ESPACEPRO - LIMOUSINE.ORG
        </h2>
        <div>
          <p className="errorMessage">{errorMessage}</p>
          <input
            id="loginId"
            name="loginId"
            type="text"
            className="connectInput first"
            placeholder="Numéro de cheptel (ex : FR12345678)"
            ref={input_login}
          />
          <div className="div_pass">
            <input
              id="loginPass"
              name="loginPass"
              type={showPass ? "text" : "password"}
              className="connectInput second"
              placeholder="Votre mot de passe"
              ref={input_pass}
            />
            <div
              className="icon_visibility pointer ripple2"
              onClick={showHidePass}
            >
              {showPass ? (
                <span className="material-symbols-outlined">
                  visibility_off
                </span>
              ) : (
                <span className="material-symbols-outlined">visibility</span>
              )}
            </div>
          </div>
          <div className="line_links_login">
            <a
              className="login_links pass_forgotten pointer ripple2"
              onClick={showReinitModal}
            >
              Mot de passe oublié ?
            </a>
          </div>
          <button type="submit" className="deleteButton" onClick={askDelete}>
            Suppression
          </button>
        </div>
      </div>
      {modalReinit && <ModalReinit />}
    </div>
  )
}

export default DeleteAccount
