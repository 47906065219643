import { fetchUploadBlob } from "./fetch"

// Function to save an image to server
export const fetchSaveAnimalPitcure = async (photo, animal) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "saveAnimalPicture"

  const formData = new FormData()
  formData.append("a", action)
  formData.append("photo", photo)
  formData.append("ani", animal)

  return fetchUploadBlob({
    url: url,
    formData: formData,
  })
}
