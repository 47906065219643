import { useRef } from "react"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import ModalLoader from "../ModalLoader"
import "./ModalShareFicheStation.scss"
import { goBack } from "../../functions/handleNavigation"
import { blobToBase64 } from "../../functions/fetches/fetch"
import { fetchLogActionFiche } from "../../functions/fetches/fetchLogActionFiche"

const ModalShareFicheStation = ({ blobShareFiche, numani }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  // Center the modal
  const divModal = useRef()

  const refImage = useRef()

  ////////////////
  // useEffects //
  ////////////////
  ///////////////
  // Functions //
  ///////////////
  const onLoadImage = () => {
    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    divModal.current.style.left = `${leftWidth}px`
  }
  const downloadImage = async () => {
    let divModal = document.getElementById("loader_dl_fiche")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    fetchLogActionFiche("fiche_station", "download", numani)

    if ("ReactNativeWebView" in window) {
      blobToBase64(blobShareFiche).then(b64 => {
        let message = {
          type: "saveFile",
          content: {
            title: numani + ".jpg",
            b64: b64,
            mime: "image/jpeg",
          },
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      })
    } else {
      var url = window.URL.createObjectURL(blobShareFiche)
      var a = document.createElement("a")
      a.href = url
      // a.target = "_blank"
      a.download = numani + ".jpg"
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
  }
  const openImage = async () => {
    if ("ReactNativeWebView" in window) {
      await Promise.all([blobToBase64(blobShareFiche)]).then(values => {
        let b64 = values[0]

        let message = {
          type: "ImageB64",
          content: {
            title: numani,
            b64: b64,
          },
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      })
    } else {
      const blobUrl = URL.createObjectURL(blobShareFiche)

      window.open(blobUrl, "_blank")
    }
  }
  const shareFiles = async () => {
    if ("ReactNativeWebView" in window) {
      fetchLogActionFiche("fiche_station", "share", numani)

      await Promise.all([blobToBase64(blobShareFiche)]).then(values => {
        let message = {
          type: "shareFiles",
          urls: JSON.stringify(values),
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      })
    } else {
      doAlert(
        "La fonctionnalité de partage n'est disponible que sur l'application mobile de votre EspacePro."
      )
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_dl_fiche" message="Téléchargement en cours..." />
      <ModalLoader id="loader_send_fiche" message="Partage en cours..." />

      <div className="modal_share_fiche_station" ref={divModal}>
        <div className="div_modal_header">
          <h3>Partager votre fiche</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
        </div>

        <div className="modal_content">
          <div className="div_image">
            <img
              ref={refImage}
              className="img_fiche_ani pointer"
              src={blobShareFiche && URL.createObjectURL(blobShareFiche)}
              onLoad={onLoadImage}
              onClick={openImage}
            />
          </div>

          <div className="buttons_share">
            {"ReactNativeWebView" in window && (
              <button
                className="ico_share share pointer ripple2"
                onClick={shareFiles}
              >
                <span className="icone_btn material-symbols-outlined">
                  share
                </span>
              </button>
            )}
            <button
              className="ico_share dl pointer ripple2"
              onClick={downloadImage}
            >
              <span className="icone_btn material-symbols-outlined">
                download
              </span>
            </button>
          </div>
        </div>
      </div>

      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalShareFicheStation
