import { useRef } from "react"
import "./ModalChoices.scss"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack } from "../../../functions/handleNavigation"

const ModalChoices = ({ col, callback }) => {
  let analysesFilter = JSON.parse(window.localStorage.analyseFilter)
  let confirmFilter = JSON.parse(window.localStorage.confirmFilter)

  //////////////////////////////////////
  // OBJECT DEFINING WHAT TO GENERATE //
  //////////////////////////////////////
  let values = {
    SEXBOV: [
      {
        text: "Tous",
        value: null,
      },
      {
        text: "Mâle",
        value: "1",
      },
      {
        text: "Femelle",
        value: "2",
      },
    ],
    analyses: [
      {
        text: "Aucun filtre",
        value: null,
      },
      {
        text: `Analyse à faire (${analysesFilter?.needAnalyse ?? 0})`,
        value: "needAnalyse",
      },
      {
        text: `Avec incompatibilité (${
          analysesFilter?.hasIncompatibility ?? 0
        })`,
        value: "hasIncompatibility",
      },
      {
        text: `Sans compatibilité de Père (${
          analysesFilter.maleSansCompatPere ?? 0
        })`,
        value: "noCompatibilityPere",
      },
      {
        text: `Sans analyse MH (${analysesFilter.maleSansMh ?? 0})`,
        value: "noAnalyseMh",
      },
      {
        text: `Sans profil ADN (${analysesFilter.maleSansProfilAdn ?? 0})`,
        value: "noProfilAdn",
      },
    ],
    prelevements: [
      {
        text: `Aucun filtre`,
        value: null,
      },
      {
        text: `Animaux à mon inventaire`,
        value: "inventory",
      },
      {
        text: `Tous les prélèvements`,
        value: "all",
      },
    ],
    confirmation: [
      {
        text: `Aucun filtre`,
        value: null,
      },
      {
        text: `Génisses à examiner (${confirmFilter.nbGenissesToExamine})`,
        value: "nbGenissesToExamine",
      },
      {
        text: `Génisses à confirmées (${confirmFilter.nbGenissesToConfirm})`,
        value: "nbGenissesToConfirm",
      },
      {
        text: `Vaches à confirmer (${confirmFilter.nbVachesToConfirm})`,
        value: "nbVachesToConfirm",
      },
      {
        text: `Femelles +6 mois non inscrites HBL (${confirmFilter.nbFemP6MNnInscrite})`,
        value: "nbFemP6MNnInscrite",
      },
      {
        text: `Mâles 6-18 mois à examiner (${confirmFilter.nbMaleToExamine})`,
        value: "nbMaleToExamine",
      },
      {
        text: `Mâles à confirmer (${confirmFilter.nbMaleToConfirm})`,
        value: "nbMaleToConfirm",
      },
      {
        text: `Mâles +6 mois non inscrits HBL (${confirmFilter.nbMalP6MNnInscrit})`,
        value: "nbMalP6MNnInscrit",
      },
    ],
  }

  ///////////
  // HOOKS //
  ///////////
  let divModal = useRef()
  useCenterModal(divModal)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////

  ///////////////
  // EXTRA CODE //
  ///////////////
  if (col === "prelevements_fix") {
    col = "prelevements"
    // Remove no filter for prelevements inventory
    values["prelevements"].shift()
  }
  return (
    <>
      <div ref={divModal} className="modal_choices_filter">
        <div className="div_choices_input">
          {values[col].map((line, i) => (
            <p
              key={i}
              className="ripple2 pointer"
              onClick={() => callback(line.value, col)}
            >
              {line.text}
            </p>
          ))}
        </div>
        <div className="div_button_input_choices">
          <button className="ripple2 pointer" onClick={goBack}>
            Annuler
          </button>
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalChoices
