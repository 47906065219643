import { useEffect, useRef, useState } from "react"
import "./ModalListePeres.scss"
import ModalLoader from "../ModalLoader"
import { goBack } from "../../functions/handleNavigation"
import { fetchListeComparaisonPeres } from "../../functions/fetches/fetchListeComparaisonPeres"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"
import Alert from "../Alert"

const ModalListePeres = ({ codeActivite, dateVisite, campagne, idVisite }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  let numCheptel = window.localStorage.numCheptel ?? ""

  const pereSelected = useRef([])
  const [peres, setPeres] = useState({})

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  // Center the modal
  const divModal = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    goGetPeres()
  }, [])
  useEffect(() => {
    centerModalPere()
  }, [peres])
  ///////////////
  // Functions //
  ///////////////
  const goGetPeres = async () => {
    let divModal = document.getElementById("loader_liste_peres")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let listePeres = await fetchListeComparaisonPeres(codeActivite, numCheptel)

    if (listePeres && listePeres.error !== undefined) {
      doAlert("Un problème est survenu lors de la récupération des pères.")
    }

    setPeres(listePeres)

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const cocheCase = nPere => {
    let div = document.getElementById(`div_ligne_${nPere}`)

    if (pereSelected.current.includes(nPere)) {
      div.firstChild.classList.remove("on")
      pereSelected.current = pereSelected.current.filter(val => val !== nPere)
    } else {
      if (pereSelected.current.length < 6) {
        div.firstChild.classList.add("on")
        pereSelected.current.push(nPere)
      } else
        doAlert("Vous ne pouvez comparer que 6 peres à la fois au maximum.")
    }
  }
  const getPdf = async (link, name) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let isOnline = await checkInternet()

    if (isOnline) {
      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      )
    }

    goBack()
  }
  const centerModalPere = () => {
    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    divModal.current.style.left = `${leftWidth}px`
  }
  const doComparaisonPeres = e => {
    e.target.disabled = true

    let peresA = null
    let peresS = null

    if (pereSelected.current.length < 2) {
      doAlert(
        "Veuillez sélectionner au moins deux pères pour faire la comparaison."
      )
    } else {
      let choix
      let isMulti = null

      if (codeActivite === "PTGE_A") {
        choix = "moy_pere_ptgeA"
        peresA = JSON.stringify(pereSelected.current)
      } else if (codeActivite === "PTGE_S") {
        choix = "moy_pere_ptgeS"
        peresS = JSON.stringify(pereSelected.current)
      }

      let date = dateVisite.substr(0, 10)

      let cSplit = campagne.split("/")

      let ts = Math.round(Date.now() / 1000)
      let name = `${choix}_${numCheptel}_${date}_${ts}`

      let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf_peres/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${choix}/${idVisite}/${isMulti}/${peresA}/${peresS}/${name}.pdf`

      setTimeout(() => {
        getPdf(link, name)
      }, 0)
    }

    e.target.disabled = false
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      <ModalLoader
        id="loader_liste_peres"
        message="Récupération des pères en cours..."
      />

      {Object.keys(peres).length !== 0 && (
        <div className="modal_listePeres" ref={divModal}>
          <div className="div_header_filter">
            <h3 className="titre_filtrer">
              Choix des pères{" "}
              {codeActivite === "PTGE_A"
                ? "p. adultes"
                : codeActivite === "PTGE_S"
                ? "p. sevrages"
                : ""}
            </h3>
          </div>
          <div className="div_filters">
            {Object.keys(peres).map(nPere => (
              <div
                id={`div_ligne_${nPere}`}
                className="div_ligne pointer ripple2"
                key={nPere}
                onClick={e => cocheCase(nPere)}
              >
                <div className="cocheConfirmable"></div>
                <p className="p_filter">
                  {`${peres[nPere]["nomPere"]}${
                    peres[nPere]["qualif"] ? " - " + peres[nPere]["qualif"] : ""
                  } (${nPere}) - ${peres[nPere]["nbFilles"]} filles`}
                </p>
              </div>
            ))}
          </div>
          <div className="div_close_modal_text">
            <button
              className="btn_validate_list ripple2 pointer"
              onClick={doComparaisonPeres}
            >
              Valider
            </button>
            <button
              className="btn_cancel_list ripple2 pointer"
              onClick={goBack}
            >
              Annuler
            </button>
          </div>
        </div>
      )}
      <button className={`background-modal`} onClick={goBack}></button>
    </>
  )
}

export default ModalListePeres
