import { fetchUrlEncoded } from "./fetch"

export const fetchLogHighScore = async () => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "logHighScore"
  let highScore = localStorage.monthHighScore
  let dateHighScore = localStorage.dateMonthHighScore ?? Date.now() / 1000
  let app_version = localStorage.app_version

  if (window.localStorage.JWT_TOKEN && highScore) {
    let params = {
      a: action,
      v: app_version,
      h: highScore,
      d: dateHighScore,
    }

    const formBody = Object.keys(params)
      .map(
        key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&")

    return fetchUrlEncoded({
      url: url,
      formBody: formBody,
    })
  }

  return false
}
