import { fetchUrlEncoded } from "./fetch"
import { getDeviceData } from "../getDeviceData"

// Function to login
export const fetchSaveFb = async token => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "saveFbToken"
  let device = getDeviceData()

  let params = {
    a: action,
    uuid: window.localStorage.uuid,
    t: token,
    d: JSON.stringify(device),
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
