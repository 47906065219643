import { useState } from "react"
import "./FillingBar.scss"

const FillingBar = ({ styleValues }) => {
  const [regle, setRegle] = useState(false)

  const handleMouseOver = () => {
    setRegle(true)
  }

  const handleMouseOut = () => {
    setRegle(false)
  }

  return (
    <div
      className="fillingbar"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className={
          styleValues.colorFiller
            ? styleValues.colorFiller === "green"
              ? "filler green"
              : styleValues.colorFiller === "blue" && "filler blue"
            : "filler"
        }
        style={{ width: styleValues.width, marginLeft: styleValues.margin }}
      ></div>
      {regle && (
        <div className="regle">
          <span className="point" style={{ marginLeft: "2px" }}>
            {styleValues.txtPoint0}
          </span>
          <span className="point" style={{ marginLeft: styleValues.point1 }}>
            {styleValues.txtPoint1}
          </span>
          <span className="point" style={{ marginLeft: styleValues.point2 }}>
            {styleValues.txtPoint2}
          </span>
          <span className="point" style={{ marginLeft: styleValues.point3 }}>
            {styleValues.txtPoint3}
          </span>
          <span className="point" style={{ marginLeft: styleValues.point4 }}>
            {styleValues.txtPoint4}
          </span>
        </div>
      )}
    </div>
  )
}

export default FillingBar
