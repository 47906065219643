import { useEffect, useRef } from "react"
import { goBack, goBackXAmount } from "../../functions/handleNavigation"
import DetailsVisite from "./DetailsVisite"
import "./ModalVisite.scss"
import RecapVisite from "./RecapVisite"

const ModalVisite = ({ page, pageOption, data, cheptelName }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let divRecap = useRef()
  let divWrap = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = divWrap.current

    if (div) {
      if (
        localStorage.scrolledElementIdNative &&
        localStorage.scrolledElementIdNative === "div_body_recap"
      ) {
        div.scrollLeft = localStorage.scrollXPageNative ?? 0
        div.scrollTop = localStorage.scrollYPageNative ?? 0

        delete localStorage.scrolledElementIdNative
        delete localStorage.scrollXPageNative
        delete localStorage.scrollYPageNative
      }
    }

    let curr = divRecap.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.top = newHeight + "px"
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const altGoBack = () => {
    if (pageOption === "details") goBackXAmount(2)
    else goBack()
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let arrowCl =
    "close_modal ripple2 material-symbols-outlined material-symbols-outlined_even_more_weight"
  if (pageOption !== "details") arrowCl += " mobile"

  return (
    <>
      <div className="div_recap" ref={divRecap}>
        <div className="header_recap">
          <div className="title_header">
            <p>
              {window.innerWidth < 770 && cheptelName
                ? cheptelName
                : "Récapitulatif de la visite"}
            </p>
          </div>
          <span
            onClick={altGoBack}
            className="close_modal ripple2 material-symbols-outlined desktop right pointer"
          >
            close
          </span>
          <span onClick={goBack} className={arrowCl}>
            arrow_back
          </span>
        </div>
        <div ref={divWrap} className="div_body_recap" id="div_body_recap">
          {pageOption === "recap" && <RecapVisite data={data} page={page} />}
          {pageOption === "details" && (
            <DetailsVisite data={data} page={page} />
          )}
        </div>
      </div>
      {!("ReactNativeWebView" in window) && (
        <button className={"background-modal"} onClick={goBack}></button>
      )}
    </>
  )
}

export default ModalVisite
