import "./ModalReinit.scss"
import { useRef, useState } from "react"
import { goBack } from "../../functions/handleNavigation"
import { useCenterModal } from "../../hooks/useCenterModal"
import BigGreenButton from "../BigGreenButton"
import { fetchReinit } from "../../functions/fetches/fetchReinit"
import { validateEmail } from "../../functions/validateEmail"

const ModalReinit = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let divRef = useRef()
  useCenterModal(divRef)

  const [errorMessage, setErrorMessage] = useState("") // Reinit pass error message
  const [emailSuccess, setEmailSuccess] = useState(null)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorCheptel, setErrorCheptel] = useState(false)

  let inputEmail = useRef()
  let inputCheptel = useRef()
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const errorReturn = ({
    errorMessage = null,
    errorCheptel = false,
    errorEmail = false,
  }) => {
    setErrorMessage(errorMessage)

    setErrorEmail(errorEmail)
    setErrorCheptel(errorCheptel)

    let divModal = document.getElementById("laoder_reinit_pass")
    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  // Do password reinitialization logic
  const reinitPassword = async e => {
    let divModal = document.getElementById("laoder_reinit_pass")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let email = inputEmail.current.value.trim()
    let cheptel = inputCheptel.current.value.trim()

    if (!email) {
      errorReturn({
        errorEmail: true,
        errorMessage: "Veuillez renseigner votre adresse email s.v.p.",
      })

      return false
    }

    if (!validateEmail(email)) {
      errorReturn({
        errorMessage:
          "Le format de l'adresse email est incorrecte. Ex: mon.email@email.com",
        errorEmail: true,
      })

      return false
    }

    if (!cheptel) {
      errorReturn({
        errorCheptel: true,
        errorMessage: "Veuillez renseigner votre numéro de cheptel s.v.p.",
      })

      return false
    }

    let retour = await fetchReinit(email, cheptel)

    if (!retour || Object.keys(retour).length === 0) {
      errorReturn({
        errorMessage:
          "Un problème est survenu lors de l'envoi de votre demande de réinitialisation. Merci de réessayer.",
      })
    } else if (retour && retour.v === 0) {
      errorReturn({
        errorEmail: true,
        errorCheptel: true,
        errorMessage:
          "La combinaison d'adresse mail et de numéro de cheptel saisie est non reconnue.",
      })
    } else if (retour && retour.reponse === "ok") {
      setEmailSuccess(email)
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  return (
    <>
      <div className="modal_reinit">
        <div className="div_modal_header">
          <h3>Mot de passe oublié</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2 pointer material-symbols-outlined"
          >
            close
          </span>
        </div>

        <div className="modal-content">
          {emailSuccess ? (
            <div className="errorMessage">
              Un email vous invitant à réinitialiser votre mot de passe vient
              d'être envoyer à l'adresse <b>{emailSuccess}</b>
            </div>
          ) : (
            <>
              {errorMessage && <p className="errorMessage">{errorMessage}</p>}

              <div className="inputs_reinit">
                <input
                  className={errorEmail ? "erreur_input" : ""}
                  onFocus={() => errorEmail && setErrorEmail(false)}
                  placeholder="Votre adresse email"
                  ref={inputEmail}
                  required
                />
                <input
                  className={errorCheptel ? "erreur_input" : ""}
                  onFocus={() => errorCheptel && setErrorCheptel(false)}
                  placeholder="Votre numéro de cheptel"
                  ref={inputCheptel}
                  required
                  onChange={e =>
                    (e.target.value = e.target.value.toUpperCase().trim())
                  }
                />
              </div>
              <div className="div_btn_reinit">
                <BigGreenButton
                  text="Réinitialiser mon mot de passe"
                  onClick={reinitPassword}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalReinit
