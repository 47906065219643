import "./Comportement.scss"
import FillingBar from "../../FillingBar"
import { useState } from "react"

let width = {
  compsev: 2,
  reacsev: 2,
}

let margin = {
  compsev: 42,
  reacsev: 42,
}

const Comportement = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow =
    window.localStorage.showcomportement === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showcomportement = !show
  }
  const calculateWidthMargin = (key, value) => {
    if (value === "") {
      width[key] = 0
      margin[key] = 0
    } else if (value > 100) {
      margin[key] = 43
      let tmpValue = value - 100
      tmpValue = (tmpValue * 100) / 40
      tmpValue = (tmpValue * 57) / 100
      width[key] = tmpValue
    } else if (value < 100) {
      let tmpValue = 100 - value
      tmpValue = (tmpValue * 100) / 30
      tmpValue = (tmpValue * 43) / 100
      width[key] = tmpValue
      margin[key] = 43 - tmpValue
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  calculateWidthMargin("compsev", animal.Acompse)
  calculateWidthMargin("reacsev", animal.Areacse)

  return (
    <div className="comportement" id="div_comportement">
      <h3 onClick={hideOrShow} className="ripple2">
        Comportement <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>

      {show &&
        (animal.Admpsfs !== "" ||
        animal.Adspsfs !== "" ||
        animal.Abaspss !== "" ||
        animal.Aafpsfs !== "" ||
        animal.Afospss !== "" ? (
          <table id="comportement">
            <thead>
              <tr>
                <th colSpan="3">Index</th>
                <th>CD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>COMPsev</td>
                <td className="td-less-padding">{animal.Acompse}</td>
                <td className="full_width">
                  {" "}
                  <FillingBar
                    styleValues={{
                      width: `${width["compsev"]}%`,
                      margin: `${margin["compsev"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdcomp !== "" && animal.Acdcomp}</td>
              </tr>
              <tr>
                <td>REACsev</td>
                <td className="td-less-padding">{animal.Areacse}</td>
                <td className="full_width">
                  <FillingBar
                    styleValues={{
                      width: `${width["reacsev"]}%`,
                      margin: `${margin["reacsev"]}%`,
                      point1: "calc(21.5% - 5px)",
                      point2: "calc(43% - 7px)",
                      point3: "calc(71.5% - 7px)",
                      point4: "calc(100% - 17px)",
                      txtPoint0: "70",
                      txtPoint1: "85",
                      txtPoint2: "100",
                      txtPoint3: "120",
                      txtPoint4: "140",
                    }}
                  />
                </td>
                <td>{animal.Acdreac !== "" && animal.Acdreac}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="alert">Pas d'index connus</div>
        ))}
    </div>
  )
}

export default Comportement
