import CheptelSectionTitle from "../CheptelSectionTitle"
import ModalLoader from "../ModalLoader"
import "./MyInfosHBL.scss"
import { useRef, useState } from "react"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import { checkIfConnected } from "../../functions/checkIfConnecetd"
import ModalVisite from "../visites/ModalVisite"
import BigGreenButton from "../BigGreenButton"
import noPhoto from "../../assets/images/no_photo.png"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"
import { goBack } from "../../functions/handleNavigation"
import { useCenterModal } from "../../hooks/useCenterModal"
import { formatDate2 } from "../../functions/formatDate"
import ModalListePeres from "../visites/ModalListePeres"

const MyInfosHBL = ({
  data,
  pocketData,
  pageOption,
  screenWide,
  cheptelName,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  let numCheptel = window.localStorage.numCheptel ?? ""

  let ts = Math.round(Date.now() / 1000)

  let savedShow = window.localStorage.showevolution === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  let oldVisites = []
  let newVisites = []
  let indexVisites = {}

  let activitesInVisites = {}

  if (pocketData && pocketData.constructor === Array) {
    pocketData?.forEach((visite, index) => {
      let dv = visite.dateVisite.replace(" ", "T")
      let dateVisite = new Date(dv)
      let now = new Date()
      let nowYear = now.getFullYear()
      let nowMonth = now.getMonth()
      let nowDay = now.getDate()
      let nowHours = now.getHours()
      let visiteYear = dateVisite.getFullYear()
      let visiteMonth = dateVisite.getMonth()
      let visiteDay = dateVisite.getDate()
      let visiteHours = dateVisite.getHours()

      let isToCome = false
      if (nowYear < visiteYear) isToCome = true
      else if (nowYear === visiteYear) {
        if (nowMonth < visiteMonth) isToCome = true
        else if (nowMonth === visiteMonth) {
          if (nowDay < visiteDay) isToCome = true
          else if (nowDay === visiteDay) {
            if (now < dateVisite) isToCome = true
            else {
              if (nowHours >= 13 && nowHours <= 19 && visiteHours > 13)
                isToCome = true
              else if (nowHours < 13 && visiteHours < 13) isToCome = true
            }
          }
        }
      }

      indexVisites[visite.idVisite] = index

      visite.indexVisite = index
      visite.heureVisite = visiteHours

      if (isToCome) newVisites.push(visite)
      else oldVisites.push(visite)

      Object.keys(visite.postes).forEach(activite => {
        if (!activitesInVisites[activite]) activitesInVisites[activite] = []

        activitesInVisites[activite].push(visite?.idVisite)
      })
    })

    oldVisites = oldVisites?.sort((a, b) =>
      +a.dateVisite.substr(0, 10).replace("-", "") >
      +b.dateVisite.substr(0, 10).replace("-", "")
        ? 1
        : +a.dateVisite.substr(0, 10).replace("-", "") <
          +b.dateVisite.substr(0, 10).replace("-", "")
        ? -1
        : 0
    )
    oldVisites = oldVisites.filter(
      v => v?.postes?.length !== 0 || v.codeStatut === "4"
    )

    newVisites?.sort((a, b) =>
      +a.dateVisite.substr(0, 10).replace("-", "") >
      +b.dateVisite.substr(0, 10).replace("-", "")
        ? 1
        : +a.dateVisite.substr(0, 10).replace("-", "") <
          +b.dateVisite.substr(0, 10).replace("-", "")
        ? -1
        : 0
    )
  }

  // Handle display recap modal
  const [clRecap, setClRecap] = useState(false)
  if ((pageOption === "recap" || pageOption === "details") && !clRecap)
    setClRecap(true)
  if (pageOption !== "recap" && pageOption !== "details" && clRecap)
    setClRecap(false)
  const [modalListePDFs, setModalListePDFs] = useState(false)
  pageOption === "pdfs" && !modalListePDFs && setModalListePDFs(true)
  pageOption !== "pdfs" && modalListePDFs && setModalListePDFs(false)

  let indexVisite = window.location.hash.split("|")[1] ?? 0

  let photoInspecteur = useRef()
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showinfoshbl = !show
  }
  const goConfirmFiltered = type => {
    localStorage.choixInv = "confirmation"
    let liveFilters = JSON.parse(localStorage.liveFilters ?? "{}")

    if (!liveFilters["confirmation"]) liveFilters["confirmation"] = {}
    liveFilters["confirmation"]["confirmation"] = {
      value: type,
    }

    localStorage.liveFilters = JSON.stringify(liveFilters)

    window.location.hash = "inventaire"
  }
  const goAnalyseFiltered = type => {
    localStorage.choixInv = "analyses"
    let liveFilters = JSON.parse(localStorage.liveFilters ?? "{}")

    if (!liveFilters["analyses"]) liveFilters["analyses"] = {}
    liveFilters["analyses"]["analyses"] = {
      value: type,
    }
    localStorage.liveFilters = JSON.stringify(liveFilters)

    window.location.hash = "inventaire"
  }
  const getPdf = async (link, name) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      if (window.location.hostname === "localhost")
        link = "http://localhost:2765/Fiches_tarifs_HBL_2022-07_V5.pdf"

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let scrolledElementId = "wrap_page_cheptel"
                let div = document.getElementById(scrolledElementId)

                if (div) {
                  localStorage.scrollXPageNative = div.scrollLeft
                  localStorage.scrollYPageNative = div.scrollTop
                  localStorage.scrolledElementIdNative = scrolledElementId
                }

                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              a.download = name
              // a.target = "_blank"
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  const showConfirmation = async (link, date) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      if ("ReactNativeWebView" in window) {
        let scrolledElementId = "wrap_page_cheptel"
        let div = document.getElementById(scrolledElementId)

        if (div) {
          localStorage.scrollXPageNative = div.scrollLeft
          localStorage.scrollYPageNative = div.scrollTop
          localStorage.scrolledElementIdNative = scrolledElementId
        }

        let message = {
          type: "PageDemandeConfirm",
          url: link,
          date: date,
        }

        let stringified = JSON.stringify(message)

        window.onLeaveExternalWebView = () => {
          if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

          window.onLeaveExternalWebView = null
        }

        window.ReactNativeWebView.postMessage(stringified)
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

        setTimeout(() => {
          window.open(link, "_blank")
        })
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  const moveToVisites = () => {
    localStorage.filterAct = "[]"
    window.location.hash = "visites"
  }
  const goToRecapVisite = index => {
    window.location.hash = `cheptel_recap|${index}`
  }
  const noPhotoFound = () => {
    photoInspecteur.current.src = noPhoto
  }
  const goLinkDiffTab = link => {
    var a = document.createElement("a")
    a.href = link
    a.target = "_blank"
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove() //afterwards we remove the element again
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      {clRecap && (
        <ModalVisite
          data={pocketData[indexVisite]}
          page={"cheptel"}
          pageOption={pageOption}
          cheptelName={cheptelName}
        />
      )}

      {modalListePDFs && (
        <ModalPDFs
          pocketData={pocketData}
          data={data}
          visites={activitesInVisites}
          indexVisites={indexVisites}
        />
      )}

      <CheptelSectionTitle
        title="Mes infos Herd Book Limousin"
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="myinfoshbl">
          <ModalLoader
            id="loader_dlpdfhbl"
            message="Téléchargement en cours..."
          />
          <div className="visites">
            <div className="visites_a_venir">
              <div className="visite_future">
                <p className="title">Prochaine visite</p>
                {newVisites.length !== 0 && (
                  <div
                    className="infos_visite pointer ripple2"
                    onClick={() =>
                      newVisites[0].portable &&
                      goLinkDiffTab(
                        "tel:" + newVisites[0].portable.replaceAll(" ", "")
                      )
                    }
                  >
                    <img
                      ref={photoInspecteur}
                      onError={noPhotoFound}
                      className="photo_inspecteur"
                      src={`https://intranet.limousine.org/avatars/${newVisites[0].idPointeur}.jpg`}
                    ></img>
                    <div className="text_next_visite">
                      <p className="nowrap">
                        {newVisites[0]?.prenomPointeur +
                          newVisites[0]?.nomPointeur}
                      </p>
                      <p className="nowrap">
                        le{" "}
                        <span className="bold">
                          {newVisites[0]?.dateVisiteFr.substr(0, 10)}
                        </span>
                      </p>
                      <p className="nowrap">
                        <span className="bold">
                          {newVisites[0]?.heureVisite > 12
                            ? "après-midi"
                            : "matin"}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                {newVisites.length === 0 && (
                  <div className="no_visit_planned">Aucune visite de prévu</div>
                )}
              </div>
            </div>
            <div className="visites_past">
              <div className="visites_passees">
                <p className="title">Mes précédentes visites</p>
                <div
                  id="content_visites_passees"
                  className="content_visites_passees"
                  onTouchStart={e => e.nativeEvent.stopImmediatePropagation()}
                >
                  {oldVisites.length !== 0 && (
                    <>
                      {oldVisites.map((visite, index) => {
                        if (
                          (!screenWide && index > 2) ||
                          (screenWide && index > 4)
                        )
                          return

                        return (
                          <div
                            className={
                              visite?.postes?.length !== 0
                                ? "card_visite pointer"
                                : "card_visite"
                            }
                            key={visite.idVisite}
                            onClick={() =>
                              visite?.postes?.length !== 0 &&
                              goToRecapVisite(visite?.indexVisite)
                            }
                          >
                            <h3 className="nowrap">
                              {visite?.prenomPointeur +
                                " " +
                                visite?.nomPointeur}
                            </h3>
                            <p className="nowrap">
                              le {visite?.dateVisiteFr.substr(0, 10)}{" "}
                              {visite?.heureVisite > 12
                                ? "après-midi"
                                : "matin"}
                            </p>
                          </div>
                        )
                      })}
                    </>
                  )}

                  {oldVisites.length === 0 && (
                    <div className="no_visit_passee">Aucune visite passée</div>
                  )}
                </div>
                {oldVisites.length !== 0 && (
                  <div className="div_btn_visits">
                    <BigGreenButton
                      onClick={moveToVisites}
                      text={"Voir toutes mes visites"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="rapports_pdfs">
            <p className="title">Mes rapports</p>
            <div
              id="pdfs_hbl"
              className={isMobile ? "pdfs_hbl isMobile" : "pdfs_hbl"}
            >
              <CardPDFs
                pocketData={pocketData}
                oldData={data.pointagesHBl}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="ptgeA_ind"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={data.pointagesHBlDetail}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="ptgeA_col"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={data.pointagesHBlPere}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="moy_pere_ptgeA"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_el_ptgeA"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_ins"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="exam_qualif2"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_sabots"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="ptgeS_col"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="moy_pere_ptgeS"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_el_ptgeS"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_op"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="f_pm"
              />
              <CardPDFs
                pocketData={pocketData}
                oldData={null}
                visites={activitesInVisites}
                indexVisites={indexVisites}
                pdf="tri_hbl"
              />
            </div>
          </div>
          <div className="infos-hbl">
            <div
              className="box_color red pointer ripple7"
              onClick={() => goAnalyseFiltered("needAnalyse")}
            >
              <h3>
                Analyses <p>à faire</p>
              </h3>
              <span className="infos-hbl-value">{data.needAnalyse ?? 0}</span>
            </div>
            <div
              className="box_color purple pointer ripple8"
              onClick={() => goConfirmFiltered("nbGenissesToExamine")}
            >
              <h3>
                Génisses <p>à examiner</p>
              </h3>
              <span className="infos-hbl-value">
                {data.nbGenissesToExamine}
              </span>
            </div>
            <div
              className="box_color yellow pointer ripple9"
              onClick={() => goConfirmFiltered("nbGenissesToConfirm")}
            >
              <h3>
                Génisses <p>à confirmer</p>
              </h3>
              <span className="infos-hbl-value">
                {data.nbGenissesToConfirm}
              </span>
            </div>
            <div
              className="box_color green pointer ripple10"
              onClick={() => goConfirmFiltered("nbVachesToConfirm")}
            >
              <h3>
                Vaches <p>à confirmer</p>
              </h3>
              <span className="infos-hbl-value">{data.nbVachesToConfirm}</span>
            </div>
            <div
              className="box_color blue pointer ripple11"
              onClick={() => goConfirmFiltered("nbFemP6MNnInscrite")}
            >
              <h3>
                Femelles +6 mois <p>non inscrites HBL</p>
              </h3>
              <span className="infos-hbl-value">{data.nbFemP6MNnInscrite}</span>
            </div>
            <div
              className="box_color red pointer ripple7"
              onClick={() => goConfirmFiltered("nbMaleToExamine")}
            >
              <h3>
                Mâles 6-18 mois <p>à examiner</p>
              </h3>
              <span className="infos-hbl-value">{data.nbMaleToExamine}</span>
            </div>
            <div
              className="box_color purple pointer ripple8"
              onClick={() => goConfirmFiltered("nbMaleToConfirm")}
            >
              <h3>
                Mâles <p>à confirmer</p>
              </h3>
              <span className="infos-hbl-value">{data.nbMaleToConfirm}</span>
            </div>
            <div
              className="box_color yellow pointer ripple9"
              onClick={() => goConfirmFiltered("nbMalP6MNnInscrit")}
            >
              <h3>
                Mâles +6 mois <p>non inscrits HBL</p>
              </h3>
              <span className="infos-hbl-value">{data.nbMalP6MNnInscrit}</span>
            </div>
          </div>
          <div className="docs-hbl">
            {data.demandesConfirmation !== undefined && (
              <div className="docs">
                <h3>Demande de confirmation</h3>
                <div className="list">
                  {data.demandesConfirmation?.map((doc, i) => {
                    return (
                      <p
                        className="eye-link ripple2"
                        target="_blank"
                        rel="noreferrer"
                        key={"conf" + i}
                        onClick={() =>
                          showConfirmation(
                            `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/confirmation/recap/${doc.demande}`,
                            doc.date
                          )
                        }
                      >
                        {doc.date}
                      </p>
                    )
                  })}
                </div>
              </div>
            )}
            <div className="docs">
              <h3>Autres documents</h3>
              <div className="list-autres">
                <p
                  className="pdf-link ripple2"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    getPdf(
                      `${process.env.REACT_APP_ESPRO_PDF_URL}fichiers/docs_hbl/Tarifs 2024-25.pdf`,
                      `Tarifs_2024-25_${ts}.pdf`
                    )
                  }
                >
                  Tarifs 2024-2025
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MyInfosHBL

const CardPDFs = ({ pocketData, oldData, visites, indexVisites, pdf }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let activite = []
  let title = ""
  let color = ""

  switch (pdf) {
    case "ptgeA_ind":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiches individuelle"
      color = "red"
      break
    case "ptgeA_col":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiches collective"
      color = "purple"
      break
    case "moy_pere_ptgeA":
      activite = ["PTGE_A"]
      title = "Pointages adultes - comparaison par pères"
      color = "yellow"
      break
    case "f_el_ptgeA":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiche élevage"
      color = "green"
      break
    case "f_ins":
      activite = ["CERTIF"]
      title = "Certification HBL"
      color = "blue"
      break
    case "exam_qualif2":
      activite = ["QUALIF", "QUALIF2"]
      title = "Qualification HBL"
      color = "red"
      break
    case "f_sabots":
      activite = ["DGLOC"]
      title = "Pointages des sabots"
      color = "purple"
      break
    case "ptgeS_col":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - fiches collective"
      color = "yellow"
      break
    case "moy_pere_ptgeS":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - comparaison par pères"
      color = "green"
      break
    case "f_el_ptgeS":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - fiche élevage"
      color = "blue"
      break
    case "f_op":
      activite = ["PELV2"]
      title = "Mensurations de pelvimétrie"
      color = "red"
      break
    case "f_pm":
      activite = ["DG"]
      title = "Pointages mamelles"
      color = "purple"
      break
    case "tri_hbl":
      activite = ["TRI"]
      title = "Tri morphologique HBL"
      color = "yellow"
      break
    default:
      break
  }

  let nbEditions = 0
  let dateLastEdition = ""
  let listeDates = new Set()

  activite.forEach(act => {
    if (visites[act] && pdf !== "ptgeA_ind") {
      nbEditions += visites[act].length

      visites[act].forEach(idVisite => {
        let indexVisite = indexVisites[idVisite]
        let date = pocketData[indexVisite].dateVisite

        date = date.substr(0, 10)
        listeDates.add(date)

        if (dateLastEdition === "" || dateLastEdition < date)
          dateLastEdition = date
      })
    }
  })

  if (oldData) {
    oldData.forEach(d => {
      if (dateLastEdition === "" || dateLastEdition < d.d) dateLastEdition = d.d

      if (!listeDates.has(d.d)) nbEditions += 1
    })
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const goModalPDFs = () => {
    window.location.hash = `cheptel_pdfs|${pdf}`
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div
      className={nbEditions !== 0 ? "card_pdf pointer" : "card_pdf"}
      onClick={() => nbEditions !== 0 && goModalPDFs()}
    >
      <div className={`title_pdf ${color}`}>{title}</div>

      {nbEditions !== 0 ? (
        <div className="card_pdf_infos">
          <div className={`card_pdf_nb_editions ${color}`}>
            <p className={`nb_editions_pdf ${color}`}>{nbEditions}</p>
            <p>éditions</p>
          </div>
          <div className="card_pdf_last_editions">
            <p>dernière édition</p>
            <p className="date_last_edition">{formatDate2(dateLastEdition)}</p>
          </div>
        </div>
      ) : (
        <div className="no_editions">Aucune édition</div>
      )}
    </div>
  )
}

const ModalPDFs = ({ pocketData, data, visites, indexVisites }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)
  const [dataPeres, setDataPeres] = useState(null)

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  let pdf = window.location.hash.split("|")[1] ?? 0
  let numCheptel = window.localStorage.numCheptel ?? ""
  let ts = Math.round(Date.now() / 1000)

  let title = ""
  let activite = []
  let choix = {}
  let listePDFs = []
  let listeDates = new Set()

  switch (pdf) {
    case "ptgeA_ind":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiches individuelle"
      choix = { PTGE_A: "ptgeA_ind" }

      if (data?.pointagesHBl && data?.pointagesHBl.constructor === Array)
        data?.pointagesHBl.forEach(ligne => {
          listePDFs.push({
            archive: true,
            dateVisite: ligne.d,
            file: ligne.f,
          })
        })
      break
    case "ptgeA_col":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiches collective"
      choix = { PTGE_A: "ptgeA_col" }

      if (
        data?.pointagesHBlDetail &&
        data?.pointagesHBlDetail.constructor === Array
      )
        data?.pointagesHBlDetail.forEach(ligne => {
          listePDFs.push({
            archive: true,
            dateVisite: ligne.d,
            file: ligne.f,
          })
        })
      break
    case "moy_pere_ptgeA":
      activite = ["PTGE_A"]
      title = "Pointages adultes - comparaison par pères"
      choix = { PTGE_A: "moy_pere_ptgeA" }

      if (
        data?.pointagesHBlPere &&
        data?.pointagesHBlPere.constructor === Array
      )
        data?.pointagesHBlPere.forEach(ligne => {
          listePDFs.push({
            archive: true,
            dateVisite: ligne.d,
            file: ligne.f,
          })
        })
      break
    case "f_el_ptgeA":
      activite = ["PTGE_A"]
      title = "Pointages adultes - fiche élevage"
      choix = { PTGE_A: "f_el_ptgeA" }
      break
    case "f_ins":
      activite = ["CERTIF"]
      title = "Certification HBL"
      choix = { CERTIF: "f_ins" }
      break
    case "exam_qualif2":
      activite = ["QUALIF", "QUALIF2"]
      title = "Qualification HBL"
      choix = { QUALIF: "exam_qualif", QUALIF2: "exam_qualif2" }
      break
    case "f_sabots":
      activite = ["DGLOC"]
      title = "Pointages des sabots"
      choix = { DGLOC: "f_sabots" }
      break
    case "ptgeS_col":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - fiches collective"
      choix = { PTGE_S: "ptgeS_col" }
      break
    case "moy_pere_ptgeS":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - comparaison par pères"
      choix = { PTGE_S: "moy_pere_ptgeS" }
      break
    case "f_el_ptgeS":
      activite = ["PTGE_S"]
      title = "Pointages sevrage - fiche élevage"
      choix = { PTGE_S: "f_el_ptgeS" }
      break
    case "f_op":
      activite = ["PELV2"]
      title = "Mensurations de pelvimétrie"
      choix = { PELV2: "f_op" }
      break
    case "f_pm":
      activite = ["DG"]
      title = "Pointages mamelles"
      choix = { DG: "f_pm" }
      break
    case "tri_hbl":
      activite = ["TRI"]
      title = "Tri morphologique HBL"
      choix = { TRI: "tri_hbl" }
      break
    default:
      break
  }

  activite.forEach(act => {
    if (visites[act] && pdf !== "ptgeA_ind") {
      visites[act].forEach(idVisite => {
        let indexVisite = indexVisites[idVisite]

        let date = pocketData[indexVisite]?.dateVisite
        listeDates.add(date.substr(0, 10))

        let year = date.substring(0, 4)
        let month = date.substring(5, 7)
        let campagne = ""
        let early = month > 7 ? true : false

        if (early) campagne = `${year}/${+year + 1}`
        else campagne = `${+year - 1}/${year}`

        listePDFs.push({
          archive: false,
          idVisite: idVisite,
          indexVisite: indexVisite,
          dateVisite: date,
          campagne: campagne,
          activite: act,
        })
      })
    }
  })

  // Lets remove double (if PDF edite in access after a pocketOS visit)
  let listeIndexToDelete = []

  listePDFs.forEach((line, index) => {
    if (line.archive && listeDates.has(line.dateVisite)) {
      listeIndexToDelete.push(index)
    }
  })

  for (let i = listeIndexToDelete.length - 1; i >= 0; i--) {
    listePDFs.splice(listeIndexToDelete[i], 1)
  }

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const goDlPdf = ligne => {
    if (!ligne.archive) {
      let nomPDF = choix[ligne.activite]
      if (["moy_pere_ptgeA", "moy_pere_ptgeS"].includes(nomPDF)) {
        setDataPeres({
          codeActivite: ligne.activite,
          dateVisite: ligne.dateVisite,
          campagne: ligne.campagne,
          idVisite: ligne.idVisite,
        })
      } else {
        let date = ligne.dateVisite.substr(0, 10)

        let cSplit = ligne.campagne.split("/")

        let ts = Math.round(Date.now() / 1000)
        let name = `${nomPDF}_${numCheptel}_${date}_${ts}.pdf`
        let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${nomPDF}/${ligne.idVisite}/${name}.pdf`

        getPdf(link, name, () => goBack())
      }
    } else {
      let name = ligne.file.replaceAll(".pdf", "")
      getOldPdf(
        `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/pointages/recap/${ligne.file}`,
        `${name}_${ts}.pdf`
      )
    }
  }
  // Get PDF generated by PHP (the ones in PocketOS)
  const getPdf = async (link, name, callback) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let isOnline = await checkInternet()

    if (isOnline) {
      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      )
    }

    // typeof callback === "function" && callback()
  }
  // Get PDF saved on server (the old ones done with Access)
  const getOldPdf = async (link, name) => {
    console.log(link)
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      // if (window.location.hostname === "localhost")
      //   link = "http://localhost:2765/Fiches_tarifs_HBL_2022-07_V5.pdf"

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let scrolledElementId = "wrap_page_cheptel"
                let div = document.getElementById(scrolledElementId)

                if (div) {
                  localStorage.scrollXPageNative = div.scrollLeft
                  localStorage.scrollYPageNative = div.scrollTop
                  localStorage.scrolledElementIdNative = scrolledElementId
                }

                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              a.download = name
              // a.target = "_blank"
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  // Sort listePDFs by date
  listePDFs.sort((b, a) =>
    a.dateVisite > b.dateVisite ? 1 : a.dateVisite < b.dateVisite ? -1 : 0
  )

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {dataPeres ? (
        <>
          <ModalListePeres
            codeActivite={dataPeres?.codeActivite}
            dateVisite={dataPeres?.dateVisite}
            campagne={dataPeres?.campagne}
            idVisite={dataPeres?.idVisite}
          />
        </>
      ) : (
        <>
          <div className="modal_liste_pdfs" ref={divModal}>
            <div className="div_modal_header">
              <span
                onClick={goBack}
                className="close_modal ripple2 pointer material-symbols-outlined"
              >
                close
              </span>
              <h3>{title}</h3>
            </div>
            <div className="div_pdfs">
              {listePDFs.map((ligne, index) => (
                <div
                  key={index}
                  className="div_ligne pointer ripple2"
                  onClick={() => goDlPdf(ligne)}
                >
                  <span className="span_pdf"></span>
                  <p className="p_pdf">
                    Edition du {formatDate2(ligne.dateVisite.substr(0, 10))}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <button className={`background-modal`} onClick={goBack}></button>
        </>
      )}
    </>
  )
}
