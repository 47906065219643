import { useEffect, useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./IndexStation.scss"

const IndexStation = ({ data }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [showIndexes, setShowIndexes] = useState(
    localStorage.showIndexesFS ?? "true" === "true"
  )
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    localStorage.showIndexesFS = showIndexes
  }, [showIndexes])
  ///////////////
  // Functions //
  ///////////////

  return (
    <div className="index_station" id="wrapper_index_station">
      <CheptelSectionTitle
        title={"Index station"}
        show={showIndexes}
        onClick={() => setShowIndexes(!showIndexes)}
        id="index_station_title"
      />
      {showIndexes && (
        <>
          {data?.idcrei ||
          data?.iddmei ||
          data?.iddsei ||
          data?.imocei ||
          data?.idopei ||
          data?.idafei ||
          data?.idqrei ? (
            <div className="div_table" id="index_station">
              <table>
                <thead>
                  <tr>
                    <th>CR</th>
                    <th>DM</th>
                    <th>DS</th>
                    <th>IMOCR</th>
                    <th>OP</th>
                    <th>AF</th>
                    <th>QR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.idcrei}</td>
                    <td>{data?.iddmei}</td>
                    <td>{data?.iddsei}</td>
                    <td>{data?.imocei}</td>
                    <td>{data?.idopei}</td>
                    <td>{data?.idafei}</td>
                    <td>{data?.idqrei}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert">Pas d'index connus</div>
          )}
        </>
      )}
    </div>
  )
}

export default IndexStation
