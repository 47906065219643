import "./PointageSevrage.scss"
import FillingBar from "../../FillingBar"
import { useState } from "react"

let width = {
  deepau: 2,
  lados: 2,
  arculo: 2,
  laculo: 2,
  loculo: 2,
  epdess: 2,
  lodess: 2,
  lobass: 2,
  lahanc: 2,
  develo: 2,
  lamufl: 2,
  apavan: 2,
  aparri: 2,
  redess: 2,
  prpoit: 2,
  lapoit: 2,
  latroc: 2,
}

let margin = {
  deepau: 49,
  lados: 49,
  arculo: 49,
  laculo: 49,
  loculo: 49,
  epdess: 49,
  lodess: 49,
  lobass: 49,
  lahanc: 49,
  develo: 49,
  lamufl: 49,
  apavan: 49,
  aparri: 49,
  redess: 49,
  prpoit: 49,
  lapoit: 49,
  latroc: 49,
}

let color = {
  deepau: "",
  lados: "",
  arculo: "",
  laculo: "",
  loculo: "",
  epdess: "",
  lodess: "",
  lobass: "",
  lahanc: "",
  develo: "",
  lamufl: "",
  apavan: "",
  aparri: "",
  redess: "",
  prpoit: "",
  lapoit: "",
  latroc: "",
}

const PointageSevrage = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow =
    window.localStorage.showpointagesevrage === "false" ? false : true
  const [show, setShow] = useState(savedShow)
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showpointagesevrage = !show
  }
  const calculateWidthMargin = (key, value) => {
    if (value === "") {
      width[key] = 0
      margin[key] = 0
    } else if (value > 5) {
      margin[key] = 50
      let tmpValue = value - 5
      tmpValue = (tmpValue * 100) / 5
      tmpValue = (tmpValue * 50) / 100
      width[key] = tmpValue
    } else if (value < 5) {
      let tmpValue = 5 - value
      tmpValue = (tmpValue * 100) / 5
      tmpValue = (tmpValue * 50) / 100
      width[key] = tmpValue
      margin[key] = 50 - tmpValue
    }

    // Color
    if (value >= 7) color[key] = "green"
    else if (value < 7 && value > 3) color[key] = "blue"
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  calculateWidthMargin("deepau", animal.bopoalDeepau)
  calculateWidthMargin("lados", animal.bopoalLados)
  calculateWidthMargin("arculo", animal.bopoalArculo)
  calculateWidthMargin("laculo", animal.bopoalLaculo)
  calculateWidthMargin("loculo", animal.bopoalLoculo)
  calculateWidthMargin("epdess", animal.bopoalEpdess)
  calculateWidthMargin("lodess", animal.bopoalLodess)
  calculateWidthMargin("lobass", animal.bopoalLobass)
  calculateWidthMargin("lahanc", animal.bopoalLahanc)
  calculateWidthMargin("develo", animal.bopoalDevelo)
  calculateWidthMargin("lamufl", animal.bopoalLamufl)
  calculateWidthMargin("apavan", animal.bopoalApavan)
  calculateWidthMargin("aparri", animal.bopoalAparri)
  calculateWidthMargin("redess", animal.bopoalRedess)
  calculateWidthMargin("prpoit", animal.bopoalPrpoit)
  calculateWidthMargin("lapoit", animal.bopoalLapoit)
  calculateWidthMargin("latroc", animal.bopoalLatroc)

  return (
    <div className="pointagesevrage" id="div_pointagesevrage">
      <h3 onClick={hideOrShow} className="ripple2">
        Pointage sevrage{" "}
        <span className="showorhideicon">{show ? "-" : "+"}</span>
      </h3>
      {show &&
        (animal.bopoalNoetpo !== "" ||
        animal.bopoalDevmus !== "" ||
        animal.bopoalDevsqe !== "" ||
        animal.bopoalAptfon !== "" ? (
          <div id="pointagesevrage">
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Note d'état attribuée lors du pointage</th>
                  <th className="index-header">{animal.bopoalNoetpo}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Dessus d'épaule</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["deepau"]}%`,
                        margin: `${margin["deepau"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["deepau"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalDeepau}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur du dos</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lados"]}%`,
                        margin: `${margin["lados"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lados"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLados}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Arrondi de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["arculo"]}%`,
                        margin: `${margin["arculo"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["arculo"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalArculo}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["laculo"]}%`,
                        margin: `${margin["laculo"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["laculo"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLaculo}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur de culotte</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["loculo"]}%`,
                        margin: `${margin["loculo"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["loculo"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLoculo}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Epaisseur du dessus</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["epdess"]}%`,
                        margin: `${margin["epdess"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["epdess"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalEpdess}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Développement musculaire</th>
                  <th className="index-header">{animal.bopoalDevmus}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur du dessus</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lodess"]}%`,
                        margin: `${margin["lodess"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lodess"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLodess}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Longueur du bassin</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lobass"]}%`,
                        margin: `${margin["lobass"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lobass"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLobass}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur aux hanches</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lahanc"]}%`,
                        margin: `${margin["lahanc"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lahanc"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLahanc}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Développement</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["develo"]}%`,
                        margin: `${margin["develo"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["develo"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalDevelo}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Développement squelettique</th>
                  <th className="index-header">{animal.bopoalDevsqe}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur du mufle</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lamufl"]}%`,
                        margin: `${margin["lamufl"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lamufl"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLamufl}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs avant</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["apavan"]}%`,
                        margin: `${margin["apavan"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["apavan"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalApavan}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Aplombs arrière</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["aparri"]}%`,
                        margin: `${margin["aparri"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["aparri"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalAparri}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Rectitude du dessus</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["redess"]}%`,
                        margin: `${margin["redess"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["redess"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalRedess}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Aptitude fonctionnelle</th>
                  <th className="index-header">{animal.bopoalAptfon}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="first_td_p_width">Profondeur de poitrine</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["prpoit"]}%`,
                        margin: `${margin["prpoit"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["prpoit"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalPrpoit}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur de poitrine</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["lapoit"]}%`,
                        margin: `${margin["lapoit"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["lapoit"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLapoit}</td>
                </tr>
                <tr>
                  <td>
                    <p className="first_td_p_width">Largeur aux trochanters</p>
                  </td>
                  <td className="full_width">
                    {" "}
                    <FillingBar
                      styleValues={{
                        width: `${width["latroc"]}%`,
                        margin: `${margin["latroc"]}%`,
                        point1: "calc(25% - 5px)",
                        point2: "calc(50% - 2px)",
                        point3: "calc(75% - 5px)",
                        point4: "calc(100% - 12px)",
                        txtPoint0: "0",
                        txtPoint1: "2.5",
                        txtPoint2: "5",
                        txtPoint3: "7.5",
                        txtPoint4: "10",
                        colorFiller: color["latroc"],
                      }}
                    />
                  </td>
                  <td>{animal.bopoalLatroc}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Grosseur des canons</th>
                  <th className="index-header">{animal.bopoalGrcano}</th>
                </tr>
              </thead>
            </table>
          </div>
        ) : (
          <div className="alert">Pas de pointages connus</div>
        ))}
    </div>
  )
}

export default PointageSevrage
