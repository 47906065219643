import { useEffect, useRef } from "react"
import BigGreenButton from "../components/BigGreenButton"
import "./FailDl.scss"

const FailDl = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const reloadPage = () => (window.location.hash = "loadata")

  const divWrap = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divWrap.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("margin-top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.marginTop = newHeight + "px"
    }
  }, [])

  ///////////////
  // Functions //
  ///////////////

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="restart_dl" ref={divWrap}>
      <BigGreenButton onClick={reloadPage} text="Relancer le téléchargement" />
    </div>
  )
}

export default FailDl
