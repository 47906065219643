import "./MentionsLegales.scss"

const MentionsLegales = () => {
  return (
    <div className="mentions-legales" id="page">
      <h3>Editeur du site, conception et réalisation du site internet :</h3>
      <p>
        <span className="span-arial">
          France Limousin Sélection
          <br />
          Pôle de Lanaud
          <br />
          87220 BOISSEUIL
          <br />
          Tél. : 00 33 (0)5 55 06 46 27
          <br />
          Fax : 00 33 (0)5 55 06 46 30
          <br />
          Directeur de la publication : Bernard Roux
        </span>
      </p>
      <h2>MENTIONS LEGALES</h2>
      <h3>Utilisation </h3>
      <p>
        <span className="span-arial">
          L'utilisateur du site internet{" "}
          <a href="http://www.limousine.org/">www.limousine.org</a> reconnaît
          disposer de la compétence et des moyens nécessaires pour accéder et
          utiliser ce site internet. Les utilisateurs du site web sont tenus de
          respecter les dispositions de la loi relative à l'informatique, aux
          fichiers et aux libertés, dont la violation est passible de sanctions
          pénales. Ils doivent notamment s'abstenir, s'agissant d'informations
          nominatives auxquelles ils accèdent, de toute collecte, de toute
          utilisation détournée et d'une manière générale, de tout acte
          susceptible de porter atteinte à la vie privée ou à la réputation des
          personnes.
        </span>
      </p>
      <h3>Contenu du site</h3>
      <p>
        <span className="span-arial">
          France Limousin Sélection met à disposition des utilisateurs du site
          web <a href="http://www.limousine.org/">www.limousine.org</a> des
          informations et outils disponibles et vérifiés, mais elle ne saurait
          être tenue pour responsable des erreurs ou de l'indisponibilité de
          certaines informations. Nous remercions les utilisateurs du site de
          nous faire part d'éventuelles omissions, erreurs ou corrections, en
          adressant un courriel au webmestre.
        </span>
      </p>
      <h3>Propriété </h3>
      <p>
        <span className="span-arial">
          La structure générale, ainsi que les textes, images animées ou fixes,
          savoir-faire, dessins, graphismes et tous autres éléments composant le
          site web <a href="http://www.limousine.org/">www.limousine.org</a>{" "}
          &nbsp;sont de l'utilisation exclusive de France Limousin
          Sélection&nbsp;. Toute représentation totale ou partielle de ce site,
          par quelques procédés que ce soient, sans autorisation expresse de
          France Limousin Sélection, est interdite et constituerait une
          contrefaçon sanctionnée par les articles L335-2 et suivants du Code de
          la propriété intellectuelle. Les liens hypertextes mis en place dans
          le cadre du présent site web en direction d'autres ressources
          présentes sur le réseau internet, ne sauraient engager la
          responsabilité de France Limousin Sélection. Les utilisateurs et
          visiteurs du site web ne peuvent mettre en place un hyperlien en
          direction de ce site sans l'autorisation expresse et préalable de
          France Limousin Sélection&nbsp;.&nbsp;
        </span>
      </p>
      <h3>Loi informatique et liberté</h3>
      <p>
        <span className="span-arial">
          L'usage de ce site web est régi par la loi française à l'exception de
          toute autre législation.
        </span>
      </p>
    </div>
  )
}

export default MentionsLegales
