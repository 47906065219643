import CheptelSectionTitle from "../CheptelSectionTitle"
import "./Iboval.scss"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { useState } from "react"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const Iboval = ({ data, screenWide }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showevolution === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 150,
      },
    },
  }
  const labels = [
    "IFNAIS",
    "CRsev",
    "DMsev",
    "DSsev",
    "FOSsev",
    "ISEVR",
    "AVEL",
    "ALait",
    "IVMAT",
  ]
  const chartData = {
    labels,
    datasets: [
      {
        label: `Mâles (${data.nbMaleIndex})`,
        data: [
          data.moyenneIfnaisM,
          data.moyenneCrsevsM,
          data.moyenneDmsevsM,
          data.moyenneDssevsM,
          data.moyenneFosevsM,
          data.moyenneIsevreM,
          data.moyenneAvelagM,
          data.moyenneAlaitsM,
          data.moyenneIvmateM,
        ],
        backgroundColor: "#00b4e3",
        borderColor: "#00b4e3",
        borderWidth: 1,
      },
      {
        label: `Vaches (${data.nbVachesIndex})`,
        data: [
          data.moyenneIfnaisV,
          data.moyenneCrsevsV,
          data.moyenneDmsevsV,
          data.moyenneDssevsV,
          data.moyenneFosevsV,
          data.moyenneIsevreV,
          data.moyenneAvelagV,
          data.moyenneAlaitsV,
          data.moyenneIvmateV,
        ],
        backgroundColor: "#ae66ca",
        borderColor: "#ae66ca",
        borderWidth: 1,
      },
      {
        label: `Génisses (${data.nbGenissesIndex})`,
        data: [
          data.moyenneIfnaisG,
          data.moyenneCrsevsG,
          data.moyenneDmsevsG,
          data.moyenneDssevsG,
          data.moyenneFosevsG,
          data.moyenneIsevreG,
          data.moyenneAvelagG,
          data.moyenneAlaitsG,
          data.moyenneIvmateG,
        ],
        backgroundColor: "#ffabf2",
        borderColor: "#ffabf2",
        borderWidth: 1,
      },
    ],
  }
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showiboval = !show
  }
  return (
    <div>
      <CheptelSectionTitle
        title="IBOVAL"
        onClick={screenWide ? undefined : hideOrShow}
        show={screenWide ? undefined : show}
        id="title_iboval"
        cheptelPage={true}
      />
      {(screenWide || show) && (
        <div className="iboval">
          <Bar options={options} data={chartData} />
        </div>
      )}
    </div>
  )
}

export default Iboval
