import { useEffect, useRef, useState } from "react"
import AnalyseGenetique from "../components/animal/AnalyseGenetique"
import AnimalHeader from "../components/animal/AnimalHeader"
import Genealogie from "../components/animal/Genealogie"
import Performances from "../components/animal/Performances"
import Reproduction from "../components/animal/Reproduction"
import "./Animal.scss"
import ModalAskConfirm from "../components/inventaire/modals/ModalAskConfirm"
import ModalLoader from "../components/ModalLoader"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalResultConfirm from "../components/inventaire/modals/ModalResultConfirm"
import { fetchGoConfirm } from "../functions/fetches/fetchGoConfirm"
import ModalAskDeclaration from "../components/inventaire/modals/ModalAskDeclaration"
import { declareLanaud } from "../functions/fetches/declareLanaud"
import localforage from "localforage"
import ModalResultDeclar from "../components/inventaire/modals/ModalResultDeclar"
import html2canvas from "html2canvas"
import { fetchAnimal } from "../functions/fetches/fetchAnimal"
import Loader from "../components/Loader"
import { checkIfConnected } from "../functions/checkIfConnecetd"
import { checkInternet } from "../functions/checkInternet"
import { blobToBase64 } from "../functions/fetches/fetch"
import { goBack, goBackXAmount } from "../functions/handleNavigation"
import { fetchSaveAnimalPitcure } from "../functions/fetches/fetchSaveAnimalPitcure"
import FicheAniShare from "../components/animal/FicheAniShare"
import ModalShareFiche from "../components/animal/ModalShareFiche"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import Confirm from "../components/Confirm"
import { fetchDeletePhoto } from "../functions/fetches/fetchDeletePhoto"
import FicheSimpleShare from "../components/animal/FicheSimpleShare"
import FicheGenealogieShare from "../components/animal/FicheGenealogieShare"
import FicheIndexShare from "../components/animal/FicheIndexShare"
import { statutBarColor, statutBarN1Color } from "../functions/statutBarColor"
import { changeHashOption } from "../functions/hash"
import { extractDataAnimal } from "../functions/extractAnimalData"
import { fetchLogActionFiche } from "../functions/fetches/fetchLogActionFiche"
import { getRFQUAL } from "../functions/objectColums"
import { todayDate } from "../functions/todayDate"
import { fetchParcours } from "../functions/fetches/fetchParcours"
import ModalParcours from "../components/animal/ModalParcours"
import { formatDate3 } from "../functions/formatDate"

let isScrolling // Setup isScrolling variable
let downloadInProgress = false
let resultConfirmations
let lastIdentConfirm
let libelleSerie = ""
let aniToDeclare = []
let messageDeclaration = {}
let origin = ""

const Animal = ({ dataCalculated, aniData, pageOption }) => {
  console.log(aniData)
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  let valA = window.localStorage.valA === "true" ? true : false

  const div = useRef()
  const inputPicture = useRef()
  let scrollObject = JSON.parse(
    window.localStorage.scrollObjectFicheAni ?? "{}"
  )

  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  // Handle display dots menu
  const [clDots, setClDots] = useState("small")
  pageOption === "dots" && clDots !== "" && setClDots("")
  pageOption !== "dots" && clDots !== "small" && setClDots("small")

  const [modalAskConfirm, setModalAskConfirm] = useState(false)
  const [modalResultConfirm, setModalResultConfirm] = useState(false)
  pageOption === "confirm" &&
    !modalAskConfirm &&
    !modalResultConfirm &&
    setModalAskConfirm(true)
  pageOption !== "confirm" && modalAskConfirm && setModalAskConfirm(false)
  pageOption !== "confirm" && modalResultConfirm && setModalResultConfirm(false)
  const [modalAskDeclar, setModalAskDeclar] = useState(false)
  const [modalResultDeclar, setModalResultDeclar] = useState(false)
  pageOption === "declar" &&
    !modalAskDeclar &&
    !modalResultDeclar &&
    setModalAskDeclar(true)
  pageOption !== "declar" && modalAskDeclar && setModalAskDeclar(false)
  pageOption !== "declar" && modalResultDeclar && setModalResultDeclar(false)

  // const [clChoicePicture, setClChoicePicture] = useState(false)
  // pageOption === "choicepicture" && !clChoicePicture && setClChoicePicture(true)
  // pageOption !== "choicepicture" && clChoicePicture && setClChoicePicture(false)
  // const [clCamera, setClCamera] = useState(false)
  // pageOption === "camera" && !clCamera && setClCamera(true)
  // pageOption !== "camera" && clCamera && setClCamera(false)

  let dataSuffix = window.localStorage.dataSuffix ?? ""

  let optionsDeclCochees = JSON.parse(
    window.localStorage.optionsDeclCochees ?? "{}"
  )

  let hash = window.location.hash
  let hVals = hash.split("|")
  let nAni = hVals[1]
  if (localStorage.failedAniDl && localStorage.failedAniDl !== nAni)
    localStorage.failedAniDl = ""

  let listeHistoAni = JSON.parse(window.localStorage.listeHistoAni ?? "[]")

  if (!listeHistoAni.includes(nAni)) {
    listeHistoAni.push(nAni)
    window.localStorage.listeHistoAni = JSON.stringify(listeHistoAni)
  }

  let haveConfirm = false
  let haveCertificat = false
  let haveDeclaration = false
  let haveParcours = valA
  const havePicture = useRef(false)

  if (aniData?.reponse === "ok") haveConfirm = true
  if (
    aniData?.inscrAnimal &&
    aniData?.A_boqual_COQUBO !== "04" &&
    aniData?.A_boqual_COQUBO !== "ST"
  )
    haveCertificat = true
  if (aniData?.canBeDeclared && !aniData.dejaDeclare) haveDeclaration = true

  const refCompleteFiche = useRef()
  const refSimpleFiche = useRef()
  const refGenealogieShare = useRef()
  const refIndexShare = useRef()
  const blurredPhoto = useRef()
  const blurredCanvas = useRef()
  const b64Blur = useRef()
  const graphISU = useRef()
  const [contentShare, setContentShare] = useState(false)
  const [blobCompleteFiche, setBlobCompleteFiche] = useState(null)
  const [blobSimpleFiche, setBlobSimpleFiche] = useState(null)
  const [blobGenealogieFiche, setBlobGenealogieFiche] = useState(null)
  const [blobIndexFiche, setBlobIndexFiche] = useState(null)

  const [clSharing, setClSharing] = useState(false)
  pageOption === "sharing" && !clSharing && setClSharing(true)
  pageOption !== "sharing" && clSharing && setClSharing(false)
  pageOption !== "sharing" && (window.localStorage.indexCarrousel = 0)
  pageOption === "sharing" &&
    !contentShare &&
    !blobCompleteFiche &&
    !blobSimpleFiche &&
    !blobGenealogieFiche &&
    !blobIndexFiche &&
    setContentShare(true)

  const [parcours, setParcours] = useState(null)
  const [clParcours, setClParcours] = useState(false)
  pageOption === "parcours" && !clParcours && setClParcours(true)
  pageOption !== "parcours" && clParcours && setClParcours(false)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let page = document.getElementById("page")
    let curr = div.current

    const shareFiche = async () => {
      if (aniData.isDetenteur !== "oui") {
        doAlert("L'animal doit être à votre inventaire pour être partagé.")

        return
      }

      let isOnline = await checkInternet()

      if (isOnline) {
        fetchLogActionFiche("fiche_animal", "generation", nAni)

        setContentShare(true)
      } else {
        doAlert(
          "Vous devez être connecté à internet pour partager votre fiche. Veuillez vérifier votre connexion internet et réessayer s.v.p."
        )
      }
    }

    curr.addEventListener("shareFiche", shareFiche)
    curr.addEventListener("ficheAniCamera", ficheAniCamera)
    page.addEventListener("scroll", checkScroll)

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = addHeight + 60

      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }

    return () => {
      curr.removeEventListener("shareFiche", shareFiche)
      curr.removeEventListener("ficheAniCamera", ficheAniCamera)
      page.removeEventListener("scroll", checkScroll)
    }
  }, [])
  useEffect(() => {
    let page = document.getElementById("page")

    if (page) {
      page.scrollLeft = scrollObject?.[nAni]?.x ?? 0
      page.scrollTop = scrollObject?.[nAni]?.y ?? 0
    }

    document.getElementById("header")?.dispatchEvent(
      new CustomEvent("setFlagDetenteur", {
        detail: {
          isDetenteur: aniData ? aniData?.isDetenteur : "non",
        },
      })
    )
  }, [aniData])
  useEffect(() => {
    if (contentShare) {
      generateImageFiche()
    }
  }, [contentShare])
  useEffect(() => {
    if (clDots === "") {
      if (
        !haveConfirm &&
        !haveCertificat &&
        !haveDeclaration &&
        !havePicture.current
      ) {
        doAlert("Aucune option disponible pour cet animal.")
        setCallback(() => goBack())
      }
    }

    let div = document.getElementById("modal_dots_inv")

    if (div) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0

        div.style.top = addHeight + "px"
      }
    }
  }, [clDots])
  ///////////////
  // Functions //
  ///////////////
  const goConfirm = async () => {
    let divModal = document.getElementById("loader_do_confirm")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let changeDone = false

    aniData["numFormatSpace"] = aniData.COPAIP + " " + aniData.NUNATI

    let listeResult = []

    let ii = 1
    let ed = true
    let ar = JSON.stringify(aniData)
    let n = aniData.numFormatSpace
    let nNoSpace = n.replace(" ", "")
    let d = aniData.DANAIS
    let e = ""

    let caseConfirm = JSON.parse(localStorage.caseConfirm ?? "[]")
    let listCouldntTry = JSON.parse(window.localStorage.listCouldntTry ?? "[]")

    let result = await fetchGoConfirm(ii, ed, ar, n, d, e)
    // let result = JSON.parse(
    //   `{"reponse":"ok","ident_demande":121596,"rejet":0,"liste_rejet":[{"type_rejet":null,"num_rejet":null,"lib_rejet":null}],"explication":"STANDARD : O-------------ResExec : OK-------------","erreurs":""}`
    // )

    if (result?.rejet !== undefined) {
      changeDone = true
      let identDemande = result.ident_demande
      let rejected = result.rejet !== 0 ? true : false
      let rejets = null

      if (rejected) {
        rejets = []

        for (ii in result.liste_rejet) {
          rejets.push(result.liste_rejet[ii].lib_rejet)
        }
      } else {
        let numCheptel = window.localStorage.numCheptel ?? ""

        let dataObject = await localforage.getItem(
          "dataCalculated" + dataSuffix + numCheptel
        )
        let animalsObj = dataObject?.inventaire?.Custom
        let aniNotInInvObj = dataObject?.inventaire?.aniNotInInv
        let rfqual = getRFQUAL()

        const updateAniWithConfirm = ani => {
          let sexbov = ani.SEXBOV
          let etqubo = ani.borexa_etqubo
          let coqubo = ani.borexa_coqubo
          let ccqubo = ani.borexa_ccqubo

          ani.reponse = "non"
          ani.erreurs = [[1, "L'animal est déjà inscrit", 0]]

          ani.A_boqual_ETQUBO = etqubo
          ani.A_boqual_COQUBO = coqubo
          ani.A_boqual_CCQUBO = ccqubo
          ani.A_boqual_DAQUBO = todayDate()
          ani.inscrAnimal = rfqual[sexbov][etqubo][coqubo][ccqubo].LIBECO
          ani.inscrAnimalLIDETA = rfqual[sexbov][etqubo][coqubo][ccqubo].LIDETA
          ani.inscrAnimalLIBELO = rfqual[sexbov][etqubo][coqubo][ccqubo].LIBELO

          ani.examAni = ""
          ani.examAniLIBELO = ""
          ani.examAniLIDETA = ""
          ani.borexa_c_CORADI = ""
          ani.borexa_c_COREEX = ""
          ani.borexa_c_ETQUBO = ""
          ani.borexa_ccqubo = ""
          ani.borexa_copace = ""
          ani.borexa_copoin = ""
          ani.borexa_coqubo = ""
          ani.borexa_coreex = ""
          ani.borexa_corefu = ""
          ani.borexa_daexbo = ""
          ani.borexa_etqubo = ""
          ani.borexa_nuchex = ""
        }
        const updateAniWithParentConfirm = (ani, parent) => {
          let sexbov = parent === "mere" ? "2" : "1"
          let etqubo = "1"
          let coqubo =
            parent === "mere" ? ani.coqubo_exa_mere : ani.coqubo_exa_pere
          let ccqubo =
            parent === "mere" ? ani.ccqubo_exa_mere : ani.ccqubo_exa_pere
          let inscr = rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBECO
          let inscrLIDETA =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIDETA
          let inscrLIBELO =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBELO

          if (parent === "mere") {
            ani.inscrMere = inscr
            ani.inscrMereLIDETA = inscrLIDETA
            ani.inscrMereLIBELO = inscrLIBELO

            ani.coqubo_exa_mere = ""
            ani.ccqubo_exa_mere = ""
            ani.etqubo_exa_mere = ""
          } else {
            ani.inscrPere = inscr
            ani.inscrPereLIDETA = inscrLIDETA
            ani.inscrPereLIBELO = inscrLIBELO

            ani.coqubo_exa_pere = ""
            ani.ccqubo_exa_pere = ""
            ani.etqubo_exa_pere = ""
          }

          ani?.erreurs?.forEach((erreur, index) => {
            if (parent === "mere" && erreur[0] === 5) {
              ani.erreurs.splice(index, 1)
            }
            if (parent === "pere" && erreur[0] === 4) {
              ani.erreurs.splice(index, 1)
            }
          })

          ani.reponse = ani.erreurs.length > 0 ? "non" : "ok"
        }

        animalsObj &&
          animalsObj.forEach(ani => {
            let nAni = ani.COPAIP + ani.NUNATI
            let nMere = ani?.numMere ?? null
            let nPere = ani?.numPere ?? null

            if (nNoSpace === nAni) updateAniWithConfirm(ani)

            if (nMere && nNoSpace === nMere)
              updateAniWithParentConfirm(ani, "mere")

            if (nPere && nNoSpace === nPere)
              updateAniWithParentConfirm(ani, "pere")
          })

        aniNotInInvObj &&
          Object.keys(aniNotInInvObj).forEach(nAni => {
            let ani = aniNotInInvObj[nAni]
            let nMere = ani?.numMere ?? null
            let nPere = ani?.numPere ?? null

            if (nNoSpace === nAni) updateAniWithConfirm(ani)

            if (nMere && nNoSpace === nMere)
              updateAniWithParentConfirm(ani, "mere")

            if (nPere && nNoSpace === nPere)
              updateAniWithParentConfirm(ani, "pere")
          })

        await localforage.setItem(
          "dataCalculated" + dataSuffix + numCheptel,
          dataObject
        )

        document.getElementById("app")?.dispatchEvent(
          new CustomEvent("setNewData", {
            detail: {
              dataCalculated: dataObject,
            },
          })
        )
      }

      lastIdentConfirm = identDemande

      let formatedResult = {
        numani: nNoSpace,
        rejected: rejected,
        identDemande: identDemande,
        rejets: rejets,
      }

      listeResult.push(formatedResult)

      resultConfirmations = listeResult
      caseConfirm = caseConfirm.filter(ani => ani !== nNoSpace)
      localStorage.caseConfirm = JSON.stringify(caseConfirm)

      listCouldntTry = listCouldntTry.filter(ani => ani !== nNoSpace)
      window.localStorage.listCouldntTry = JSON.stringify(listCouldntTry)

      setModalAskConfirm(false)
      setModalResultConfirm(true)

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    } else {
      if (!caseConfirm.includes(nNoSpace)) caseConfirm.push(nNoSpace)

      localStorage.caseConfirm = JSON.stringify(caseConfirm)

      if (!listCouldntTry.includes(nNoSpace)) listCouldntTry.push(nNoSpace)
      window.localStorage.listCouldntTry = JSON.stringify(listCouldntTry)

      doAlert(
        "Les confirmations n'ont pas pu être réalisées. Veuillez réessayer plus tard s.v.p"
      )
      let originBtn = window.localStorage.btnConfirmFicheAniOrigin ?? ""
      setCallback(() => (originBtn === "menu" ? goBackXAmount(2) : goBack()))

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
  }
  const cocheOptionsDeclar = (e, numAnimal, option) => {
    if (e.target.className === "coche") {
      e.target.className = "coche on"

      if (option === "evalim") optionsDeclCochees[numAnimal].evalim = 1
      if (option === "techIa") optionsDeclCochees[numAnimal].techIa = 1
    } else {
      e.target.className = "coche"

      if (option === "evalim") optionsDeclCochees[numAnimal].evalim = 0
      if (option === "techIa") optionsDeclCochees[numAnimal].techIa = 0
    }

    window.localStorage.optionsDeclCochees = JSON.stringify(optionsDeclCochees)
  }
  const validateDeclarations = async () => {
    let divModal = document.getElementById("loader_do_declare")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let nAni = aniData.COPAIP + aniData.NUNATI
    let evalim = optionsDeclCochees[nAni].evalim
    let ssCornes = optionsDeclCochees[nAni].ssCornes
    let techIa = optionsDeclCochees[nAni].techIa

    let lineStringToSend = `${nAni};${evalim};${ssCornes};${techIa}|`

    let declareToLanaud = await declareLanaud(libelleSerie, lineStringToSend)

    if (declareToLanaud && declareToLanaud.message) {
      messageDeclaration = { __html: declareToLanaud.message }

      let numCheptel = window.localStorage.numCheptel ?? ""

      let dataObject = await localforage.getItem(
        "dataCalculated" + dataSuffix + numCheptel
      )
      let animalsObj = dataObject?.inventaire?.Custom

      animalsObj.forEach(ani => {
        if (nAni === ani.COPAIP + ani.NUNATI) ani.dejaDeclare = true
      })

      await localforage.setItem(
        "dataCalculated" + dataSuffix + numCheptel,
        dataObject
      )

      document.getElementById("app")?.dispatchEvent(
        new CustomEvent("setNewData", {
          detail: {
            dataCalculated: dataObject,
          },
        })
      )

      setModalAskDeclar(false)
      setModalResultDeclar(true)
    } else {
      let listCouldntTryDecl = JSON.parse(
        window.localStorage.listCouldntTryDecl ?? "[]"
      )
      listCouldntTryDecl.push(nAni)
      window.localStorage.listCouldntTryDecl =
        JSON.stringify(listCouldntTryDecl)

      let caseDeclar = JSON.parse(localStorage.caseDeclar)
      caseDeclar.push(nAni)
      localStorage.caseDeclar = JSON.stringify(caseDeclar)

      doAlert(
        "Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard s.v.p"
      )
      setCallback(() => goBackXAmount(2))

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const generateImageFiche = async () => {
    const generateImage = async ref => {
      let toPrint = ref.current

      if (!toPrint) {
        doAlert(
          "Une erreur est survenue lors de la génération de la fiche à partagée."
        )

        setContentShare(false)

        return false
      }

      let width = toPrint.scrollWidth
      let height = toPrint.scrollHeight

      const canvas = await html2canvas(toPrint, {
        windowWidth: width,
        windowHeight: height,
        scale: 1,
        useCORS: true,
      })

      const blob = await new Promise(resolve => canvas.toBlob(resolve))

      return blob
    }

    let divModal = document.getElementById("loader_share_ani")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    await Promise.all([
      generateImage(refCompleteFiche),
      generateImage(refSimpleFiche),
      generateImage(refGenealogieShare),
      generateImage(refIndexShare),
    ]).then(values => {
      let completeFiche = values[0]
      let simpleFiche = values[1]
      let genealogieFiche = values[2]
      let indexFiche = values[3]

      setBlobCompleteFiche(completeFiche)
      setBlobSimpleFiche(simpleFiche)
      setBlobGenealogieFiche(genealogieFiche)
      setBlobIndexFiche(indexFiche)

      setContentShare(false)
      changeHashOption("sharing")
    })

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  // Go do camera logic
  const ficheAniCamera = () => {
    if (aniData.isDetenteur !== "oui") {
      doAlert("L'animal doit être à votre inventaire pour changer sa photo.")

      return
    }

    inputPicture.current.click()
  }
  // Go search for the animal if not in data
  const checkAnimal = async (copaip, nunati) => {
    let numAnimal = copaip + nunati
    if (downloadInProgress) return
    else downloadInProgress = true

    let msgAlert =
      "Un problème est survenu lors de la récupération de la fiche animal."
    let success = true

    let isOnline = await checkInternet()

    if (isOnline) {
      let dataAnimal = await fetchAnimal(copaip, nunati)
      console.log(dataAnimal)

      if (
        !dataAnimal ||
        typeof dataAnimal !== "object" ||
        Object.keys(dataAnimal)?.length === 0
      ) {
        msgAlert =
          "Un problème est survenu lors de la recherche. Veuillez réessayer s.v.p"
        success = false
      } else if (dataAnimal.error) {
        if (dataAnimal.error === "no_ani") {
          msgAlert = "Numéro animal non trouvé."
          success = false
        }
      } else {
        if (
          !dataAnimal ||
          typeof dataAnimal !== "object" ||
          Object.keys(dataAnimal).length === 0
        ) {
          success = false
        } else {
          let dataSuffix = window.localStorage.dataSuffix ?? ""
          let numCheptel = window.localStorage.numCheptel ?? ""

          downloadInProgress = false

          let dataExtracted = extractDataAnimal(dataAnimal.animal)

          let ts = Date.now()

          dataExtracted.tsFetch = ts

          if (numCheptel) {
            await localforage
              .getItem("dataCalculated" + dataSuffix + numCheptel)
              .then(async value => {
                if (value) {
                  if (!value.inventaire.aniNotInInv)
                    value.inventaire.aniNotInInv = {}

                  value.inventaire.aniNotInInv[numAnimal] = dataExtracted

                  await localforage.setItem(
                    "dataCalculated" + dataSuffix + numCheptel,
                    value
                  )

                  document.getElementById("app")?.dispatchEvent(
                    new CustomEvent("setNewData", {
                      detail: {
                        dataCalculated: value,
                      },
                    })
                  )
                }
              })
          } else {
            await localforage.getItem("dataCalculated").then(async value => {
              let nextSave = value ? value : {}

              if (!nextSave.inventaire) nextSave.inventaire = {}
              if (!nextSave.inventaire.aniNotInInv)
                nextSave.inventaire.aniNotInInv = {}

              nextSave.inventaire.aniNotInInv[numAnimal] = dataExtracted

              await localforage.setItem("dataCalculated", nextSave)

              document.getElementById("app")?.dispatchEvent(
                new CustomEvent("setNewData", {
                  detail: {
                    dataCalculated: nextSave,
                  },
                })
              )
            })
          }
        }
      }
    } else {
      msgAlert =
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      success = false
    }

    if (!success) {
      localStorage.failedAniDl = numAnimal
      downloadInProgress = false

      // Check hash to avoid renders to queue up a failing try and go back too many times
      if (window.location.hash.split("|")[0] === "#animal") {
        document.getElementById("app")?.dispatchEvent(
          new CustomEvent("doAlert", {
            detail: {
              message: msgAlert,
            },
          })
        )

        goBack()
      }
    }
  }
  const dlCertificat = async (doBack = true) => {
    let divModal = document.getElementById("loader_certificate")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      let numCheptel = window.localStorage.numCheptel ?? ""

      await checkIfConnected(numCheptel)

      let link = `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/certificat/${aniData.COPAIP}/${aniData.NUNATI}-F`

      if (doBack) goBack()

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            let ts = Math.round(Date.now() / 1000)
            let name = `certificat_${aniData.COPAIP}/${aniData.NUNATI}_${ts}.pdf`

            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")
            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        window.open(link)

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  const fnConfirm = e => {
    let valO = window.localStorage.valO === "true" ? true : false

    if (valO) {
      doAlert("Vous n'avez pas le droit de confirmer des animaux.")
      return
    }
    e.stopPropagation() // Needed to not proc the goBack from above
    // Coded this way for the effect when menu appears
    origin = "animal"
    window.localStorage.btnConfirmFicheAniOrigin = "menu"
    changeHashOption("confirm")
  }
  const fnCertificat = e => {
    e.stopPropagation() // Needed to not proc the goBack from above
    // Coded this way for the effect when menu appears
    dlCertificat()
  }
  const fnDeclaration = e => {
    e.stopPropagation() // Needed to not proc the goBack from above
    // Coded this way for the effect when menu appears
    origin = "animal"
    if (!aniData.dejaDeclare) changeHashOption("declar")
    else {
      doAlert("L'animal est déjà declaré.")
      setCallback(() => goBack())
    }
  }
  const checkScroll = () => {
    let page = document.getElementById("page")
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling)

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(() => {
      if (page) {
        scrollObject[nAni] = {
          x: page.scrollLeft,
          y: page.scrollTop,
        }

        window.localStorage.scrollObjectFicheAni = JSON.stringify(scrollObject)
      }
    }, 250)
  }
  const inputFileSelected = async () => {
    let divModal = document.getElementById("loader_sending_picture")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let fileBlob = inputPicture.current.files?.[0]
    // console.log(fileBlob)

    let ret = null

    if (fileBlob) {
      // console.log(URL.createObjectURL(fileBlob))
      // document.getElementById("photo_fiche_animal").src =
      //   URL.createObjectURL(fileBlob)
      ret = await fetchSaveAnimalPitcure(
        fileBlob,
        aniData.COPAIP + aniData.NUNATI
      )

      inputPicture.current.value = ""
    }

    if ((ret && ret.error) || !ret) {
      doAlert("Un erreur est survenue lors de l'envoi.")
    } else {
      window.location.reload()
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const deletePhoto = async confirmed => {
    hideConfirm()

    if (confirmed) {
      if (aniData.isDetenteur !== "oui") {
        doAlert(
          "L'animal doit être à votre inventaire pour supprimer sa photo."
        )

        return
      }

      let divModal = document.getElementById("loader_delete_photo")
      if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

      let didDelete = await fetchDeletePhoto(aniData.COPAIP + aniData.NUNATI)

      if (didDelete === true) {
        window.location.reload()
      } else {
        doAlert("Une erreur est survenue lors de la suppression.")
      }

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
    }
  }
  const onLoadBlurredPhoto = () => {
    let ctx = blurredCanvas.current.getContext("2d")

    blurredCanvas.current.width = blurredPhoto.current.width
    blurredCanvas.current.height = blurredPhoto.current.height

    // filter
    if (typeof ctx.filter !== "undefined") {
      ctx.filter = "blur(16px)"
      ctx.drawImage(blurredPhoto.current, 0, 0)
    } else {
      ctx.drawImage(blurredPhoto.current, 0, 0)
    }

    b64Blur.current = blurredCanvas.current.toDataURL("image/png")
  }
  const getParcours = async e => {
    let divModal = document.getElementById("loader_load_message")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    if (!parcours) {
      let parcours = await fetchParcours(aniData.COPAIP, aniData.NUNATI)
      setParcours(parcours)
    }

    changeHashOption("parcours")

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  if (pageOption === "declar") {
    libelleSerie = ""

    let listeSeries = JSON.parse(window.localStorage.seriesLanaud ?? "{}")
    let dateNaiss = new Date(formatDate3(aniData.DANAIS))
    let nAni = aniData.COPAIP + aniData.NUNATI

    console.log(listeSeries)
    console.log(dateNaiss)

    for (let numSerie in listeSeries) {
      let serie = listeSeries[numSerie]

      let dateDebut = new Date(serie.dadena)
      let dateFin = new Date(serie.dafina)
      console.log(dateDebut, dateFin)

      if (dateNaiss >= dateDebut && dateNaiss <= dateFin)
        libelleSerie = serie.libelo
    }

    aniToDeclare = [
      {
        numAnimal: nAni,
        nom: aniData.NOBOVI,
        datenaissance: aniData.DANAIS,
      },
    ]

    if (!optionsDeclCochees[nAni])
      optionsDeclCochees[nAni] = {
        evalim: 0,
        ssCornes: 0,
        techIa: 0,
      }
  }

  let infosAni = {}

  if (aniData) {
    let numInfosAni = aniData.COPAIP + aniData.NUNATI

    infosAni[numInfosAni] = {
      SEXBOV: aniData.SEXBOV,
      COFGMU: aniData.COFGMU,
      NOBOVI: aniData.NOBOVI,
    }
  }

  // Code to start DL of animal fiche we dont have
  if (
    !aniData &&
    !downloadInProgress &&
    nAni &&
    localStorage.failedAniDl !== nAni
  ) {
    checkAnimal(nAni?.substring(0, 2), nAni?.substring(2))
  }

  if (pageOption === "sharing") {
    statutBarColor(statutBarN1Color)
  }

  return (
    <div className="fiche-animal" id="fiche_animal" ref={div}>
      <div className="photo_ani_blur">
        <canvas ref={blurredCanvas}></canvas>
        <img
          crossOrigin="*"
          ref={blurredPhoto}
          onLoad={onLoadBlurredPhoto}
          src={`${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL}${
            aniData?.COPAIP + aniData?.NUNATI
          }_${aniData?.ts_photos}.jpg`}
        />
      </div>
      {contentShare && (
        <div className="side_to_print">
          <div ref={refCompleteFiche}>
            <FicheAniShare
              aniData={aniData}
              b64Blur={b64Blur.current}
              b64Graph={graphISU?.current?.toBase64Image()}
            />
          </div>
          <div ref={refSimpleFiche}>
            <FicheSimpleShare
              aniData={aniData}
              b64Blur={b64Blur.current}
              havePicture={havePicture}
            />
          </div>
          <div ref={refGenealogieShare}>
            <FicheGenealogieShare aniData={aniData} b64Blur={b64Blur.current} />
          </div>
          <div ref={refIndexShare}>
            <FicheIndexShare
              aniData={aniData}
              b64Blur={b64Blur.current}
              b64Graph={graphISU?.current?.toBase64Image()}
            />
          </div>
        </div>
      )}
      {clSharing &&
        (blobCompleteFiche ||
          blobSimpleFiche ||
          blobGenealogieFiche ||
          blobIndexFiche) && (
          <ModalShareFiche
            blobCompleteFiche={blobCompleteFiche}
            blobSimpleFiche={blobSimpleFiche}
            blobGenealogieFiche={blobGenealogieFiche}
            blobIndexFiche={blobIndexFiche}
            numani={aniData.COPAIP + aniData.NUNATI}
            havePicture={havePicture}
          />
        )}
      {clParcours && (
        <ModalParcours
          parcours={parcours}
          numAnimal={aniData.COPAIP + aniData.NUNATI}
          origin={origin}
        />
      )}

      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm message={messageConfirm} giveConfirm={deletePhoto} />
      )}

      <ModalLoader id="loader_load_message" message="Chargement en cours..." />
      <ModalLoader id="loader_share_ani" message="Génération en cours..." />
      <ModalLoader
        id="loader_certificate"
        message="Téléchargement en cours..."
      />
      <ModalLoader id="loader_sending_picture" message="Envoi en cours..." />
      <ModalLoader id="loader_delete_photo" message="Suppression en cours..." />
      <input
        ref={inputPicture}
        type="file"
        accept="image/*"
        className="input_file_picture"
        onChange={inputFileSelected}
      />
      {!aniData && (
        <div className="loader_dl_ani">
          <Loader loadingMessage="Chargement de l'animal en cours..." />
        </div>
      )}
      {aniData && (
        <>
          {(haveConfirm || haveCertificat || haveDeclaration) && (
            <div className="fast_actions">
              <div className="left_side_actions">
                <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                  emoji_objects
                </span>
                <div className="text_fast_action">Actions rapides</div>
              </div>
              <div className="actions_buttons">
                {haveConfirm && (
                  <button
                    className="btn_action_rapide ripple6 pointer"
                    onClick={() => {
                      let valO =
                        window.localStorage.valO === "true" ? true : false

                      if (valO) {
                        doAlert(
                          "Vous n'avez pas le droit de confirmer des animaux."
                        )
                        return
                      }

                      origin = ""
                      window.localStorage.btnConfirmFicheAniOrigin = "btn"
                      changeHashOption("confirm")
                    }}
                  >
                    Confirmer
                  </button>
                )}
                {haveCertificat && (
                  <button
                    className="btn_action_rapide ripple6 pointer"
                    onClick={() => dlCertificat(false)}
                  >
                    Certificat d'inscription
                  </button>
                )}
                {haveDeclaration && (
                  <button
                    className="btn_action_rapide ripple6 pointer"
                    onClick={() => {
                      origin = ""
                      changeHashOption("declar")
                    }}
                  >
                    Déclarer à Lanaud
                  </button>
                )}
              </div>
            </div>
          )}
          <AnimalHeader
            dataCalculated={dataCalculated}
            animal={aniData}
            havePicture={havePicture}
            pageOption={pageOption}
            getParcours={getParcours}
          />
          {([1, 2, 4, 5, 8, 9].includes(+aniData.CODEUT) ||
            aniData.retyupsc ||
            aniData.retyupmh ||
            aniData.RETYUP_HPF ||
            aniData.retyuppaf ||
            aniData.retyuppro) && <AnalyseGenetique animal={aniData} />}

          {(aniData.Pnunati !== "" || aniData.Mnunati !== "") && (
            <Genealogie animal={aniData} pageOption={pageOption} />
          )}

          {aniData.passage === "ok" || aniData.passage === "forc" ? (
            <>
              <Performances animal={aniData} graphISU={graphISU} />
              <Reproduction animal={aniData} />
            </>
          ) : (
            <div className="neverInInventory">
              Cet animal n'a jamais été à votre inventaire
            </div>
          )}

          {pageOption === "confirm" && (
            <>
              <ModalLoader
                id="loader_do_confirm"
                message="Confirmation(s) en cours..."
              />
              {modalAskConfirm && (
                <ModalAskConfirm
                  origin={origin}
                  casesCochees={[aniData.COPAIP + aniData.NUNATI]}
                  infosAni={infosAni}
                  callback={goConfirm}
                />
              )}
              {modalResultConfirm && (
                <ModalResultConfirm
                  origin={origin}
                  resultConfirmations={resultConfirmations}
                  lastIdent={lastIdentConfirm}
                  objDate={new Date()}
                />
              )}
            </>
          )}
          {pageOption === "declar" && (
            <>
              <ModalLoader
                id="loader_do_declare"
                message="Déclaration en cours..."
              />
              {modalAskDeclar && (
                <ModalAskDeclaration
                  libelleSerie={libelleSerie}
                  aniToDeclare={aniToDeclare}
                  optionsDeclCochees={optionsDeclCochees}
                  cocheOptionsDeclar={cocheOptionsDeclar}
                  validateDeclarations={validateDeclarations}
                  origin={origin}
                />
              )}
              {modalResultDeclar && (
                <ModalResultDeclar
                  libelleSerie={libelleSerie}
                  messageDeclaration={messageDeclaration}
                  origin={origin}
                />
              )}
            </>
          )}
        </>
      )}
      <div className={`modal_bg_dots_ani ${clDots}`} onClick={goBack}>
        <div id="modal_dots_inv" className={`modal_dots_inv`}>
          <ul>
            {haveConfirm && (
              <li className="ripple2 pointer" onClick={fnConfirm}>
                Confirmer
              </li>
            )}
            {haveCertificat && (
              <li className="ripple2 pointer" onClick={fnCertificat}>
                Certificat d'inscription
              </li>
            )}

            {haveDeclaration && (
              <li className="ripple2 pointer" onClick={fnDeclaration}>
                Déclarer à Lanaud
              </li>
            )}

            {aniData &&
              aniData?.isDetenteur === "oui" &&
              havePicture.current && (
                <li
                  className="ripple2 pointer"
                  onClick={() =>
                    doConfirm(
                      "Vous allez supprimer définitivement la photo de cette fiche animal.<br /><br /> Etes-vous sur de vouloir continuer ?"
                    )
                  }
                >
                  Supprimer la photo
                </li>
              )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Animal
