import "./FilterList.scss"
import ModalChoices from "./inventaire/modals/ModalChoices"
import { getColumns } from "../functions/objectColums"
import { formatDate } from "../functions/formatDate"
import ModalSelectVals from "./inventaire/modals/ModalSelectVals"
import { Fragment } from "react"
import { goBack } from "../functions/handleNavigation"

const FilterList = ({ inventory, filters, setFilters, allData, origin }) => {
  const columnsAvailable = getColumns()

  // Filter data
  let analysesFilter = JSON.parse(window.localStorage.analyseFilter)
  let confirmFilter = JSON.parse(window.localStorage.confirmFilter)
  // Hash parameters
  let hashV = window.location.hash.split("|")
  let hash = hashV[0]
  let param1 = hashV[1] // Inventory index
  let param2 = hashV[2] // Used to define wich modal to show !!

  let analyses = false // On montre le filtre analyse si une colonne de la categorie analyse est presente
  let prelevements = false // On montre le filtre prelevements si une colonne de la categorie prelevements est presente
  inventory?.columns?.forEach(col => {
    if (columnsAvailable?.[col]?.["category"] === "analyse") analyses = true
    if (columnsAvailable?.[col]?.["category"] === "prelev") prelevements = true
  })
  let confirmation = inventory?.action === "confirm" // Filtre confirm si inventaire avec confirmation
  let declarations = inventory?.action === "declar" // Filtre declarations si inventaire avec declarations

  ///////////
  // HOOKS //
  ///////////

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const clDivWrapper = () => {
    let cl = "filter_custom_wrapper"

    origin === "custom" && (cl += " fromCustom")
    origin === "side_inventaire" && (cl += " fromSideInv")

    return cl
  }
  const setFilterModal = (filter, col) => {
    if (filter) {
      filters[col] = { value: filter }
    } else if (!filter && filters[col] && filters[col].value) {
      delete filters[col]
    }

    setFilters(structuredClone(filters))
    goBack()
  }
  const setFourchette = (col, value, side) => {
    if (!filters[col] && value) {
      filters[col] = {
        min: "",
        max: "",
      }
    }
    if (side === "min") filters[col].min = value
    if (side === "max") filters[col].max = value

    if (!filters[col].min && !filters[col].max) delete filters[col]

    setFilters(structuredClone(filters))
  }
  const setText = (col, value) => {
    if (!filters[col] && value) {
      filters[col] = {
        value: "",
      }
    }

    if (value) filters[col].value = value
    else delete filters[col]

    setFilters(structuredClone(filters))
  }
  const deleteFilter = col => {
    document
      .querySelectorAll(`[datacol='${col}'`)
      .forEach(el => (el.value = ""))

    delete filters[col]

    setFilters(structuredClone(filters))
  }
  const setFilterSelectVals = (col, selectedVals) => {
    if (selectedVals.length !== 0) {
      filters[col] = { value: selectedVals }
    } else if (filters[col] && filters[col].value) {
      delete filters[col]
    }

    setFilters(structuredClone(filters))
  }

  return (
    <div className={clDivWrapper()}>
      {confirmation &&
        (!filters?.confirmation?.locked || origin !== "side_inventaire") && (
          <div
            className={
              filters?.confirmation?.locked
                ? `card_custom_filter locked`
                : `card_custom_filter`
            }
          >
            <TitleFilter
              title="Confirmations"
              display={
                !filters?.confirmation?.locked && filters?.confirmation?.value
              }
              delFnc={() => deleteFilter("confirmation")}
            />
            <input
              value={
                filters?.confirmation?.value
                  ? filters?.confirmation?.value === "nbGenissesToExamine"
                    ? `Génisses à examiner (${confirmFilter.nbGenissesToExamine})`
                    : filters?.confirmation?.value === "nbGenissesToConfirm"
                    ? `Génisses à confirmer (${confirmFilter.nbGenissesToConfirm})`
                    : filters?.confirmation?.value === "nbVachesToConfirm"
                    ? `Vaches à confirmer (${confirmFilter.nbVachesToConfirm})`
                    : filters?.confirmation?.value === "nbFemP6MNnInscrite"
                    ? `Femelles +6 mois non inscrites HBL (${confirmFilter.nbFemP6MNnInscrite})`
                    : filters?.confirmation?.value === "nbMaleToExamine"
                    ? `Mâles 6-18 mois à examiner (${confirmFilter.nbMaleToExamine})`
                    : filters?.confirmation?.value === "nbMalP6MNnInscrit"
                    ? `Mâles +6 mois non inscrits HBL (${confirmFilter.nbMalP6MNnInscrit})`
                    : filters?.confirmation?.value === "nbMaleToConfirm" &&
                      `Mâles à confirmer (${confirmFilter.nbMaleToConfirm})`
                  : "Aucun filtre"
              }
              className={
                filters?.confirmation?.locked
                  ? `input_type1 locked`
                  : `input_type1 ripple2`
              }
              type="text"
              datacol={"confirmation"}
              onClick={() =>
                !filters?.prelevements?.locked &&
                (window.location.hash = `${hash}|${param1}|confirmation`)
              }
              readOnly
            />
            {param2 === "confirmation" && (
              <ModalChoices col="confirmation" callback={setFilterModal} />
            )}
          </div>
        )}
      {analyses &&
        (!filters?.analyses?.locked || origin !== "side_inventaire") && (
          <div
            className={
              filters?.analyses?.locked
                ? `card_custom_filter locked`
                : `card_custom_filter`
            }
          >
            <TitleFilter
              title="Analyses"
              display={!filters?.analyses?.locked && filters?.analyses?.value}
              delFnc={() => deleteFilter("analyses")}
            />
            <input
              value={
                filters?.analyses?.value
                  ? filters.analyses.value === "needAnalyse"
                    ? `Analyse à faire (${analysesFilter?.needAnalyse ?? 0})`
                    : filters.analyses.value === "hasIncompatibility"
                    ? `Avec incompatibilité (${
                        analysesFilter?.hasIncompatibility ?? 0
                      })`
                    : filters.analyses.value === "noCompatibilityPere"
                    ? `Sans compatibilité de Père (${
                        analysesFilter?.maleSansCompatPere ?? 0
                      })`
                    : filters.analyses.value === "noAnalyseMh"
                    ? `Sans analyse MH (${analysesFilter?.maleSansMh ?? 0})`
                    : filters.analyses.value === "noProfilAdn" &&
                      `Sans profil ADN (${
                        analysesFilter?.maleSansProfilAdn ?? 0
                      })`
                  : "Aucun filtre"
              }
              className={
                filters?.analyses?.locked
                  ? `input_type1 locked`
                  : `input_type1 ripple2`
              }
              type="text"
              datacol={"analyses"}
              onClick={() =>
                !filters?.analyses?.locked &&
                (window.location.hash = `${hash}|${param1}|analyses`)
              }
              readOnly
            />
            {param2 === "analyses" && (
              <ModalChoices col="analyses" callback={setFilterModal} />
            )}
          </div>
        )}
      {prelevements &&
        (!filters?.prelevements?.locked || origin !== "side_inventaire") && (
          <div
            className={
              filters?.prelevements?.locked
                ? `card_custom_filter locked`
                : `card_custom_filter`
            }
          >
            <TitleFilter
              title="Prélèvements"
              display={
                !filters?.prelevements?.locked && filters?.prelevements?.value
              }
              delFnc={() => deleteFilter("prelevements")}
            />
            <input
              value={
                filters?.prelevements?.value
                  ? filters?.prelevements?.value === "all"
                    ? "Tous les prélèvements"
                    : filters?.prelevements?.value === "inventory" &&
                      "Animaux à mon inventaire"
                  : "Aucun filtre"
              }
              className={
                filters?.prelevements?.locked
                  ? `input_type1 locked`
                  : `input_type1 ripple2`
              }
              type="text"
              datacol={"prelevements"}
              onClick={() =>
                !filters?.prelevements?.locked &&
                inventory.num === "prelevements"
                  ? (window.location.hash = `${hash}|${param1}|prelev`)
                  : (window.location.hash = `${hash}|${param1}|prelevements`)
              }
              readOnly
            />
            {param2 === "prelev" && (
              <ModalChoices col="prelevements_fix" callback={setFilterModal} />
            )}
            {param2 === "prelevements" && (
              <ModalChoices col="prelevements" callback={setFilterModal} />
            )}
          </div>
        )}
      {/* Filtre sur le sexe present en permanence */}
      {((!filters?.SEXBOV?.locked && !declarations) ||
        origin !== "side_inventaire") && (
        <div
          className={
            filters?.SEXBOV?.locked || declarations
              ? `card_custom_filter locked`
              : `card_custom_filter`
          }
        >
          <TitleFilter
            title="Sexe"
            display={!filters?.SEXBOV?.locked && filters?.SEXBOV?.value}
            delFnc={() => deleteFilter("SEXBOV")}
          />
          <input
            value={
              declarations
                ? "Mâle"
                : filters?.SEXBOV?.value
                ? filters.SEXBOV.value === "1"
                  ? "Mâle"
                  : filters.SEXBOV.value === "2" && "Femelle"
                : "Tous"
            }
            className={
              filters?.SEXBOV?.locked || declarations
                ? `input_type1 locked`
                : `input_type1 ripple2`
            }
            type="text"
            datacol={"SEXBOV"}
            onClick={() =>
              !filters?.SEXBOV?.locked &&
              !declarations &&
              (window.location.hash = `${hash}|${param1}|SEXBOV`)
            }
            readOnly
          />
          {param2 === "SEXBOV" && (
            <ModalChoices col="SEXBOV" callback={setFilterModal} />
          )}
        </div>
      )}

      {/* Filtre sur l'age present en permanence */}
      {(!filters?.age_month?.locked || origin !== "side_inventaire") && (
        <div
          className={
            filters?.age_month?.locked
              ? `card_custom_filter locked`
              : `card_custom_filter`
          }
        >
          <TitleFilter
            title="Age en mois"
            display={
              !filters?.age_month?.locked &&
              (filters?.age_month?.min || filters?.age_month?.max)
            }
            delFnc={() => deleteFilter("age_month")}
          />
          <div className="input_type3">
            <label>{window.innerWidth > 395 ? "Minimum" : "Min"}</label>
            <input
              className={filters?.age_month?.locked && "locked"}
              type="number"
              min={0}
              max={999}
              datacol={"age_month"}
              defaultValue={filters?.age_month?.min ?? ""}
              onChange={({ target }) =>
                setFourchette("age_month", target.value ?? null, "min")
              }
              onClick={({ target }) => target.select()}
              disabled={filters?.age_month?.locked}
            />
            <label>{window.innerWidth > 395 ? "Maximum" : "Max"}</label>
            <input
              className={filters?.age_month?.locked && "locked"}
              type="number"
              min={0}
              max={999}
              datacol={"age_month"}
              defaultValue={filters?.age_month?.max ?? ""}
              onChange={({ target }) =>
                setFourchette("age_month", target.value ?? null, "max")
              }
              onClick={({ target }) => target.select()}
              disabled={filters?.age_month?.locked}
            />
          </div>
        </div>
      )}

      {/* Filtres disponible si colonnes presentes dans inventaire */}
      {inventory?.columns?.map(col => {
        // Do not generate filter for columns with permanent filter already
        if (!columnsAvailable[col]) return
        if (col === "SEXBOV" || col === "age_month") return

        let input

        let tCol = col
        if (col === "TYPANA") tCol = "ADN_filter"
        if (col === "CORDCG") tCol = "filiation_filter"
        if (col === "IDLABO") tCol = "Evalim_filter"
        if (col === "labo") tCol = "NOORGA"
        if (col === "COIDEN") tCol = "ident_adn_filter"
        if (col === "retyuppaf") tCol = "paf_filter"
        if (col === "coreex_exa_mere") tCol = "exam_mere_txt"
        if (col === "coreex_exa_pere") tCol = "exam_pere_txt"

        switch (col) {
          case "CORABO":
          case "COV1VE":
          case "qualifAnimal":
          case "qualifAnimalLIBELO":
          case "qualifAnimalLIDETA":
          case "catasc":
          case "borexa_coreex":
          case "examAni":
          case "examAniLIBELO":
          case "examAniLIDETA":
          case "inscrAnimal":
          case "inscrAnimalLIBELO":
          case "inscrAnimalLIDETA":
          case "inscrPere":
          case "inscrPereLIBELO":
          case "inscrPereLIDETA":
          case "qualifPere":
          case "qualifPereLIBELO":
          case "qualifPereLIDETA":
          case "inscrMere":
          case "inscrExamMere":
          case "inscrMereLIBELO":
          case "inscrMereLIDETA":
          case "qualifMere":
          case "qualifMereLIBELO":
          case "qualifMereLIDETA":
          case "TYPANA":
          case "CORDCG":
          case "retyupsc":
          case "retyupmh":
          case "retyuppro":
          case "retyupbli":
          case "retyuppaf":
          case "retyuptrtxt":
          case "IDLABO":
          case "labo":
          case "COIDEN":
          case "retyupPere":
          case "coreex_exa_pere":
          case "examPereLIBELO":
          case "examPereLIDETA":
          case "coreex_exa_mere":
          case "examMereLIBELO":
          case "examMereLIDETA":
          case "inscrGPP":
          case "inscrGPPLIBELO":
          case "inscrGPPLIDETA":
          case "qualifGPP":
          case "qualifGPPLIBELO":
          case "qualifGPPLIDETA":
          case "inscrPGPP":
          case "inscrPGPPLIBELO":
          case "inscrPGPPLIDETA":
          case "qualifPGPP":
          case "qualifPGPPLIBELO":
          case "qualifPGPPLIDETA":
          case "inscrMGPP":
          case "inscrMGPPLIBELO":
          case "inscrMGPPLIDETA":
          case "qualifMGPP":
          case "qualifMGPPLIBELO":
          case "qualifMGPPLIDETA":
          case "inscrGMP":
          case "inscrGMPLIBELO":
          case "inscrGMPLIDETA":
          case "qualifGMP":
          case "qualifGMPLIBELO":
          case "qualifGMPLIDETA":
          case "inscrPGMP":
          case "inscrPGMPLIBELO":
          case "inscrPGMPLIDETA":
          case "qualifPGMP":
          case "qualifPGMPLIBELO":
          case "qualifPGMPLIDETA":
          case "inscrMGMP":
          case "inscrMGMPLIBELO":
          case "inscrMGMPLIDETA":
          case "qualifMGMP":
          case "qualifMGMPLIBELO":
          case "qualifMGMPLIDETA":
          case "inscrGPM":
          case "inscrGPMLIBELO":
          case "inscrGPMLIDETA":
          case "qualifGPM":
          case "qualifGPMLIBELO":
          case "qualifGPMLIDETA":
          case "inscrPGPM":
          case "inscrPGPMLIBELO":
          case "inscrPGPMLIDETA":
          case "qualifPGPM":
          case "qualifPGPMLIBELO":
          case "qualifPGPMLIDETA":
          case "inscrMGPM":
          case "inscrMGPMLIBELO":
          case "inscrMGPMLIDETA":
          case "qualifMGPM":
          case "qualifMGPMLIBELO":
          case "qualifMGPMLIDETA":
          case "inscrGMM":
          case "inscrGMMLIBELO":
          case "inscrGMMLIDETA":
          case "qualifGMM":
          case "qualifGMMLIBELO":
          case "qualifGMMLIDETA":
          case "inscrPGMM":
          case "inscrPGMMLIBELO":
          case "inscrPGMMLIDETA":
          case "qualifPGMM":
          case "qualifPGMMLIBELO":
          case "qualifPGMMLIDETA":
          case "inscrMGMM":
          case "inscrMGMMLIBELO":
          case "inscrMGMMLIDETA":
          case "qualifMGMM":
          case "qualifMGMMLIBELO":
          case "qualifMGMMLIDETA":
          case "CIFNAI":
          case "CCRSEV":
          case "CDMSEV":
          case "CDSSEV":
          case "CFOSSE":
          case "CAVELS":
          case "CALAIT":
          case "COPSEV":
            const setQualif = new Set()

            for (let i in allData) {
              if (["examAni", "examAniLIBELO", "examAniLIDETA"].includes(col)) {
                if (!allData[i]["borexa_coreex"] || allData[i]["inscrAnimal"]) {
                  setQualif.add("")
                  continue
                }
              }
              if (
                [
                  "coreex_exa_mere",
                  "examMereLIBELO",
                  "examMereLIDETA",
                ].includes(col)
              ) {
                if (!allData[i]["coreex_exa_mere"] || allData[i]["inscrMere"]) {
                  setQualif.add("")
                  continue
                }
              }
              if (
                [
                  "coreex_exa_pere",
                  "examPereLIBELO",
                  "examPereLIDETA",
                ].includes(col)
              ) {
                if (!allData[i]["coreex_exa_pere"] || allData[i]["inscrPere"]) {
                  setQualif.add("")
                  continue
                }
              }
              setQualif.add(allData[i][tCol] ?? "")
            }

            if (col === "IDLABO" || col === "CORABO") setQualif.delete("")

            let valsDisplay = ""
            filters[tCol]?.value?.forEach(
              val => (valsDisplay += val ? val + ", " : "Aucun(e), ")
            )

            valsDisplay = valsDisplay.replace(/,\s*$/, "")

            input = (
              <>
                <input
                  value={valsDisplay ? valsDisplay : "Aucun filtre"}
                  className={
                    filters[tCol]?.locked
                      ? `input_type1 locked`
                      : `input_type1 ripple2`
                  }
                  type="text"
                  datacol={tCol}
                  onClick={() =>
                    !filters[tCol]?.locked &&
                    (window.location.hash = `${hash}|${param1}|${tCol}`)
                  }
                  readOnly
                />
                {param2 === tCol && (
                  <ModalSelectVals
                    vals={[...setQualif]}
                    selectedVals={filters[tCol]?.value ?? []}
                    col={tCol}
                    callback={setFilterSelectVals}
                  />
                )}
              </>
            )
            break

          default:
            let type = columnsAvailable?.[col]?.type
            if (type === "number") {
              input = (
                <div className="input_type3">
                  <label>{window.innerWidth > 395 ? "Minimum" : "Min"}</label>
                  <input
                    type="number"
                    min={0}
                    max={999}
                    datacol={col}
                    defaultValue={filters[col]?.min}
                    onClick={({ target }) => target.select()}
                    onChange={({ target }) =>
                      setFourchette(col, target.value ?? null, "min")
                    }
                    disabled={filters[col]?.locked}
                  />
                  <label>{window.innerWidth > 395 ? "Maximum" : "Max"}</label>
                  <input
                    type="number"
                    min={0}
                    max={999}
                    datacol={col}
                    defaultValue={filters[col]?.max}
                    onClick={({ target }) => target.select()}
                    onChange={({ target }) =>
                      setFourchette(col, target.value ?? null, "max")
                    }
                    disabled={filters[col]?.locked}
                  />
                </div>
              )
            } else if (type === "date") {
              input = (
                <div className="input_type4">
                  <div className="line_date">
                    <label>Du</label>
                    <input
                      type="date"
                      min={0}
                      max={999}
                      datacol={col}
                      defaultValue={filters[col]?.min}
                      onClick={({ target }) => target.select()}
                      onChange={({ target }) =>
                        setFourchette(col, target.value ?? null, "min")
                      }
                    />
                  </div>
                  <div className="line_date line2">
                    <label className="secondlabel">au</label>
                    <input
                      type="date"
                      min={0}
                      max={999}
                      datacol={col}
                      defaultValue={filters[col]?.max}
                      onClick={({ target }) => target.select()}
                      onChange={({ target }) =>
                        setFourchette(col, target.value ?? null, "max")
                      }
                    />
                  </div>
                </div>
              )
            } else {
              input = (
                <input
                  defaultValue={filters[col]?.value}
                  datacol={col}
                  className={
                    filters[col]?.locked ? `input_type2 locked` : `input_type2`
                  }
                  type="text"
                  onChange={({ target }) => setText(col, target.value ?? null)}
                />
              )
            }
            break
        }

        let filterLocked = filters?.[col]?.locked
        let isCol = filters?.[tCol]

        return (
          <Fragment key={col}>
            {(!filters[col]?.locked || origin !== "side_inventaire") && (
              <div
                className={
                  filters[col]?.locked
                    ? `card_custom_filter locked`
                    : `card_custom_filter`
                }
              >
                <TitleFilter
                  title={columnsAvailable?.[col]?.name}
                  display={!filterLocked && isCol}
                  delFnc={() => deleteFilter(tCol)}
                />
                {input}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default FilterList

const TitleFilter = ({ title, display, delFnc }) => {
  return (
    <div className="title_div_filter">
      <h3>{title}</h3>
      {display && (
        <span
          className="icon_remove pointer material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight"
          onClick={delFnc}
        >
          block
        </span>
      )}
    </div>
  )
}
