import { fetchUrlEncoded } from "./fetch"

export const fetchExportsOp = async (type, animaux) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_op.php`
  let action = "exports_op"

  let params = {
    a: action,
    type: type,
    animaux: JSON.stringify(animaux),
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
