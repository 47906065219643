import { useEffect, useRef, useState } from "react"
import Loader from "./Loader"
import "./ModalLoader.scss"
import {
  statutBarColor,
  statutBarN1Color,
  statutBarN2Color,
  statutBarN3Color,
} from "../functions/statutBarColor"

let tmpHeaderColor = null

const ModalLoader = ({ id, message, parentCenterModal = null }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [clHidden, setClHidden] = useState("hidden")
  const divModal = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = divModal.current

    const showModal = () => {
      setClHidden("")

      tmpHeaderColor = document.querySelector(
        'meta[name="theme-color"]'
      ).content

      if (tmpHeaderColor == statutBarN1Color) statutBarColor(statutBarN2Color)
      else if (tmpHeaderColor == statutBarN2Color)
        statutBarColor(statutBarN3Color)
      else statutBarColor(statutBarN1Color)
    }
    const hideModal = () => {
      setClHidden("hidden")

      if (tmpHeaderColor) {
        statutBarColor(tmpHeaderColor)
      }
    }

    div.addEventListener("showModal", showModal)
    div.addEventListener("hideModal", hideModal)

    return () => {
      div.removeEventListener("showModal", showModal)
      div.removeEventListener("hideModal", hideModal)
    }
  }, [])
  useEffect(() => {
    centerTheModal()
  }, [clHidden])

  ///////////////
  // Functions //
  ///////////////
  const centerTheModal = () => {
    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    if (divModal.current.offsetWidth !== 0)
      divModal.current.style.left = `${leftWidth}px`
  }

  if (parentCenterModal) parentCenterModal.current = centerTheModal

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div id={id} className={`modal_loader ${clHidden}`} ref={divModal}>
        <Loader />
        <p>{message}</p>
      </div>
      <button
        id={"button_" + id}
        className={`button_modal_loader ${clHidden}`}
      ></button>
    </>
  )
}

export default ModalLoader
