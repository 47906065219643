import { fetchUrlEncoded } from "./fetch"

export const fetchGetTsPhoto = async animal => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "getTsAnimalPicture"

  let params = {
    a: action,
    ani: animal,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
