import { fetchUrlEncoded } from "./fetch"

// Function to fetch the data of the cheptel
export const fetchIsAnimal = async valeur => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "check_if_animal"

  let params = {
    a: action,
    val: valeur,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
