import { useEffect, useRef, useState } from "react"
import "./ModalListeCertifs.scss"
import { useCenterModal } from "../../../hooks/useCenterModal"
import Alert from "../../Alert"
import ModalLoader from "../../ModalLoader"
import { useCustomAlert } from "../../../hooks/useCustomAlert"
import { checkInternet } from "../../../functions/checkInternet"
import { checkIfConnected } from "../../../functions/checkIfConnecetd"
import { blobToBase64 } from "../../../functions/fetches/fetch"
import { goBack } from "../../../functions/handleNavigation"
import {
  statutBarColor,
  statutBarN1Color,
} from "../../../functions/statutBarColor"

const ModalListeCertifs = ({ aniCertifs }) => {
  ///////////
  // HOOKS //
  ///////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()

  let divModal = useRef()
  let modalContent = useRef()
  useCenterModal(divModal)

  let hash = window.location.hash
  let hVals = hash.split("|")
  let annee = +hVals[1]

  let defaultCaseCochees = []
  if (localStorage.caseCocheesNative) {
    defaultCaseCochees = JSON.parse(localStorage.caseCocheesNative)

    delete localStorage.caseCocheesNative
  }
  const [casesCochees, setCasesCochees] = useState(defaultCaseCochees)

  let listeAni = aniCertifs[annee]
  const [liste, setListe] = useState(Object.keys(listeAni))

  const [sortCase, setSortCase] = useState("")
  const [sortNum, setSortNum] = useState("sortDown")
  const [sortNom, setSortNom] = useState("")
  const [sortSexe, setSortSexe] = useState("")
  const [sortInscr, setSortInscr] = useState("")

  let numCheptel = window.localStorage.numCheptel ?? ""
  let listeAniCertif = []

  casesCochees.forEach(ani => {
    listeAniCertif.push({
      CodePays: ani.substr(0, 2),
      NumeroAnimal: ani.substr(2),
    })
  })

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let div = modalContent.current

    if (div) {
      if (
        localStorage.scrolledElementIdNative &&
        localStorage.scrolledElementIdNative === "modal_liste_certifs_content"
      ) {
        statutBarColor(statutBarN1Color)

        div.scrollLeft = localStorage.scrollXPageNative ?? 0
        div.scrollTop = localStorage.scrollYPageNative ?? 0

        delete localStorage.scrolledElementIdNative
        delete localStorage.scrollXPageNative
        delete localStorage.scrollYPageNative
      }
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const doCocheCase = nAni => {
    if (casesCochees.includes(nAni)) {
      setCasesCochees(prev => prev.filter(num => num != nAni))
    } else {
      setCasesCochees(prev => [...prev, nAni])
    }
  }
  const cocheeAll = e => {
    e.stopPropagation()

    if (casesCochees.length === liste.length) {
      setCasesCochees([])
    } else {
      setCasesCochees(liste)
    }
  }
  const sortCol = col => {
    let datatmp = liste.sort((a, b) =>
      +a.substring(a.length - 4) > +b.substring(b.length - 4)
        ? 1
        : +a.substring(a.length - 4) < +b.substring(b.length - 4)
        ? -1
        : 0
    )

    if (col === "case") {
      if (sortCase !== "sortDown") {
        datatmp.sort((a, b) => {
          let isA = casesCochees.includes(a)
          let isB = casesCochees.includes(b)

          if (isA && !isB) return 1
          if (!isA && isB) return -1

          return 0
        })

        setSortCase("sortDown")
      } else {
        datatmp.sort((b, a) => {
          let isA = casesCochees.includes(a)
          let isB = casesCochees.includes(b)

          if (isA && !isB) return 1
          if (!isA && isB) return -1

          return 0
        })

        setSortCase("sortUp")
      }

      setSortNum("")
      setSortNom("")
      setSortSexe("")
      setSortInscr("")
    } else if (col === "num") {
      if (sortNum !== "sortDown") {
        datatmp.sort((a, b) =>
          +a.substring(a.length - 4) > +b.substring(b.length - 4)
            ? 1
            : +a.substring(a.length - 4) < +b.substring(b.length - 4)
            ? -1
            : 0
        )

        setSortNum("sortDown")
      } else {
        datatmp.sort((b, a) =>
          +a.substring(a.length - 4) > +b.substring(b.length - 4)
            ? 1
            : +a.substring(a.length - 4) < +b.substring(b.length - 4)
            ? -1
            : 0
        )

        setSortNum("sortUp")
      }

      setSortCase("")
      setSortNom("")
      setSortSexe("")
      setSortInscr("")
    } else if (col === "nom") {
      const frCollator = new Intl.Collator("fr")

      if (sortNom !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(listeAni[a].nom ?? "", listeAni[b].nom ?? "")
        )

        setSortNom("sortDown")
      } else {
        datatmp.sort((b, a) =>
          frCollator.compare(listeAni[a].nom ?? "", listeAni[b].nom ?? "")
        )

        setSortNom("sortUp")
      }

      setSortCase("")
      setSortNum("")
      setSortSexe("")
      setSortInscr("")
    } else if (col === "sexe") {
      if (sortSexe !== "sortDown") {
        datatmp.sort((a, b) =>
          +listeAni[a].sexe > +listeAni[b].sexe
            ? 1
            : +listeAni[a].sexe < +listeAni[b].sexe
            ? -1
            : 0
        )

        setSortSexe("sortDown")
      } else {
        datatmp.sort((b, a) =>
          +listeAni[a].sexe > +listeAni[b].sexe
            ? 1
            : +listeAni[a].sexe < +listeAni[b].sexe
            ? -1
            : 0
        )

        setSortSexe("sortUp")
      }

      setSortCase("")
      setSortNum("")
      setSortNom("")
      setSortInscr("")
    } else if (col === "inscr") {
      const frCollator = new Intl.Collator("fr")

      if (sortInscr !== "sortDown") {
        datatmp.sort((a, b) =>
          frCollator.compare(listeAni[a].certif ?? "", listeAni[b].certif ?? "")
        )

        setSortInscr("sortDown")
      } else {
        datatmp.sort((a, b) =>
          frCollator.compare(listeAni[a].certif ?? "", listeAni[b].certif ?? "")
        )

        setSortInscr("sortUp")
      }

      setSortCase("")
      setSortNum("")
      setSortNom("")
      setSortSexe("")
    }
  }
  const dlCertificat = async () => {
    let divModal = document.getElementById("loader_certificate")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      let link = `${
        process.env.REACT_APP_ESPRO_PDF_URL
      }espace-pro/hbl/certificat-groupe/${JSON.stringify(listeAniCertif)}/O-F`

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            let ts = Math.round(Date.now() / 1000)
            let name =
              casesCochees.length === 1
                ? `certificat_${casesCochees[0]}_${ts}.pdf`
                : `certificats_${annee}_${numCheptel}_${ts}.pdf`

            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let scrolledElementId = "modal_liste_certifs_content"
                let div = document.getElementById(scrolledElementId)

                if (div) {
                  localStorage.scrollXPageNative = div.scrollLeft
                  localStorage.scrollYPageNative = div.scrollTop
                  localStorage.scrolledElementIdNative = scrolledElementId
                }
                localStorage.caseCocheesNative = JSON.stringify(casesCochees)

                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")
            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        window.open(link)

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_certificate"
        message="Téléchargement en cours..."
      />
      <div ref={divModal} className="modal_liste_certifs">
        <div className="div_modal_header">
          <span
            className="close_modal ripple2  material-symbols-outlined"
            onClick={goBack}
          >
            close
          </span>
          <h3>Animaux certifiés en {annee}</h3>
        </div>
        <div
          ref={modalContent}
          className="modal_content"
          id="modal_liste_certifs_content"
        >
          <div className="div_table">
            <table>
              <thead>
                <tr>
                  <th
                    onClick={cocheeAll}
                    className={sortCase + " pointer ripple2"}
                  >
                    <div
                      className={
                        casesCochees.length === liste.length
                          ? "coche on"
                          : "coche"
                      }
                    ></div>
                  </th>
                  <th
                    onClick={() => sortCol("num")}
                    className={sortNum + " pointer ripple2"}
                  >
                    Numéro
                  </th>
                  <th
                    onClick={() => sortCol("nom")}
                    className={sortNom + " pointer ripple2"}
                  >
                    Nom
                  </th>
                  <th
                    onClick={() => sortCol("sexe")}
                    className={sortSexe + " pointer ripple2"}
                  >
                    Sexe
                  </th>
                  <th
                    onClick={() => sortCol("inscr")}
                    className={sortInscr + " pointer ripple2"}
                  >
                    Inscr.
                  </th>
                </tr>
              </thead>
              <tbody>
                {liste.map((nAni, index) => (
                  <tr key={index}>
                    <td
                      onClick={() => doCocheCase(nAni)}
                      className="pointer ripple2"
                    >
                      <div
                        className={
                          casesCochees.includes(nAni) ? "coche on" : "coche"
                        }
                      ></div>
                    </td>
                    <td>
                      {nAni.substring(0, nAni.length - 4)}
                      <span className="numtrav">
                        {nAni.substring(nAni.length - 4)}
                      </span>
                    </td>
                    <td>{listeAni[nAni].nom}</td>
                    <td>
                      {listeAni[nAni].sexe === "1" ? (
                        <div className="sexe male">1</div>
                      ) : (
                        <div className="sexe femelle">2</div>
                      )}
                    </td>
                    <td>{listeAni[nAni].certif}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="div_bottom" id="div-bottom">
          <div className="div_button">
            <button
              disabled={casesCochees.length === 0}
              className="button_validate pointer"
              onClick={dlCertificat}
            >
              {casesCochees.length > 1
                ? "Télécharger les certificats"
                : "Télécharger le certificat"}
            </button>
          </div>
        </div>
      </div>
      <button className="background-modal" onClick={goBack}></button>
    </>
  )
}

export default ModalListeCertifs
