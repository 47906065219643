// Go to hash while keeping params
export const goToHashWithParams = hash => {
  let newHash = ""

  let hashOptions = window.location.hash.split("|")
  if (hashOptions.length === 1) newHash = hash
  else {
    let hashArr = hashOptions.map((opt, index) =>
      index === 0
        ? hash + "|"
        : index === hashOptions.length - 1
        ? opt
        : opt + "|"
    )

    hashArr.forEach(h => (newHash += h))
  }

  window.location.hash = newHash
}

// Change hash option while keeping params
export const changeHashOption = option => {
  let hashOptions = window.location.hash.split("|")
  let hashPreOption = hashOptions[0].split("_")[0].substring(1)
  let hashArr = hashOptions.map((opt, index) =>
    index === 0
      ? `${hashPreOption}_${option}|`
      : index === hashOptions.length - 1
      ? opt
      : opt + "|"
  )

  let newHash = ""
  hashArr.forEach(h => (newHash += h))

  window.location.hash = newHash
}
