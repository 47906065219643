import localforage from "localforage"
import { fetchMessages } from "./fetches/fetchMessages"
import { blobToBase64, fetchBlob } from "./fetches/fetch"
import { isArray } from "chart.js/helpers"

export const updateMessages = async () => {
  let oldMessages = []

  await localforage.getItem("messages", (err, value) => {
    if (value) oldMessages = value
  })

  let messages = await fetchMessages()

  if (!isArray(messages)) messages = oldMessages
  if (messages.length === 0) return messages

  messages.sort((b, a) => (+a.id > +b.id ? 1 : +b.id > +a.id ? -1 : 0))

  if (messages.length > 50)
    messages = messages.slice(Math.max(messages.length - 50, 0))

  localforage.setItem("messages", messages)

  return messages
}
