import { useEffect, useRef } from "react"
import "./ReglesConfidentialites.scss"

const ReglesConfidentialites = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const div = useRef()

  let hashV = window.location.hash.split("|")

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = div.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("margin-top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.marginTop = newHeight + "px"
      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }
  }, [])
  ///////////////
  // Functions //
  ///////////////

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="regles_conf" ref={div}>
      <p>
        EspacePro est éditée par France Limousin Sélection - Pôle de Lanaud,
        87220 BOISSEUIL.
      </p>
      <br />
      <h3>Données collectées</h3>
      <p>
        L'EspacePro collecte automatiquement des données liées à votre matériel
        (type d'appareil, navigateur web, version du navigateur web, système
        d'exploitation, version de l'OS) et enregistre vos connexions et
        certaines actions réalisées (acceptation des notifications, installation
        en PWA) pour des raisons de maintenance et d'assurance au bon
        fonctionnement de l'EspacePro. Ces informations sont sauvegardées sur
        nos serveurs et ne sont partagées avec personne, ni utilisées à
        quelconque fin commerciale.
      </p>
      <br />
      <h3>Permissions demandées</h3>
      <h4>Envoie de notifications</h4>
      <p>
        Si vous l'accepter, votre EspacePro peut vous envoyer des notifications
        à la réception de messages.
      </p>
      <h4>Ecriture sur le stockage de l'appareil</h4>
      <p>
        Dans les systèmes d'exploitation qui le nécessitent, l'EspacePro peut
        vous demander l'autorisation d'écrire sur le stockage de votre appareil
        afin de pouvoir enregistrer vos documents. Cet action ne se fait qu'à la
        demande de l'utilisateur, en utilisant la fonctionnalité de sauvegarde
        de document.
      </p>
      <br />
      <h3>A propos du développeur</h3>
      <p>
        <span className="span-arial">
          France Limousin Sélection
          <br />
          Pôle de Lanaud
          <br />
          87220 BOISSEUIL
          <br />
          Tél. : +33 5 55 06 46 00
          <br />
          dev@limousine.org
        </span>
      </p>
    </div>
  )
}

export default ReglesConfidentialites
