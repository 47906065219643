import "./ModalResultConfirm.scss"
import { useRef } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { checkIfConnected } from "../../../functions/checkIfConnecetd"
import { checkInternet } from "../../../functions/checkInternet"
import ModalLoader from "../../ModalLoader"
import Alert from "../../Alert"
import { useCustomAlert } from "../../../hooks/useCustomAlert"
import { blobToBase64 } from "../../../functions/fetches/fetch"
import { goBack, goBackXAmount } from "../../../functions/handleNavigation"

const ModalResultConfirm = ({
  resultConfirmations,
  lastIdent,
  origin,
  objDate,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  let nbValid = 0

  let listeAniValid = []

  resultConfirmations.forEach(r => {
    if (!r.rejected) {
      nbValid++

      listeAniValid.push({
        CodePays: r.numani.substr(0, 2),
        NumeroAnimal: r.numani.substr(2),
      })
    }
  })

  let numCheptel = window.localStorage.numCheptel ?? ""
  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const altGoBack = () => {
    if (origin === "animal") goBackXAmount(2)
    else goBack()
  }
  const showConfirmation = async link => {
    let divModal = document.getElementById("loader_certificate")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      if ("ReactNativeWebView" in window) {
        let scrolledElementId = "wrap_page_cheptel"
        let div = document.getElementById(scrolledElementId)

        if (div) {
          localStorage.scrollXPageNative = div.scrollLeft
          localStorage.scrollYPageNative = div.scrollTop
          localStorage.scrolledElementIdNative = scrolledElementId
        }

        let day = objDate.getDate()
        let month = objDate.getMonth()
        let year = objDate.getFullYear()
        let hours = objDate.getHours()
        let mins = objDate.getMinutes()

        let date = `${day}-${month}-${year} ${hours}:${mins}`

        let message = {
          type: "PageDemandeConfirm",
          url: link,
          date: date,
        }

        let stringified = JSON.stringify(message)

        window.onLeaveExternalWebView = () => {
          if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

          window.onLeaveExternalWebView = null
        }

        window.ReactNativeWebView.postMessage(stringified)
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  const dlCertificat = async numani => {
    let divModal = document.getElementById("loader_certificate")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      let copaip = numani.substr(0, 2)
      let numati = numani.substr(2)

      let link = `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/certificat/${copaip}/${numati}-F`

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            let ts = Math.round(Date.now() / 1000)
            let name = `certificat_${copaip}/${numati}_${ts}.pdf`

            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")
            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        window.open(link)

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }
  const dlCertificatGroupe = async () => {
    let divModal = document.getElementById("loader_certificate")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let isOnline = await checkInternet()

    if (isOnline) {
      await checkIfConnected(numCheptel)

      let link = `${
        process.env.REACT_APP_ESPRO_PDF_URL
      }espace-pro/hbl/certificat-groupe/${JSON.stringify(listeAniValid)}/O-F`

      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            let ts = Math.round(Date.now() / 1000)
            let name = `certificats_${lastIdent}_${ts}`

            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")
            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        window.open(link)

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
      )
    }
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_certificate"
        message="Téléchargement en cours..."
      />
      <div ref={divModal} className={"modal_results_confirm"}>
        <div className="div_modal_header">
          <span
            onClick={altGoBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <h3>Résultats confirmations</h3>
        </div>
        <div className="modal_content">
          {resultConfirmations.map(r => {
            if (r.rejected) {
              return (
                <div key={r.numani}>
                  <div className="confirm_done rejected">
                    <div className="num_ani">
                      <p>{r.numani}</p>
                    </div>
                    <div className="result_div">
                      <p className="rej">Rejetté</p>
                    </div>
                  </div>
                  <ul>
                    {r.rejets.map(rej => (
                      <li key={rej}>{rej}</li>
                    ))}
                  </ul>
                </div>
              )
            } else {
              return (
                <div key={r.numani} className="confirm_done valid">
                  <div className="num_ani">
                    <p>{r.numani}</p>
                  </div>
                  <div className="result_div">
                    <p className="conf">Confirmé</p>
                    <button
                      className="btn_certificat ripple6 pointer"
                      onClick={() => dlCertificat(r.numani)}
                    >
                      Certificat
                    </button>
                  </div>
                </div>
              )
            }
          })}
          <div className="footer_btn">
            <button
              className="btn_all_certificate  ripple6 pointer"
              onClick={() =>
                showConfirmation(
                  `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/confirmation/recap/${lastIdent}`
                )
              }
            >
              voir détails
            </button>
            {nbValid > 1 && (
              <button
                className="btn_all_certificate ripple6 pointer"
                onClick={dlCertificatGroupe}
              >
                certificats groupés
              </button>
            )}
          </div>
        </div>
      </div>
      <button className={"background-modal"}></button>
    </>
  )
}

export default ModalResultConfirm
