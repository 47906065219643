import { useEffect, useRef, useState } from "react"
import "./Op.scss"
import { useCustomAlert } from "../hooks/useCustomAlert"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import ModalChangeLogs from "../components/ModalChangeLogs"
import Alert from "../components/Alert"
import localforage from "localforage"
import Confirm from "../components/Confirm"
import ModalCheptels from "../components/op/ModalCheptels"

const Op = ({ pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const divOp = useRef()

  let listeAdherent = JSON.parse(localStorage.adherents ?? "[]")
  const [listeCheptels, setListeCheptels] = useState(listeAdherent)

  const [searchOp, setSearchOp] = useState("")

  const [clCheptels, setClCheptels] = useState(false)
  pageOption === "cheptels" && !clCheptels && setClCheptels(true)
  pageOption !== "cheptels" && clCheptels && setClCheptels(false)

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  let tmpListSaved = JSON.parse(localStorage.ldlCheptel ?? "[]")
  const chepToDel = useRef("")

  const centerModal = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divOp.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let topActual =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("margin-top")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newHeight = topActual + addHeight

      curr.style.marginTop = newHeight + "px"
      curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
    }

    const searchUpdated = e => {
      setSearchOp(e.detail.searchOp)
    }

    curr.addEventListener("searchUpdated", searchUpdated)

    window.onresize = () =>
      typeof centerModal.current === "function" && centerModal.current()

    return () => {
      curr.removeEventListener("searchUpdated", searchUpdated)
      window.onresize = null
    }
  }, [])
  useEffect(() => {
    filterList()
  }, [searchOp])
  ///////////////
  // Functions //
  ///////////////
  const filterList = () => {
    let listeAdherent = JSON.parse(localStorage.adherents ?? "[]")

    listeAdherent = listeAdherent.filter(
      adh =>
        adh.fullnumchep.includes(searchOp) ||
        adh.nomducheptelraisonsociale_membres.includes(searchOp)
    )

    setListeCheptels(listeAdherent)
  }
  const goCheptel = numChep => {
    if (!numChep) return false

    window.localStorage.numCheptel = numChep

    document.getElementById("app")?.dispatchEvent(new CustomEvent("loadData"))
  }
  const askDeleteCheptel = () => {
    doConfirm(
      "Voulez-vous supprimer les données du cheptel de votre appareil ?"
    )
  }
  const deleteCheptel = async chep => {
    tmpListSaved = tmpListSaved.filter(num => num !== chep)
    localStorage.ldlCheptel = JSON.stringify(tmpListSaved)

    await localforage.keys().then(async keys => {
      for (let indexKey in keys) {
        let nameKey = keys[indexKey]
        if (nameKey.includes(chep)) await localforage.removeItem(nameKey)
      }
    })
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  return (
    <div className="op_content" id="op_content" ref={divOp}>
      {clCheptels && <ModalCheptels setListeCheptels={setListeCheptels} />}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm
          message={messageConfirm}
          giveConfirm={confirmed => {
            hideConfirm()
            if (confirmed) deleteCheptel(chepToDel.current)
          }}
        />
      )}

      {listeCheptels.length === 0 ? (
        <h2>Aucun résultat ne correspond à votre recherche.</h2>
      ) : (
        <div className="liste_cheptels">
          {listeCheptels?.map((chep, i) => {
            if (chep?.fullnumchep)
              return (
                <div
                  key={i}
                  className="chep_card pointer"
                  onClick={() => goCheptel(chep?.fullnumchep)}
                >
                  <div className="first_line">
                    <p className="nom_cheptel">
                      {chep?.nomducheptelraisonsociale_membres}
                    </p>
                    {chep.hbl_membres === "1" && (
                      <div className="adherent">HBL</div>
                    )}
                  </div>
                  {chep?.nomducheptelraisonsociale_membres &&
                    !chep?.nomducheptelraisonsociale_membres.includes(
                      chep?.nomducheptelpersonne_membres
                    ) && (
                      <p>
                        {chep?.nomducheptelpersonne_membres}{" "}
                        {chep?.prenomducheptelpersonne_membres}
                      </p>
                    )}

                  <p className="numchep">{chep?.fullnumchep}</p>

                  <p className="text">
                    {chep?.codepostal_membres}{" "}
                    {chep?.commune_membres && `- ${chep?.commune_membres}`}
                  </p>
                  <p className="text">{chep?.adresse_membres}</p>
                  <p className="text">{chep?.telphone_membres}</p>
                  <p className="text">{chep?.email_membres}</p>
                  {tmpListSaved.includes(chep?.fullnumchep) && (
                    <>
                      <div className="in_app">
                        <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                          mobile_friendly
                        </span>
                      </div>
                      <div
                        onClick={e => {
                          e.stopPropagation()
                          chepToDel.current = chep?.fullnumchep
                          askDeleteCheptel()
                        }}
                        className="btn_delete ripple12"
                      >
                        <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                          delete
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )
          })}
        </div>
      )}
    </div>
  )
}

export default Op
