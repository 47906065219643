import { useEffect, useRef } from "react"
import "./ModalPhoto.scss"
import { useCenterModal } from "../hooks/useCenterModal"
import { goBack } from "../functions/handleNavigation"

const ModalPhoto = ({ url, title }) => {
  // Center the modal
  const divModal = useRef()
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const centerModal = () => {
    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    divModal.current.style.left = `${leftWidth}px`
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      <div ref={divModal} className="modal_photo">
        <div className="div_modal_header">
          <span
            onClick={goBack}
            className="close_modal ripple2 pointer material-symbols-outlined"
          >
            close
          </span>
          <h3>{title}</h3>
        </div>
        <div className="div_img_photo">
          <img
            src={url}
            className="pointer"
            onClick={() => window.open(url)}
            onLoad={centerModal}
          ></img>
        </div>
      </div>
      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalPhoto
