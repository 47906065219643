import { useEffect, useRef } from "react"
import { getDateWritten } from "../../functions/getDateWritten"
import { getParamsActivities } from "../../functions/objectColums"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import ModalListePdf from "./ModalListePdf"
import "./RecapVisite.scss"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import ModalLoader from "../ModalLoader"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"
import { goBack, goBackXAmount } from "../../functions/handleNavigation"

ChartJS.register(ArcElement, Tooltip, Legend)

const RecapVisite = ({ data, page }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const divCards = useRef()
  const divAskPere = useRef()

  let activites = getParamsActivities()
  let indexVisite = window.location.hash.split("|")[1] ?? 0
  let paramsListe = window.location.hash.split("|")[2] ?? ""
  let indexActiviteListe = window.location.hash.split("|")[3] ?? ""
  let askForS = (window.location.hash.split("|")[4] ?? "N") === "O"
  let perePtgeA = (window.location.hash.split("|")[5] ?? "N") === "O"
  let perePtgeS = (window.location.hash.split("|")[6] ?? "N") === "O"
  let numCheptel = window.localStorage.numCheptel ?? ""
  let year = data.dateVisite.substring(0, 4)
  let month = data.dateVisite.substring(5, 7)
  let campagne = ""
  let early = month > 7 ? true : false

  if (early) campagne = `${year}/${+year + 1}`
  else campagne = `${+year - 1}/${year}`

  let dateVisite = data.dateVisite.split(" ")
  let heureVisite = dateVisite[1].substring(0, 5)

  let dVisite = new Date(data.dateVisite.replace(" ", "T"))

  let hVisite = dVisite.getHours()

  let dateInp =
    dateVisite[0].substr(0, 4) +
    dateVisite[0].substr(5, 2) +
    dateVisite[0].substr(8, 2)

  let indexPtgeA
  let indexPtgeS
  activites.activites.forEach((acti, index) => {
    if (acti.codeActivite === "PTGE_A") indexPtgeA = index
    if (acti.codeActivite === "PTGE_S") indexPtgeS = index
  })

  let listePdfs = []
  let yaPtgeA = false
  let yaPtgeS = false

  for (let codeActivite in data.postes) {
    if (codeActivite === "PTGE_A") yaPtgeA = true
    if (codeActivite === "PTGE_S") yaPtgeS = true

    switch (codeActivite) {
      case "DGLOC":
        listePdfs.push("f_sabots")
        break
      case "CERTIF":
        listePdfs.push("f_ins")
        break
      case "QUALIF":
        listePdfs.push("exam_qualif")
        break
      case "QUALIF2":
        listePdfs.push("exam_qualif2")
        break
      case "TRI":
        listePdfs.push("tri_hbl")
        break
      case "PELV2":
        listePdfs.push("f_op")
        break
      case "DG":
        listePdfs.push("f_pm")
        break
      case "PTGE_A":
        listePdfs.push("ptgeA_col")
        listePdfs.push("f_el_ptgeA")
        break
      case "PTGE_S":
        listePdfs.push("ptgeS_col")
        listePdfs.push("f_el_ptgeS")
        break

      default:
        break
    }
  }

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    let curr = divCards.current

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let acutalPadBot =
        +window
          .getComputedStyle(curr, null)
          .getPropertyValue("padding-bottom")
          .split("px")[0] ?? 0

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0
      let newPadBot = acutalPadBot + addHeight

      curr.style.paddingBottom = newPadBot + "px"
    }
  }, [])
  useEffect(() => {
    if (paramsListe === "askPere") {
      if (!divAskPere.current) return

      let halfHeightScreen = window.innerHeight / 2
      let halfHeightModal = divAskPere.current.offsetHeight / 2
      let topHeight = halfHeightScreen - halfHeightModal

      divAskPere.current.style.top = `${topHeight}px`

      let halfWidthScreen = window.innerWidth / 2
      let halWidthModal = divAskPere.current.offsetWidth / 2
      let leftWidth = halfWidthScreen - halWidthModal

      divAskPere.current.style.left = `${leftWidth}px`
    }
  }, [paramsListe])
  ///////////////
  // Functions //
  ///////////////
  const getPdf = async (link, name, callback) => {
    let divModal = document.getElementById("loader_dlmydocs")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))
    let isOnline = await checkInternet()

    if (isOnline) {
      if (isMobile) {
        await fetch(link)
          .then(response => response.blob())
          .then(blob => {
            if ("ReactNativeWebView" in window) {
              blobToBase64(blob).then(res => {
                let scrolledElementId = "div_body_recap"
                let div = document.getElementById(scrolledElementId)

                if (div) {
                  localStorage.scrollXPageNative = div.scrollLeft
                  localStorage.scrollYPageNative = div.scrollTop
                  localStorage.scrolledElementIdNative = scrolledElementId
                }

                let message = {
                  type: "PDFB64",
                  content: {
                    title: name,
                    b64: res,
                  },
                }

                let stringified = JSON.stringify(message)

                window.onLeavePdfViewer = () => {
                  if (divModal)
                    divModal.dispatchEvent(new CustomEvent("hideModal"))

                  window.onLeavePdfViewer = null
                }

                window.ReactNativeWebView.postMessage(stringified)
              })
            } else {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement("a")
              a.href = url
              // a.target = "_blank"
              a.download = name
              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click()
              a.remove() //afterwards we remove the element again

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            }
          })
          .catch(error => {
            console.log(error)
            doAlert("Une erreur est survenu lors de la récuération du PDF.")

            if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
          })
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        window.open(link)
      }
    } else {
      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      doAlert(
        "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p"
      )
    }

    typeof callback === "function" && callback()
  }
  const goToDetails = activite => {
    window.location.hash = `${page}_details|${indexVisite}|${activite}`
  }
  const returnActivityCard = codeActivite => {
    let diffValues = {}
    let nbDiffValues = 0
    let postesOb = []
    let titleGraph = ""

    for (let num in activites.postes[codeActivite]) {
      let obFormule = activites.postes[codeActivite][num].obligatoireFormule

      if (obFormule === "O") {
        postesOb.push(num)
        if (titleGraph === "")
          titleGraph = activites.postes[codeActivite][num].libelleLong
        else
          titleGraph += " - " + activites.postes[codeActivite][num].libelleLong
      }
    }

    if (postesOb.length !== 0) {
      for (let numAnimal in data.postes[codeActivite]) {
        let values = data.postes[codeActivite][numAnimal]

        let concateMandatory = ""
        for (let indiceChamp in values) {
          if (!postesOb.includes(indiceChamp)) continue

          let value = values[indiceChamp]

          if (concateMandatory !== "") concateMandatory += "-"
          concateMandatory += value.valeur.trim()
        }

        if (diffValues[concateMandatory] === undefined) {
          diffValues[concateMandatory] = 1
          nbDiffValues = nbDiffValues + 1
        } else diffValues[concateMandatory] = diffValues[concateMandatory] + 1
      }
    } else {
      for (let numAnimal in data.postes[codeActivite]) {
        let values = data.postes[codeActivite][numAnimal]

        let concateMandatory = ""
        for (let indiceChamp in values) {
          let value = values[indiceChamp]
          let posteParam = activites.postes?.[codeActivite]?.[indiceChamp]

          if (posteParam && posteParam.obligatoire === "O") {
            if (concateMandatory !== "") concateMandatory += "-"
            concateMandatory += value.valeur.trim()
          }
        }

        if (diffValues[concateMandatory] === undefined) {
          diffValues[concateMandatory] = 1
          nbDiffValues = nbDiffValues + 1
        } else diffValues[concateMandatory] = diffValues[concateMandatory] + 1
      }
    }

    let canvaDatas = []
    let totalAnimals = Object.keys(data.postes[codeActivite]).length
    for (let label in diffValues) {
      let count = diffValues[label]

      let tmpObj = {
        label: label,
        count: count,
        percent: ((count * 100) / totalAnimals).toFixed(0),
      }

      canvaDatas.push(tmpObj)
    }

    let activite
    let indexActivite
    activites.activites.forEach((acti, index) => {
      if (acti.codeActivite === codeActivite) {
        indexActivite = index
        activite = acti
      }
    })

    let showGraph = false
    let showPDF = false

    if (nbDiffValues > 0 && nbDiffValues < 5) showGraph = true

    let isLabel = false
    canvaDatas.map(row => row.label && (isLabel = true))
    if (!isLabel) showGraph = false

    if (
      codeActivite === "PTGE_A" ||
      codeActivite === "PTGE_S" ||
      codeActivite === "CERTIF" ||
      codeActivite === "TRI" ||
      codeActivite === "QUALIF" ||
      codeActivite === "QUALIF2" ||
      codeActivite === "DG" ||
      codeActivite === "PELV2" ||
      codeActivite === "DGLOC"
    )
      showPDF = true

    const pieOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: "right",
          labels: {
            boxWidth: 25,
            boxHeight: 15,
          },
        },
      },
    }

    const pieData = {
      labels: canvaDatas.map(row => row.label),
      datasets: [
        {
          label: "Acquisitions by year",
          data: canvaDatas.map(row => row.count),
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
            "#ffabf2",
            "#00b4e3",
          ],
          hoverOffset: 4,
        },
      ],
    }

    const onClickPdfLink = async e => {
      e.target.disabled = true

      e.preventDefault()

      if (codeActivite === "PTGE_A" || codeActivite === "PTGE_S") {
        window.location.hash = `${page}_recap|${indexVisite}|liste|${indexActivite}`
      } else {
        let pdfToDl

        switch (codeActivite) {
          case "DGLOC":
            pdfToDl = "f_sabots"
            break
          case "CERTIF":
            pdfToDl = "f_ins"
            break
          case "QUALIF":
            pdfToDl = "exam_qualif"
            break
          case "QUALIF2":
            pdfToDl = "exam_qualif2"
            break
          case "TRI":
            pdfToDl = "tri_hbl"
            break
          case "PELV2":
            pdfToDl = "f_op"
            break
          case "DG":
            pdfToDl = "f_pm"
            break

          default:
            break
        }

        let date = data.dateVisite.substr(0, 10)

        let cSplit = campagne.split("/")

        let ts = Math.round(Date.now() / 1000)
        let name = `${pdfToDl}_${numCheptel}_${date}_${ts}`
        let link = `https://espacepro.limousine.org/espace-pro/hbl/pdf/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${pdfToDl}/${data.idVisite}/${name}.pdf`

        getPdf(link, name)
      }

      e.target.disabled = false
    }

    return (
      <div className="activity_card" key={codeActivite}>
        <h3 className="name_activity">{activite.nom}</h3>
        <p className={!showGraph ? "animaux_vu_no_graph" : ""}>
          {totalAnimals} {totalAnimals === 1 ? "animal vu" : "animaux vus"}
        </p>
        {showGraph && (
          <>
            <div className="div_title_graph">{titleGraph}</div>
            <div className="div_graph">
              <div className="div_canvas">
                <Doughnut data={pieData} options={pieOptions} />
              </div>
              <div className="div_labels">
                {canvaDatas.map((row, index) => (
                  <div className="div_label" key={index}>
                    <span className={`span_color color${index + 1}`}></span>
                    <p className="legend_text">
                      {row.label + " (" + row.percent + "%)"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="div_buttons_card">
          {showPDF && (
            <button className="button_pdf pointer" onClick={onClickPdfLink}>
              Rapport PDF
            </button>
          )}
          <button
            className="button_details pointer"
            onClick={() => goToDetails(codeActivite)}
          >
            Détails
          </button>
        </div>
      </div>
    )
  }
  const dlAllPdf = callback => {
    let listeToSend = JSON.stringify(listePdfs)

    let date = data.dateVisite.substr(0, 10)

    let cSplit = campagne.split("/")

    let ts = Math.round(Date.now() / 1000)
    let name = `Rapport_complet_${numCheptel}_${date}_${ts}`
    let link = `https://espacepro.limousine.org/espace-pro/hbl/recap_pdf/${cSplit[0]}/${cSplit[1]}/${numCheptel}/${listeToSend}/${data.idVisite}/${name}.pdf`

    getPdf(link, name, callback)
  }
  const allPdfs = e => {
    e.target.disabled = true

    e.preventDefault()

    if (yaPtgeA || yaPtgeS) {
      if (yaPtgeA && yaPtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|askPere|${indexPtgeA}|O|N|N`
      else if (yaPtgeA && !yaPtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|askPere|${indexPtgeA}|N|N|N`
      else if (!yaPtgeA && yaPtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|askPere|${indexPtgeS}|N|N|N`
    } else {
      dlAllPdf()
    }

    e.target.disabled = false
  }
  const noToAskPeres = () => {
    let codeActivite = activites.activites[indexActiviteListe].codeActivite

    if (codeActivite === "PTGE_A") perePtgeA = false
    if (codeActivite === "PTGE_S") perePtgeS = false

    if (askForS)
      window.location.hash = `${page}_recap|${indexVisite}|askPere|${indexPtgeS}|N|N|N`
    else {
      if (!perePtgeA && !perePtgeS) {
        dlAllPdf(() => {
          if (yaPtgeA && yaPtgeS) goBackXAmount(2)
          else goBack()
        })
      } else if (perePtgeA && !perePtgeS) {
        window.location.hash = `${page}_recap|${indexVisite}|peres|${indexPtgeA}|N|O|N`
      }
    }
  }
  const yesToAskPeres = () => {
    let codeActivite = activites.activites[indexActiviteListe].codeActivite

    if (codeActivite === "PTGE_A") perePtgeA = true
    if (codeActivite === "PTGE_S") perePtgeS = true

    if (askForS)
      window.location.hash = `${page}_recap|${indexVisite}|askPere|${indexPtgeS}|N|O|N`
    else {
      if (!perePtgeA && perePtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|peres|${indexPtgeS}|N|N|O`
      else if (perePtgeA && perePtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|peres|${indexPtgeA}|N|O|O`
      else if (perePtgeA && !perePtgeS)
        window.location.hash = `${page}_recap|${indexVisite}|peres|${indexPtgeA}|N|O|N`
    }
  }

  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_dlmydocs" message="Téléchargement en cours..." />
      <div className="div_raison_sociale">
        <p className="modal_visit_name">{`${data?.prenomPointeur ?? ""} ${
          data?.nomPointeur ?? ""
        }`}</p>
        <p className="modal_visit_name">
          {`${getDateWritten(dateInp)} `}
          {hVisite > 12 ? "après-midi" : "matin"}
        </p>
        <button className="button_pdf rapport_all pointer" onClick={allPdfs}>
          Rapport complet
        </button>
      </div>
      <div className="div_activites_cards" ref={divCards}>
        {Object.keys(data.postes).map(key => returnActivityCard(key))}
      </div>
      {(paramsListe === "liste" || paramsListe === "peres") && (
        <ModalListePdf
          codeActivite={activites.activites[indexActiviteListe].codeActivite}
          nomActivite={activites.activites[indexActiviteListe].nom}
          idVisite={data.idVisite}
          dateVisite={data.dateVisite}
          numCheptel={numCheptel}
          campagne={campagne}
          paramsListe={paramsListe}
          listePdfs={listePdfs}
          indexPtgeS={indexPtgeS}
          yaPtgeA={yaPtgeA}
          yaPtgeS={yaPtgeS}
          page={page}
        />
      )}
      {paramsListe === "askPere" && (
        <>
          <div className="modal_ask_comparaison" ref={divAskPere}>
            <h3 className="title_modal">
              {activites.activites[indexActiviteListe].codeActivite === "PTGE_A"
                ? "Pointages adultes"
                : activites.activites[indexActiviteListe].codeActivite ===
                  "PTGE_S"
                ? "Pointages sevrages"
                : ""}
            </h3>
            <p className="text_modal">
              Voulez-vous ajouter une comparaison des pères au niveau des{" "}
              {activites.activites[indexActiviteListe].codeActivite === "PTGE_A"
                ? "pointages adultes"
                : activites.activites[indexActiviteListe].codeActivite ===
                  "PTGE_S"
                ? "pointages sevrages"
                : ""}
              ?
            </p>
            <div className="div_butons_modal">
              <button
                className="button_yes ripple2 pointer"
                onClick={yesToAskPeres}
              >
                Oui
              </button>
              <button
                className="button_no ripple2 pointer"
                onClick={noToAskPeres}
              >
                Non
              </button>
            </div>
          </div>
          <button className={"background-modal"}></button>
        </>
      )}
    </>
  )
}

export default RecapVisite
