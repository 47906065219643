import "./ModalErreursConf.scss"
import { useRef } from "react"
import { useCenterModal } from "../../../hooks/useCenterModal"
import { goBack, goBackXAmount } from "../../../functions/handleNavigation"

const ModalErreursConf = ({ dataErreursConf, origin }) => {
  let erreurDejaInscrit = false

  // Center the modal
  const divModal = useRef()
  useCenterModal(divModal)

  const altGoBack = () => {
    if (origin === "animal") goBackXAmount(2)
    else goBack()
  }

  return (
    <>
      <div ref={divModal} className={"modal_erreursconf"}>
        <div className="div_modal_header">
          <span
            onClick={altGoBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
          <h3>{`${dataErreursConf.nom} - ${dataErreursConf.num}`}</h3>
        </div>

        <div className="modal-content">
          {(erreurDejaInscrit = false)}
          <ul>
            {dataErreursConf?.erreurs?.map((erreur, i) => {
              if (!erreurDejaInscrit) {
                return (
                  <li
                    className="erreur"
                    key={"div_erreur" + i}
                    dangerouslySetInnerHTML={{ __html: erreur[1] }}
                  ></li>
                )
              }
              if (erreur[0] === 1) erreurDejaInscrit = true
            })}
          </ul>
        </div>
      </div>
      <button className={"background-modal"} onClick={altGoBack}></button>
    </>
  )
}

export default ModalErreursConf
