import CheptelSectionTitle from "../CheptelSectionTitle"
import "./AnalyseGenetique.scss"
import { useState } from "react"

const AnalyseGenetique = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showanalgene === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showanalgene = !show
  }

  return (
    <>
      <CheptelSectionTitle
        title="Analyses génétiques"
        onClick={hideOrShow}
        show={show}
        id="analyse_gen_title"
      />
      {show && (
        <div className="analyses-genetiques" id="analyse_genetique">
          <table>
            <thead>
              <tr>
                <th>ADN</th>
                <th>Sans Cornes</th>
                <th>Culard</th>
                <th>Palais fendu</th>
                <th>
                  Protopor-
                  <br />
                  phyrie
                </th>
                <th>BLIND</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {[1, 2, 4, 5, 8, 9].includes(+animal.CODEUT) && (
                    <div
                      className={
                        [2, 9].includes(+animal.CODEUT)
                          ? "prelev_attente"
                          : "prelev_ok"
                      }
                    >
                      {+animal.TYPANA === 1 && "GS "}
                      {+animal.TYPANA === 2 && "µSat "}
                      {+animal.TYPANA === 3 && "SNP "}
                      {[1, 4, 5, 8].includes(+animal.CODEUT) && "utilisable"}
                      {+animal.CODEUT === 2 && "inutilisable"}
                      {+animal.CODEUT === 9 && "inconnu en bdnsat"}
                    </div>
                  )}
                </td>
                <td>
                  {animal.retyupsc === "+/+" && (
                    <div className="prelev_ok">Non porteur</div>
                  )}
                  {animal.retyupsc === "P/+" && (
                    <div className="prelev_attente">Porteur hétérozygote</div>
                  )}
                  {animal.retyupsc === "P/P" && (
                    <div className="prelev_attente">Porteur homozygote</div>
                  )}
                </td>
                <td>
                  {animal.retyupmh === "+/+" && (
                    <div className="prelev_ok">Non porteur</div>
                  )}
                  {animal.retyupmh === "MH/+" && (
                    <div className="prelev_attente">Porteur hétérozygote</div>
                  )}
                  {animal.retyupmh === "MH/MH" && (
                    <div className="prelev_attente">Porteur homozygote</div>
                  )}
                </td>
                <td>
                  {animal.retyuppaf !== "" ? (
                    <>
                      {animal.retyuppaf === "+/+" && (
                        <div className="prelev_ok">Non porteur</div>
                      )}
                      {animal.retyuppaf === "PAL/+" && (
                        <div className="prelev_attente">
                          Porteur hétérozygote
                        </div>
                      )}
                      {animal.retyuppaf === "PAL/PAL" && (
                        <div className="prelev_attente">Porteur homozygote</div>
                      )}
                    </>
                  ) : (
                    <>
                      {animal.RETYUP_HPF == "0" && (
                        <div className="prelev_ok">Non porteur (hpf)</div>
                      )}
                      {animal.RETYUP_HPF == "1" && (
                        <div className="prelev_attente">
                          Porteur hétérozygote (hpf)
                        </div>
                      )}
                      {animal.RETYUP_HPF == "2" && (
                        <div className="prelev_attente">
                          Porteur homozygote (hpf)
                        </div>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {animal.retyuppro === "+/+" && (
                    <div className="prelev_ok">Non porteur</div>
                  )}
                  {animal.retyuppro === "PRO/+" && (
                    <div className="prelev_attente">Porteur hétérozygote</div>
                  )}
                  {animal.retyuppro === "PRO/PRO" && (
                    <div className="prelev_attente">Porteur homozygote</div>
                  )}
                </td>
                <td>
                  {animal.retyupbli === "+/+" && (
                    <div className="prelev_ok">Non porteur</div>
                  )}
                  {animal.retyupbli === "BLI/+" && (
                    <div className="prelev_attente">Porteur hétérozygote</div>
                  )}
                  {animal.retyupbli === "BLI/BLI" && (
                    <div className="prelev_attente">Porteur homozygote</div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default AnalyseGenetique
