import "./ModalShareFiche.scss"
import { useRef } from "react"
import { goBack } from "../../functions/handleNavigation"
import ModalLoader from "../ModalLoader"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./ModalShareFicheOverride.css"
import JSZip from "jszip"
import { blobToBase64 } from "../../functions/fetches/fetch"
import Alert from "../Alert"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import { fetchLogActionFiche } from "../../functions/fetches/fetchLogActionFiche"
import BigGreenButton from "../BigGreenButton"

const ModalShareFiche = ({
  blobCompleteFiche,
  blobSimpleFiche,
  blobGenealogieFiche,
  blobIndexFiche,
  numani,
  havePicture,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  // Center the modal
  const divModal = useRef()

  const indexCarrousel = useRef(+window.localStorage.indexCarrousel ?? 0)
  const divCarrousel = useRef()
  const imageCarrousel = useRef()

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const onLoadImage = () => {
    divCarrousel.current.style.width = imageCarrousel.current.offsetWidth + "px"

    if (!divModal.current) return

    let halfHeightScreen = window.innerHeight / 2
    let halfHeightModal = divModal.current.offsetHeight / 2
    let topHeight = halfHeightScreen - halfHeightModal

    divModal.current.style.top = `${topHeight}px`

    let halfWidthScreen = window.innerWidth / 2
    let halWidthModal = divModal.current.offsetWidth / 2
    let leftWidth = halfWidthScreen - halWidthModal

    divModal.current.style.left = `${leftWidth}px`
  }
  const downloadZip = async () => {
    let divModal = document.getElementById("loader_dl_fiche")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    fetchLogActionFiche("fiche_animal", "download", numani)

    const zip = new JSZip()

    zip.file(numani + "_complet.png", blobCompleteFiche, { binary: true })
    zip.file(numani + "_simple.png", blobSimpleFiche, { binary: true })
    zip.file(numani + "_genealogie.png", blobGenealogieFiche, { binary: true })
    zip.file(numani + "_index.png", blobIndexFiche, { binary: true })

    await zip.generateAsync({ type: "blob" }).then(blob => {
      if ("ReactNativeWebView" in window) {
        blobToBase64(blob).then(b64 => {
          let message = {
            type: "saveFile",
            content: {
              title: numani + ".zip",
              b64: b64,
              mime: "application/x-zip",
            },
          }

          let stringified = JSON.stringify(message)

          window.ReactNativeWebView.postMessage(stringified)

          if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        })
      } else {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = url
        // a.target = "_blank"
        a.download = numani + ".zip"
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again

        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
      }
    })
  }
  const getCorrectBlob = () => {
    let blob
    let nani

    switch (indexCarrousel.current) {
      case 0:
        blob = blobSimpleFiche
        nani = numani + "_simple"
        break
      case 1:
        blob = blobCompleteFiche
        nani = numani + "_complet"
        break
      case 2:
        blob = blobGenealogieFiche
        nani = numani + "_genealogie"
        break
      case 3:
        blob = blobIndexFiche
        nani = numani + "_index"
        break

      default:
        break
    }

    return [blob, nani]
  }
  const openImage = async index => {
    const [blob, title] = getCorrectBlob()

    if ("ReactNativeWebView" in window) {
      await Promise.all([
        blobToBase64(blobSimpleFiche),
        blobToBase64(blobCompleteFiche),
        blobToBase64(blobGenealogieFiche),
        blobToBase64(blobIndexFiche),
      ]).then(values => {
        let b64 = values[index]

        let message = {
          type: "ImageB64",
          content: {
            title: title,
            b64: b64,
          },
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      })
    } else {
      const blobUrl = URL.createObjectURL(blob)

      window.open(blobUrl, "_blank")
    }
  }
  const shareFiles = async () => {
    if ("ReactNativeWebView" in window) {
      fetchLogActionFiche("fiche_animal", "share", numani)

      await Promise.all([
        blobToBase64(blobCompleteFiche),
        blobToBase64(blobSimpleFiche),
        blobToBase64(blobGenealogieFiche),
        blobToBase64(blobIndexFiche),
      ]).then(values => {
        let message = {
          type: "shareFiles",
          urls: JSON.stringify(values),
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      })
    } else {
      doAlert(
        "La fonctionnalité de partage n'est disponible que sur l'application mobile de votre EspacePro."
      )
    }
  }
  const goAddPhoto = () => {
    goBack()

    document
      .getElementById("fiche_animal")
      ?.dispatchEvent(new CustomEvent("ficheAniCamera"))
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  //   if (!blob) {
  //     goBack()
  //     return
  //   }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader id="loader_dl_fiche" message="Téléchargement en cours..." />
      <ModalLoader id="loader_send_fiche" message="Partage en cours..." />

      <div ref={divModal} className="modal_share_fiche">
        <div className="div_modal_header">
          <h3>Partager votre fiche</h3>
          <span
            onClick={goBack}
            className="close_modal ripple2  material-symbols-outlined"
          >
            close
          </span>
        </div>
        <div className="modal_content">
          {!havePicture.current && (
            <div className="div_no_photo">
              <BigGreenButton
                text={"Ajouter une photo de l'animal"}
                onClick={goAddPhoto}
              />
            </div>
          )}
          <div
            className={
              havePicture.current
                ? "div_carrousel"
                : "div_carrousel div_carrousel_no_photo"
            }
            ref={divCarrousel}
          >
            <Carousel
              // centerMode={true}
              // centerSlidePercentage={100}
              // dynamicHeight={true}
              showStatus={false}
              showThumbs={false}
              selectedItem={+window.localStorage.indexCarrousel ?? 0}
              // selectedItem={3}
              onChange={index => {
                window.localStorage.indexCarrousel = index
                indexCarrousel.current = index
              }}
              onClickItem={index => openImage(index)}
            >
              <div className="div_image">
                <img
                  ref={imageCarrousel}
                  className="img_fiche_ani pointer"
                  src={blobSimpleFiche && URL.createObjectURL(blobSimpleFiche)}
                  onLoad={onLoadImage}
                />
              </div>
              <div className="div_image">
                <img
                  className="img_fiche_ani pointer"
                  src={
                    blobCompleteFiche && URL.createObjectURL(blobCompleteFiche)
                  }
                  onLoad={onLoadImage}
                />
              </div>
              <div className="div_image">
                <img
                  className="img_fiche_ani pointer"
                  src={
                    blobGenealogieFiche &&
                    URL.createObjectURL(blobGenealogieFiche)
                  }
                  onLoad={onLoadImage}
                />
              </div>
              <div className="div_image">
                <img
                  className="img_fiche_ani pointer"
                  src={blobIndexFiche && URL.createObjectURL(blobIndexFiche)}
                  onLoad={onLoadImage}
                />
              </div>
            </Carousel>
          </div>
          <div className="buttons_share">
            {"ReactNativeWebView" in window && (
              <button
                className="ico_share share pointer ripple2"
                onClick={shareFiles}
              >
                <span className="icone_btn material-symbols-outlined">
                  share
                </span>
              </button>
            )}
            <button
              className="ico_share dl pointer ripple2"
              onClick={downloadZip}
            >
              <span className="icone_btn material-symbols-outlined">
                download
              </span>
            </button>
          </div>
        </div>
      </div>

      <button className={"background-modal"} onClick={goBack}></button>
    </>
  )
}

export default ModalShareFiche
