import { useEffect, useRef, useState } from "react"
import Information from "../components/Information"
import Loader from "../components/Loader"
import "./Messagerie.scss"
import { updateMessages } from "../functions/updateMessages"
import localforage from "localforage"
import { fetchSetMessagesRead } from "../functions/fetches/fetchSetMessagesRead"

const Messagerie = () => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [messages, setMessages] = useState(null)

  const divMessages = useRef()

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    getMessages()
  }, [])
  useEffect(() => {
    if (messages) {
      let curr = divMessages.current

      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual =
          +window
            .getComputedStyle(curr, null)
            .getPropertyValue("margin-top")
            .split("px")[0] ?? 0

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        curr.style.marginTop = newHeight + "px"
        curr.style.height = `calc(calc(var(--vh) * 100) - ${newHeight}px)`
      }

      if (messages.length !== 0) {
        let messagesRead = []
        messages.forEach((m, i) => {
          if (m.read === "0") {
            messages[i].read = "1"
            messagesRead = [...messagesRead, +messages[i].id]
          }
        })

        if (messagesRead.length !== 0) fetchSetMessagesRead(messagesRead)

        localforage.setItem("messages", messages)

        let hdr = document.getElementById("header")

        const event = new CustomEvent("messagesRead")

        if (hdr) hdr.dispatchEvent(event)
      }
    }
  }, [messages])
  ///////////////
  // Functions //
  ///////////////
  const getMessages = async () => {
    let updMessages = await updateMessages()

    setMessages(updMessages)
  }

  return (
    <>
      {messages ? (
        <div className="overflow_container_messagerie" ref={divMessages}>
          <div className="container_messagerie">
            {messages.length !== 0 ? (
              <>
                {messages.map(m => (
                  <Information
                    message={m}
                    structure={+m.structure}
                    key={m.id}
                  />
                ))}
              </>
            ) : (
              <div>Vous n'avez reçu aucun message.</div>
            )}
          </div>
        </div>
      ) : (
        <div className="load_messages">
          <Loader loadingMessage="Chargement des messages en cours..." />
        </div>
      )}
    </>
  )
}

export default Messagerie
