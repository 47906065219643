import { useEffect, useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./EvalimStation.scss"

const EvalimStation = ({ data }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [showEvalim, setShowEvalim] = useState(
    localStorage.showEvalimFS ?? "true" === "true"
  )
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    localStorage.showEvalimFS = showEvalim
  }, [showEvalim])
  ///////////////
  // Functions //
  ///////////////

  return (
    <div className="evalim_station" id="wrapper_evalim_station">
      <CheptelSectionTitle
        title={"Evalim"}
        show={showEvalim}
        onClick={() => setShowEvalim(!showEvalim)}
        id="evalim_station_title"
      />
      {showEvalim && (
        <>
          {data?.Evalim_CIFNAI ||
          data?.Evalim_CCRSEV ||
          data?.Evalim_CDMSEV ||
          data?.Evalim_CDSSEV ||
          data?.Evalim_CFOSSE ||
          data?.Evalim_CAVELS ||
          data?.Evalim_CALAIT ||
          data?.Evalim_COPSEV ? (
            <div className="div_table" id="evalim_station">
              <table>
                <thead>
                  <tr>
                    <th>FN</th>
                    <th>CR</th>
                    <th>DM</th>
                    <th>DS</th>
                    <th>FOS</th>
                    <th>AV</th>
                    <th>AL</th>
                    <th>OP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.Evalim_CIFNAI}</td>
                    <td>{data?.Evalim_CCRSEV}</td>
                    <td>{data?.Evalim_CDMSEV}</td>
                    <td>{data?.Evalim_CDSSEV}</td>
                    <td>{data?.Evalim_CFOSSE}</td>
                    <td>{data?.Evalim_CAVELS}</td>
                    <td>{data?.Evalim_CALAIT}</td>
                    <td>{data?.Evalim_COPSEV}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div className="result_evalim_baseref">
                Base de référence: {data?.Evalim_BASREF}
              </div>
            </div>
          ) : (
            <div className="alert">Pas d'evalim connu</div>
          )}
        </>
      )}
    </div>
  )
}

export default EvalimStation
