import { fetchUrlEncoded } from "./fetch"

// Fetch for password reinitialization
export const fetchReinit = async (email, cheptel) => {
  let url = `${process.env.REACT_APP_API_URL}ajax_pwa.php`
  let action = "reinit_password"

  let params = {
    a: action,
    e: email,
    c: cheptel,
  }

  const formBody = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  return fetchUrlEncoded({
    url: url,
    formBody: formBody,
  })
}
