import "./PerfStation.scss"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import CheptelSectionTitle from "../CheptelSectionTitle"
import { useEffect, useState } from "react"
import { formatDate2 } from "../../functions/formatDate"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
)

const PerfStation = ({ data, moyennes, graphPerf }) => {
  console.log(data)

  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [showPerf, setShowPerf] = useState(
    window.localStorage.showPerfFS ?? "true" === "true"
  )

  const optionsGMQ = {
    responsive: true,
    spanGaps: true,
    plugins: {
      title: {
        display: false,
        text: "GMQ",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        // ticks: {
        //   display: false,
        // },
        title: {
          display: false,
          text: "Date",
        },
      },
      y: {
        title: {
          display: false,
          text: "Poids",
        },
      },
    },
  }

  const optionsGMQ2 = {
    responsive: true,
    spanGaps: true,
    plugins: {
      title: {
        display: false,
        text: "GMQ",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        title: {
          display: false,
          text: "Date",
        },
      },
      y: {
        title: {
          display: false,
          text: "Poids",
        },
      },
    },
  }

  let labels = []
  let dataGraph = []
  let codeStation = data.costat
  let campagne = data.caenst
  let serie = data.nuseri
  let lab
  let dataMoy = []
  let moyenneSerie = moyennes?.[codeStation]?.[campagne]?.[serie]

  // Poid arrivé
  if (data.povipe_PA || moyenneSerie?.PA?.moyenne) {
    if (data.povipe_PA) labels.push(formatDate2(data.dapevi_PA))
    else if (moyenneSerie?.PA?.moyenne_PA) {
      labels.push(formatDate2(moyenneSerie?.PA?.date))
    }

    if (data.povipe_PA) dataGraph.push(data.povipe_PA)
    else dataGraph.push(null)

    if (moyenneSerie?.PA?.moyenne) dataMoy.push(moyenneSerie?.PA?.moyenne)
    else dataMoy.push(null)
  }
  // Poid DD
  if (data.povipe_DD || moyenneSerie?.DD?.moyenne) {
    if (data.povipe_DD) labels.push(formatDate2(data.dapevi_DD))
    else if (moyenneSerie?.DD?.moyenne)
      labels.push(formatDate2(moyenneSerie?.DD?.date))

    if (data.povipe_DD) dataGraph.push(data.povipe_DD)
    else dataGraph.push(null)

    if (moyenneSerie?.DD?.moyenne) dataMoy.push(moyenneSerie?.DD?.moyenne)
    else dataMoy.push(null)
  }
  // Poid P1
  if (data.povipe_P1 || moyenneSerie?.P1?.moyenne) {
    if (data.povipe_P1) labels.push(formatDate2(data.dapevi_P1))
    else if (moyenneSerie?.P1?.moyenne)
      labels.push(formatDate2(moyenneSerie?.P1?.date))

    if (data.povipe_P1) dataGraph.push(data.povipe_P1)
    else dataGraph.push(null)

    if (moyenneSerie?.P1?.moyenne) dataMoy.push(moyenneSerie?.P1?.moyenne)
    else dataMoy.push(null)
  }
  // Poid P2
  if (data.povipe_P2 || moyenneSerie?.P2?.moyenne) {
    if (data.povipe_P2) labels.push(formatDate2(data.dapevi_P2))
    else if (moyenneSerie?.P2?.moyenne)
      labels.push(formatDate2(moyenneSerie?.P2?.date))

    if (data.povipe_P2) dataGraph.push(data.povipe_P2)
    else dataGraph.push(null)

    if (moyenneSerie?.P2?.moyenne) dataMoy.push(moyenneSerie?.P2?.moyenne)
    else dataMoy.push(null)
  }
  // Poid DF
  if (data.povipe_DF || moyenneSerie?.DF?.moyenne) {
    if (data.povipe_DF) labels.push(formatDate2(data.dapevi_DF))
    else if (moyenneSerie?.DF?.moyenne)
      labels.push(formatDate2(moyenneSerie?.DF?.date))

    if (data.povipe_DF) dataGraph.push(data.povipe_DF)
    else dataGraph.push(null)

    if (moyenneSerie?.DF?.moyenne) dataMoy.push(moyenneSerie?.DF?.moyenne)
    else dataMoy.push(null)
  }

  const dataGMQ = {
    labels: labels,
    datasets: [
      {
        label: "Poids",
        backgroundColor: "rgb(211 232 247 / 50%)",
        borderColor: "#4CABEC",
        data: dataGraph,
        fill: {
          target: "origin",
        },
        tension: 0.4,
      },
      {
        label: "Moyenne série",
        backgroundColor: "#979595",
        borderColor: "#979595",
        data: dataMoy,
        tension: 0.4,
      },
    ],
  }
  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    window.localStorage.showPerfFS = showPerf
  }, [showPerf])
  ///////////////
  // Functions //
  ///////////////

  return (
    <div className="perf_station" id="wrapper_perf_station">
      <CheptelSectionTitle
        title={"Performances en station"}
        show={showPerf}
        onClick={() => setShowPerf(!showPerf)}
        id="perf_station_title"
      />
      <div className="graph_for_ref">
        <Line options={optionsGMQ2} data={dataGMQ} ref={graphPerf} />
      </div>
      {showPerf && (
        <>
          {data?.povipe_PA ? (
            data?.povipe_DF || data?.costat !== "191" ? (
              <div className="graph_gmq" id="perf_station">
                <Line options={optionsGMQ} data={dataGMQ} />
                <div className="line_billes">
                  <div className="bille_gmq">
                    {data.GMQ ? (
                      <>GMQ : {Math.floor(data.GMQ * 1000, 2)} g/j</>
                    ) : (
                      "Animal"
                    )}
                    {data?.isRealGMQ === false && "*"}
                  </div>
                  <div className="bille_serie">
                    {moyenneSerie?.GMQ ? (
                      <>Serie : {Math.floor(moyenneSerie?.GMQ * 1000, 2)} g/j</>
                    ) : (
                      "Série"
                    )}
                    {moyenneSerie?.isRealGMQ === false && "*"}
                  </div>
                </div>
                {(data?.isRealGMQ === false ||
                  moyenneSerie?.isRealGMQ === false) && (
                  <div className="legend_gmq_unofficial">
                    * GMQ non officiel
                  </div>
                )}
              </div>
            ) : (
              <div className="alert">
                Pour la station ABL, les performances en station ne sont
                disponibles qu'après la pesée de fin de contrôle.
              </div>
            )
          ) : (
            <div className="alert">Pas de performances connues</div>
          )}
        </>
      )}
    </div>
  )
}

export default PerfStation
