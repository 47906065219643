import { useEffect, useRef, useState } from "react"
import Header from "../layouts/Header"
import Page from "../layouts/Page"
import MentionsLegales from "../pages/MentionsLegales"
import "./DefaultTemplate.scss"
import Login from "../pages/Login"
import Admin from "../pages/Admin"
import LoaderDl from "../pages/LoaderDl"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import localforage from "localforage"
import Loader from "../components/Loader"
import { downloadData } from "../functions/downloadData"
import { todayDate } from "../functions/todayDate"
import { isValidDate } from "../functions/formatDate"
import NotificationUpdate from "../components/NotificationUpdate"
import FailDl from "../pages/FailDl"
import { objectIsEqual } from "../functions/objectIsEqual"
import Animal from "../pages/Animal"
import { fetchSaveCustInv } from "../functions/fetches/fetchSaveCustInv"
import { fetchSaveOrdInv } from "../functions/fetches/fetchSaveOrdInv"
import { fetchAppParameters } from "../functions/fetches/fetchAppParameters"
import { fetchLogError } from "../functions/fetches/fetchLogError"
import { fetchLogHighScore } from "../functions/fetches/fetchLogHighScore"
import PageGame from "../pages/PageGame"
import Messagerie from "../pages/Messagerie"
import { goBack } from "../functions/handleNavigation"
import { checkAppParamsIntegrity } from "../functions/checkAppParamsIntegrity"
import DeleteAccount from "../pages/DeleteAccount"
import ReglesConfidentialites from "../pages/ReglesConfidentialites"
import Op from "../pages/Op"
import OutilsOP from "../pages/OutilsOP"

let downloading = false
let downloadingCheptel = ""
let tmpNewData = null
let controller = new AbortController()
let signal = controller.signal

const DefaultTemplate = ({ page }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const divStatutBar = useRef()
  const div = useRef()
  const [alert, hideAlert, messageAlert, doAlert, setCallback] =
    useCustomAlert()

  const [classNotif, setClassNotif] = useState("hide")
  const [dataCalculated, setDataCalculated] = useState(null)
  const [adminAnimals, setAdminAnimals] = useState(null)
  const [dataSuffix, setDataSuffix] = useState(
    window.localStorage.dataSuffix ?? ""
  )
  const [isDownloading, setIsDownloading] = useState(false)

  let pageOption = null

  if (page && page.includes("_")) {
    pageOption = page.split("_")[1]
    page = page.split("_")[0]
  }

  let valA = window.localStorage.valA === "true" ? true : false
  let valO = window.localStorage.valO === "true" ? true : false

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let statutBar = divStatutBar.current

      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0

      statutBar.style.height = addHeight + "px"
    }

    let curr = div.current
    let interval

    const dAlert = e => {
      setCallback(null)
      doAlert(e.detail.message)
    }
    const goRefresh = () => refreshData()
    const goDate = e => changeDate(e.detail.date)
    const loadChep = async () => {
      let numCheptel = window.localStorage.numCheptel ?? ""
      if (!numCheptel) return
      let lastUpdate = window.localStorage.getItem(
        "dateOfLatestData" + numCheptel
      )

      await localforage
        .getItem("dataCalculated" + dataSuffix + numCheptel)
        .then(value => {
          if (value) {
            setDataCalculated(value)
            window.location.hash = "cheptel"
          } else window.location.hash = "loadata"
        })
    }
    const setNewData = e => {
      if (window.localStorage.numCheptel)
        setDataCalculated(e.detail.dataCalculated)
      else setAdminAnimals(e.detail.dataCalculated)
    }
    const dataAfterGame = async e => goDlAfterGame(e.detail.data)
    const cancelDownload = () => {
      if (dataSuffix) {
        setDataSuffix("")
        window.location.hash = "cheptel"
      }

      downloadingCheptel = ""
      downloading = false
      setIsDownloading(false)
      controller.abort()

      controller = new AbortController()
      signal = controller.signal
    }
    const changeChep = e => {
      changeCheptel(e?.detail?.action)
    }

    curr.addEventListener("doAlert", dAlert)
    curr.addEventListener("refreshData", goRefresh)
    curr.addEventListener("changeDate", goDate)
    curr.addEventListener("loadData", loadChep)
    curr.addEventListener("dataAfterGame", dataAfterGame)
    curr.addEventListener("changeChep", changeChep)
    curr.addEventListener("setNewData", setNewData)
    curr.addEventListener("cancelDownload", cancelDownload)

    checkUpdate()
    interval = setInterval(checkUpdate, 5 * 60 * 1000)

    return () => {
      curr.removeEventListener("doAlert", dAlert)
      curr.removeEventListener("refreshData", goRefresh)
      curr.removeEventListener("changeDate", goDate)
      curr.removeEventListener("loadData", loadChep)
      curr.removeEventListener("dataAfterGame", dataAfterGame)
      curr.removeEventListener("changeChep", changeChep)
      curr.removeEventListener("setNewData", setNewData)
      curr.removeEventListener("cancelDownload", cancelDownload)

      clearInterval(interval)
    }
  }, [])
  useEffect(() => {
    page === "loadata" && (window.localStorage.dlRedirect = "true")

    if (!dataCalculated && page !== "loadata" && page !== "faildl") {
      loadData()
    }
    if (!adminAnimals && (window.localStorage.numCheptel ?? "") === "") {
      loadAdminAnimals()
    }
    if (!dataCalculated && page === "loadata") {
      if (
        !downloading ||
        (window.localStorage.numCheptel ?? "") !== downloadingCheptel
      )
        goDl()
    }
    if (dataCalculated && (page === "loadata" || page === "admin")) {
      window.location.hash = "cheptel"
    }
  }, [page])
  useEffect(() => {
    let numCheptel = window.localStorage.numCheptel ?? ""
    if (!numCheptel) return
    // dateStored is used to check if older dataSuffix and new one are different
    // Very likely can be done without
    let dateStored = window.localStorage.dateStored ?? ""

    // Only store one date per cheptel to not have too much in cache
    if (dateStored !== dataSuffix && dateStored !== "" && dataSuffix !== "")
      localforage.removeItem("dataCalculated" + dateStored + numCheptel)

    if (dataSuffix !== "") window.localStorage.dateStored = dataSuffix
    if (window.localStorage.dataSuffix !== dataSuffix) {
      window.localStorage.dataSuffix = dataSuffix
      window.localStorage.tsDataSuffix = Date.now()
    }

    if (dataCalculated) {
      setDataCalculated(null)
      loadData()
    }
  }, [dataSuffix])
  useEffect(() => {
    // it's extra code to fix certain cases the code in CustomInventaire cant handle
    let isNotifUpd = document
      .getElementById("div_topnotif_sw")
      .classList.contains("show")

    if (!isNotifUpd && classNotif === "hide") {
      let invFooter = document.getElementById("inventaire_footer")
      let invTable = document.getElementById("inventaire_table")

      invFooter?.classList.remove("diff_bottom")
      invTable?.classList.remove("diff_bottom")
    }

    let tableInv = document.getElementById("table-to-xlsx")
    if (tableInv) tableInv.dispatchEvent(new CustomEvent("footerPositioning"))
  }, [classNotif])

  ///////////////
  // Functions //
  ///////////////
  const loadData = async () => {
    let numCheptel = window.localStorage.numCheptel ?? ""
    if (!numCheptel) return

    let actualAppParams = JSON.parse(localStorage.appParams ?? "{}")

    // Check if appParams are here
    if (!checkAppParamsIntegrity(actualAppParams)) {
      let appParams = await fetchAppParameters()

      if (checkAppParamsIntegrity(appParams))
        localStorage.appParams = JSON.stringify(appParams ?? "{}")
      else {
        await fetchLogError("App param invalide", localStorage.appParams)
        setDataCalculated(null)
        window.location.hash = "loadata"
        return
      }
      window.location.reload()
    }

    await localforage
      .getItem("dataCalculated" + dataSuffix + numCheptel)
      .then(value => {
        if (value) setDataCalculated(value)
        else window.location.hash = "loadata"
      })
  }
  const loadAdminAnimals = async () => {
    await localforage.getItem("dataCalculated").then(value => {
      if (value) setAdminAnimals(value)
      else setAdminAnimals({})
    })
  }
  const goDl = async () => {
    let dateData = dataSuffix
    if (dataSuffix === todayDate()) {
      dateData = ""
      localStorage.dataSuffix = ""
    }

    let inBackGround = window.localStorage.dlRedirect === "false"
    let numCheptel = window.localStorage.numCheptel ?? ""
    downloading = true
    downloadingCheptel = numCheptel

    let newData = await downloadData(dateData, signal)

    window.localStorage.dlStartedByUser = "false"

    if (newData === "downloadAborted") {
      let isThereData = true
      let numCheptel = window.localStorage.numCheptel ?? ""
      // Get time of latest data update for this cheptel
      let lastUpdate = window.localStorage.getItem(
        "dateOfLatestData" + numCheptel
      )
      if (!lastUpdate) isThereData = false

      if (isThereData) {
        window.localStorage.dlRedirect = "false"
        window.location.hash = "cheptel"
      } else {
        if (valA) {
          window.localStorage.numCheptel = ""
          window.location.hash = "admin"
        }
      }

      return
    }

    // return
    if (newData === "gameInProgress") return
    if (inBackGround && numCheptel !== (window.localStorage.numCheptel ?? ""))
      return

    if (newData)
      await localforage
        .setItem("dataCalculated" + dateData + numCheptel, newData)
        .then(() => {
          if (window.localStorage.dlRedirect !== "false") {
            setDataCalculated(newData)
            window.location.hash = "cheptel"
          } else {
            // Go use localforage to avoid problem on first render
            // otherwise datacalculated state var is null
            localforage
              .getItem("dataCalculated" + dateData + numCheptel)
              .then(value => {
                if (value) {
                  if (!objectIsEqual(value, newData)) {
                    tmpNewData = newData
                    setClassNotif("show")
                  }
                }
              })
          }
        })
    else if (window.localStorage.dlRedirect !== "false")
      await localforage
        .getItem("dataCalculated" + dateData + numCheptel)
        .then(value => {
          if (value) {
            setDataCalculated(value)
            goBack()
          } else {
            if (dateData) {
              setDataSuffix("")
              window.location.hash = "cheptel"
            } else window.location.hash = "faildl"
          }
        })

    downloading = false
    setIsDownloading(false)
  }
  const goDlAfterGame = async newData => {
    let dateData = dataSuffix
    if (dataSuffix === todayDate()) {
      dateData = ""
      localStorage.dataSuffix = ""
    }

    let numCheptel = window.localStorage.numCheptel ?? ""

    await localforage
      .setItem("dataCalculated" + dateData + numCheptel, newData)
      .then(() => {
        if (window.localStorage.dlRedirect !== "false") {
          setDataCalculated(newData)
          window.location.hash = "cheptel"
        } else {
          // Go use localforage to avoid problem on first render
          // otherwise datacalculated state var is null
          localforage
            .getItem("dataCalculated" + dateData + numCheptel)
            .then(value => {
              if (value) {
                if (!objectIsEqual(value, newData)) {
                  tmpNewData = newData
                  setClassNotif("show")
                }
              }
            })
        }
      })

    downloading = false
    setIsDownloading(false)
  }
  const refreshData = () => {
    window.localStorage.dlRedirect = "false"
    window.localStorage.dlStartedByUser = "true"
    setIsDownloading(true)
    goDl()
  }
  const changeDate = date => {
    let numCheptel = window.localStorage.numCheptel ?? ""
    if (!numCheptel) return

    let dateOfLatestData =
      +localStorage.getItem("dateOfLatestData" + numCheptel) ?? null
    if (!dateOfLatestData) return

    let dateJour = todayDate("display", dateOfLatestData)

    if (dateOfLatestData)
      if (isValidDate(date) === false) {
        doAlert(
          "La date saisie n'est pas valide. Elle doit être sur cet exemple : 17-05-2018"
        )
        return false
      }

    for (let key in localStorage) {
      if (key.includes("caseCompare")) localStorage.removeItem(key)
    }

    if (date !== dateJour) setDataSuffix(date)
    else setDataSuffix("")
  }
  const checkUpdate = () => {
    let resendCustomInv = window.localStorage.resendCustomInv ?? "false"
    if (resendCustomInv === "true") {
      window.localStorage.resendCustomInv = false

      let customInv = window.localStorage.listCustInv ?? "{}"
      fetchSaveCustInv(customInv)
      let ordList = window.localStorage.orderedList ?? "{}"
      fetchSaveOrdInv(ordList)
    }

    let needSetHS = localStorage.needSendHS
    if (needSetHS === "true")
      fetchLogHighScore() && (localStorage.needSendHS = false)

    let numCheptel = window.localStorage.numCheptel ?? ""
    if (!numCheptel) return
    let lastUpdate = window.localStorage.getItem(
      "dateOfLatestData" + numCheptel
    )

    if (!lastUpdate) return

    let timeNow = Date.now()
    let diffTime = timeNow - lastUpdate

    if (diffTime > 24 * 60 * 60 * 1000 && !downloading) {
      window.localStorage.dlRedirect = "false"
      setIsDownloading(true)
      goDl()
    }
  }
  const onClickUpdateData = () => {
    setDataCalculated(tmpNewData)
    window.location.reload()
  }
  const changeCheptel = (action = "changeCheptel") => {
    window.localStorage.numCheptel = ""
    window.localStorage.dataSuffix = ""
    window.localStorage.listCouldntTry = "[]"
    window.localStorage.listCouldntTryDecl = "[]"
    window.localStorage.caseConfirm = "[]"
    window.localStorage.caseDeclar = "[]"
    window.localStorage.scrollXPageCheptel = 0
    window.localStorage.scrollYPageCheptel = 0

    if (valO && action === "changeCheptel")
      window.localStorage.needGoListAdhrents = "goListeAdhrents"

    for (let key in localStorage) {
      if (key.includes("caseCompare")) localStorage.removeItem(key)
    }

    setDataCalculated(null)
    setDataSuffix("")

    if (valA) window.location.hash = "admin"
    else if (valO) window.location.hash = "op"
  }

  ////////////////
  // EXTRA CODE //
  ////////////////
  let loadedObject
  switch (page) {
    case "supprcompte":
      loadedObject = <DeleteAccount pageOption={pageOption} />
      break
    case "reglesconf":
      loadedObject = <ReglesConfidentialites />
      break
    case "login":
      loadedObject = <Login pageOption={pageOption} />
      break
    case "mentions":
      loadedObject = <MentionsLegales />
      break
    case "admin":
      loadedObject = <Admin pageOption={pageOption} />
      break
    case "op":
      loadedObject = <OutilsOP pageOption={pageOption} />
      break
    case "adherentsop":
      loadedObject = <Op pageOption={pageOption} />
      break
    case "loadata":
      loadedObject = <LoaderDl />
      break
    case "faildl":
      loadedObject = <FailDl />
      break
    case "game":
      loadedObject = <PageGame pageOption={pageOption} />
      break
    case "messagerie":
      loadedObject = <Messagerie />
      break
    default:
      if (dataCalculated)
        loadedObject = (
          <Page
            page={page}
            pageOption={pageOption}
            dataCalculated={dataCalculated}
            downloading={isDownloading}
            cheptelName={
              dataCalculated?.account?.societe_nom
                ? dataCalculated?.account?.societe_nom
                : ""
            }
          />
        )
      else {
        loadedObject = (
          <div className="loading_template">
            <Loader loadingMessage="Chargement en cours.." />
          </div>
        )
      }
      break
  }

  if (page === "animal" && (window.localStorage.numCheptel ?? "") === "") {
    let numtrav = window.location.hash.split("|")[1]

    // CALCUL MARGIN TOP
    let marginTop = 60

    if (
      "ReactNativeWebView" in window &&
      window.localStorage.platformNative === "android"
    ) {
      let addHeight = window.localStorage.statutBarHeight
        ? +window.localStorage.statutBarHeight
        : 0

      marginTop += addHeight
    }

    let aniData = adminAnimals?.inventaire?.aniNotInInv?.[numtrav]

    let tsLastFetch = aniData?.tsFetch ?? null

    if (tsLastFetch) {
      let dateFetch = new Date(tsLastFetch)
      let dateNow = new Date()

      if (dateFetch.getFullYear() < dateNow.getFullYear()) aniData = null
      else if (dateFetch.getMonth() < dateNow.getMonth()) aniData = null
      else if (
        dateNow.getHours() >= 6 &&
        dateFetch.getDate() < dateNow.getDate()
      )
        aniData = null
      else if (
        dateNow.getHours() < 6 &&
        dateFetch.getHours() < 6 &&
        dateFetch.getDate() < dateNow.getDate()
      )
        aniData = null
      else if (
        dateNow.getHours() < 6 &&
        dateFetch.getHours() >= 6 &&
        dateFetch.getDate() < dateNow.getDate() - 1
      )
        aniData = null
    }

    loadedObject = (
      <div
        className="page"
        id="page"
        style={{
          marginTop: `${marginTop}px`,
        }}
      >
        <Animal aniData={aniData} key={numtrav} pageOption={pageOption} />
      </div>
    )
  }

  return (
    <div id="app" className="app" ref={div}>
      {"ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android" && (
          <div className="div_statut_bar" ref={divStatutBar}></div>
        )}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}

      <NotificationUpdate
        id="new_data"
        classNotif={classNotif}
        messageNotif="Nouvelles données disponibles"
        messageButton="Obtenir"
        onClick={onClickUpdateData}
      />

      <div id="content" className="content">
        {page !== "login" && page !== "loadata" && page !== "supprcompte" && (
          <Header
            page={page}
            pageOption={pageOption}
            downloading={isDownloading}
            name={
              dataCalculated?.account?.societe_nom
                ? dataCalculated?.account?.societe_nom
                : ""
            }
          />
        )}
        {loadedObject}
      </div>
    </div>
  )
}

export default DefaultTemplate
