import { fetchLogHighScore } from "../functions/fetches/fetchLogHighScore"

export default class Score {
  score = 0
  HIGH_SCORE_KEY = "highScore"
  MONTH_HIGH_SCORE_KEY = "monthHighScore"
  MONTH_KEY = "monthOfScore"

  constructor(ctx, scaleRatio) {
    this.ctx = ctx
    this.canvas = ctx.canvas
    this.scaleRatio = scaleRatio
  }

  update(frameTimeDelta) {
    this.score += frameTimeDelta * 0.01
  }

  reset() {
    this.score = 0
  }

  setHighScore() {
    const highScore = Number(localStorage.getItem(this.HIGH_SCORE_KEY))
    if (this.score > highScore) {
      localStorage.setItem(this.HIGH_SCORE_KEY, Math.floor(this.score))
      localStorage.dateHighScore = Date.now() / 1000
      localStorage.needSendHS = true
    }

    const monthOfHighScore = localStorage.getItem(this.MONTH_KEY)

    let today = new Date()
    let month = today.getMonth() + 1
    if (month < 10) month = "0" + month
    let thisMonth = `${today.getFullYear()}${month}`

    const monthHighScore = Number(
      localStorage.getItem(this.MONTH_HIGH_SCORE_KEY)
    )
    if (monthOfHighScore === thisMonth) {
      if (this.score > monthHighScore) {
        localStorage.setItem(this.MONTH_HIGH_SCORE_KEY, Math.floor(this.score))
        localStorage.dateMonthHighScore = Date.now() / 1000
        localStorage.needSendHS = true
      }
    } else {
      localStorage.setItem(this.MONTH_HIGH_SCORE_KEY, Math.floor(this.score))
      localStorage.setItem(this.MONTH_KEY, thisMonth)
      localStorage.needSendHS = true
    }

    if (localStorage.needSendHS)
      fetchLogHighScore() && (localStorage.needSendHS = false)
  }

  draw() {
    const monthOfHighScore = localStorage.getItem(this.MONTH_KEY)

    let today = new Date()
    let month = today.getMonth() + 1
    if (month < 10) month = "0" + month
    let thisMonth = `${today.getFullYear()}${month}`

    const highScore =
      monthOfHighScore === thisMonth
        ? Number(localStorage.getItem(this.MONTH_HIGH_SCORE_KEY))
        : 0

    const y = 20 * this.scaleRatio

    const fontSize = 20 * this.scaleRatio
    this.ctx.font = `${fontSize}px serif`
    this.ctx.fillStyle = "#525250"
    const scoreX = this.canvas.width - 75 * this.scaleRatio
    const highScoreX = scoreX - 125 * this.scaleRatio

    const scorePadded = Math.floor(this.score).toString().padStart(6, 0)
    const highScorePadded = highScore.toString().padStart(6, 0)

    this.ctx.fillText(scorePadded, scoreX, y)
    this.ctx.fillText(`HI ${highScorePadded}`, highScoreX, y)
  }
}
