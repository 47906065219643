import localforage from "localforage"
import { fetchAdmChep } from "./fetches/fetchAdmChep"
import { fetchAccountData } from "./fetches/fetchAccountData"
import { fetchGetCustomInv } from "./fetches/fetchGetCustomInv"
import { fetchGetOrderInv } from "./fetches/fetchGetOrderInv"
import { fetchAppParameters } from "./fetches/fetchAppParameters"
import { fetchHighScore } from "./fetches/fetchHighScore"
import { checkAppParamsIntegrity } from "./checkAppParamsIntegrity"
import { fetchDataCheptel } from "./fetches/fetchDataCheptel"
import { calculateData } from "./calculateData"
import { changeDateData } from "./fetches/changeDateData"

export const downloadData = async (date = "", signal = null) => {
  window.localStorage.onGoingDlStep = ""

  let account
  let highScores
  let custInv
  let ordInv
  let appParams
  let dataCheptel

  try {
    // Go tell native wrapper to enable wakelock
    if ("ReactNativeWebView" in window) {
      let message = {
        type: "keepAwake",
        action: "on",
      }

      let stringified = JSON.stringify(message)

      window.ReactNativeWebView.postMessage(stringified)
    }

    let valA = window.localStorage.valA === "true" ? true : false
    let valO = window.localStorage.valO === "true" ? true : false
    let numCheptel = window.localStorage.numCheptel ?? ""

    // Admin set cheptel
    if (valA || valO) {
      let ldlCheptel = localStorage.ldlCheptel ?? ""
      if (ldlCheptel) ldlCheptel = JSON.parse(ldlCheptel)
      else ldlCheptel = []

      let retAdmnChep = await fetchAdmChep(numCheptel, signal)

      if (retAdmnChep.token) {
        window.localStorage.JWT_TOKEN = retAdmnChep.token
      } else {
        sendAlert("Une erreur est survenue lors du téléchargement.")
        return false
      }

      // Avoid adding same cheptel twice in list of loaded cheptel
      // We remove to add again to have it be last in list
      // The goal is to always delete the oldest DL
      if (ldlCheptel.includes(numCheptel))
        ldlCheptel = ldlCheptel.filter(chep => chep !== numCheptel)

      ldlCheptel.push(numCheptel)

      // if more than 10 cheptel, delete one
      if (ldlCheptel.length > 10) {
        let ncf = ldlCheptel.shift() // Delete first one as it is the oldest

        await localforage.keys().then(async keys => {
          for (let indexKey in keys) {
            if (keys[indexKey].includes(ncf))
              await localforage.removeItem(keys[indexKey])
          }
        })
      }

      localStorage.ldlCheptel = JSON.stringify(ldlCheptel)
    }

    if (date !== "") {
      let retChangeDate = await changeDateData(date, signal)

      if (retChangeDate.token) {
        window.localStorage.JWT_TOKEN = retChangeDate.token
      } else {
        sendAlert("Une erreur est survenue lors du téléchargement.")
        return false
      }
    }

    let listePromise = [
      fetchHighScore(signal),
      fetchAccountData(signal),
      fetchGetCustomInv(signal),
      fetchGetOrderInv(signal),
      fetchAppParameters(signal),
      fetchDataCheptel(signal),
    ]

    let errorInPromise = false

    await Promise.all(listePromise)
      .then(values => {
        highScores = values[0]
        account = values[1]
        custInv = values[2]
        ordInv = values[3]
        appParams = values[4]
        dataCheptel = values[5]

        if (highScores === "downloadAborted") errorInPromise = "downloadAborted"
        if (account === "downloadAborted") errorInPromise = "downloadAborted"
        if (custInv === "downloadAborted") errorInPromise = "downloadAborted"
        if (ordInv === "downloadAborted") errorInPromise = "downloadAborted"
        if (appParams === "downloadAborted") errorInPromise = "downloadAborted"
        if (dataCheptel === "downloadAborted")
          errorInPromise = "downloadAborted"

        // HighScores
        window.localStorage.highScore = highScores.h
        window.localStorage.monthHighScore = highScores.mh
        window.localStorage.monthOfScore = highScores.mois

        // custInv
        if (typeof custInv !== "object") custInv = {}
        window.localStorage.listCustInv = JSON.stringify(custInv ?? "{}")

        // ordInv
        if (!Array.isArray(ordInv)) ordInv = []
        window.localStorage.orderedList = JSON.stringify(ordInv ?? "[]")

        // appParams
        if (checkAppParamsIntegrity(appParams))
          localStorage.appParams = JSON.stringify(appParams)
        else errorInPromise = true

        console.log(dataCheptel)
      })
      .catch(error => {
        // Handle any errors
        errorInPromise = true
      })

    if (errorInPromise === "downloadAborted") return "downloadAborted"

    if (errorInPromise) {
      sendAlert("Une erreur est survenue lors du téléchargement.")

      return false
    }
    if (!dataCheptel.animaux || dataCheptel.animaux === null) {
      sendAlert("Il n'y a pas d'animaux dans ce cheptel.")

      return false
    }

    let dataCalculated = calculateData(dataCheptel, date)

    dataCalculated.account = account

    console.log(dataCalculated)

    // Set timestamp of last data update
    if (date === "") {
      localStorage.setItem("dateOfLatestData" + numCheptel, Date.now())
    }

    // Go tell Native wrapper it can disable wakelock
    if ("ReactNativeWebView" in window) {
      let message = {
        type: "keepAwake",
        action: "off",
      }

      let stringified = JSON.stringify(message)

      window.ReactNativeWebView.postMessage(stringified)
    }

    return dataCalculated
  } catch (error) {
    console.log(error)
  }
}

const sendAlert = message => {
  if (
    window.localStorage.dlRedirect !== "false" ||
    window.localStorage.dlStartedByUser === "true"
  )
    document.getElementById("app")?.dispatchEvent(
      new CustomEvent("doAlert", {
        detail: {
          message: message,
        },
      })
    )
}
